<template>
  <div class="report-template">
    <Watermark :isSampleReport="isSampleReport" />

    <v-card class="report-page-card" outlined flat>
      <div class="section">
        <div class="section-title">
          <div class="section-badge"></div>
          Data Quality
        </div>
      </div>

      <div class="section">
        <div class="section-content">
          The pie charts below indicate that {{ getMembersAnalysed }}% of the data set ({{ data.totalMemberCount }}
          members) received was analysed and scored,
          {{ getOutlierShorContib }}% ({{ data.outlierGroups['Short Contribution History'] || '0' }}
          members) had contribution history of less than 12 months and hence were not scored, while
          {{ getOutlierPercent }}% ({{ outlierCount }} members) were deemed outliers. The outliers are then segregated
          into various categories to determine the underlying reasons behind the suspect data. It is recommended to
          investigate the outlier profiles individually for data cleansing purposes.
        </div>
      </div>

      <div v-if="data && data.outliers && !fetchingOutliers" class="report-chart">
        <OutlierChart
          :data="data"
          :memberAnalysedPercent="getMembersAnalysed"
          :outlierPercent="getOutlierPercent"
          :providerId="providerId"
          :isSampleReport="isSampleReport"
        />
        <OutlierSectionsChart :data="data" :providerId="providerId" :isSampleReport="isSampleReport" />
      </div>

      <!-- <div class="outliers-table">
        <ReportTable v-if="data && data.outliers" :outliers="data.outliers" />
      </div> -->
      <!-- {{outliers}} -->
      <div class="footer">
        <div></div>
        <!-- <div class="page-box">
          <div class="pageno">Page 3/4</div>
        </div> -->
        <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
      </div>
    </v-card>
  </div>
</template>
<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { FETCH_MEMBERS, FETCH_CONTRIBUTION_HISTORY } from '@/modules/members/store/actionTypes';
import ReportTable from '@/components/ReportTable.vue';
import OutlierChart from '@/components/charts/OutlierPieChart.vue';
import OutlierSectionsChart from '@/components/charts/OutlierSectionsChart.vue';
import Utils from '../../../../utils/Utils';
import Watermark from './Watermark.vue';

@Options({
  components: {
    ReportTable,
    OutlierChart,
    OutlierSectionsChart,
    Watermark
  }
})
export default class Outliers extends Vue {
  created() {
    this.bootstrap();
  }

  @Prop()
  public data?: any;

  @Prop()
  public providerName?: string;

  @Prop()
  public providerId?: number;

  @Prop() isSampleReport!: boolean;

  get moduleIsReady() {
    return true;
  }

  bootstrap() {
    //
  }

  public get outlierCount() {
    const shortContribCount = this.data.outlierGroups['Short Contribution History'] || 0;
    return this.data.totalOutlier - shortContribCount;
  }

  public get getOutlierPercent() {
    return ((this.outlierCount * 100) / (this.data.totalMemberCount + this.data.totalOutlier)).toFixed(1);
  }

  public get getOutlierShorContib() {
    return (
      (this.data.outlierGroups['Short Contribution History'] * 100 || 0) /
      (this.data.totalMemberCount + this.data.totalOutlier)
    ).toFixed(1);
  }

  public get getMembersAnalysed() {
    return ((this.data.totalMemberCount * 100) / (this.data.totalMemberCount + this.data.totalOutlier)).toFixed(1);
  }

  public get fetchingOutliers() {
    return this.$store.getters.fetchingOutliers;
  }
}
</script>

<style lang="scss">
.outliers-table {
  max-width: 80%;
  width: 100%;
  margin: auto;
}
</style>
