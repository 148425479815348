import * as MT from '@/modules/imports/store/mutationTypes';
import { IImportState } from '@/modules/imports/store/state';
import { MutationTree } from 'vuex';
import Import from '@/models/Import';

/*--------------------------------------------------/
 * Import Mutations
 *--------------------------------------------------*/
export const ImportMutations: IImportMutations = {
  [MT.SET_IMPORTS](state: IImportState, imports: Import[]) {
    state.imports = imports;
  },
  [MT.SET_FETCHING_IMPORTS](state: IImportState, value: boolean) {
    state.fetchingImports = value;
  }
};

/*--------------------------------------------------/
 * Import Mutations Tree Interface
 *--------------------------------------------------*/
export interface IImportMutations extends MutationTree<IImportState> {}
