import User from '@/models/User';

export interface IAuthState {
  // Log in
  loggingIn: boolean;
  loggingInError: boolean | object;
  // Current User
  currentUser: User | boolean;
  fetchingCurrentUser: boolean;
  fetchCurrentUserError: boolean | object;

  // Session Timeout
  timeout: boolean;
  //Reset Password
  isChangingPassword: boolean;
  changePasswordError: boolean | object | string;
}

/**
 * --------------------------------------------------
 *	Auth State
 * --------------------------------------------------
 **/

export const AuthState: IAuthState = {
  currentUser: false,
  // Log in
  loggingIn: false,
  loggingInError: false,
  // Current User
  fetchingCurrentUser: false,
  fetchCurrentUserError: false,

  // Session Timeout
  timeout: false,
  // Reset password
  isChangingPassword: false,
  changePasswordError: false,
};
