import * as AT from '@/modules/price-track/store/actionTypes';
import * as MT from '@/modules/price-track/store/mutationTypes';
import { IRootState } from '@/store/state';
import { IPriceTrackState } from '@/modules/price-track/store/state';
import { ActionContext, ActionTree } from 'vuex';
import { PriceTrackService } from '@/modules/price-track/services';
import { SET_MEMBER_FILTER_RANGES, SET_MEMBER_ANALYSIS } from '@/modules/members/store/mutationTypes';
import { SET_CHARTDATA } from '@/modules/providers-analysis/store/mutationTypes';
import { FETCHING_CHARTDATA } from '@/modules/providers-analysis/store/actionTypes';

/**
 * --------------------------------------------------
 *  PriceTrack Actions
 * --------------------------------------------------
 **/
export const PriceTrackActions: IPriceTracksActions = {
  /**
   * --------------------------------------------------
   *  Fetch All the PriceTracks
   * --------------------------------------------------
   **/
  [AT.FETCH_PRICE_TRACKS]: ({ commit, dispatch }: any) => {
    return PriceTrackService.getPriceTracks()
      .then(priceTracks => {
        commit(MT.SET_PRICE_TRACKS, priceTracks);
        return priceTracks;
      });
  },
  [AT.FETCH_PRICE_TRACK]: ({ commit, dispatch }: any, id: string) => {
    return PriceTrackService.getPriceTrack(id)
      .then(priceTrack => {
        commit(MT.SET_PRICE_TRACK, priceTrack);
        return priceTrack;
      });
  },
  [AT.ADD_PRICE_TRACK]: ({ commit, dispatch }: any, data: any) => {
      commit(MT.ADDING_PRICE_TRACK, true);
      return PriceTrackService.addPriceTrack(data)
      .then(resp => {
        return resp;
      })
      .finally(() => {
        commit(MT.ADDING_PRICE_TRACK, false);
      });
  },
  [AT.FETCH_FILTERED_MEMBERS_ANALYSIS_PRICE_TRACK]: async ({ commit, dispatch }, params?: any) => {
    const { data: ranges } = await PriceTrackService.getPriceTrackFilterRanges(params);
    commit(SET_MEMBER_FILTER_RANGES, ranges);
    return ranges;
  },

  [AT.FETCH_FILTERED_MEMBERS_ANALYSIS_PAST_PERFORMANCE_PRICE_TRACK]: async ({ commit, dispatch }, data: any) => {
    const resp = await PriceTrackService.getPriceTrackFilterRangesPastPerformance(data);
    return resp;
  },

  [AT.FETCH_CHARTDATA_PRICE_TRACK]: ({ commit, dispatch }: any, data: any) => {
    dispatch(FETCHING_CHARTDATA, true);
    commit(SET_CHARTDATA, {});
    return PriceTrackService.getChartDataPriceTrack({ priceTrackId: data.priceTrackId })
      .then((chartdata: any) => {
        commit(SET_CHARTDATA, chartdata);
        return chartdata;
      })
      .finally(() => dispatch(FETCHING_CHARTDATA, false));
  },

  [AT.FETCH_MEMBERS_ANALYSIS_PRICE_TRACK]: ({ commit, dispatch }: any, params: any) => {
    return PriceTrackService.getMemberAnalysisByPagePriceTrack(params).then((analysis: any) => {
      commit(SET_MEMBER_ANALYSIS, analysis);
      return analysis.data;
    });
  },
};

/**
 * --------------------------------------------------
 *  PriceTrack Action Context Interface
 * --------------------------------------------------
 **/
export interface IPriceTrackActionContext extends ActionContext<IPriceTrackState, IRootState> {}

/**
 * --------------------------------------------------
 *  PriceTrack Actions Interface
 * --------------------------------------------------
 **/
export interface IPriceTracksActions extends ActionTree<IPriceTrackState, IRootState> {}
