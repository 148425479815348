<template>
  <div class="report-template">
    <v-card class="report-page-card" outlined flat>
      <div class="section">
        <KeyStats
          v-if="!fetchingDataQuality"
          class="mt-4"
          type="dataQuality"
          :data="metrics"
          :tooltipVisibility="false"
        ></KeyStats>
      </div>

      <div class="section">
        <div class="section-title">
          <div class="section-badge"></div>
          Data Quality Benchmarking
        </div>
        <div class="section-content">
          The algorithm captures almost all scores but inevitably there will be outliers, determined by IRRs which are
          exceptionally high or low. By setting the tolerance for outliers at a reasonable level, AgeWage establishes
          which outliers are a result of data inaccuracies.
        </div>
        <div>
          AgeWage measures the percentage of IRRs that are outliers and expresses this as Suspect Data – being the
          percentage of IRRs that fell outside of tolerances. The metrics above indicate that
          {{ providerDataQuality.data_analysed }}% of the data set ({{
            formatNumber(providerDataQuality.member_count, '0,00')
          }}
          members) received was analysed and scored, {{ providerDataQuality.short_contrib_percent }}% ({{
            formatNumber(providerDataQuality.short_contrib_count, '0,00')
          }}
          members) had contribution history of less than 12 months and hence were not scored, while
          {{ providerDataQuality.suspect_data }}% ({{ formatNumber(providerDataQuality.suspect_count, '0,00') }}
          members) were deemed as suspect data on account of extreme IRRs. The following graph shows the Smart Pension
          Data Quality against other schemes in the pension industry.
        </div>
      </div>
      <div v-if="!fetchingBechmarkingReports && !fetchingDataQuality && data && data.length" class="report-chart">
        <div class="d-flex justify-end">
          <div class="benchmark-filter-menu">
            <v-menu
              v-model="filterIsVisible"
              :close-on-content-click="false"
              :nudge-bottom="15"
              :nudge-right="12"
              content-class="benchmark-filter-menu-wrapper"
              location="left"
              max-width="100%"
              min-width="100%"
              offset="20"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  class="filter-btn elevation-6 mt-0 mx-2"
                  :class="{ 'is-active': filtersAreApplied }"
                  data-testid="FilterBtn"
                  variant="text"
                  v-bind="props"
                  size="small"
                >
                  <span>Filter</span>
                  <div class="ml-1">
                    <v-img v-if="!filtersAreApplied" class="filter-icon" src="../../../../assets/icons/filter.svg" />
                    <v-img
                      v-if="filtersAreApplied"
                      class="filter-icon"
                      src="../../../../assets/icons/filter-light.svg"
                    />
                  </div>
                </v-btn>
              </template>
              <!-- Filter Menu -->
              <BenchmarkFilterTable
                :dateFilters="true"
                :filters="filters"
                :initFilters="appliedFilters"
                @apply="(e) => onFiltersApplied(e)"
              ></BenchmarkFilterTable>
            </v-menu>
          </div>
        </div>
        <DataQualityChart
          v-if="chartData"
          :data="chartData"
          :providerDataQuality="providerDataQuality"
          :providerId="providerId"
          :providerName="providerName"
        />
      </div>

      <div class="footer">
        <div></div>
        <!-- <div class="page-box">
            <div class="pageno">Page 2/4</div>
            </div> -->
        <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import DataQualityChart from '@/components/charts/DataQualityChart.vue';
import KeyStats from '@/components/KeyStats.vue';
import Utils from '../../../../utils/Utils';
import BenchmarkFilterTable from './BenchmarkFilter.vue';

@Options({
  components: {
    DataQualityChart,
    KeyStats,
    BenchmarkFilterTable,
  },
})
export default class DataQuality extends Vue {
  @Prop()
  public data?: any;

  @Prop()
  public providerName?: string;

  @Prop()
  public providerId?: string;

  mounted() {
    this.chartData = this.getChartData();
  }

  public filterIsVisible = false;
  public appliedFilters = {
    tax_year: [],
    scheme: [],
    pathway: [],
    data_type: [],
    consolidator: [],
    asset_size: [],
  };
  public initialFilters = {
    tax_year: ['2019-2020', '2020-2021', '2021-2022'],
    scheme: [
      'Master Trusts',
      'HMRC Group Personal Pension',
      'Occupational Pension',
      'Employer Scheme in a GPP',
      'Employer Scheme in a Master Trust',
      'Investment Pathway',
    ],
    pathway: ['Accumulation', 'Drawdown', 'Cash', 'Annuity', 'Remain Invested'],
    data_type: ['Member Experience', 'Synthetic'],
    consolidator: ['Accepting Full Consolidation', 'Accepting Partial Consolidation', 'Not Accepting Consolidation'],
    asset_size: { min: 20, max: 100 },
  };

  public chartData = this.getChartData();

  public getChartData() {
    if (!this.checkAppliedFilters) {
      return this.data;
    }

    const filteredChart = this.data.filter((item: any) => {
      let isInRange = false;
      const navInRange = this.checkNavInRange(item);
      const schemeFound = this.checkScheme(item);
      const taxYearFound = this.checkTaxYear(item);
      const pathwayFound = this.checkPathway(item);
      const dataTypeFound = this.checkDatatype(item);
      const consolidatorFound = this.checkConsolidator(item);

      if (navInRange && schemeFound && taxYearFound && pathwayFound && dataTypeFound && consolidatorFound) {
        isInRange = true;
      }
      return isInRange;
    });
    return filteredChart;
  }

  get moduleIsReady() {
    return true;
  }

  public checkNavInRange(item: any) {
    const filterData = this.appliedFilters;
    if (!filterData.asset_size.length) {
      return true;
    }
    if (+item.total_nav <= filterData.asset_size[1] && +item.total_nav >= filterData.asset_size[0]) {
      return true;
    }
    return false;
  }

  public checkScheme(item: any) {
    const filterData = this.appliedFilters;
    if (!filterData.scheme.length) {
      return true;
    }

    if (filterData.scheme.find((element) => element == item.scheme)) {
      return true;
    }
    return false;
  }

  public checkTaxYear(item: any) {
    const filterData = this.appliedFilters;
    if (!filterData.tax_year.length) {
      return true;
    }
    if (filterData.tax_year.find((element) => element == item.tax_year)) {
      return true;
    }
    return false;
  }

  public checkPathway(item: any) {
    const filterData = this.appliedFilters;
    if (!filterData.pathway.length) {
      return true;
    }
    if (filterData.pathway.find((element) => element == item.pathway)) {
      return true;
    }
    return false;
  }

  public checkDatatype(item: any) {
    const filterData = this.appliedFilters;
    if (!filterData.data_type.length) {
      return true;
    }
    if (filterData.data_type.find((element) => element == item.data_type)) {
      return true;
    }
    return false;
  }
  public checkConsolidator(item: { consolidator: string | number }) {
    const filterData = this.appliedFilters;
    if (!filterData.consolidator.length) {
      return true;
    }
    const mapConsolidator = {
      'Accepting Full Consolidation': 'yes',
      'Not Accepting Consolidation': 'no',
      'Accepting Partial Consolidation': 'partial',
    };
    if (filterData.consolidator.find((element) => mapConsolidator[element] == item.consolidator)) {
      return true;
    }
    return false;
  }

  public get checkAppliedFilters() {
    if (
      this.appliedFilters.asset_size.length > 0 ||
      this.appliedFilters.scheme.length > 0 ||
      this.appliedFilters.tax_year.length > 0 ||
      this.appliedFilters.pathway.length > 0 ||
      this.appliedFilters.data_type.length > 0 ||
      this.appliedFilters.consolidator.length > 0
    ) {
      return true;
    }
    return false;
  }

  public get filtersAreApplied() {
    return !!(this.appliedFilters && Object.keys(this.appliedFilters).length);
  }

  public get fetchingBechmarkingReports() {
    return this.$store.getters.fetchingBechmarkingReports;
  }

  public get fetchingDataQuality() {
    return this.$store.getters.fetchingDataQuality;
  }

  public get providerDataQuality() {
    return this.$store.getters.providerDataQuality;
  }

  public formatNumber(n: number, format = '0.0a') {
    return Utils.formatNumber(n, format);
  }

  public get filters() {
    this.initialFilters.asset_size.min = Math.min.apply(
      Math,
      this.data.map(function (o: { total_nav: number }) {
        return +o.total_nav;
      })
    );
    this.initialFilters.asset_size.max = Math.max.apply(
      Math,
      this.data.map(function (o: { total_nav: number }) {
        return +o.total_nav;
      })
    );
    return {
      ...this.initialFilters,
    };
  }

  public onFiltersApplied(filters: any = {}) {
    this.appliedFilters = filters || {};
    this.filterIsVisible = false;
    this.chartData = this.getChartData();
  }

  public get metrics() {
    return {
      stat1_title: +this.providerDataQuality.data_analysed,
      stat2_title: +(this.providerDataQuality.short_contrib_percent / 100),
      stat3_title: +(this.providerDataQuality.suspect_data / 100),
      stat4_title: this.providerDataQuality.member_count,
      stat1_text: 'Data Analysed',
      stat2_text: 'Short Contribution History',
      stat3_text: 'Suspect Data',
      stat4_text: `Members Analysed`,
    };
  }
}
</script>
<style lang="scss">
.benchmark-filter-menu-wrapper {
  min-width: 75% !important;
  max-width: 90% !important;
  right: 7% !important;
  left: unset !important;
}
</style>
