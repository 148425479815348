<template>
  <div class="report-template">
    <Watermark :isSampleReport="isSampleReport" />

    <v-card class="report-page-card" outlined flat>
      <div v-if="filters.yearSeg" class="section">
        <div class="section-title">
          <div class="section-badge"></div>
          Impact of Start Date
        </div>
        <div class="section-content">
          IRRs vary over time. Viewing the experience of each joiner cohort helps understand pot adequacy at retirement.
          The graph below shows the impact of the first contribution date on the average IRRs of data set. It gives
          insights into why some savers have outperformed others in terms of absolute returns.
        </div>
      </div>
      <div v-if="!fetchingChartData" class="report-chart">
        <ISYChart
          v-if="isyData && filters.yearSeg"
          :chartData="isyData"
          :provider="filters.provider"
          :priceTrack="filters.priceTrackObject"
          :isSampleReport="isSampleReport"
          :reportfilterRanges="reportfilterRanges"
        ></ISYChart>
      </div>
      <div v-else>
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <div class="footer">
        <div></div>
        <!-- <div class="page-box">
          <div class="pageno">Page 3/4</div>
        </div> -->
        <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
      </div>
    </v-card>

    <v-card class="report-page-card" outlined flat>
      <div v-if="filters.yearSeg" class="section">
        <div class="section-title">
          <div class="section-badge"></div>
          Impact of Start Date
        </div>
        <div class="section-content">
          Scores vary over time. Viewing the experience of each joiner cohort helps understand pot adequacy at retirement.
          The graph below shows the impact of the first contribution date on the average scores of data set. It gives
          insights into why some savers have outperformed others in terms of absolute returns.
        </div>
      </div>
      <div v-if="!fetchingChartData" class="report-chart">
        <ScoreYearChart
          v-if="isyData && filters.yearSeg"
          :chartData="isyData"
          :provider="filters.provider"
          :priceTrack="filters.priceTrackObject"
          :isSampleReport="isSampleReport"
          :reportfilterRanges="reportfilterRanges"
        ></ScoreYearChart>
      </div>
      <div v-else>
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <div class="footer">
        <div></div>
        <!-- <div class="page-box">
          <div class="pageno">Page 3/4</div>
        </div> -->
        <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
      </div>
    </v-card>
  </div>
</template>
<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { FETCH_MEMBERS, FETCH_CONTRIBUTION_HISTORY } from '@/modules/members/store/actionTypes';
// import SchemeComparisonChart from '@/components/charts/SchemeComparisonChart.vue';
import ISYChart from '@/components/charts/ISYChart.vue';
import ScoreYearChart from '@/components/charts/ScoreYearChart.vue';
import Watermark from './Watermark.vue';

@Options({
  components: {
    ISYChart,
    Watermark,
    ScoreYearChart
  }
})
export default class SccAndIsy extends Vue {
  created() {
    this.bootstrap();
  }
  @Prop()
  public chartData?: any;

  @Prop()
  public data?: any;

  @Prop()
  public reportfilterRanges?: any;

  @Prop({
    default: () => ({
      awDist: true,
      yearSeg: true,
      Irr: true,
      avb: true,
      schemeSeg: true,
      outliers: true,
      startDate: null,
      endDate: null,
      provider: null
    })
  })
  filters?: any;

  @Prop() isSampleReport!: boolean;

  public barColor: any = {
    primary: '#a085bc',
    secondary: '#cbafd5'
  };

  public plotterColor: any = {
    primary: '#756287',
    secondary: '#cbafd5',
    shadow: '#eee6f1'
  };

  get moduleIsReady() {
    return true;
  }

  // public get sccData() {
  //   return this.chartData.scheme;
  // }

  public get isyData() {
    return this.chartData.investments;
  }

  public get fetchingChartData() {
    return this.$store.getters.fetchingChartData;
  }

  bootstrap() {
    //
  }
}
</script>
