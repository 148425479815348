<template>
  <div>
    <!-- No data snackbar -->
    <v-snackbar v-model="noDataAlert" :timeout="3000" color="primary" location="top">
      <h3>No data available for this range</h3>
      <v-btn color="blue" variant="text" class="d-flex ml-auto" @click="noDataAlert = false"> Close </v-btn>
    </v-snackbar>
    <v-snackbar v-model="pdfTimeoutError" :timeout="3000" color="primary" location="top">
      <h3>Error downloading requested pdf. Please try again.</h3>
      <v-btn color="blue" variant="text" class="d-flex ml-auto" @click="pdfTimeoutError = false"> Close </v-btn>
    </v-snackbar>
    <!-- Chart -->

    <div class="sccWrapper">
      <div class="elevation-1 chartWrapper sccCard">
        <div class="chart-title text-center">
          {{ title }} segmentation
          <div v-if="type === 'string' && !isSampleReport" class="label-abbr-btn">
            <v-btn color="deep-purple accent-3" elevation="5" variant="text" @click="showLabelAbbreviation = true">
              Abbreviate Labels
            </v-btn>
          </div>
        </div>
        <div class="d-flex">
          <base-chart
            v-if="type == 'string'"
            :options="chartOptions"
            :scroll-options="stringChartSize"
            @click="(e) => chartClick(e)"
            class="mt-7 ml-0"
          />
          <base-chart
            v-else
            :options="chartOptions"
            :scroll-options="chartSize"
            @click="(e) => chartClick(e)"
            class="mt-7 ml-0"
          />
          <div v-if="type === 'string'" class="label-abbr-list">
            <v-card>
              <v-list>
                <v-list-item
                  color="#ccc"
                  class="abbr-label-item align-center"
                  v-for="(labelData, key) in getFilteredLabelList(labelAbbrData)"
                  :key="key"
                >
                  <div>{{ labelData.labelAbbr }} - {{ labelData.labelKey }}</div>
                </v-list-item>
              </v-list>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <!-- Members Modal -->
    <v-dialog
      v-model="membersDialog"
      @click:outside="membersDialog = false"
      persistent
      max-width="80vw"
      max-height="90%"
    >
      <v-card class="py-4">
        <v-card-title class="d-flex align-center justify-space-between">
          <div>
            <div>Member Analysis for the selected range:</div>
          </div>
          <div>
            <v-btn
              left
              color="primary"
              class="mr-4"
              :disabled="!provider || isSampleReport"
              :loading="processingCSV"
              @click="exportMemberCSV()"
            >
              <v-icon small class="mr-3">fas fas fa-file-download</v-icon>Download
            </v-btn>

            <v-btn rounded size="small" density="comfortable" variant="flat" icon @click="membersDialog = false">
              <v-icon small>fa fa-times</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <AppTable
            :table-data="membersTableData"
            :disableSort="true"
            :disablePagination="true"
            :default-sort-column="[{ key: 'dataId', order: 'asc' }]"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey-darken-1" variant="text" @click="membersDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AppLoader v-if="fetchingMembersAnalysis" />

    <!-- label abbreviate modal -->
    <v-dialog
      v-model="showLabelAbbreviation"
      @click:outside="showLabelAbbreviation = false"
      persistent
      max-width="60vw"
    >
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <div>Abbreviate Labels for {{ title }} segmentation</div>
        </v-card-title>
        <v-card-text>
          <div>
            <v-form ref="form">
              <div
                v-for="key in Object.keys(chartDataOptions).filter((a) => typeof chartDataOptions[a].name === 'string')"
                :key="key"
                class="label-abbr-row"
              >
                <div class="mr-4">{{ title }}:</div>
                <div class="label-name mr-4">{{ chartDataOptions[key].name }}</div>
                <v-text-field
                  v-model="labelFormArray[chartDataOptions[key].name]"
                  required
                  variant="underlined"
                  color="primary"
                  class="label-input"
                ></v-text-field>
              </div>
              <div class="modal-btns">
                <v-progress-circular
                  v-if="updatingLabels"
                  class="mr-4"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-btn class="mr-4" color="primary" @click="updateLabelsAbbr" :disabled="updatingLabels">
                  Submit
                </v-btn>
                <v-btn class="mr-4" @click="resetLabels" :disabled="updatingLabels"> Reset </v-btn>
                <v-btn @click="closeLabelModal" :disabled="updatingLabels">Close</v-btn>
              </div>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import BaseChart from '@/components/charts/BaseChart.vue';
import _ from 'lodash';
import Utils from '../../utils/Utils';
import * as AT from '@/modules/members/store/actionTypes';
import { GET_EXPORT_JOB_BY_ID } from '@/modules/reports/store/actionTypes';
import { GET_CSV_URL } from '@/modules/members/store/actionTypes';
import { UPDATE_SEGMENT_LABEL_DATA } from '@/modules/reports/store/actionTypes';
import { Table, TableColumn, TableRow, TableColumnType } from '@/types';
import AppTable from '@/components/AppTable.vue';
import AppLoader from '@/components/AppLoder.vue';

@Options({
  components: {
    BaseChart,
    AppTable,
    AppLoader,
  },
})
export default class NumberChart extends Vue {
  @Prop({
    default: () => [],
  })
  chartData!: any;

  @Prop({
    default: () => [],
  })
  provider?: number[];

  @Prop({
    default: () => {},
  })
  reportfilterRanges?: any;

  @Prop({
    default: () => 'Graph',
  })
  title?: string;

  @Prop({
    default: () => 'string',
  })
  type?: string;

  @Prop() isSampleReport!: boolean;

  public chartSize: any = {
    chartWidth: 70,
    chartHeight: 30,
    unit: 'rem',
  };

  public stringChartSize: any = {
    chartWidth: 60,
    chartHeight: 30,
    unit: 'rem',
  };

  public chartDataOptions = this.chartData;

  public pdfTimeoutError = false;

  public labelAbbrData: any = {};

  public labelFormArray: any = {};

  public showLabelAbbreviation: boolean = false;

  public membersDialog = false;

  public fetchingMembersAnalysis = false;

  public noDataAlert = false;

  public processingCSV = false;

  public selectedRange = [];

  public currentFilter: any = {};

  public optionsData: any = {};

  public segmentId: number = this.chartDataOptions && this.chartDataOptions[0].id;

  public updatingLabels: boolean = false;

  public pagination = {
    page: 1,
    limit: 15,
  };

  mounted() {
    this.chartDataOptions = this.chartData;
    this.segmentId = this.chartDataOptions.length && this.chartDataOptions[0].id;
    this.setOptions();
  }

  private async fetchMembersAnalysis(data: any = {}) {
    this.fetchingMembersAnalysis = true;
    let analysis = null;
    if (this.isSampleReport) {
      analysis = await this.$store.dispatch(AT.FETCH_SAMPLE_MEMBERS_ANALYSIS, data);
    } else {
      analysis = await this.$store.dispatch(AT.FETCH_MEMBERS_ANALYSIS, data);
    }
    this.fetchingMembersAnalysis = false;
    return analysis;
  }

  public get chartOptions() {
    return this.optionsData;
  }

  public getFilteredLabelList(list: any) {
    const listValue = this.abbreviatedLabels(list);
    const filteredList = listValue.filter((labelData: any) => labelData.labelAbbr);
    return filteredList;
  }

  public abbreviatedLabels(labelArr: any) {
    const data: any = [];
    Object.keys(labelArr).map((label) => {
      data.push({ labelAbbr: labelArr[label] || '', labelKey: label });
    });
    return data;
  }

  public resetLabels() {
    const labelArr: any = {};
    this.chartDataOptions.forEach((item: any) => {
      labelArr[item.name] = item.labelAbbr || '';
    });
    this.labelFormArray = labelArr;
  }

  public async updateLabelsAbbr() {
    this.updatingLabels = true;
    const data = this.abbreviatedLabels(this.labelFormArray);
    const dataToSend = { providerId: this.provider, labelData: data, id: this.segmentId };
    const result = await this.$store.dispatch(UPDATE_SEGMENT_LABEL_DATA, dataToSend);
    this.updatingLabels = false;
    if (result.status) {
      this.labelAbbrData = this.labelFormArray;
    }
    this.updateChartData();
  }

  public closeLabelModal() {
    this.showLabelAbbreviation = false;
    this.resetLabels();
  }

  public updateChartData() {
    const data = this.chartDataOptions.map((item: { name: string; labelAbbr: any }) => {
      const labelAbrrVal = Object.keys(this.labelAbbrData).find((label) => label === item.name);
      if (labelAbrrVal) {
        item.labelAbbr = this.labelAbbrData[labelAbrrVal];
      }
      return item;
    });
    this.chartDataOptions = data;
    this.setOptions();
    this.closeLabelModal();
  }

  public async exportMemberCSV() {
    //PDF Export Polling with 2sec interval and 16sec Timout
    let job = { id: -1 };

    this.processingCSV = true;
    const newFilters = {
      providers: this.currentFilter.providers,
      ...this.currentFilter,
    };
    newFilters.page = 1;
    newFilters.limit = -1;
    job = await this.$store.dispatch(AT.EXPORT_MEMBERS_ANALYSIS, newFilters);

    let pdfFetchInterval = setInterval(async () => {
      let response = await this.$store.dispatch(GET_EXPORT_JOB_BY_ID, job.id);
      if (response[0].status === 'completed') {
        const url = await this.$store.dispatch(GET_CSV_URL, job.id);
        await window.open(url);
        this.processingCSV = false;
        clearInterval(pdfFetchInterval);
      }
      return response;
    }, Utils.pdfInterval);
    setTimeout(() => {
      clearInterval(pdfFetchInterval);
      if (this.processingCSV) {
        this.pdfTimeoutError = true;
      }
      this.processingCSV = false;
    }, Utils.pdfTimeout);
  }

  public async chartClick(e: any) {
    if (e.dataIndex !== undefined) {
      this.noDataAlert = false;
      const index = await e.dataIndex;
      const dynamicFilters: any = {
        order: this.chartDataOptions[index].id,
        val: this.chartDataOptions[index].name,
        dataPointId: this.chartDataOptions[index].dataPointId,
      };
      const convertedFilters = Utils.convertMinMaxToArrayFilters(this.reportfilterRanges);
      const appliedFilters: any = {
        providers: [this.provider],
      };
      if (this.type === 'number') {
        dynamicFilters.min = this.chartDataOptions[index].min;
        dynamicFilters.max = this.chartDataOptions[index].max;
        appliedFilters.numberGraph = [dynamicFilters];
      } else {
        dynamicFilters.isOthers = this.chartDataOptions[index].dataPointId ? false : true;
        appliedFilters.stringGraph = [dynamicFilters];
      }
      this.currentFilter = {
        ...convertedFilters,
        ...this.pagination,
        ...appliedFilters,
      };
      const analysis = await this.fetchMembersAnalysis(this.currentFilter);
      analysis.length ? (this.membersDialog = true) : (this.noDataAlert = true);
    }
  }

  public get barData() {
    var avg_airr = this.chartDataOptions.map((item: any) => item.avg_airr);
    var avg_birr = this.chartDataOptions.map((item: any) => item.avg_birr);
    const labelArr: any = {};
    var xlabels = this.chartDataOptions.map((item: any) => {
      if (item.labelAbbr) {
        labelArr[item.name] = item.labelAbbr;
        return item.labelAbbr;
      }
      if (typeof item.name !== 'string') {
        return 'Others';
      }
      labelArr[item.name] = '';
      return item.name;
    });
    this.labelFormArray = { ...labelArr };
    this.labelAbbrData = { ...labelArr };
    const bars = {
      xlabels,
      avg_airr,
      avg_birr,
    };
    return bars;
  }

  public get memberAnalysis(): any[] | null {
    return this.$store.getters.memberAnalysis && this.$store.getters.memberAnalysis.data;
  }

  public get membersTableData(): Table {
    const data = this.memberAnalysis ? this.memberAnalysis : [];
    const rows = data.map((it) => {
      const retVal: any = {
        dataId: it.dataId,
        // fundName: it.fund.name,
        firstContributionDate: it.firstContributionDate,
        score: it.scores.score || it.scores.score === 0 ? parseInt(it.scores.score) : '-',
        totalContributionAmount: it.totalContributionAmount,
        navAmount: it.analysis.navAmount,
        navDate: it.analysis.navDate,
        irr: it.analysis.irr,
        benchmarkIrr: it.analysis.benchmarkIrr,
        benchmarkNavAmount: it.analysis.benchmarkNavAmount,
      };
      retVal.customData = it.analysis.segmentData[this.chartDataOptions[0].id];
      return retVal;
    });
    return {
      columns: [
        {
          value: 'dataId',
          title: 'Saver ID',
          type: TableColumnType.NUMBER,
          align: 'center',
        },
        // {
        //   value: 'fundName',
        //   text: 'Fund Name',
        //   type: TableColumnType.STRING
        // },
        {
          value: 'score',
          title: 'AW Score',
          type: TableColumnType.NUMBER,
          align: 'center',
        },
        {
          value: 'firstContributionDate',
          title: 'First Contribution Date',
          type: TableColumnType.DATE,
          formatter: (v: any) => Utils.formatDate(new Date(v), 'MMM dd, yyyy'),
          align: 'center',
        },
        {
          value: 'navDate',
          title: 'NAV Date',
          type: TableColumnType.DATE,
          formatter: (v: any) => Utils.formatDate(new Date(v), 'MMM dd, yyyy'),
          align: 'center',
        },
        {
          value: 'totalContributionAmount',
          title: 'Total Contribution Amount',
          type: TableColumnType.NUMBER,
          formatter: (n) => Utils.formatNumber(n, '$0.00a'),
          align: 'center',
        },
        {
          value: 'navAmount',
          title: 'NAV Amount',
          type: TableColumnType.NUMBER,
          formatter: (n) => Utils.formatNumber(n, '$0.00a'),
          align: 'center',
        },
        {
          value: 'benchmarkNavAmount',
          title: 'Benchmark Nav Amount',
          type: TableColumnType.NUMBER,
          formatter: (n) => Utils.formatNumber(n, '$0.00a'),
          align: 'center',
        },
        {
          value: 'irr',
          title: 'IRR (%)',
          type: TableColumnType.NUMBER,
          formatter: (v: any) => (v * 100).toFixed(2),
          align: 'center',
        },
        {
          value: 'benchmarkIrr',
          title: 'Benchmark IRR (%)',
          type: TableColumnType.NUMBER,
          formatter: (v: any) => (v * 100).toFixed(2),
          align: 'center',
        },
        {
          value: 'customData',
          title: `${this.title}`,
          type: TableColumnType[this.type === 'number' ? 'NUMBER' : 'STRING'],
          align: 'center',
        },
      ],
      rows,
    };
  }

  public setOptions() {
    this.optionsData = {
      // color: ['#1d8867', '#a78cc0'],
      color: [
        {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: '#4ac29a', // color at 0% position
            },
            {
              offset: 1,
              color: '#92dac2', // color at 100% position
            },
          ],
        },
        {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: '#d6c1e1', // color at 0% position
            },
            {
              offset: 1,
              color: '#ae83c4', // color at 100% position
            },
          ],
        },
      ],
      tooltip: {
        show: true,
        formatter: (params: any) =>
          `Savers: ${this.chartDataOptions[params.dataIndex].doc_count} <br> ${params.seriesName}: ${Utils.formatNumber(
            params.value * 100,
            '0.[00]'
          )}%`,
      },

      legend: {
        // data: ['Avg IRR', 'Avg Benchmark IRR'],
        textStyle: { fontSize: 10, fontWeight: 'bold', fontFamily: 'Open Sans' },
        padding: [0, 0, 0, 0],
        right: '10%',
      },

      xAxis: [
        {
          name: this.title,
          nameLocation: 'middle',
          nameTextStyle: { color: '#6f6c76', padding: [14, 0, 0, 0], fontFamily: 'Open Sans', fontWeight: 'bolder' },
          type: 'category',
          axisTick: { show: false },
          axisLabel: {
            padding: [0, 0, 0, 0],
            fontWeight: '600',
            fontSize: 10,
            fontFamily: 'Open Sans',
            color: '#6f6c76',
            interval: 0,
            formatter: (v: string) => v.toUpperCase(),
          },
          axisLine: { show: true, lineStyle: { color: '#ccc' } },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#eee',
            },
          },
          data: this.barData.xlabels,
        },
      ],
      yAxis: {
        name: 'IRR',
        nameLocation: 'middle',
        nameRotate: 90,
        nameTextStyle: { color: '#6f6c76', padding: [0, 0, 30, 0], fontFamily: 'Open Sans', fontWeight: 'bolder' },
        type: 'value',
        axisLabel: {
          formatter: (v: any) => Utils.formatNumber(v * 100, '0.[00]') + '%',
          fontWeight: '600',
          fontFamily: 'Open Sans',
          color: '#6f6c76',
          interval: false,
        },
        axisLine: { show: true, lineStyle: { color: '#ccc' } },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#eee',
          },
        },
      },
      series: [
        {
          name: 'Avg IRR',
          type: 'bar',
          barGap: 0,
          data: this.barData.avg_airr,
          label: {
            show: true,
            position: 'top',
            offset: [-2, 0],
            color: '#ae83c4',
            fontSize: 10,
            rich: { name: { fontFamily: 'Open Sans', color: '#6f6c76' } },
            textBorderColor: '#fff',
            formatter: (params: any) => `${this.chartDataOptions[params.dataIndex].doc_count}`,
          },
        },
        {
          name: 'Avg Benchmark IRR',
          type: 'bar',
          barGap: 0,
          data: this.barData.avg_birr,
          label: {
            show: true,
            rotate: 90,
            position: 'right',
            align: 'center',
            color: '#ae83c4',
            distance: 10,
            fontSize: 10,
            verticalAlign: 'bottom',
            rich: { name: { fontFamily: 'Open Sans', color: '#6f6c76' } },
            textBorderColor: '#fff',
            formatter: (params: any) =>
              `Score: ${
                this.chartDataOptions[params.dataIndex].avg_score ||
                this.chartDataOptions[params.dataIndex].avg_score === 0
                  ? this.chartDataOptions[params.dataIndex].avg_score.toFixed(0)
                  : 'NA'
              }`,
          },
        },
      ],
      barWidth: 20,
      barCategoryGap: '40%',
    };
  }
}
</script>

<style lang="scss" scoped>
.sccWrapper {
  padding: 1rem 0rem !important;
}
.label-abbr-btn {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: -4%;
}
.label-abbr-row {
  display: flex;
  align-items: center;
}
.v-card__text {
  font-size: 16px;
}
.modal-btns {
  display: flex;
  justify-content: flex-end;
}
.label-name {
  font-weight: 600;
}
.label-abbr-list {
  margin-top: 2%;
  margin-left: -12%;
  margin-right: 1%;
}
.v-list-item {
  min-height: 24px !important;
  text-align: left;
}
.v-list-item__content {
  padding: 0 !important;
}
.base-chart__wrapper {
  margin-right: 20% !important;
}
.abbr-label-item {
  max-width: 190px;
  display: -webkit-box;
  color: #6f6c76;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  font-size: 12px !important;
  margin: 10px auto !important;
  word-break: break-word;
}
</style>
