import ImportModule from './ImportModule.vue';
import ListImportsPage from './pages/ListImportsPage.vue';

export const ImportRoutes = [
  {
    path: '/imports',
    component: ImportModule,
    children: [
      {
        path: '',
        name: 'dashboard.imports.list',
        component: ListImportsPage
      }
    ]
  }
];
