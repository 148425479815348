// actions
export const FETCH_PROVIDERS = 'b2c/FETCH_PROVIDERS';
export const FETCH_PROVIDER = 'b2c/FETCH_PROVIDER';
export const EDIT_PROVIDER = 'b2c/EDIT_PROVIDER';
export const FETCH_USERS = 'b2c/FETCH_USERS';
export const FETCH_USER = 'b2c/FETCH_USER';
export const EDIT_USER = 'b2c/EDIT_USER';
export const ADD_SCORE_BULK = 'b2c/ADD_SCORE_BULK';
export const FETCH_IMPORTS = 'b2c/FETCH_IMPORTS';
export const EXPORT_B2C_CONTRIBUTIONS = 'b2c/EXPORT_B2C_CONTRIBUTIONS';
export const EXPORT_USERS = 'b2c/EXPORT_USERS';
