import { Simulation, SimulationFull } from '@/models/Simulation';
import { HTTPServiceClient } from '@/packages/http/service';
import * as ep from './endpoints';

export const SimulationService = {
  /**
   * Description:
   * @param {}
   * @returns { Promise<Simulation[]> }
   */
  getSimulations(): Promise<Simulation[]> {
    return HTTPServiceClient.get(ep.FETCH_SIMULATIONS).then(({ data }) => data);
  },
  getSimulation(id: string): Promise<SimulationFull> {
    return HTTPServiceClient.get(ep.FETCH_SIMULATION(id)).then(({ data }) => data);
  },
  addSimulation(data: any) {
    return HTTPServiceClient.post(ep.ADD_SIMULATION, data);
  },
  getStDevsCSVURL(data: any): Promise<string | null> {
    return HTTPServiceClient.get(ep.DOWNLOAD_STD_DEVS).then(({ data }) => {
      return data.url || null;
    });
  }
};
