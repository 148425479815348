// import Reports from '@/models/Reports';

export interface IReportState {
  reports: any;
  outliers: any;
  reportOptions: any;
  fetchingReports: boolean;
  performanceAttribution: any;
  addingFreeText: any;
  addedFreeText: any;
  fetchingOutliers: boolean;
  fetchingSampleReport: boolean;
  sampleReport: any;
}

/**
 * --------------------------------------------------
 *	Reports State
 * --------------------------------------------------
 **/

export const ReportState: IReportState = {
  reports: [],
  outliers: [],
  reportOptions: {},
  fetchingReports: false,
  performanceAttribution: {},
  addingFreeText: {},
  addedFreeText: {},
  fetchingOutliers: false,
  fetchingSampleReport: false,
  sampleReport: {}
};
