import Import from '@/models/Import';

/**
 * --------------------------------------------------
 *	Import State
 * --------------------------------------------------
 **/

export const ImportState: IImportState = {
  imports: null,
  fetchingImports: false,
};

export interface IImportState {
  imports: Import[] | null;
  fetchingImports: boolean;
}
