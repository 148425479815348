<template>
  <div class="elevation-10 chartWrapper">
    <div class="chart-title text-center pb-8">Effect of Contribution Frequency</div>
    <BaseChart :options="effectOptionsData" :scrollOptions="scrollOptions" />
  </div>
</template>

<script>
import BaseChart from './BaseChart.vue';
const echarts = require('echarts/lib/echarts');
export default {
  components: {
    BaseChart
  },
  props: ['chartData'],
  data() {
    return {
      scrollOptions: {
        chartHeight: '30',
        unit: 'rem'
      },
      effectOptionsData: this.editEffectsGraphData(),
    };
  },
  methods: {
    editEffectsGraphData() {
      const effectoptions = this.chartData;
      if (effectoptions) {
        const arrLength = effectoptions.valueArr.length;
        for (let i = 0; i < arrLength; i++) {
          effectoptions.series[0].data[i].itemStyle = {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: [effectoptions.colorShades[i][1]]
              },
              {
                offset: 1,
                color: [effectoptions.colorShades[i][0]]
              }
            ])
          };
        }
        if(effectoptions.graphic.elements.length <= effectoptions.legendColorArr.length){
          for (let i = 0; i < effectoptions.legendColorArr.length; i++) {
            effectoptions.graphic.elements[i].children[0].style = {
              text: `${effectoptions.legendLabelArr[i]}    `
            };
            effectoptions.graphic.elements[i].children[1].style = {
              fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: [effectoptions.legendColorArr[i][0]]
                },
                {
                  offset: 1,
                  color: [effectoptions.legendColorArr[i][1]]
                }
              ])
            };
          }
          effectoptions.graphic.elements.unshift(
            {
              type: 'group',
              right: '1.5%',
              top: '10%',
              children: [
                {
                  type: 'text',
                  style: {
                    text: 'Range of IRRs',
                    font: '600 12px sans-serif',
                    fill: '#222222',
                    textAlign: 'center',
                  }
                },
              ],
          });
        }
        effectoptions.tooltip = {
          show: true,
          formatter: (v) => {
            return `${v.value}% <br/> ${effectoptions.labelArr[v.dataIndex]}`
          }
        }
        effectoptions.series[0].label.formatter = (v) => {
          return v.value && effectoptions.labelData.functionData[v.dataIndex];
        }

      }
      return effectoptions;
    }
  }
};
</script>
