export const GENERATE_REPORT = 'reports/GENERATE_REPORT';
export const GENERATE_PRICE_TRACK_REPORT = 'reports/GENERATE_PRICE_TRACK_REPORT';
export const FETCH_REPORTS = 'reports/FETCH_REPORTS';
export const FETCH_OUTLIER_ANALYSIS = 'reports/FETCH_OUTLIER_ANALYSIS';
export const TOGGLE_FETCHING_REPORTS = 'reports/TOGGLE_FETCHING_REPORTS';
export const GET_PDF_URL = 'reports/GET_PDF_URL';
export const GET_EXPORT_JOB_BY_ID = 'reports/GET_EXPORT_JOB_BY_ID';
export const FETCH_PERFORMANCE_ATTRIBUTION = 'reports/FETCH_PERFORMANCE_ATTRIBUTION';
export const UPDATE_SEGMENT_LABEL_DATA = 'reports/UPDATE_SEGMENT_LABEL_DATA';
export const ADD_PA_FREE_TEXT = 'reports/ADD_PA_FREE_TEXT';
export const FETCH_PA_REPORT_URL = 'reports/FETCH_PA_REPORT_URL';
export const RUN_PA_SEPARATELY = 'reports/RUN_PA_SEPARATELY';
export const FETCH_SAMPLE_REPORT = 'reports/FETCH_SAMPLE_REPORT';
