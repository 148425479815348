import { MemberActions } from '@/modules/members/store/actions';
import { MemberGetters } from '@/modules/members/store/getters';
import { MemberMutations } from '@/modules/members/store/mutations';
import { MemberState, IMemberState } from '@/modules/members/store/state';
import { IRootState } from '@/store/state';
import { Module } from 'vuex';

export const MemberStore: IMemberStore = {
  state: MemberState,
  actions: MemberActions,
  getters: MemberGetters,
  mutations: MemberMutations
};

/**
 * --------------------------------------------------
 *	Fund Store Interface
 * --------------------------------------------------
 **/
export interface IMemberStore extends Module<IMemberState, IRootState> {}
