// actions
export const FETCH_MEMBERS = 'members/FETCH_MEMBERS';
export const FETCH_MEMBER_HISTORY = 'members/FETCH_MEMBER_HISTORY';
export const FETCH_CONTRIBUTION_HISTORY = 'members/FETCH_CONTRIBUTION_HISTORY';
export const FETCH_MEMBERS_ANALYSIS = 'members/FETCH_MEMBERS_ANALYSIS';
export const FETCH_MEMBER_FILTER_RANGES = 'members/FETCH_MEMBER_FILTER_RANGES';
export const EXPORT_MEMBERS_ANALYSIS = 'members/EXPORT_MEMBERS_ANALYSIS';
export const EXPORT_OUTLIER_ANALYSIS = 'members/EXPORT_OUTLIER_ANALYSIS';
export const FETCH_OUTLIERS_ANALYSIS = 'members/FETCH_OUTLIERS_ANALYSIS';
export const FETCH_FILTERED_MEMBERS_ANALYSIS = 'members/FETCH_FILTERED_MEMBERS_ANALYSIS';
export const TOGGLE_FETCHING_MEMBERS = 'members/FETCHING_MEMBERS';
export const GET_CSV_URL = 'members/GET_CSV_URL';
export const FETCH_OUTLIER_REASON_ANALYSIS = 'members/FETCH_OUTLIER_REASON_ANALYSIS';
export const EXPORT_OUTLIER_REASON_ANALYSIS = 'members/EXPORT_OUTLIER_REASON_ANALYSIS';
export const FETCH_SAMPLE_MEMBER_DATA = 'members/FETCH_SAMPLE_MEMBER_DATA';
export const FETCH_SAMPLE_MEMBERS_ANALYSIS = 'members/FETCH_SAMPLE_MEMBERS_ANALYSIS';
export const FETCH_SAMPLE_OUTLIER_REASON_ANALYSIS = 'members/FETCH_SAMPLE_OUTLIER_REASON_ANALYSIS';
