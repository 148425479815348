import Member from '@/models/Member';
/**
 * --------------------------------------------------
 *	Member State
 * --------------------------------------------------
 **/

export const MemberState: IMemberState = {
  members: [],
  memberHistory: null,
  outlierData: null,
  memberAnalysis: null,
  contributionHistory: [],
  fetchingMembers: false,
  memberFilterRanges: null,
  outlierReasonAnalysis: null,
  sampleMemberAnalysis: null
};

export interface IMemberState {
  members: Member[];
  memberHistory: any[] | null;
  outlierData: any[] | null;
  memberAnalysis: any[] | null;
  contributionHistory: any[];
  fetchingMembers: boolean;
  memberFilterRanges: any | null;
  outlierReasonAnalysis: any[] | null;
  sampleMemberAnalysis: any | null;
}
