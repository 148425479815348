import User from '@/models/User';
import * as AT from '@/modules/auth/endpoints';
import { HTTPServiceClient } from '@/packages/http/service';
import { LoginFormData, LoginResponseData, ResetPasswordFormData, ResetPasswordJWTFORM } from '@/modules/auth/types';
import Auth from '@/packages/Auth';

/**
 * --------------------------------------------------
 *	Authentication HTTP Serice
 * --------------------------------------------------
 **/
export const AuthService = {
  /**
   * Log in a User.
   * @param data {LoginFormData} Credentials from the login form.
   * @returns {Promise<LoginResponseData>} Auth Token and User Data.
   */
  login(data: LoginFormData): Promise<LoginResponseData> {
    return HTTPServiceClient.post(AT.LOGIN_ENDPOINT, { ...data }).then(({ data }) => data);
  },

  /**
   * Send reset password email.
   * @param data {LoginFormData} Credentials from the login form.
   * @returns {Promise<LoginResponseData>} Auth Token and User Data.
   */
   sendResetPasswordEmail(data: ResetPasswordFormData): Promise<LoginResponseData> {
    return HTTPServiceClient.post(AT.RESET_PASSWORD, { ...data }).then(({ data }) => data);
  },

  /**
   * Confirm reset password JWT.
   * @param data {LoginFormData} Credentials from the login form.
   * @returns {Promise<LoginResponseData>} Auth Token and User Data.
   */
   confirmJWTForPasswordReset(data: ResetPasswordJWTFORM): Promise<LoginResponseData> {
    return HTTPServiceClient.post(AT.RESET_CONFIRM_JWT, { ...data }).then(({ data }) => data);
  },

  /**
   * Fetch the currently logged in User.
   * @returns {Promise<User>} User Data.
   */
  getCurrentUser(): Promise<User> {
    return HTTPServiceClient.get(AT.CURRENT_USER_ENDPOINT)
      .then(({ data }) => {
        return data;
      })
      .catch(e => {
        Auth.removeUser();
        Auth.destroyToken();
        throw Error('User UnAuthorized');
      });
  }

  // getUserToken(token: string): Promise<any> {
  //   return HTTPServiceClient.get(AT.PASSWORD_RESET_ENDPOINT + `/${token}`).then(({ data }) => data);
  // }
};
