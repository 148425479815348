<template>
  <div data-testid="ProviderModule">
    <router-view v-if="moduleIsReady" />
    <div v-else>Loading Providers...</div>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { FETCH_PROVIDERS } from '@/modules/providers/store/actionTypes';

export default class ProvidersModule extends Vue {
  created() {
    this.bootstrap();
  }

  get providers() {
    return this.$store.getters.providers;
  }

  get moduleIsReady() {
    return this.providers;
  }

  bootstrap() {
    this.$store.dispatch(FETCH_PROVIDERS);
  }
}
</script>
