import * as AT from '@/modules/simulations/store/actionTypes';
import * as MT from '@/modules/simulations/store/mutationTypes';
import { IRootState } from '@/store/state';
import { ISimulationState } from '@/modules/simulations/store/state';
import { ActionContext, ActionTree } from 'vuex';
import { SimulationService } from '@/modules/simulations/services';

/**
 * --------------------------------------------------
 *  Simulation Actions
 * --------------------------------------------------
 **/
export const SimulationActions: ISimulationActions = {
  /**
   * --------------------------------------------------
   *  Fetch All the Simulations
   * --------------------------------------------------
   **/
  [AT.FETCH_SIMULATIONS]: ({ commit, dispatch }: any) => {
    return SimulationService.getSimulations()
      .then(simulations => {
        commit(MT.SET_SIMULATIONS, simulations);
        return simulations;
      });
  },
  [AT.FETCH_SIMULATION]: ({ commit, dispatch }: any, id: string) => {
    return SimulationService.getSimulation(id)
      .then(simulation => {
        commit(MT.SET_SIMULATION, simulation);
        return simulation;
      });
  },
  [AT.DOWNLOAD_STD_DEVS]: ({ commit, dispatch }: any, providerId: number) => {
    commit(MT.GETTING_STANDARD_DEVIATIONS, true);
    return SimulationService.getStDevsCSVURL(providerId)
    .finally(() => {
      commit(MT.GETTING_STANDARD_DEVIATIONS, false);
    });
  },
  [AT.ADD_SIMULATION]: ({ commit, dispatch }: any, data: any) => {
      commit(MT.ADDING_SIMULATION, true);
      return SimulationService.addSimulation(data)
      .then(resp => {
        return resp;
      })
      .finally(() => {
        commit(MT.ADDING_SIMULATION, false);
      });
  },
};

/**
 * --------------------------------------------------
 *  Simulation Action Context Interface
 * --------------------------------------------------
 **/
export interface ISimulationActionContext extends ActionContext<ISimulationState, IRootState> {}

/**
 * --------------------------------------------------
 *  Simulation Actions Interface
 * --------------------------------------------------
 **/
export interface ISimulationActions extends ActionTree<ISimulationState, IRootState> {}
