<template>
  <div data-testid="add-simulation-page" class="page add-simulation-page">
    <v-container fluid>
      <v-layout class="mt-4 pa-0">
        <div class="flex w-100">
          <v-card>
            <v-form @submit.prevent="addSimulation" class="py-5">
              <v-card-title class="d-flex align-center justify-space-between my-4">
                <span class="text-h5"> Add Simulation </span>
              </v-card-title>
              <v-card-text>
                <v-switch
                  hide-details
                  color="accent"
                  :persistent-hint="false"
                  :label="`Is SoS(Simulation of simulations): ${simulationObject.isSos ? 'Yes' : 'No'}`"
                  v-model="simulationObject.isSos"
                  class="mb-5"
                />
                <v-divider class="mb-6" />

                <v-label>Max number of users</v-label>
                <v-text-field
                  hide-details
                  :persistent-hint="false"
                  placeholder="Number of contributors in each simulation"
                  v-model="simulationObject.maxUsers"
                  variant="underlined"
                  type="number"
                  class="mb-5"
                />
                <v-label>Start Date (YYYY-MM-DD)</v-label>
                <v-text-field
                  hide-details
                  :persistent-hint="false"
                  placeholder="Start Date"
                  variant="underlined"
                  v-model="simulationObject.startDate"
                  class="mb-5"
                />

                <v-label>Start Date Type</v-label>
                <v-select
                  hide-details
                  :items="['month', 'year']"
                  :persistent-hint="false"
                  variant="underlined"
                  v-model="simulationObject.startDateType"
                  class="mb-5"
                />
                <v-label>End Date (YYYY-MM-DD)</v-label>
                <v-text-field
                  hide-details
                  :persistent-hint="false"
                  placeholder="End Date"
                  variant="underlined"
                  v-model="simulationObject.endDate"
                  class="mb-5"
                />

                <v-switch
                  hide-details
                  color="accent"
                  :persistent-hint="false"
                  :label="`Fixed Amount Contributions: ${simulationObject.fixedAmounts.toString()}`"
                  v-model="simulationObject.fixedAmounts"
                  class="mb-5"
                />
                <v-label>Max Contribution Amount</v-label>
                <v-text-field
                  hide-details
                  :persistent-hint="false"
                  placeholder="End Year"
                  type="number"
                  variant="underlined"
                  v-model="simulationObject.maxAmount"
                  class="mb-5"
                />
                <!-- <v-label>Fixed Number of Contributions</v-label> -->
                <v-switch
                  hide-details
                  color="accent"
                  :persistent-hint="false"
                  :label="`Fixed Number of Contributions: ${simulationObject.fixedContributions.toString()}`"
                  v-model="simulationObject.fixedContributions"
                  class="mb-5"
                />
                <v-label>Number of contributions/ Max number of contributions(if not fixed)</v-label>
                <v-text-field
                  hide-details
                  :persistent-hint="false"
                  variant="underlined"
                  placeholder="Number of contributions/ Max number of contributions(if not fixed)"
                  type="number"
                  v-model="simulationObject.maxContributions"
                  class="mb-5"
                />
                <div v-if="simulationObject.isSos">
                  <v-label>Number of simulations per contribution number</v-label>
                  <v-text-field
                    hide-details
                    :persistent-hint="false"
                    variant="underlined"
                    placeholder="Number of simulations per contribution number"
                    type="number"
                    v-model="simulationObject.simulationsPerContribution"
                    class="mb-5"
                  />
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  variant="flat"
                  data-testid="SubmitUploadBtn"
                  :color="addingSimulation ? 'disabled' : 'success'"
                  type="submit"
                  :loading="addingSimulation"
                  :disabled="addingSimulation"
                  >Add</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Vue, Watch } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppUploader from '@/components/AppUploader.vue';
import { ADD_SIMULATION } from '@/modules/simulations/store/actionTypes';
import { SET_NAV_HEADER } from '@/store/actionTypes';

@Options({
  components: {
    AppUploader,
  },
})
export default class ProvidersPage extends Vue {
  public validationError = '';
  public simulationObject = {
    isSos: true,
    maxUsers: 5000,
    startDate: '2015-01-01',
    startDateType: 'year',
    endDate: '2019-12-31',
    fixedAmounts: false,
    maxAmount: 1000,
    fixedContributions: true,
    maxContributions: 2,
    simulationsPerContribution: 20,
  };

  @Watch('addingSimulation')
  public userUpdated() {
    if (this.addingSimulation === false) {
      this.$router.push({ name: 'dashboard.simulations.list' });
    }
  }

  get addingSimulation() {
    return this.$store.getters.addingSimulation;
  }

  created() {
    this.bootstrap();
  }

  public bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Simulations', tooltip: 'Add Simulation' });
  }

  public addSimulation() {
    this.$store.dispatch(ADD_SIMULATION, this.simulationObject);
  }
}
</script>
