<template>
  <div data-testid="list-reports-page" class="page list-reports-page">
    <div>
      <div ref="filterPanel" class="mt-10 page-filters-wrapper mb-6">
        <div
          :threshold="filterPanelPosition"
          class="navbar page-filters-panel pos-relative justify-space-between"
          :class="scrolledAll ? 'vue-fixed-header--isFixed' : ''"
        >
          <v-layout>
            <v-row no-gutters align="end" justify="space-between">
              <!-- Date Filter -->
              <v-col class="d-flex" cols="12" sm="9">
                <!-- Provider Filter -->
                <v-row>
                  <v-col cols="3" align-self="center">
                    <v-label>Provider</v-label>
                    <v-autocomplete
                      :model-value="currentFilters.priceTrackId"
                      :items="priceTracks"
                      item-title="name"
                      item-value="id"
                      label="Price Track"
                      variant="solo"
                      :disabled="fetchingChartData"
                      hide-details
                      density="compact"
                      class="mt-2 filter-btn-active"
                      single-line
                      @update:modelValue="
                        (e) => {
                          onPriceTrackChange(e);
                        }
                      "
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <!-- End Buttons -->
              <v-col class="d-flex justify-end my-2" cols="12" sm="3">
                <v-btn
                  class="filter-btn back-btn elevation-6 mt-0 mr-2"
                  :ripple="false"
                  v-if="false"
                  variant="text"
                  to="/reports"
                >
                  <v-icon small>fas fa-arrow-left</v-icon>
                </v-btn>
                <v-btn
                  class="filter-btn elevation-6 mt-0 mx-2"
                  :ripple="false"
                  data-testid="ExportReportBtn"
                  variant="text"
                  :disabled="false"
                  :loading="processingReport"
                  @click="exportReport"
                >
                  <div class="mx-2">Export</div>
                  <v-icon small>fas fa-file-pdf</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-layout>
        </div>
      </div>

      <div>
        <div v-if="!fetchingChartData" height="100%">
          <ReportPageCover
            v-if="currentFilters.coverPage"
            :data="coverPageData"
            :chartData="chartData"
          ></ReportPageCover>
          <ScoreDist
            v-if="currentFilters.awDist"
            :data="page2Data"
            :chartData="chartData"
            :filters="currentFilters"
          ></ScoreDist>

          <div
            class="report-template-pa"
            v-if="
              this.currentFilters.priceTrackObject &&
              this.currentFilters.priceTrackObject.details &&
              (this.currentFilters.priceTrackObject.details.beta ||
                this.currentFilters.priceTrackObject.details.beta == 0)
            "
          >
            <v-card class="report-page-card-pa" outlined flat>
              <div class="card-data">
                <div class="page-content-pa">
                  <div class="page-content-title-pa sub-header-title-pa">
                    {{ currentFilters.coverPage.title }} Attribution Analysis
                  </div>
                  <div class="page-content-text-pa">
                    The following results are from the analysis conducted for a cohort of
                    {{ numberWithCommas(chartData.saversCount) }} members over the time period of {{ this.timePeriod }}.
                    <br />
                  </div>

                  <div class="page-content-title-pa">Level of Risk Taken</div>
                  <div class="page-content-text-pa">
                    The level of risk taken is represented by Beta (ß) which indicates the relative returns of the fund
                    compared to the AgeWage benchmark index:
                  </div>

                  <div class="page-content-title-pa text-center">
                    Beta (ß) = {{ this.currentFilters.priceTrackObject.details.beta }}
                  </div>

                  <div class="page-content-text-pa">
                    The above Beta (ß) indicates that for a 1% benchmark return, the portfolio should have returned
                    {{ this.currentFilters.priceTrackObject.details.beta }}% on a risk-adjusted basis. Hence if the
                    portfolio returned more than {{ this.currentFilters.priceTrackObject.details.beta }} times the
                    benchmark returns, it enhanced the outcome compared to the benchmark. As the Beta is
                    {{ +this.currentFilters.priceTrackObject.details.beta > 1 ? 'over' : 'under' }} 1, savers have been
                    exposed to {{ this.currentFilters.riskCompare }} risk than benchmark and should be expecting a
                    {{ this.currentFilters.riskCompare }} return
                    {{ this.currentFilters.priceTrackObject.details.returnCompare }} the benchmark index over time.
                  </div>

                  <div class="page-content-title-pa">Risk Adjusted Outcome</div>
                  <div class="page-content-text-pa">
                    The risk-adjusted outcome represents the impact of factors such as costs, fees, stock selection, bid
                    offer spread and other variables on the investment portfolio returns. The experienced portfolio
                    index is compared to the benchmark index to understand the level of risk taken. It is subsequently
                    used to calculate the outcome enhancement / shortfall based on the level of risk taken. Based on the
                    data analysis conducted for {{ numberWithCommas(chartData.saversCount) }}
                    members, the following portfolio outcome was determined (using the benchmark index as a comparison):
                  </div>

                  <div class="page-content-title-pa text-center">
                    Risk Adjusted Annual Outcome
                    {{
                      this.currentFilters.priceTrackObject.details.alpha &&
                      this.currentFilters.priceTrackObject.details.alpha > 0
                        ? 'Enhancement'
                        : 'Shortfall'
                    }}
                    =
                    {{
                      this.currentFilters.priceTrackObject.details.alpha &&
                      Math.abs(this.currentFilters.priceTrackObject.details.alpha)
                    }}%
                  </div>

                  <div class="page-content-text-pa">
                    The calculation indicates that <b> after adjusting for risks</b>, the
                    {{ currentFilters.coverPage.title }} fund, on average, has generated
                    {{
                      this.currentFilters.priceTrackObject.details.alpha &&
                      Math.abs(this.currentFilters.priceTrackObject.details.alpha)
                    }}% {{ this.currentFilters.priceTrackObject.details.benchmarkCompare }} returns
                    {{ this.currentFilters.priceTrackObject.details.returnCompare }} the benchmark index annually from
                    {{ this.timePeriod }}
                  </div>
                </div>
              </div>
              <div class="footer">
                <div></div>
                <v-img class="footer-img" src="../../../assets/aw-footer-logo.jpg"></v-img>
              </div>
            </v-card>
          </div>
          <div class="report-template-pa">
            <v-card class="report-page-card-pa" outlined flat>
              <div class="card-data">
                <div class="page-content-pa">
                  <div class="page-content-title-pa">Price Track Comparison</div>
                  <div class="page-content-text-pa">
                    This figure shows how the portfolio price index has changed over time compared to the benchmark
                    index. The difference between the indexes highlights the effects of asset allocation, fees and
                    charges, stock selection, sequential risk and other variables on the portfolio's performance.
                  </div>
                  <div class="report-chart-pa">
                    <PriceTrackChart :chartData="getPriceTrackGraph()" title="Price Track Comparison" />
                  </div>
                  <div class="chart-legend-pa">Figure : Portfolio vs Benchmark price track</div>
                </div>
              </div>

              <div class="footer">
                <div></div>
                <v-img class="footer-img" src="../../../assets/aw-footer-logo.jpg"></v-img>
              </div>
            </v-card>
          </div>

          <div v-if="pastPerformanceFetched" class="report-template-pa">
            <v-card class="report-page-card-pa" outlined flat>
              <div class="card-data">
                <div class="page-content-pa">
                  <div class="page-content-title-pa">Past Performance Comparison</div>
                  <div class="page-content-text-pa">
                    This figure shows how the AgeWage scores have changed over time.
                  </div>
                  <div class="report-chart-pa">
                    <PriceTrackChart :chartData="getPastPerformanceGraph()" title="Past Performance Comparison" />
                  </div>
                  <div class="chart-legend-pa">Figure : AgeWage Score vs Time</div>
                </div>
              </div>

              <div class="footer">
                <div></div>
                <v-img class="footer-img" src="../../../assets/aw-footer-logo.jpg"></v-img>
              </div>
            </v-card>
          </div>

          <div
            v-if="
              this.currentFilters.priceTrackObject &&
              this.currentFilters.priceTrackObject.details &&
              (this.currentFilters.priceTrackObject.details.beta ||
                this.currentFilters.priceTrackObject.details.beta == 0)
            "
            class="report-template-pa"
          >
            <v-card class="report-page-card-pa" outlined flat>
              <div class="card-data">
                <div class="page-content-pa">
                  <div class="page-content-title-pa">Monthly deltas over time</div>
                  <div class="page-content-text-pa">This figure shows delta over the last month's return.</div>
                  <div class="report-chart-pa">
                    <PriceTrackChart :chartData="getDeltaGraph()" title="Delta over time" />
                  </div>
                  <div class="chart-legend-pa">Figure : Delta vs Time</div>
                </div>
              </div>

              <div class="footer">
                <div></div>
                <v-img class="footer-img" src="../../../assets/aw-footer-logo.jpg"></v-img>
              </div>
            </v-card>
          </div>

          <IrrAndIav
            v-if="currentFilters.iAsset || currentFilters.Irr"
            :data="page2Data"
            :chartData="irrAndIavData"
            :filters="currentFilters"
          ></IrrAndIav>
          <EPS v-if="currentFilters.ePotSize" :data="page2Data" :chartData="ePotData" :filters="currentFilters"></EPS>
          <SccAndIsy
            v-if="(sccAndIsyData && currentFilters.schemeSeg) || currentFilters.yearSeg"
            :data="page2Data"
            :chartData="sccAndIsyData"
            :filters="currentFilters"
          ></SccAndIsy>
          <ReportStaticText> </ReportStaticText>
        </div>
        <div v-if="fetchingChartData" class="d-flex align-center justify-center pt-5 mt-5">
          <v-progress-circular indeterminate color="grey" />
        </div>
      </div>
    </div>
    <v-snackbar v-model="pdfTimeoutError" :timeout="3000" color="primary" location="top">
      <h3>Error downloading requested pdf. Please try again.</h3>
      <v-btn color="blue" variant="text" class="d-flex ml-auto" @click="pdfTimeoutError = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { SET_NAV_HEADER } from '@/store/actionTypes';
import { GENERATE_PRICE_TRACK_REPORT, GET_PDF_URL, GET_EXPORT_JOB_BY_ID } from '@/modules/reports/store/actionTypes';
import { FETCH_CURRENT_USER } from '@/modules/auth/store/actionTypes';
import User from '@/models/User';
import * as AT from '@/modules/price-track/store/actionTypes';
import ReportPageCover from '@/modules/reports/pages/templates/ReportPageCover.vue';
import SccAndIsy from '@/modules/reports/pages/templates/SccAndIsy.vue';
import IrrAndIav from '@/modules/reports/pages/templates/IrrAndIav.vue';
import EPS from '@/modules/reports/pages/templates/EPS.vue';
import AVB from '@/modules/reports/pages/templates/AVB.vue';
import ScoreDist from '@/modules/reports/pages/templates/ScoreDist.vue';
import ReportStaticText from '@/modules/reports/pages/templates/ReportStaticText.vue';
// import FixedHeader from 'vue-fixed-header';
import Utils from '@/utils/Utils';
import PriceTrackChart from '@/components/charts/PriceTrackChart.vue';

@Options({
  components: {
    ReportPageCover,
    IrrAndIav,
    EPS,
    AVB,
    SccAndIsy,
    ReportStaticText,
    // FixedHeader,
    ScoreDist,
    PriceTrackChart,
  },
})
export default class ReportGenPage extends Vue {
  created() {
    this.bootstrap();
  }

  public fetchingReport = false;

  public processingReport = false;

  public filterIsVisible = false;

  public filterPanelPosition = 200;

  public pdfTimeoutError = false;

  public pastPerformanceFetched = false;

  public scrolledAll: Boolean = false;

  public pastPerformanceData: any = {};

  public currentFilters: any = {
    coverPage: {
      title: '',
      subTitle: '',
    },
    awDist: true,
    yearSeg: true,
    avb: true,
    Irr: true,
    schemeSeg: false,
    iAsset: true,
    ePotSize: true,
    priceTrackId: this.priceTracks && this.priceTracks.length > 0 ? this.priceTracks[0].id : null,
    startDate: null,
    endDate: null,
    priceTrackObject: null,
  };

  public get coverPageData() {
    return {
      title: this.selectedPriceTrack && this.selectedPriceTrack.name,
      subTitle: 'VALUE FOR MONEY REPORT',
    };
  }

  public get user(): User {
    return this.$store.getters.currentUser;
  }

  public get page2Data() {
    return {
      title: 'Year Segmentation',
      subTitle: 'Monthly Report',
      avgIrr: '7%',
      underperform: '1%',
    };
  }

  public get selectedPriceTrack() {
    const id = this.currentFilters.priceTrackId;
    if (!id) {
      return null;
    }

    return this.priceTracks && this.priceTracks.find((p: any) => p.id === id);
  }

  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (this.$refs.filterPanel && this.$refs.filterPanel instanceof Element) {
      const topPosition = this.$refs.filterPanel.getBoundingClientRect().top;
      if (topPosition == 0) {
        this.scrolledAll = true;
      } else {
        this.scrolledAll = false;
      }
    }
  }

  public async exportReport() {
    //PDF Export Polling with 2sec interval and 16sec Timout
    this.processingReport = true;
    const job = await this.$store.dispatch(GENERATE_PRICE_TRACK_REPORT, this.currentFilters);

    let pdfFetchInterval = setInterval(async () => {
      let response = await this.$store.dispatch(GET_EXPORT_JOB_BY_ID, job.id);
      if (response[0].status === 'completed') {
        const url = await this.$store.dispatch(GET_PDF_URL, job.id);
        await window.open(url);
        this.processingReport = false;
        clearInterval(pdfFetchInterval);
      }
      return response;
    }, Utils.pdfInterval);
    setTimeout(() => {
      clearInterval(pdfFetchInterval);
      if (this.processingReport) {
        this.pdfTimeoutError = true;
      }
      this.processingReport = false;
    }, Utils.pdfTimeout);
  }

  public numberWithCommas(x: string) {
    if (!x) return '';
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  public async bootstrap() {
    await this.$store.dispatch(AT.FETCH_PRICE_TRACKS);
    this.currentFilters.priceTrackId = this.priceTracks && this.priceTracks.length > 0 ? this.priceTracks[0].id : null;
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Price Track Reports', tooltip: 'Export Reports' });
    await this.onPriceTrackChange(this.currentFilters.priceTrackId);
    this.$store.dispatch(FETCH_CURRENT_USER);
  }

  public get chartData() {
    return this.$store.getters.chartData;
  }

  public get fetchingReports() {
    return this.$store.getters.fetchingReports;
  }

  public get timePeriod() {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let timePeriod = '';
    if (this.currentFilters.priceTrackObject) {
      let startDate = new Date(`${this.currentFilters.priceTrackObject.firstDate}T00:00:00.000Z`);
      let endDate = new Date(`${this.currentFilters.priceTrackObject.navDate}T00:00:00.000Z`);
      timePeriod = `${monthNames[startDate.getUTCMonth()]}, ${startDate.getUTCFullYear()} to ${
        monthNames[endDate.getUTCMonth()]
      }, ${endDate.getUTCFullYear()}`;
    }
    return timePeriod;
  }

  public get sccAndIsyData() {
    if (!this.chartData) {
      return null;
    }

    return {
      investments: this.chartData.investments,
    };
  }

  public get irrAndIavData() {
    if (!this.chartData) {
      return null;
    }

    return {
      irr: this.chartData.irr,
      iav: this.chartData.assetImpactVal,
    };
  }

  public get ePotData() {
    if (!this.chartData) {
      return null;
    }

    return {
      ePot: this.chartData.potEffectVal,
    };
  }

  public get fetchingChartData() {
    return this.$store.getters.fetchingChartData;
  }

  public get currentPriceTrack() {
    return this.$store.getters.currentPriceTrack;
  }

  public get priceTracks() {
    return this.$store && this.$store.getters.priceTracks.filter((a: any) => !!a.dataBatchId);
  }

  public getPriceTrackGraph() {
    let priceTrackOptions = null;
    if (
      this.currentFilters.priceTrackObject &&
      this.currentFilters.priceTrackObject.details &&
      this.currentFilters.priceTrackObject.details.priceTrackGraphData
    ) {
      priceTrackOptions = {
        grid: {
          containLabel: true,
        },
        xAxis: {
          name: 'Date',
          nameLocation: 'middle',
          nameTextStyle: {
            color: '#6f6c76',
            fontFamily: 'Open Sans',
            fontWeight: 'bolder',
            padding: 50,
          },
          type: 'category',
          data: this.currentFilters.priceTrackObject.details.priceTrackGraphData.map((a: (number | string)[]) => a[0]),
          axisLabel: {
            rotate: '50',
            fontWeight: '600',
            fontFamily: 'Open Sans',
            color: '#6f6c76',
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        legend: {
          show: true,
          icon: 'circle',
          top: 10,
          data: ['Benchmark', 'Portfolio'],
        },
        tooltip: {
          trigger: 'axis',
        },
        yAxis: {
          name: 'Unit Price',
          nameGap: 50,
          nameTextStyle: {
            color: '#6f6c76',
            fontFamily: 'Open Sans',
            fontWeight: 'bolder',
          },
          nameLocation: 'middle',
          type: 'value',
        },
        series: [
          {
            name: 'Portfolio',
            data: this.currentFilters.priceTrackObject.details.priceTrackGraphData.map(
              (a: (number | string)[]) => a[1]
            ),
            type: 'line',
            color: '#c23431',
          },
          {
            name: 'Benchmark',
            data: this.currentFilters.priceTrackObject.details.priceTrackGraphData.map(
              (a: (number | string)[]) => a[2]
            ),
            type: 'line',
            color: '#334b5c',
          },
        ],
        toolbox: {
          feature: {
            dataZoom: {
              show: true,
              title: {
                zoom: 'Zoom In',
                back: 'Zoom Out',
              },
              iconStyle: {
                borderWidth: 2,
              },
            },
            restore: {
              title: 'Refresh',
              iconStyle: {
                borderWidth: 2,
              },
            },
          },
          itemGap: 15,
          right: '10%',
        },
      };
    }
    return priceTrackOptions;
  }

  public get deltaData() {
    if (!this.chartData) {
      return null;
    }

    return this.chartData.deltaSeries;
  }

  public getDeltaGraph() {
    let deltaOptions = null;
    if (this.deltaData && this.deltaData.length) {
      deltaOptions = {
        grid: {
          containLabel: true,
        },
        xAxis: {
          name: 'Date',
          nameLocation: 'middle',
          nameTextStyle: {
            color: '#6f6c76',
            fontFamily: 'Open Sans',
            fontWeight: 'bolder',
            padding: 50,
          },
          type: 'category',
          data: this.deltaData.map((a: any) => a.date),
          axisLabel: {
            rotate: '50',
            fontWeight: '600',
            fontFamily: 'Open Sans',
            color: '#6f6c76',
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        legend: {
          show: true,
          icon: 'circle',
          top: 10,
          data: ['Portfolio vs Benchmark Delta'],
        },
        tooltip: {
          show: true,
          trigger: 'axis',
        },
        yAxis: {
          name: 'Delta Percentage',
          nameGap: 50,
          nameTextStyle: {
            color: '#6f6c76',
            fontFamily: 'Open Sans',
            fontWeight: 'bolder',
          },
          nameLocation: 'middle',
          type: 'value',
        },
        series: [
          {
            name: 'Portfolio vs Benchmark Delta',
            data: this.deltaData.map((a: any) => a.portfolioDelta - a.benchmarkDelta),
            type: 'line',
            color: '#c23431',
          },
        ],
        toolbox: {
          feature: {
            dataZoom: {
              show: true,
              title: {
                zoom: 'Zoom In',
                back: 'Zoom Out',
              },
              iconStyle: {
                borderWidth: 2,
              },
            },
            restore: {
              title: 'Refresh',
              iconStyle: {
                borderWidth: 2,
              },
            },
          },
          itemGap: 15,
          right: '10%',
        },
      };
    }
    return deltaOptions;
  }

  public getPastPerformanceGraph() {
    let pastPerformanceOptions = null;
    if (
      this.currentFilters.priceTrackObject &&
      this.currentFilters.priceTrackObject.details &&
      this.pastPerformanceData &&
      Object.keys(this.pastPerformanceData).length
    ) {
      pastPerformanceOptions = {
        grid: {
          containLabel: true,
        },
        xAxis: {
          name: 'Date',
          nameLocation: 'middle',
          nameTextStyle: {
            color: '#6f6c76',
            fontFamily: 'Open Sans',
            fontWeight: 'bolder',
            padding: 50,
          },
          type: 'category',
          data: Object.keys(this.pastPerformanceData),
          axisLabel: {
            rotate: '50',
            fontWeight: '600',
            fontFamily: 'Open Sans',
            color: '#6f6c76',
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        legend: {
          show: true,
          icon: 'circle',
          top: 10,
          data: ['Score', 'Average IRR', 'Average Benchmark IRR'],
        },
        tooltip: {
          trigger: 'axis',
        },
        yAxis: {
          name: 'Score',
          nameGap: 50,
          nameTextStyle: {
            color: '#6f6c76',
            fontFamily: 'Open Sans',
            fontWeight: 'bolder',
          },
          nameLocation: 'middle',
          type: 'value',
        },
        series: [
          {
            name: 'Score',
            data: Object.values(this.pastPerformanceData)
              .map((a: any) => a.score),
            type: 'line',
            color: '#c23431',
          },
          {
            name: 'Average IRR',
            data: Object.values(this.pastPerformanceData)
              .map((a: any) => a.irr * 100),
            type: 'line',
            color: '#334b5c',
          },
          {
            name: 'Average Benchmark IRR',
            data: Object.values(this.pastPerformanceData)
              .map((a: any) => a.birr * 100),
            type: 'line',
            color: '#62a0a7',
          },
        ],
        toolbox: {
          feature: {
            dataZoom: {
              show: true,
              title: {
                zoom: 'Zoom In',
                back: 'Zoom Out',
              },
              iconStyle: {
                borderWidth: 2,
              },
            },
            restore: {
              title: 'Refresh',
              iconStyle: {
                borderWidth: 2,
              },
            },
          },
          itemGap: 15,
          right: '10%',
        },
      };
    }
    return pastPerformanceOptions;
  }

  private getMedian(arr: number[]): number {
    const mid = Math.floor(arr.length / 2),
      nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
  }

  private getMean(deltas: number[]): number {
    return deltas.reduce((m, n) => Number(m) + Number(n), 0) / deltas.length;
  }

  private getStandardDeviation(arr: number[]): number {
    const mean = this.getMean(arr);
    return Math.sqrt(
      arr.reduce((sq, n) => {
        return sq + Math.pow(n - mean, 2);
      }, 0) / arr.length
    );
  }

  public async onPriceTrackChange(priceTrackId: any) {
    this.pastPerformanceFetched = false;
    this.pastPerformanceData = {};
    this.currentFilters.priceTrackId = priceTrackId;
    await this.$store.dispatch(AT.FETCH_PRICE_TRACK, priceTrackId);
    this.currentFilters.priceTrackObject = this.currentPriceTrack;

    if (this.currentPriceTrack) {
      await this.$store.dispatch(AT.FETCH_FILTERED_MEMBERS_ANALYSIS_PRICE_TRACK, {
        priceTrackId,
      });
      await this.$store.dispatch(AT.FETCH_CHARTDATA_PRICE_TRACK, { priceTrackId: this.currentPriceTrack.id });
      if (this.currentPriceTrack.details.multipleNAVs && this.currentPriceTrack.details.multipleNAVs.length) {
        let resp = await this.$store.dispatch(
          AT.FETCH_FILTERED_MEMBERS_ANALYSIS_PAST_PERFORMANCE_PRICE_TRACK,
          this.currentFilters.priceTrackId
        );
        this.pastPerformanceFetched = true;
        this.pastPerformanceData = resp.data;
        console.log('this.pastPerformanceData', this.pastPerformanceData);
        let scoresArray = [];
        let irrArray = [];
        let birrArray = [];
        for (const customNavDate in this.pastPerformanceData) {
          if (this.pastPerformanceData[customNavDate]) {
            scoresArray.push(this.pastPerformanceData[customNavDate].score);
            irrArray.push(this.pastPerformanceData[customNavDate].irr);
            birrArray.push(this.pastPerformanceData[customNavDate].birr);
          }
        }
        const scoreStDev = this.getStandardDeviation(scoresArray);
        const scoreMean = this.getMean(scoresArray);
        const scoreMedian = this.getMedian(scoresArray);

        const irrStDev = this.getStandardDeviation(irrArray);
        const irrMean = this.getMean(irrArray);
        const irrMedian = this.getMedian(irrArray);

        const birrStDev = this.getStandardDeviation(birrArray);
        const birrMean = this.getMean(birrArray);
        const birrMedian = this.getMedian(birrArray);

        console.log('Score Data StDev, mean, median', scoreStDev, scoreMean, scoreMedian);
        console.log('IRR Data StDev, mean, median', irrStDev, irrMean, irrMedian);
        console.log('BIRR Data StDev, mean, median', birrStDev, birrMean, birrMedian);
        // Use the scoreMedian as the final Data point with +- scoreStDev as the range.
      }
      console.log('fetched multiple NAVs data');
    }
  }
}
</script>
<style lang="scss">
.report-template-pa .report-page-card-pa .main-page-feature-pa {
  width: 100%;
  margin: auto;
  max-width: 85%;
  margin-top: 15rem;
  margin-left: 5rem;
}
.report-template-pa .report-page-card-pa .main-page-feature-pa .feature-title-pa {
  font-size: 3.5rem;
  font-weight: 500;
}

.report-template-pa .report-page-card-pa .main-page-feature-pa .feature-sub-title-pa {
  font-size: 1.3rem;
  font-weight: 500;
  color: #e2af06;
}

.report-template-pa .report-page-card-pa .main-page-feature-pa .feature-sub-header-pa {
  font-size: 2.5rem;
  font-weight: 500;
  color: #e2af06;
}

.report-template-pa .report-page-card-pa .page-content-pa {
  width: 100%;
  margin: auto;
  max-width: 85%;
  margin-top: 1rem;
  text-align: center;
}

.report-template-pa .report-page-card-pa .page-content-pa .page-content-title-pa {
  font-size: 1.2rem;
  font-weight: 700;
  padding-top: 2rem;
  padding-bottom: 1rem;
  text-align: left;
}

.report-template-pa .report-page-card-pa .page-content-pa .page-content-text-pa {
  font-size: 14px;
  padding-bottom: 0rem;
  text-align: justify;
  color: #463b4a;
  line-height: 2rem;
}
.sub-header-title-pa {
  text-align: center !important;
  font-size: 1.7rem !important;
}
.chartWrapper-pa {
  height: 27rem;
  max-width: 90% !important;
  border-radius: 0.2rem;
  background: #fff;
  margin: 0 auto;
}
.report-chart-pa {
  margin: 0 auto;
  width: 100%;
  padding: 2rem 0rem;
  margin: 2rem 0 !important;
  background: #dac5e221;
}

.chart-title-pa {
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center !important;
  padding: 1rem 0 0 2rem;
}
.chart-legend-pa {
  text-align: center;
  color: #463b4aad;
  font-weight: 600;
}
.v-input__icon .v-input__icon--append {
  cursor: pointer;
}

.filter-menu {
  background: #ffffff !important;
  .v-label {
    font-weight: 500;
  }
}

.filter-btn-active {
  background-color: #dfc3e6 !important;
}
.filter-btn-active .v-input__slot {
  background-color: #dfc3e6 !important;
}

.page-filters-wrapper {
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  position: sticky !important;
}

.page-filters-panel {
  &.vue-fixed-header--isFixed {
    background: #fff;
    margin-top: 0 !important;
    padding: 1rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  }
}
.list-reports-page {
  .truncate {
    width: 7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .back-btn:hover::before,
  .back-btn::before {
    opacity: 0 !important;
  }
}

.report-template-pa {
  // padding: 0.5rem;
  margin-top: 0.5rem;
  .report-page-card-pa {
    .card-data {
      padding: 1rem 0rem;
      min-height: 70rem;
    }
    .top-triangle {
      position: absolute;
      right: 0;
      .top-right-color-triangle {
        position: absolute;
        right: 0;
        width: 0;
        height: 0;
        z-index: 1;
        border-style: solid;
        border-width: 0 200px 200px 0;
        border-color: transparent #dfc6e6 transparent transparent;
      }
      .top-right-grey-triangle {
        position: absolute;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 400px 200px 0;
        border-color: transparent #f5f5f5 transparent transparent;
      }
    }
    .footer {
      width: 100%;
      max-width: 90%;
      padding: 1.5rem 0;
      margin: auto;
      display: flex;
      justify-content: space-between;

      .page-box {
        padding: 0.2rem;
        width: 7rem;
        margin: auto 0;
        border-radius: 0.2rem;
        background-color: #a994b1;
        .pageno {
          text-align: center;
          color: #fff;
        }
      }
      .footer-img {
        max-width: 10rem;
      }
    }
    .report-page-title {
      margin: 0 auto;
      text-align: center;
      font-size: 2.5rem;
      font-weight: 500;
    }
    .report-page-subtitle {
      margin: 0 auto;
      text-align: center;
      font-size: 2rem;
      font-weight: 300;
    }

    .main-page-feature-pa {
      margin-left: 5rem;
      margin-top: 10rem;
      .v-image {
        width: 30rem;
      }
      .feature-title {
        font-size: 7rem;
        font-weight: 500;
        // color: #e2af06;
      }
      .feature-sub-title {
        font-size: 1.7rem;
        font-weight: 500;
        color: #e2af06;
      }
    }
    .main-page-content {
      margin: 7rem 0 0 5rem;
      .main-page-content-title {
        font-size: 1.5rem;
        font-weight: 600;
        padding-top: 3rem;
        padding-bottom: 1rem;
      }
      .main-page-text {
        // margin: 1.5rem auto 0 auto;
        // text-align: center;
        font-size: 1rem;
        padding-bottom: 4rem;
        max-width: 90%;
        text-align: justify;
      }
    }
    .section {
      max-width: 80%;
      width: 100%;
      margin: auto;
      margin-top: 3rem;
      // text-align: center;
      .section-title {
        margin: 0 auto;
        font-size: 1.4rem;
        font-weight: 600;
        padding-bottom: 1rem;
        display: flex;
        color: #000;
      }
      .section-content {
        font-size: 1rem;
        margin-bottom: 3rem;
        text-align: justify;
      }
    }
    .section-badge {
      height: 30px;
      width: 7px;
      margin-right: 1rem;
      background-color: #796581;
    }
    .report-chart {
      margin: 0 auto;
      width: 100%;
      padding: 2rem 0rem;
      background: #dac5e221;
    }
    .chart-title {
      font-weight: 600;
      font-size: 1.2rem;
      text-align: left;
      padding: 2rem 0 1.7rem 2rem;
    }
    .chartWrapper {
      height: auto;
      width: 100%;
      max-width: 70rem;
      border-radius: 0.2rem;
      background: #fff;
      margin: 0 auto;
    }
    .text-blue {
      color: blue;
    }
  }
}
.provider-btn {
  position: relative !important;
  display: flex !important;
  padding: 0 !important;
  width: 95% !important;
  margin: 0 !important;
  flex: 1 1 auto;
  justify-content: flex-start;
  text-transform: none;
  padding-left: 6px !important;
  font-size: 16px !important;
  .v-btn__content {
    max-width: 90% !important;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left !important;
  }
}
</style>
