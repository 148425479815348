import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import './registerServiceWorker';
import RootStore from './store';
import './sass/main.scss';
import vuetify from './plugins/vuetify';

const app = createApp(App);
app.use(RootStore).use(router).use(vuetify).mount('#app');
