<template>
  <div data-testid="list-reports-page" class="page list-reports-page">
    <div>
      <div ref="filterPanel" class="mt-10 page-filters-wrapper mb-6">
        <div
          :threshold="filterPanelPosition"
          class="navbar page-filters-panel pos-relative justify-space-between"
          :class="scrolledAll ? 'vue-fixed-header--isFixed' : ''"
        >
          <v-layout>
            <v-row no-gutters align="end" justify="space-between">
              <!-- Date Filter -->
              <v-col class="d-flex" cols="12" sm="9">
                <!-- Provider Filter -->
                <v-row>
                  <v-col cols="3" align-self="center">
                    <v-label>Provider</v-label>
                    <v-autocomplete
                      :model-value="currentFilters.provider"
                      :items="providers"
                      item-title="name"
                      item-value="id"
                      label="Provider"
                      variant="solo"
                      :disabled="fetchingBechmarkingReports"
                      hide-details
                      density="compact"
                      class="mt-2 filter-btn-active"
                      single-line
                      @update:modelValue="
                        (e) => {
                          onProviderChange(e);
                        }
                      "
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <!-- End Buttons -->
              <v-col class="d-flex justify-end my-2" cols="12" sm="3">
                <v-btn
                  class="filter-btn back-btn elevation-6 mt-0 mr-2"
                  :ripple="false"
                  v-if="false"
                  variant="text"
                  to="/reports"
                >
                  <v-icon small>fas fa-arrow-left</v-icon>
                </v-btn>
                <v-btn
                  class="filter-btn elevation-6 mt-0 mx-2"
                  :ripple="false"
                  data-testid="ExportReportBtn"
                  variant="text"
                  :disabled="fetchingBechmarkingReports"
                  :loading="processingReport"
                  @click="exportReport"
                >
                  <div class="mx-2">Export</div>
                  <v-icon small>fas fa-file-pdf</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-layout>
        </div>
      </div>

      <div>
        <div v-if="!fetchingBechmarkingReports" height="100%">
          <ReportPageCover v-if="currentFilters.coverPage" :data="coverPageData"></ReportPageCover>
          <VFMCompare
            v-if="reportsData && reportsData.length"
            :data="reportsData"
            :providerName="selectedProvider && selectedProvider.name"
            :providerId="currentFilters.provider"
          />
          <DataQuality
            v-if="reportsData && reportsData.length"
            :data="reportsData"
            :providerName="selectedProvider && selectedProvider.name"
            :providerId="currentFilters.provider"
          />
          <ReportStaticText :getVFMReport="getVFMReport"> </ReportStaticText>
        </div>
        <div v-if="fetchingBechmarkingReports" class="d-flex align-center justify-center pt-5 mt-5">
          <v-progress-circular indeterminate color="grey" />
        </div>
      </div>
      <v-dialog
        v-model="requestVFMSuccessDialog"
        @click:outside="requestVFMSuccessDialog = false"
        persistent
        max-width="50vw"
      >
        <v-card>
          <v-card-title style="word-break: break-word">
            {{ requestVFMmsg }}
          </v-card-title>
          <v-card-actions>
            <v-btn class="mx-auto" color="grey-darken-1" variant="text" @click="requestVFMSuccessDialog = false"
              >Ok</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar v-model="pdfTimeoutError" :timeout="3000" color="primary" location="top">
      <h3>Error downloading requested pdf. Please try again.</h3>
      <v-btn color="blue" variant="text" class="d-flex ml-auto" @click="pdfTimeoutError = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { SET_NAV_HEADER } from '@/store/actionTypes';
import { GENERATE_REPORT, GET_PDF_URL, GET_EXPORT_JOB_BY_ID } from '@/modules/reports/store/actionTypes';
import {
  FETCH_REPORT_DATA,
  GENERATE_BENCHMARK_REPORT,
  REQUEST_VFM_REPORT,
} from '@/modules/benchmarking/store/actionTypes';
import { FETCH_PROVIDER_DATA_QUALITY } from '@/modules/providers/store/actionTypes';
import { FETCH_CURRENT_USER } from '@/modules/auth/store/actionTypes';
import User from '@/models/User';
import * as AT from '@/modules/members/store/actionTypes';
import ReportPageCover from './templates/ReportPageCover.vue';
import ReportStaticText from './templates/ReportStaticText.vue';
// import FixedHeader  from 'vue-fixed-header';
import axios from 'axios';
import Utils from '@/utils/Utils';
import DataQuality from './templates/DataQuality.vue';
import VFMCompare from './templates/VFMCompare.vue';

@Options({
  components: {
    ReportPageCover,
    ReportStaticText,
    // FixedHeader,
    DataQuality,
    VFMCompare,
  },
})
export default class ReportGenPage extends Vue {
  created() {
    this.bootstrap();
    window.scrollTo(0, 0);
  }

  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  public startDatePicker = false;
  public endDatePicker = false;

  public filtersAreApplied: boolean = false;

  public fetchingReport = false;

  public processingReport = false;

  public triggeringPA = false;

  // public paCallSuccessful = false;

  public filterIsVisible = false;

  public filterPanelPosition = 200;

  public capitalizeFirstLetterEveryWord = Utils.capitalizeLetter;

  public requestVFMSuccessDialog = false;

  public requestVFMmsg = '';

  public pdfTimeoutError = false;

  public scrolledAll: Boolean = false;

  public currentFilters: any = {
    coverPage: true,
    awDist: true,
    yearSeg: true,
    avb: true,
    Irr: true,
    schemeSeg: false,
    segmentData: true,
    iAsset: true,
    ePotSize: true,
    outliers: true,
    provider: this.providers && this.providers.length > 0 ? this.providers[0].id : null,
    startDate: null,
    endDate: null,
  };

  public get coverPageData() {
    return {
      title: this.selectedProvider && this.selectedProvider.name,
      subTitle: 'BENCHMARKING REPORT',
    };
  }

  public get user(): User {
    return this.$store.getters.currentUser;
  }

  public get isSuperUser() {
    return this.providers && this.user && this.user.role === 'superuser';
  }

  public get isPerformanceAttribution() {
    return true;
  }

  public get outliers() {
    return this.$store.getters.outliers && this.$store.getters.outliers.data;
  }

  public get outlierGroups() {
    return this.$store.getters.outliers && this.$store.getters.outliers.outlierGroups;
  }

  public get totalOutliers() {
    return this.$store.getters.outliers && this.$store.getters.outliers.totalOutlier;
  }

  public get totalMemberCount() {
    return this.$store.getters.outliers && this.$store.getters.outliers.totalMemberCount;
  }

  public get selectedProvider() {
    const id = this.currentFilters && this.currentFilters.provider;
    if (!id) {
      return null;
    }

    return this.providers && this.providers.find((p: any) => p.id === id);
  }

  public get longStartDate() {
    return this.currentFilters.startDate ? this.toLongDate(this.currentFilters.startDate) : '';
  }

  public get longEndDate() {
    return this.currentFilters.endDate ? this.toLongDate(this.currentFilters.endDate) : '';
  }

  public toLongDate(date: string) {
    return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  }

  public updateStartDate(date: any) {
    this.currentFilters.startDate = date;
  }

  public updateEndDate(date: any) {
    this.currentFilters.endDate = date;
  }

  public async exportReport() {
    //PDF Export Polling with 2sec interval and 16sec Timout
    this.processingReport = true;
    const providerId = this.currentFilters.provider;
    const job = await this.$store.dispatch(GENERATE_BENCHMARK_REPORT, { providerId });

    let pdfFetchInterval = setInterval(async () => {
      let response = await this.$store.dispatch(GET_EXPORT_JOB_BY_ID, job.id);
      if (response[0].status === 'completed') {
        const url = await this.$store.dispatch(GET_PDF_URL, job.id);
        await window.open(url);
        this.processingReport = false;
        clearInterval(pdfFetchInterval);
      }
      return response;
    }, Utils.pdfInterval);
    setTimeout(() => {
      clearInterval(pdfFetchInterval);
      if (this.processingReport) {
        this.pdfTimeoutError = true;
      }
      this.processingReport = false;
    }, Utils.pdfTimeout);
  }

  mounted() {
    if (!this.currentFilters.provider) {
      this.currentFilters.provider = this.providers && this.providers.length > 0 ? this.providers[0].id : null;
    }
  }

  public async bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Reports', tooltip: 'Export Reports' });
    const providerId = this.providers && this.providers.length > 0 ? this.providers[0].id : null;
    if (providerId) {
      await this.$store.dispatch(FETCH_REPORT_DATA, { providerId });
      await this.$store.dispatch(FETCH_PROVIDER_DATA_QUALITY, { providerId });
    }
    this.$store.dispatch(FETCH_CURRENT_USER);
  }

  public get reportsData() {
    return this.$store.getters.bechmarkingReports;
  }

  public get fetchingBechmarkingReports() {
    return this.$store.getters.fetchingBechmarkingReports;
  }

  public getProviderName(id: any) {
    const providerData = this.providers.find((item: any) => item.id == id);
    if (providerData) {
      return providerData.name;
    }
    return '';
  }

  public get providers() {
    return this.$store && this.$store.getters.providers;
  }

  public async onProviderChange(providerId: any) {
    this.currentFilters.provider = providerId;
    await this.$store.dispatch(FETCH_REPORT_DATA, { providerId });
    await this.$store.dispatch(FETCH_PROVIDER_DATA_QUALITY, { providerId });
  }

  public async setProvider(event: any) {
    const providerId = this.currentFilters.provider;
    await this.$store.dispatch(FETCH_REPORT_DATA, { providerId });
    await this.$store.dispatch(FETCH_PROVIDER_DATA_QUALITY, { providerId });
  }

  public async getVFMReport() {
    const result = await this.$store.dispatch(REQUEST_VFM_REPORT);
    if (result) {
      this.requestVFMSuccessDialog = true;
      this.requestVFMmsg =
        'Thank you for requesting the VFM report. The AgeWage team will shortly be in touch with you.';
    } else {
      this.requestVFMSuccessDialog = true;
      this.requestVFMmsg = 'Error sending request for the VFM report. Please try again.';
    }
  }

  handleScroll() {
    if (this.$refs.filterPanel && this.$refs.filterPanel instanceof Element) {
      const topPosition = this.$refs.filterPanel.getBoundingClientRect().top;
      if (topPosition == 0) {
        this.scrolledAll = true;
      } else {
        this.scrolledAll = false;
      }
    }
  }
}
</script>
<style lang="scss">
.filter-menu {
  background: #ffffff !important;
  .v-label {
    font-weight: 500;
  }
}

.filter-btn-active {
  border-radius: 4px;
  background-color: #dfc3e6 !important;
}
.filter-btn-active .v-input__slot,
.filter-btn-active .v-field__overlay {
  background-color: #dfc3e6 !important;
}

.page-filters-wrapper {
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  position: sticky !important;
}

.page-filters-panel {
  &.vue-fixed-header--isFixed {
    background: #fff;
    margin-top: 0 !important;
    padding: 1rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  }
}
.list-reports-page {
  .truncate {
    width: 7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .back-btn:hover::before,
  .back-btn::before {
    opacity: 0 !important;
  }
}

.report-template {
  // padding: 0.5rem;
  margin-top: 0.5rem;
  .report-page-card {
    .card-data {
      padding: 1rem 0rem;
      min-height: 70rem;
    }
    .top-triangle {
      position: absolute;
      right: 0;
      .top-right-color-triangle {
        position: absolute;
        right: 0;
        width: 0;
        height: 0;
        z-index: 1;
        border-style: solid;
        border-width: 0 200px 200px 0;
        border-color: transparent #dfc6e6 transparent transparent;
      }
      .top-right-grey-triangle {
        position: absolute;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 400px 200px 0;
        border-color: transparent #f5f5f5 transparent transparent;
      }
    }
    .footer {
      width: 100%;
      max-width: 90%;
      padding: 1.5rem 0;
      margin: auto;
      display: flex;
      justify-content: space-between;

      .page-box {
        padding: 0.2rem;
        width: 7rem;
        margin: auto 0;
        border-radius: 0.2rem;
        background-color: #a994b1;
        .pageno {
          text-align: center;
          color: #fff;
        }
      }
      .footer-img {
        max-width: 10rem;
      }
    }
    .report-page-title {
      margin: 0 auto;
      text-align: center;
      font-size: 2.5rem;
      font-weight: 500;
    }
    .report-page-subtitle {
      margin: 0 auto;
      text-align: center;
      font-size: 2rem;
      font-weight: 300;
    }

    .main-page-feature {
      margin-left: 5rem;
      margin-top: 10rem;
      .v-image {
        width: 30rem;
      }
      .feature-title {
        font-size: 7rem;
        font-weight: 500;
        // color: #e2af06;
      }
      .feature-sub-title {
        font-size: 1.7rem;
        font-weight: 500;
        color: #e2af06;
      }
    }
    .main-page-content {
      margin: 7rem 0 0 5rem;
      .main-page-content-title {
        font-size: 1.5rem;
        font-weight: 600;
        padding-top: 3rem;
        padding-bottom: 1rem;
      }
      .main-page-text {
        // margin: 1.5rem auto 0 auto;
        // text-align: center;
        font-size: 1rem;
        padding-bottom: 4rem;
        max-width: 90%;
        text-align: justify;
      }
    }
    .section {
      max-width: 80%;
      width: 100%;
      margin: auto;
      margin-top: 3rem;
      // text-align: center;
      .section-title {
        margin: 0 auto;
        font-size: 1.4rem;
        font-weight: 600;
        padding-bottom: 1rem;
        display: flex;
        color: #000;
      }
      .section-content {
        font-size: 1rem;
        margin-bottom: 3rem;
        text-align: justify;
      }
    }
    .section-badge {
      height: 30px;
      width: 7px;
      margin-right: 1rem;
      background-color: #796581;
    }
    .report-chart {
      margin: 0 auto;
      width: 100%;
      padding: 2rem 0rem;
      background: #dac5e221;
    }
    .chart-title {
      font-weight: 600;
      font-size: 1.2rem;
      text-align: left;
      padding: 2rem 0 1.7rem 2rem;
    }
    .chartWrapper {
      height: auto;
      width: 100%;
      max-width: 70rem;
      border-radius: 0.2rem;
      background: #fff;
      margin: 0 auto;
    }
    .text-blue {
      color: blue;
    }
  }
}
.provider-btn {
  position: relative !important;
  display: flex !important;
  padding: 0 !important;
  width: 95% !important;
  margin: 0 !important;
  flex: 1 1 auto;
  justify-content: flex-start;
  text-transform: none;
  padding-left: 6px !important;
  font-size: 16px !important;
  .v-btn__content {
    max-width: 90% !important;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left !important;
  }
}
.benchmark-filter-menu {
  // display: flex;
  // justify-content: end;
  // z-index: 2;
  // width: 100%;
  margin-right: 9%;
}
.benchmark-filter-menu-data-quality {
  margin-right: 9%;
}
.benchmark-filter-menu-data-quality .v-menu__content {
  margin-left: 7%;
  margin-right: 7%;
}
.benchmark-filter-menu .v-menu__content {
  margin-left: 7%;
  margin-right: 7%;
}
</style>
