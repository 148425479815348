import { IRootState } from '@/store/state';
import { IPriceTrackState } from '@/modules/price-track/store/state';
import { GetterTree } from 'vuex';
import { PriceTrackObject, PriceTrackListItems } from '@/models/PriceTrack';

/**
 * --------------------------------------------------
 *	PriceTrack Getters
 * --------------------------------------------------
 **/
export const PriceTrackGetters: IPriceTrackGetters = {
  priceTracks: (state: IPriceTrackState) => state.priceTracks,
  addingPriceTrack: (state: IPriceTrackState) => state.addingPriceTrack,
  currentPriceTrack: (state: IPriceTrackState) => state.currentPriceTrack,
};

/**
 * --------------------------------------------------
 *	PriceTrack Getters Interface
 * --------------------------------------------------
 **/
export interface IPriceTrackGetters extends GetterTree<IPriceTrackState, IRootState> {
  priceTracks(state: IPriceTrackState): PriceTrackListItems[];
  addingPriceTrack(state: IPriceTrackState): boolean;
  currentPriceTrack(state: IPriceTrackState): PriceTrackObject;
}
