import { AuthMiddleware } from '@/router/middlewares/AuthMiddleware';
import { GuestMiddleware } from '@/router/middlewares/GuestMiddleware';
import { RoleMiddleware } from '@/router/middlewares/RoleMiddleware';
import { MiddlewareMapping } from '@/router/middlewares/types';

export const MiddlewareMappings: MiddlewareMapping = {
  requiresAuth: AuthMiddleware,
  requiresGuest: GuestMiddleware,
  hasRoles: RoleMiddleware
};
