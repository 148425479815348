<template>
  <div class="elevation-10 chartWrapper">
    <div class="chart-title text-center pb-8">Regression Analysis</div>
    <BaseChart :options="chartOptions" :scrollOptions="scrollOptions" />
  </div>
</template>

<script>
import BaseChart from './BaseChart.vue';
const echarts = require('echarts/lib/echarts');
export default {
  components: {
    BaseChart,
  },
  props: ['chartData'],
  data() {
    return {
      chartOptions: this.editChartData(),
      scrollOptions: {
        chartHeight: '30',
        unit: 'rem',
      },
    };
  },
  methods: {
    editChartData() {
      const chartOptions = this.chartData;
      if (chartOptions) {
        chartOptions.xAxis.axisLabel.formatter = (v) => {
          return v.toFixed(0);
        };
        chartOptions.yAxis.axisLabel.formatter = (v) => {
          return v.toFixed(0);
        };
        chartOptions.tooltip.formatter = (v) => v.value;
      }
      return chartOptions;
    },
  },
};
</script>
