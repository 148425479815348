import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "svgRef",
  class: "base-chart__wrapper echarts-render-wrapper svg"
}
const _hoisted_2 = {
  key: 1,
  ref: "canvasReference",
  class: "base-chart__wrapper echarts-render-wrapper can"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "base-chart",
    style: _normalizeStyle({
      width: _ctx.scrollOptions && _ctx.scrollOptions.chartWidth + _ctx.scrollOptions.unit,
      height: _ctx.scrollOptions && _ctx.scrollOptions.chartHeight + _ctx.scrollOptions.unit,
    })
  }, [
    (_ctx.renderer == 'svg')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, null, 512))
      : _createCommentVNode("", true),
    (_ctx.renderer == 'canvas')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, null, 512))
      : _createCommentVNode("", true)
  ], 4))
}