<template>
  <div>
    <div data-testid="ProviderAnalysisChart" v-if="!fetchingChartData">
      <AIRRChart data-testid="AIRRChart" v-if="airrData" :chartData="airrData"></AIRRChart>
      <ISYChart data-testid="ISYChart" v-if="isyData" :chartData="isyData"></ISYChart>
      <AVBChart data-testid="AVBChart" v-if="avbData" :chartData="avbData"></AVBChart>
      <SchemeComparisonChart data-testid="SCC-Chart" v-if="sccData" :chartData="sccData"></SchemeComparisonChart>
    </div>
    <div v-else>
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import SchemeComparisonChart from '@/components/charts/SchemeComparisonChart.vue';
import AVBChart from '@/components/charts/AVBChart.vue';
import ISYChart from '@/components/charts/ISYChart.vue';
import AIRRChart from '@/components/charts/AIRRChart.vue';

@Options({
  components: {
    SchemeComparisonChart,
    AVBChart,
    ISYChart,
    AIRRChart
  }
})
export default class Analysis extends Vue {
  @Prop()
  public id!: number;

  @Prop()
  public chartData!: any;

  public get fetchingChartData() {
    return this.$store.getters.fetchingChartData;
  }

  public get sccData() {
    return this.chartData.scheme;
  }

  public get avbData() {
    return this.chartData.avb;
  }

  public get isyData() {
    return this.chartData.investments;
  }
  public get airrData() {
    return this.chartData.irr;
  }
}
</script>
