import Import from '@/models/Import';
import { IImportState } from '@/modules/imports/store/state';
import { IRootState } from '@/store/state';
import { GetterTree } from 'vuex';

/**
 * --------------------------------------------------
 *	Import Getters
 * --------------------------------------------------
 **/
export const ImportGetters: IImportGetters = {
  imports: (state: IImportState) => state.imports,
  fetchingImports: (state: IImportState) => state.fetchingImports,
};

/**
 * --------------------------------------------------
 *	Import Getters Interface
 * --------------------------------------------------
 **/
export interface IImportGetters extends GetterTree<IImportState, IRootState> {
  imports(state: IImportState): Import[] | null;
  fetchingImports(state: IImportState): boolean;
}
