import { SimulationActions } from '@/modules/simulations/store/actions';
import { SimulationGetters } from '@/modules/simulations/store/getters';
import { SimulationMutations } from '@/modules/simulations/store/mutations';
import { SimulationState, ISimulationState } from '@/modules/simulations/store/state';
import { IRootState } from '@/store/state';
import { Module } from 'vuex';

export const SimulationStore: ISimulationStore = {
  state: SimulationState,
  actions: SimulationActions,
  getters: SimulationGetters,
  mutations: SimulationMutations
};

/**
 * --------------------------------------------------
 *	Simulation Store Interface
 * --------------------------------------------------
 **/
export interface ISimulationStore extends Module<ISimulationState, IRootState> {}
