import { BenchmarkListItems, BenchmarkObject } from '@/models/Benchmark';
/**
 * --------------------------------------------------
 *	Benchmark State
 * --------------------------------------------------
 **/

export const BenchmarkState: IBenchmarkState = {
  benchmarks: [],
  addingBenchmark: false,
  currentBenchmark: {
    id: null,
    name: '',
    startDate: '',
    endDate: ''
  },
};

export interface IBenchmarkState {
  benchmarks: BenchmarkListItems[];
  addingBenchmark: boolean;
  currentBenchmark: BenchmarkObject;
}
