import UserModule from './UserModule.vue';
import ListUsersPage from './pages/ListUsersPage.vue';

export const UserRoutes = [
  {
    path: '/users',
    component: UserModule,
    children: [
      {
        path: '',
        name: 'dashboard.users.list',
        component: ListUsersPage,
      },
    ],
  },
];
