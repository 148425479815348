import Auth from '@/packages/Auth';
import { Middleware, MiddlewareNext } from '@/router/middlewares/types';
import { RouteLocationNormalized } from 'vue-router';

export const RoleMiddleware: Middleware = {
  handle(to: RouteLocationNormalized, from: RouteLocationNormalized, next: MiddlewareNext, roles: string[]) {
    const user = Auth.getUser();
    const hasAccess = user && user.role && roles.includes(user.role);

    if (!hasAccess) {
      return next({ name: 'index' });
    }
  }
};
