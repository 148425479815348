export const SET_PROVIDERS = 'b2c/SET_PROVIDERS';
export const SET_PROVIDER = 'b2c/SET_PROVIDER';
export const UPDATING_PROVIDER = 'b2c/UPDATING_PROVIDER';
export const SET_USERS = 'b2c/SET_USERS';
export const SET_USER = 'b2c/SET_USER';
export const UPDATING_USER = 'b2c/UPDATING_USER';
export const ADDING_SCORE = 'b2c/ADDING_SCORE';
export const SET_IMPORTS = 'b2c/SET_IMPORTS';
export const GETTING_B2C_CONTRIBUTIONS = 'b2c/GETTING_B2C_CONTRIBUTIONS';
export const EXPORT_USERS = 'b2c/EXPORT_USERS';
