export interface IProviderAnalysisState {
  analysis?: [];
  chartData?: [];
  pdfLink?: string;
  fetchingAnalysis: boolean;
  fetchingChartData: boolean;
  generatingPdf: boolean;
}

/**
 * --------------------------------------------------
 *	ProviderAnalysis State
 * --------------------------------------------------
 **/

export const ProviderAnalysisState: IProviderAnalysisState = {
  analysis: [],
  chartData: [],
  pdfLink: '',
  fetchingAnalysis: false,
  fetchingChartData: false,
  generatingPdf: false
};
