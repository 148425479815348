<template>
  <div>
    <div class="report-template">
      <Watermark :isSampleReport="isSampleReport" />
      <v-card class="report-page-card top-right-color-triangle" outlined flat>
        <div>
          <div class="report-page-card temp-class">
            <div class="row" style="max-width: 92%; margin-left: auto; margin-right: auto">
              <div class="column">
                <div class="section">
                  <div class="section-title">
                    <div class="section-badge"></div>
                    Risk and the Management of Investment Portfolios
                  </div>
                  <div class="section-content">
                    There’s a simple metric which allows us to compare, unbiasedly, portfolio results regardless of the
                    strategy which generated them; this is the internal rate of return (IRR). We call it a
                    risk-experienced average rate of return. Risk events which have occurred are incorporated into the
                    values on which it is based. We often hear portfolio managers complain they underperformed because
                    they had incurred costs hedging and mitigating events which did not come to pass. Hedging all risks
                    is simply not feasible. The only perfect hedge of an asset (or equivalently a liability) is its
                    sale. The opposite, the stoic acceptance of the outcomes of all occurring events, is perfectly
                    feasible; it is passive investment. By introducing a comparator benchmark, we may establish the
                    relative costs of different strategies. The use of this passive, but rebalanced benchmark provides
                    an IRR which reflects all of those risk events which did occur. Comparison of a particular fund’s
                    IRR with this benchmark IRR delivers an objective measure of the value-added delivered by that fund.
                    The AgeWage score is simply a normalisation of this, where the average constitutes a score of 50.
                    The value for money analysis conducted in this manner is all historic, and may be a poor indicator
                    of future performance.
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="section">
                  <div class="section-title">
                    <div class="section-badge"></div>
                    Methodology Employed
                  </div>
                  <div class="section-content">
                    The algorithm generates the AgeWage score that allows people to make sense of the value for money of
                    their pension pot. We calculate the internal rate of return (IRR) for the pot using the value of the
                    pot and the contribution history that built it up. But telling people their pot had an IRR of 5% per
                    annum (for instance) won’t help them understand if they have got value for money. It is unlikely to
                    engage them. This is where the AgeWage score comes in. AgeWage compares the individual’s pension pot
                    IRR with the IRR they would have got if they had their contributions invested in the average
                    benchmark pension pot. The average benchmark pot represents the opportunity set available to savers.
                    It has been constructed by Hymans Robertson and is maintained by them. This comparison generates the
                    AgeWage score. The AgeWage score tells people if they are below or above average; in other words, in
                    a world of 0 to 100, the score shows the individual whether they are below or above 50 – and by how
                    much. The AgeWage score is an effective way of engaging people through a simple view of “how things
                    have gone”. The following remarks concern themselves with how the benchmark that compares people’s
                    actual experience is created.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="section">
                <div class="section-title">
                  <div class="section-badge"></div>
                  Creating the Benchmark
                </div>
                <div class="section-content">
                  Talking about concepts like “value for money” and “value added” begs the question – “relative to
                  what”. We need a baseline metric to evaluate and quantify these concepts.There is no need to represent
                  a particular style or management mandate when creating a benchmark portfolio. The benchmark should be
                  agnostic with respect to management strategies, such as ‘life-styling’. It reflects the broad
                  opportunity set available to savers, and the success or failure of these strategies will be reflected
                  in the resultant AgeWage score. The benchmark we have settled upon is constructed from research by
                  Hymans Robertson. It reflects the funds available to and used by savers over the period from 1998.
                  Prior to 1998 down to 1980, we use weightings of Morningstar broad category averages of funds. This
                  date was chosen as before 1980, the UK capital markets were distorted by exchange controls, the dollar
                  premium and unearned income taxation considerations. Many savings vehicles were expressly designed to
                  create capital gains at the expense of income; total return was a secondary matter. The benchmark
                  weightings of Morningstar indices from initiation in 1980 is: 20 % - EAA Fund GBP Government Bond and
                  80 % - EAA Fund UK Large-Cap Equity. The removal of exchange controls, together with major revisions
                  to the tax-code led to greater interest in overseas equities, which took a little time to be
                  widespread. For example, the FTSE world equity ex UK index was not introduced until 1986.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>
    <div class="report-template">
      <v-card class="report-page-card top-right-color-triangle mt-2" outlined flat>
        <div>
          <Watermark :isSampleReport="isSampleReport" />

          <div class="report-page-card temp-class" style="min-height: 70rem">
            <div class="section">
              <div class="section-content">
                At this date, we modify the benchmark allocation to reflect this broader allocation and the benchmark
                becomes: 52 % - EAA Fund UK Large-Cap Equity, 28 % - EAA Fund Global Large Cap Fund Equity and 20 % -
                EAA Fund GBP Government Bond. This weighting continues until the end of 1990. From 1991 to 1998 the
                weighting is further modified to reflect the growing importance of index linked gilts and the allocation
                are changed to be: 52 % - EAA Fund UK Large-Cap Equity, 28 % - EAA Fund Global Large Cap Fund Equity, 15
                % - EAA Fund GBP Government Bond, and 5% - EAA Fund GBP Inflation-Linked Bond. From 1998 onwards the
                weightings provided by Hymans Robertson can be seen in the table below.
              </div>
            </div>
            <div class="row">
              <div style="margin: 20px; display: flex; justify-content: center; margin-left: auto; margin-right: auto">
                <table style="max-width: 100%">
                  <tr>
                    <th>Effective Date</th>
                    <th>Overseas Equity</th>
                    <th>UK Equity</th>
                    <th>Emerging Equity</th>
                    <th>Fixed Income</th>
                    <th>Cash</th>
                    <th>Property & Alternatives</th>
                  </tr>
                  <tr>
                    <td>Dec 1998</td>
                    <td>45%</td>
                    <td>35%</td>
                    <td></td>
                    <td>15%</td>
                    <td>5%</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Dec 2013</td>
                    <td>45%</td>
                    <td>32.5%</td>
                    <td>2.5%</td>
                    <td>10%</td>
                    <td>5%</td>
                    <td>5%</td>
                  </tr>
                  <tr>
                    <td>Dec 2016</td>
                    <td>40%</td>
                    <td>25%</td>
                    <td>5%</td>
                    <td>20%</td>
                    <td>5%</td>
                    <td>5%</td>
                  </tr>
                  <tr>
                    <td>Dec 2018</td>
                    <td>50%</td>
                    <td>10%</td>
                    <td>5%</td>
                    <td>20%</td>
                    <td>5%</td>
                    <td>10%</td>
                  </tr>
                  <tr>
                    <td>Dec 2020</td>
                    <td>52.2%</td>
                    <td>6%</td>
                    <td>1.8%</td>
                    <td>26.8%</td>
                    <td>7.9%</td>
                    <td>5.3%</td>
                  </tr>
                  <tr>
                    <td>Dec 2021</td>
                    <td>55%</td>
                    <td>4.2%</td>
                    <td>2%</td>
                    <td>25.5%</td>
                    <td>7.1%</td>
                    <td>6.2%</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="section">
              <div class="section-content">
                We are satisfied that this weighting structure captures how the average portfolio has been invested, but
                will continue to monitor this for changes. We recognize that it is possible that new asset classes, such
                as infrastructure, or investments based upon specific attributes such as climate change, ESG, or ethical
                investment, may emerge and become significant.
              </div>
            </div>

            <div class="section">
              <div class="section-title">
                <div class="section-badge"></div>
                Refining the Benchmark
              </div>
              <div class="section-content">
                Both benchmark and the saver IRRs are net results after deduction of charges. When we have tested
                sufficient contribution histories to have what we will consider to be a full dataset, we will be able to
                refine and recalibrate the interaction between the actual IRR and the benchmark IRR. This process will
                be iterative and ongoing, and will include further consideration of the dynamics of the time-adjusted
                asset allocations, as well as further work on costs and fees. The more data we put through the
                algorithm, the more refined it will be.
              </div>
            </div>
          </div>
        </div>

        <div class="footer">
          <div class="text-primary font-weight-bold">Henry Tapper</div>
          <!-- <div class="page-box">
          <div class="pageno">Page 4/4</div>
        </div> -->
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script lang="ts">
import { Prop, Vue } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import Watermark from './Watermark.vue';

@Options({
  components: {
    Watermark,
  },
})
export default class ReportStaticText extends Vue {
  created() {
    this.bootstrap();
  }

  bootstrap() {
    //
  }
  @Prop() isSampleReport!: boolean;
}
</script>

<style lang="scss" scoped>
.column {
  float: left;
  width: 50%;
  padding: 10px;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

th,
td {
  padding: 4px;
  text-align: center;
  width: 110px;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}
</style>
