import { IRootState } from '@/store/state';
import { GetterTree } from 'vuex';

/**
 * --------------------------------------------------
 *	Root Getters
 * --------------------------------------------------
 **/
export const RootGetters: IRootGettersTree = {
  isDark: (state: IRootState): boolean => state.dark,
  isDataUploadDialogVisible: (state: IRootState): boolean => state.dataUploadDialogIsVisible,
  navDrawerIsVisible: (state: IRootState): boolean => state.navDrawerIsVisible,
  filterIsVisible: (state: IRootState): boolean => state.filterIsVisible,
  selectedItems: (state: IRootState) => state.selectedItems,
  navHeader: (state: IRootState) => state.navHeader
};
/**
 * --------------------------------------------------
 *	Root Getter (Tree) Interface
 * --------------------------------------------------
 **/
export interface IRootGettersTree extends GetterTree<IRootState, IRootState> {}
