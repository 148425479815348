<template>
  <v-dialog v-model="isDialogVisible" persistent :max-width="showProviderForm ? '400px' : '600px'" max-height="90%">
    <v-card data-testid="UploadDialog" class="pa-2">
      <v-form @submit.prevent="uploadData">
        <v-card-title class="d-flex align-center justify-space-between">
          <span class="text-h5">
            {{ showProviderForm ? 'Add Provider' : 'Upload Data' }}
          </span>
          <v-btn @click="toggleAddProviderForm" size="small" variant="outlined" color="primary">
            <v-icon class="mr-2" size="small" left>
              {{ showProviderForm ? 'fas fa-chevron-left' : 'fas fa-plus' }}
            </v-icon>
            <span v-show="!showProviderForm">Add Provider</span>
            <span v-show="showProviderForm">Go back</span>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-show="showProviderForm">
            <v-form @submit.prevent="createProvider" class="py-5">
              <div>
                <v-text-field
                  hide-details
                  :persistent-hint="false"
                  placeholder="Provider Name"
                  v-model="newProviderName"
                  class="mb-5"
                />
                <v-text-field
                  hide-details
                  :persistent-hint="false"
                  placeholder="Provider Email"
                  v-model="newProviderEmail"
                  class="mb-5"
                />
                <v-btn
                  block
                  type="submit"
                  :color="!newProviderName || addingProvider ? 'disabled' : 'success'"
                  class="mt-5"
                  :loading="addingProvider"
                  :disabled="!newProviderName || addingProvider"
                >
                  Add
                </v-btn>
              </div>
            </v-form>
          </div>
          <v-container grid-list-md v-show="!showProviderForm">
            <div data-testid="Upload-Container" v-if="!dataUploaded" wrap>
              <v-row class="mb-2">
                <v-col cols="12">
                  <v-label>Select a Provider</v-label>
                  <v-autocomplete
                    data-testid="Upload-Provider-List"
                    v-model="selectedProvider"
                    :loading="fetchingProviders"
                    :items="providers"
                    :v-model:search-input="providerSearch"
                    item-title="name"
                    item-value="id"
                    class="mx-0 mt-2"
                    type="text"
                    hide-no-data
                    hide-details
                    label="Provider"
                    variant="filled"
                  />
                </v-col>
              </v-row>

              <v-row class="mb-2">
                <v-col cols="12">
                  <v-label>Select Index</v-label>
                  <v-autocomplete
                    data-testid="Index-Type-List"
                    v-model="indexType"
                    :items="indexTypes"
                    item-title="name"
                    item-value="id"
                    class="mx-0 mt-2"
                    type="text"
                    hide-no-data
                    hide-details
                    label="Index Type"
                    variant="filled"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-switch
                          v-if="selectedProvider"
                          :disabled="!(selectedProvider && (!providerHasExistingBatch || !useExistingBatch))"
                          hide-details
                          :persistent-hint="false"
                          :label="`Scoring Methodology: ${scoringMethod ? 'New Test Method' : 'Old scoring method'}`"
                          v-model="scoringMethod"
                          class="mb-5"
                        />
                      </v-col>

                      <v-col cols="12" sm="12">
                        <v-switch
                          v-if="selectedProvider"
                          :disabled="!selectedProvider"
                          hide-details
                          :persistent-hint="false"
                          :label="`Performance Attribution: ${performanceAttribution ? 'Yes' : 'No'}`"
                          v-model="performanceAttribution"
                          class="mb-5"
                        />
                      </v-col>

                      <v-col cols="12" sm="12">
                        <v-switch
                          v-model="useExistingBatch"
                          inset
                          v-show="selectedProvider && providerHasExistingBatch"
                          label="Use existing batch"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6" v-show="selectedProvider">
                        <v-switch
                          v-model="editOutlierFlag"
                          inset
                          :disabled="selectedProvider && useExistingBatch && providerHasExistingBatch"
                          label="Use minimum contribution time"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="editOutlierNumber"
                          label="Number of years"
                          :disabled="selectedProvider && useExistingBatch && providerHasExistingBatch"
                          v-show="editOutlierFlag && selectedProvider"
                          single-line
                          type="number"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>

              <v-row v-show="selectedProvider && (!providerHasExistingBatch || !useExistingBatch)">
                <v-col cols="12">
                  <v-alert
                    :type="providerHasExistingBatch && !useExistingBatch ? 'warning' : 'info'"
                    class="mb-4"
                    prominent
                    ><span class="text-white">
                      A new batch will be created with this upload.
                      <b v-show="providerHasExistingBatch && !useExistingBatch"
                        >This batch will overwrite the existing batch.</b
                      >
                    </span>
                  </v-alert>
                </v-col>
              </v-row>

              <v-divider class="d-flex" horizontal />

              <v-row class="text-center mt-2">
                <v-col cols="12">
                  <AppUploader
                    data-testid="FileUpload"
                    @app-uploader="handleFile"
                    :disabled="uploadingData"
                    :fileType="'.csv'"
                  />
                </v-col>
              </v-row>
            </div>
            <v-layout v-if="dataUploaded" wrap>
              <v-row>
                <v-col cols="12">
                  <v-alert v-model="dataUploaded" closable type="success" class="mb-4" prominent
                    >Data uploaded successfully. We will notify you once we've processed it.</v-alert
                  >
                </v-col>
              </v-row>
            </v-layout>
            <v-layout v-if="dataUploadError" wrap>
              <v-row>
                <v-col cols="12">
                  <v-alert
                    data-testid="FileUploadError"
                    v-model="dataUploadError"
                    closable
                    type="error"
                    class="mb-4 white--text"
                    prominent
                    >{{ validationError }}</v-alert
                  >
                </v-col>
              </v-row>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn data-testid="CloseUploadDialog" color="grey darken-1" variant="text" @click="toggleDialog"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            data-testid="SubmitUploadBtn"
            color="success"
            type="submit"
            :loading="uploadingData"
            :disabled="uploadingData || dataUploaded"
            v-if="!showProviderForm"
            >Upload</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Prop, Watch } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { TOGGLE_DATA_UPLOAD_DIALOG } from '@/store/actionTypes';
import AppUploader from '@/components/AppUploader.vue';
import { HTTPServiceClient } from '@/packages/http/service';
import { FETCH_BENCHMARKS } from '@/modules/benchmarks/store/actionTypes';

@Options({
  components: {
    AppUploader,
  },
})
export default class DataUploadDialog extends Vue {
  public uploadingData = false;
  public dataUploaded = false;
  public dataUploadError = false;
  public validationError = '';
  public selectedProvider: any = null;
  public fetchingProviders = false;
  public useExistingBatch = true;
  public providers = [];
  public latestBatches: any[] = [];
  public providerSearch = '';
  public file: any = null;
  public scoringMethod: boolean = false;
  public performanceAttribution: boolean = false;

  // false : old
  // true: new test method
  public indexType = 'default';
  public defaultIndex = {
    id: 'default',
    name: 'Default morningstar',
  };
  public indexTypes = [this.defaultIndex];

  public showProviderForm = false;
  public addingProvider = false;
  public newProviderName = null;
  public newProviderEmail = null;
  public editOutlierFlag: boolean | null = false;
  public editOutlierNumber: number | null = null;

  public get numberOfYearsForOutlier() {
    if (!this.selectedProvider) {
      return null;
    }
    if (!this.providerHasExistingBatch) {
      return null;
    }
    let val = null;
    this.latestBatches.forEach((batch) => {
      if (+batch.providerId === +(this.selectedProvider || 0)) {
        val = batch.yearOutlier;
      }
    });
    this.editOutlierNumber = val;
    return val;
  }

  public get useMinOutliers() {
    if (!this.selectedProvider) {
      return false;
    }
    if (!this.providerHasExistingBatch) {
      return false;
    }
    let val = null;
    this.latestBatches.forEach((batch) => {
      if (+batch.providerId === +(this.selectedProvider || 0)) {
        val = batch.minOutlierYear;
      }
    });
    this.editOutlierFlag = val;
    return val;
  }

  public get getScoringMethod() {
    if (!this.selectedProvider) {
      return false;
    }
    if (!this.providerHasExistingBatch) {
      return false;
    }
    let val = false;
    this.latestBatches.forEach((batch) => {
      if (+batch.providerId === +(this.selectedProvider || 0)) {
        val = batch.scoringMethod === 'old' ? false : true;
      }
    });
    this.scoringMethod = val;
    return val;
  }

  created() {
    this.fetchProviders();
    this.fetchBenchmarks();
  }

  public get isDialogVisible() {
    return this.$store.getters.isDataUploadDialogVisible;
  }

  @Watch('isDialogVisible')
  public onVisiblityChange(newVal: boolean, oldVal: boolean) {
    if (newVal) {
      this.fetchProviders();
      this.dataUploaded = false;
      this.dataUploadError = false;
      this.validationError = '';
      this.file = null;
      this.selectedProvider = null;
      this.editOutlierNumber = null;
      this.editOutlierFlag = false;
    }
  }

  @Watch('selectedProvider')
  public providerChanged() {
    this.editOutlierFlag = this.useMinOutliers;
    this.editOutlierNumber = this.numberOfYearsForOutlier;
    this.scoringMethod = this.getScoringMethod;
    this.performanceAttribution = false;
  }

  public handleFile(data: any) {
    this.file = data.file;
  }

  public uploadData() {
    this.dataUploadError = false;
    this.validationError = '';
    const useMinOutliers = this.editOutlierFlag ? '1' : '0';
    const numberOfYearsForOutlier = this.editOutlierNumber ? String(this.editOutlierNumber) : '0';
    if (this.selectedProvider === null) {
      this.dataUploadError = true;
      this.validationError = 'Please select provider.';
      return;
    } else if (!this.file || this.file.name.slice(-4) != '.csv') {
      this.dataUploadError = true;
      this.validationError = "Please make sure you're uploading .csv file.";
      return;
    } else if (useMinOutliers === '1' && parseInt(numberOfYearsForOutlier) < 1) {
      this.dataUploadError = true;
      this.validationError = 'Please make sure the minimun time for contribution history is atleast 1 year.';
      return;
    }
    if (this.dataUploadError === false) {
      this.uploadingData = true;
      let formData = new FormData();
      const providerId = this.selectedProvider ? this.selectedProvider : '';
      const latestBatch = this.latestBatches.find((batch) => +batch.providerId === +(this.selectedProvider || 0));
      const batchId = this.useExistingBatch && latestBatch ? latestBatch.id : 0;
      const indexType = this.indexType || 'default';

      formData.append('file', this.file, this.file.name);
      formData.append('providerId', `${providerId}`);
      formData.append('batchId', batchId);
      formData.append('indexType', indexType);
      formData.append('useMinOutliers', useMinOutliers);
      formData.append('numberOfYearsForOutlier', numberOfYearsForOutlier);
      formData.append('scoringMethod', this.scoringMethod ? 'new' : 'old');
      formData.append('performanceAttribution', this.performanceAttribution ? '1' : '0');

      return HTTPServiceClient.post('/upload-data', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((res) => {
          this.dataUploaded = true;
        })
        .finally(() => {
          this.uploadingData = false;
          this.file = null;
        });
    }
  }

  public toggleDialog() {
    this.$store.dispatch(TOGGLE_DATA_UPLOAD_DIALOG, false);
  }

  public toggleAddProviderForm() {
    this.showProviderForm = !this.showProviderForm;
  }

  public get providerHasExistingBatch() {
    if (!this.selectedProvider) {
      return false;
    }

    return this.latestBatches.some((batch) => +batch.providerId === +(this.selectedProvider || 0));
  }

  public async fetchProviders() {
    this.fetchingProviders = true;
    const res = await HTTPServiceClient.get('/providers');
    this.providers = res.data;
    this.fetchingProviders = false;

    if (!this.providers || this.providers.length < 1) {
      this.showProviderForm = true;
      return;
    }

    HTTPServiceClient.get('/providers/latest-batches').then(({ data }) => (this.latestBatches = data));
  }

  get benchmarks() {
    return this.$store.getters.benchmarks;
  }

  public async fetchBenchmarks(data: any = {}) {
    await this.$store.dispatch(FETCH_BENCHMARKS, data);
    this.indexTypes = [
      this.defaultIndex,
      ...this.benchmarks.map((a: any) => {
        return {
          id: a.id,
          name: a.name,
        };
      }),
    ];
  }

  public async createProvider() {
    this.addingProvider = true;
    const formData = {
      name: this.newProviderName,
      email: this.newProviderEmail,
    };
    await HTTPServiceClient.post('/providers', formData);
    this.showProviderForm = false;
    this.fetchProviders();
  }
}
</script>
