import User from '@/models/User';
import { AuthService } from '@/modules/auth/services';
import * as AT from '@/modules/auth/store/actionTypes';
import * as MT from '@/modules/auth/store/mutationTypes';
import { IAuthState } from '@/modules/auth/store/state';
import { LoginFormData, LoginResponseData, ResetPasswordFormData, ResetPasswordJWTFORM } from '@/modules/auth/types';
import { SUCCESSFUL_PASSWORD_CHANGE_STRING } from '@/modules/auth/store/constants';
import Auth from '@/packages/Auth';
import { IRootState } from '@/store/state';
import { ActionContext, ActionTree } from 'vuex';

/**
 * --------------------------------------------------
 *  Auth Actions
 * --------------------------------------------------
 **/
export const AuthActions: IAuthActions = {
  /**
   * --------------------------------------------------
   *  Log In
   * --------------------------------------------------
   **/
  [AT.LOGIN_USER]: ({ commit, dispatch }, data: LoginFormData) => {
    // Reset state
    commit(MT.SET_LOGGING_IN, true);
    commit(MT.SET_LOGIN_ERROR, false);
    // Try to Log the user in
    AuthService.login(data)
      .then((res: LoginResponseData) => {
        // Set the user
        Auth.setUser(res.user);
        // Set the token
        Auth.setToken(res.token);
        // User Logged In Successfully
        dispatch(AT.LOGIN_USER_SUCCESS, res.user);
        dispatch(AT.SESSION_TIME_OUT, false);
      })
      .catch(error => dispatch(AT.LOGIN_USER_ERROR, error))
      .finally(() => commit(MT.SET_LOGGING_IN, false));
  },

  [AT.LOGIN_USER_ERROR]: ({ commit, dispatch }, error?: any) => {
    commit(MT.SET_LOGIN_ERROR, true);
  },

  [AT.LOGIN_USER_SUCCESS]: ({ commit, dispatch }, user: User) => {
    dispatch(AT.FETCH_CURRENT_USER_SUCCESS, user);
  },
  /**
   * --------------------------------------------------
   *	Current User
   * --------------------------------------------------
   **/
  [AT.FETCH_CURRENT_USER]: ({ dispatch, commit }) => {
    commit(MT.SET_FETCHING_CURRENT_USER, true);
    // Try to fetch the current user
    const serviceCall = AuthService.getCurrentUser().then(user => {
      dispatch(AT.FETCH_CURRENT_USER_SUCCESS, user);
      return user;
    });

    return serviceCall.catch(e => {
      dispatch(AT.FETCH_CURRENT_USER_ERROR);
      throw new Error('User UnAuthorized');
    });
  },

  [AT.FETCH_CURRENT_USER_ERROR]: ({ commit, dispatch }, error?: any) => {
    commit(MT.SET_FETCHING_CURRENT_USER, false);
    commit(MT.SET_FETCH_CURRENT_USER_ERROR, true);
    dispatch(AT.LOG_USER_OUT);
  },

  [AT.FETCH_CURRENT_USER_SUCCESS]: ({ commit, dispatch }, user: User) => {
    commit(MT.SET_CURRENT_USER, user);
    commit(MT.SET_FETCHING_CURRENT_USER, false);
    commit(MT.SET_FETCH_CURRENT_USER_ERROR, false);
  },
  [AT.SET_CURRENT_USER]: ({ commit }, user: any) => {
    commit(MT.SET_CURRENT_USER, user);
  },

  /**
   * --------------------------------------------------
   *  Reset Password
   * --------------------------------------------------
   **/

  [AT.RESET_CONFIRM_JWT]: ({ commit, dispatch }, data: ResetPasswordJWTFORM): void => {
    commit(MT.SET_CHANGING_PASSWORD, true);
    AuthService.confirmJWTForPasswordReset(data)
    .then((resp: any) => {
      if(resp && resp.error) {
        commit(MT.SET_CHANGE_PASSWORD_ERROR, resp.error);
      } else {
        commit(MT.SET_CHANGE_PASSWORD_ERROR, SUCCESSFUL_PASSWORD_CHANGE_STRING);
      }
    })
    .catch(err => {
      commit(MT.SET_CHANGE_PASSWORD_ERROR, err);
    })
    .finally(()=> {
      commit(MT.SET_CHANGING_PASSWORD, false);
    });

    // context.commit(MT.RESET_PASS, null);
  },

  /**
   * --------------------------------------------------
   *  Logout
   * --------------------------------------------------
   **/
  [AT.LOG_USER_OUT]: (context: IAuthActionContext): void => {
    Auth.destroyToken();
    Auth.removeUser();
    context.commit(MT.SET_CURRENT_USER, null);
    window.location.reload();
  },
  [AT.SESSION_TIME_OUT]: ({ commit, dispatch }, status: boolean) => {
    commit(MT.SET_SESSION_TIME_OUT, status);
  }
};

/**
 * --------------------------------------------------
 *  Auth Action Context Interface
 * --------------------------------------------------
 **/
export interface IAuthActionContext extends ActionContext<IAuthState, IRootState> {}

/**
 * --------------------------------------------------
 *  Auth Actions Interface
 * --------------------------------------------------
 **/
export interface IAuthActions extends ActionTree<IAuthState, IRootState> {
  // Log In User
  [AT.LOGIN_USER](context: IAuthActionContext, data: LoginFormData): void;
  [AT.LOGIN_USER_ERROR](context: IAuthActionContext, error?: any): void;
  [AT.LOGIN_USER_SUCCESS](context: IAuthActionContext, error?: any): void;
  // Current User
  [AT.FETCH_CURRENT_USER](context: IAuthActionContext): void;
  [AT.FETCH_CURRENT_USER_ERROR](context: IAuthActionContext, error?: any): void;
  [AT.FETCH_CURRENT_USER_SUCCESS](context: IAuthActionContext, user: User): void;
  [AT.SET_CURRENT_USER](context: IAuthActionContext, user: any): void;

  //RESET_PASSWORD
  [AT.RESET_CONFIRM_JWT](context: IAuthActionContext, user: any): void;

  [AT.SESSION_TIME_OUT](context: IAuthActionContext, status: boolean): void;
}
