import { ProviderAnalysisActions } from '@/modules/providers-analysis/store/actions';
import { ProviderAnalysisGetters } from '@/modules/providers-analysis/store/getters';
import { ProviderAnalysisMutations } from '@/modules/providers-analysis/store/mutations';
import { ProviderAnalysisState, IProviderAnalysisState } from '@/modules/providers-analysis/store/state';
import { IRootState } from '@/store/state';
import { Module } from 'vuex';

export const ProviderAnalysisStore: IProviderAnalysisStore = {
  state: ProviderAnalysisState,
  actions: ProviderAnalysisActions,
  getters: ProviderAnalysisGetters,
  mutations: ProviderAnalysisMutations
};

/**
 * --------------------------------------------------
 *	ProviderAnalysis Store Interface
 * --------------------------------------------------
 **/
export interface IProviderAnalysisStore extends Module<IProviderAnalysisState, IRootState> {}
