import { SET_REPORTS, SET_FETCHING_REPORTS } from '@/modules/benchmarking/store/mutationTypes';
import { IReportState } from '@/modules/benchmarking/store/state';
import { MutationTree } from 'vuex';

/*--------------------------------------------------/
 * Report Mutations
 *--------------------------------------------------*/
export const ReportMutations: IReportMutations = {
  [SET_REPORTS](state: any, data: any) {
    state.bechmarkingReports = data;
  },
  [SET_FETCHING_REPORTS](state: any, status: any) {
    state.fetchingBechmarkingReports = status;
  }
};

/*--------------------------------------------------/
 * Report Mutations Tree Interface
 *--------------------------------------------------*/
export interface IReportMutations extends MutationTree<IReportState> {}
