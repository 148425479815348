<template>
  <div data-testid="list-providers-page" class="page list-providers-page">
    <v-container fluid>
      <v-layout class="mt-4 pa-0">
        <div style="align-self: center">
          <h2 class="section-title">Providers</h2>
        </div>
      </v-layout>
      <v-card v-if="!fetchingProviders && providers" class="mt-4">
        <AppTable
          data-testid="ProviderTable"
          :table-data="tableData"
          v-on:removeItemClick="(e) => removeProvider(e)"
          :disablePagination="true"
        >
        </AppTable>
        <div v-if="totalPage > 1" class="text-center list-pagination">
          <v-pagination
            v-if="totalPage > 1"
            v-model="currPage"
            :length="totalPage"
            active-color="primary"
            density="comfortable"
            prev-icon="fa fa-caret-left"
            next-icon="fa fa-caret-right"
            @next="currPage + 1"
            @previous="currPage - 1"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-card>
      <!-- <div v-if="fetchingProviders && !providers.length" class="d-flex align-center justify-center pt-5 mt-5">
        <v-progress-circular indeterminate color="grey" />
      </div> -->
    </v-container>
    <AppLoader v-if="removingProvider || fetchingProviders" />
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Watch } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppTable from '@/components/AppTable.vue';
import KeyStats from '@/components/KeyStats.vue';
import { Table, DataTableHeader, TableRow, TableColumnType } from '@/types';
import Provider from '@/models/Provider';
import { HTTPServiceClient } from '@/packages/http/service';
import * as ep from '../endpoints';
import { ADD_PROVIDER, FETCH_PROVIDERS } from '@/modules/providers/store/actionTypes';
import AppLoader from '@/components/AppLoder.vue';
import { SET_NAV_HEADER } from '@/store/actionTypes';

@Options({
  components: {
    AppTable,
    KeyStats,
    AppLoader,
  },
})
export default class ListProvidersPage extends Vue {
  public dialog = false;
  public name = '';
  public email = '';
  public addingProvider = false;
  public addProviderError = false;
  public latestBatches = [];
  public providerList = [];
  public currPage = 1;
  public removingProvider = false;

  created() {
    this.bootstrap();
  }

  public async bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Provider Management', tooltip: 'All Providers' });
    await HTTPServiceClient.get('/providers/all-data').then(({ data }) => (this.providerList = data));
    await HTTPServiceClient.get('/providers/latest-batches').then(({ data }) => (this.latestBatches = data));
  }

  public get user() {
    return this.$store.getters.currentUser;
  }

  get fetchingProviders() {
    return this.$store.getters.fetchingProviders;
  }

  public get providersWithPA() {
    return this.$store.getters.providerWithPA;
  }

  get providers() {
    const providerData = this.providerList.map((provider: any) => {
      const batch: any = this.latestBatches.find((b: { providerId: any }) => b.providerId == provider.id);
      if (batch) {
        provider.batchId = batch.id;
      }

      const withPA = this.providersWithPA.find((p: { id: any }) => p.id == provider.id);
      if (withPA) {
        provider.isPA = true;
      }

      return provider;
    });
    return providerData;
  }

  public get providersData() {
    const providerList = this.providers;
    if (providerList) {
      let pageEnd = this.currPage * 15;
      const PageStart = pageEnd - 15;
      if (pageEnd > providerList.length) {
        pageEnd = providerList.length;
      }
      return providerList.slice(PageStart, pageEnd);
    }
    return providerList;
  }

  public get totalPage() {
    const totalProvider = this.providers;
    if (totalProvider) {
      return Math.ceil(totalProvider.length / 15);
    }
    return 1;
  }

  public get tableData(): Table {
    const columns: DataTableHeader[] = [
      {
        value: 'id',
        title: 'Id',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'name',
        title: 'Name',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'email',
        title: 'Email',
        type: TableColumnType.EMAIL,
        align: 'center',
      },
      {
        value: 'isPA',
        title: 'Performance Attribution',
        type: TableColumnType.BOOLEAN,
        align: 'center',
      },
      {
        value: 'batchId',
        title: 'Data Batch Id',
        type: TableColumnType.STRING,
        align: 'center',
      },
      // {
      //   value: 'remove',
      //   text: 'Actions',
      //   type: TableColumnType.ACTIONS,
      //   slot: 'action'
      // }
    ];

    return {
      columns,
      rows: this.rows,
    };
  }

  public get rows(): TableRow[] {
    return (
      this.providersData &&
      this.providersData.map((provider: { id: any; name: any; email: any; isPA: any; batchId: any }) => {
        return {
          id: provider.id,
          name: provider.name,
          email: provider.email,
          isPA: provider.isPA,
          batchId: provider.batchId,
        };
      })
    );
  }
}
</script>
<style lang="scss">
#provider-alert {
  margin: 0.5em;
}
.list-pagination {
  padding: 20px;
}
</style>
