export interface IReportState {
  bechmarkingReports: any;
  fetchingBechmarkingReports: boolean;
}

/**
 * --------------------------------------------------
 *	Reports State
 * --------------------------------------------------
 **/

export const ReportState: IReportState = {
  bechmarkingReports: [],
  fetchingBechmarkingReports: false
};
