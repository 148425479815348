import User from '@/models/User';
import { HTTPServiceClient } from '@/packages/http/service';
import * as ep from './endpoints';

export const UserService = {
  /**
   * Description:
   * @param {}
   * @returns { Promise<User[]> }
   */
  getUsers(): Promise<User[]> {
    return HTTPServiceClient.get(ep.FETCH_USERS).then(({ data }) => data);
  },

  addUser(data: any): Promise<User> {
    return HTTPServiceClient.post(ep.ADD_USER, data).then(({ data }) => data);
  },

  removeUser(data: number): Promise<User> {
    return HTTPServiceClient.delete(ep.REMOVE_USER(data)).then(({ data }) => data);
  },

  removeUserProviderMap(data: number): Promise<User> {
    return HTTPServiceClient.post(ep.REMOVE_USER_PROVIDER_MAP,data).then(({ data }) => data);
  }


};
