import { createRouter, createWebHistory } from 'vue-router';
import { Routes } from './routes';
import { MiddlewareMappings } from '@/router/middlewares';
import { Dispatcher } from '@/router/middlewares/dispatcher';

// Vue.use(Router);

// let router: Router = new Router({
//   mode: 'history',
//   routes: [
//     // The components of these routes will be rendered inside the
//     // `router-view` component, in the `/src/App.vue` component.
//     //
//     // More routes can be registered after the Application Routes.
//     ...Routes
//   ]
// });

let router = createRouter({
  history: createWebHistory(),
  routes: [...Routes],
});

router = Dispatcher.register(router, MiddlewareMappings);

export default router;
