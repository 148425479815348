import User from '@/models/User';

export interface IUserState {
  users: User[];
  fetchingUsers: Boolean;
}

/**
 * --------------------------------------------------
 *	User State
 * --------------------------------------------------
 **/

export const UserState: IUserState = {
  users: [],
  fetchingUsers: false
};
