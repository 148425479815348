// import Reports from '@/models/Reports';
import { HTTPServiceClient } from '@/packages/http/service';
import option from '@/packages/stubs/echarts/awsBar';
import * as ep from './endpoints';

export const ReportsService = {
  // /**
  //  * Description:
  //  * @param {}
  //  * @returns { Promise<Reports[]> }
  //  */
  exportReport(options: any): Promise<any> {
    const { provider, ...params } = options;
    return HTTPServiceClient.get(ep.EXPORT_REPORT(provider), { params }).then(({ data }) => data);
  },

  exportPriceTrackReport(options: any): Promise<any> {
    const { priceTrackId } = options;
    return HTTPServiceClient.get(ep.EXPORT_PRICE_TRACK_REPORT(priceTrackId)).then(({ data }) => data);
  },

  getOutlierAnalysis(providerId: number, params: any): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_OUTLIER_ANALYSIS(providerId), { params }).then(({ data }) => {
      return data;
    });
  },

  fetchReports(): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_REPORTS).then(({ data }) => data);
  },

  getPdfUrl(id: number): Promise<any> {
    return HTTPServiceClient.get(ep.GET_PDF_URL(id)).then(({ data }) => data);
  },

  getPAPdfUrl(options: any): Promise<any> {
    const { provider, ...params } = options;
    return HTTPServiceClient.get(ep.GET_PA_PDF_URL(provider), { params }).then(({ data }) => data);
  },

  getExportJobStatus(id: number): Promise<any> {
    return HTTPServiceClient.get(ep.GET_EXPORT_JOB(id)).then(({ data }) => data);
  },
  triggerPASeparately(id: number): Promise<any> {
    return HTTPServiceClient.get(ep.RUN_PA_SEPARATELY(id)).then(({ data }) => data);
  },

  getPerformanceAttribution(id: number): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_PERFORMANCE_ATTRIBUTION(id)).then(({ data }) => data);
  },
  updateSegmentationLabelData(data: any): Promise<any> {
    return HTTPServiceClient.post(ep.UPDATE_SEGMENT_LABEL_DATA, data).then(({ data }) => data);
  },

  addPerformanceAttributionFreeText(data: any): Promise<any> {
    return HTTPServiceClient.post(ep.ADD_PA_FREE_TEXT(data.providerID), data).then(({ data }) => data);
  },

  fetchSampleReport(): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_SAMPLE_REPORT).then(({ data }) => data);
  }
};
