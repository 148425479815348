// import Reports from '@/models/Reports';
import { HTTPServiceClient } from '@/packages/http/service';
import option from '@/packages/stubs/echarts/awsBar';
import * as ep from './endpoints';

export const ReportsService = {
  // /**
  //  * Description:
  //  * @param {}
  //  * @returns { Promise<Reports[]> }
  //  */
  exportReport(options: any): Promise<any> {
    return HTTPServiceClient.get(ep.EXPORT_REPORT(options.providerId)).then(({ data }) => data);
  },

  fetchReports(id: number): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_REPORT_DATA(id)).then(({ data }) => data);
  },

  getPdfUrl(id: number): Promise<any> {
    return HTTPServiceClient.get(ep.GET_PDF_URL(id)).then(({ data }) => data);
  },
  getExportJobStatus(id: number): Promise<any> {
    return HTTPServiceClient.get(ep.GET_EXPORT_JOB(id)).then(({ data }) => data);
  },
  requestVFMReport(): Promise<any> {
    return HTTPServiceClient.get(ep.REQUEST_VFM_REPORT).then(({ data }) => data);
  }
};
