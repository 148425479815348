import { IRootState } from '@/store/state';
import { IMemberState } from '@/modules/members/store/state';
import { GetterTree } from 'vuex';
import Member from '@/models/Member';

/**
 * --------------------------------------------------
 *	Member Getters
 * --------------------------------------------------
 **/
export const MemberGetters: IMemberGetters = {
  members: (state: IMemberState) => state.members,
  memberHistory: (state: IMemberState) => state.memberHistory,
  contributionHistory: (state: IMemberState) => state.contributionHistory,
  memberAnalysis: (state: IMemberState) => state.memberAnalysis,
  outlierData: (state: IMemberState) => state.outlierData,
  fetchingMembers: (state: IMemberState) => state.fetchingMembers,
  memberFilterRanges: (state: IMemberState) => state.memberFilterRanges,
  outlierReasonAnalysis: (state: IMemberState) => state.outlierReasonAnalysis,
  sampleMemberAnalysis: (state: IMemberState) => state.sampleMemberAnalysis
};

/**
 * --------------------------------------------------
 *	Member Getters Interface
 * --------------------------------------------------
 **/
export interface IMemberGetters extends GetterTree<IMemberState, IRootState> {
  members(state: IMemberState): Member[];
  memberHistory(state: IMemberState): any[] | null;
  contributionHistory(state: IMemberState): any[] | null;
  memberAnalysis(state: IMemberState): any[] | null;
  fetchingMembers(state: IMemberState): boolean;
  memberFilterRanges(state: IMemberState): any | null;
  outlierReasonAnalysis(state: IMemberState): any[] | null;
  sampleMemberAnalysis(state: IMemberState): any | null;
}
