import ReportsModule from './ReportsModule.vue';
import ReportGenPage from './pages/ReportGenPage.vue';
import ListReportsPage from './pages/ListReportsPage.vue';
import PAReportPage from './pages/PAReportPage.vue';

export const ReportRoutes = [
         {
           path: '/reports',
           component: ReportsModule,
           children: [
             {
               path: '',
               name: 'dashboard.reports.list',
               component: ListReportsPage
               // component: ReportGenPage
             },
             {
               path: '/reports/generate',
               name: 'dashboard.reports.gen',
               // component: ListReportsPage
               component: ReportGenPage
             },
             {
               path: '/reports/performance-attribution',
               name: 'dashboard.reports.pa',
               // component: ListReportsPage
               component: PAReportPage
             }
           ]
         }
       ];
