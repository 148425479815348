import Member from '@/models/Member';
import { HTTPServiceClient } from '@/packages/http/service';
import * as ep from './endpoints';
import Config from '@/config';

export const MemberService = {
  /**
   * Description:
   * @param {}
   * @returns { Promise<Member[]> }
   */
  getMembers(): Promise<Member[]> {
    return HTTPServiceClient.get(ep.FETCH_MEMBERS).then(({ data }) => data);
  },

  getMemberHistory(id: number): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_MEMBER_HISTORY(id)).then(({ data }) => data);
  },

  getContributionHistory(): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_CONTRIBUTION_HISTORY).then(({ data }) => data);
  },

  getMemberFilterRanges(params?: any): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_MEMBER_FILTER_RANGES, { params }).then(({ data }) => data);
  },

  getMemberAnalysisByPage(params: any): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_MEMBERS_ANALYSIS, { params }).then(({ data }) => data);
  },

  getSampleMemberAnalysisByPage(params: any): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_SAMPLE_MEMBERS_ANALYSIS, { params }).then(({ data }) => data);
  },

  getMemberAnalysisCSVUrl(params: any): Promise<string | null> {
    return HTTPServiceClient.get(ep.EXPORT_MEMBERS_ANALYSIS, { params }).then(({ data }) => {
      return data || null;
    });
  },

  getOutlierAnalysisCSVUrl(providerId: number): Promise<string | null> {
    return HTTPServiceClient.get(ep.EXPORT_OUTLIER_ANALYSIS(providerId)).then(({ data }) => {
      return data || null;
    });
  },

  getOutlierAnalysis(params: any): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_OUTLIER_ANALYSIS(params.providerId), { params }).then(({ data }) => data);
  },

  getMemberAnalysisByFilter(params: any): Promise<Object> {
    return HTTPServiceClient.get(ep.FETCH_FILTERED_MEMBERS_ANALYSIS, { params }).then(({ data }) => data);
  },

  getCSVUrl(id: number): Promise<any> {
    return HTTPServiceClient.get(ep.GET_CSV_URL(id)).then(({ data }) => data);
  },

  getOutlierReasonAnalysisCSVUrl(params: any): Promise<string | null> {
    return HTTPServiceClient.get(ep.EXPORT_OUTLIER_REASON_ANALYSIS(params.providers[0]), { params }).then(
      ({ data }) => {
        return data || null;
      }
    );
  },
  getSampleMemberAnalysis(params: any): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_SAMPLE_MEMBER_DATA, { params }).then(({ data }) => data);
  }
};
