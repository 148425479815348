import ProviderB2C from '@/models/ProviderB2C';
import UserB2C from '@/models/UserB2C';
import { HTTPServiceClient } from '@/packages/http/service';
import * as ep from './endpoints';
import Config from '@/config';
import ImportB2C from '@/models/ImportB2C';

export const B2CService = {
  /**
   * Description:
   * @param {}
   * @returns { Promise<ProviderB2C[]> }
   */
  getProvidersB2C(): Promise<ProviderB2C[]> {
    return HTTPServiceClient.get(ep.FETCH_PROVIDERS).then(({ data }) => data);
  },
  getProviderB2C(id: String): Promise<ProviderB2C[]> {
    return HTTPServiceClient.get(ep.FETCH_PROVIDER(id)).then(({ data }) => data);
  },
  editProviderB2C(data: any) {
    return HTTPServiceClient.post(ep.EDIT_PROVIDER, data);
  },
  getUsersB2C(): Promise<UserB2C[]> {
    return HTTPServiceClient.get(ep.FETCH_USERS).then(({ data }) => data);
  },
  getUserB2C(id: String): Promise<UserB2C[]> {
    return HTTPServiceClient.get(ep.FETCH_USER(id)).then(({ data }) => data);
  },
  editUserB2C(data: any) {
    return HTTPServiceClient.post(ep.EDIT_USER, data);
  },
  addScoreBulk(data: any) {
    return HTTPServiceClient.post(ep.ADD_SCORE_BULK, data);
  },
  getImports(): Promise<ImportB2C[]> {
    return HTTPServiceClient.get(ep.FETCH_IMPORTS).then(({ data }) => data);
  },
  getContributionsURL(importId: number): Promise<string | null> {
    return HTTPServiceClient.get(ep.EXPORT_B2C_CONTRIBUTIONS(importId)).then(({ data }) => {
      return data.url || null;
    });
  },
  getExportUsersURL(): Promise<string | null> {
    return HTTPServiceClient.get(ep.EXPORT_USERS).then(({ data }) => {
      return data.url || null;
    });
  }
};
