<template>
  <div class="elevation-10 chartWrapper">
    <div class="chart-title text-center pb-8">{{ title }}</div>
    <BaseChart :options="chartData" :scrollOptions="scrollOptions" />
  </div>
</template>

<script lang="ts">
import BaseChart from './BaseChart.vue';
export default {
  components: {
    BaseChart,
  },
  props: ['chartData', 'title'],
  data() {
    return {
      scrollOptions: {
        chartHeight: '30',
        unit: 'rem',
      },
    };
  },
};
</script>
