<template>
  <div class="report-template">
    <v-card class="report-page-card" outlined flat>
      <div class="section">
        <KeyStats v-if="!fetchingDataQuality" class="mt-4" :data="metrics" :tooltipVisibility="false"></KeyStats>
      </div>
      <div class="section">
        <div class="section-title">
          <div class="section-badge"></div>
          AgeWage Score Benchmarking
        </div>
        <div class="section-content">
          AgeWage considers the internal rate of return (IRR) of an individual’s pension account as a good measure of
          saver’s investment experience as it includes the impact of all costs and charges as well as all returns on
          invested assets. AgeWage uses the Morningstar UK pension index as a benchmark for IRRs. By shadow-investing a
          saver’s contributions into this index, AgeWage creates a benchmark IRR and by means of an algorithm, converts
          the comparison of IRRs into an AgeWage score.
        </div>
        <div class="section-content">
          The AgeWage score rates each saver’s experience between 0 and 100 with a 50 score being an average experience.
          The Scheme’s score is the mathematical average of every member’s score and reflects the average experience of
          savers over their time in the scheme
        </div>
      </div>
      <div v-if="!fetchingBechmarkingReports && !fetchingDataQuality && data && data.length" class="report-chart">
        <div class="d-flex justify-end">
          <div class="benchmark-filter-menu-data-quality">
            <v-menu
              v-model="filterIsVisible"
              :close-on-content-click="false"
              content-class="benchmark-filter-menu-wrapper"
              max-width="100%"
              min-width="100%"
              offset="20"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  class="filter-btn elevation-6 mt-0 mx-2"
                  :class="{ 'is-active': filtersAreApplied }"
                  data-testid="FilterBtn"
                  variant="text"
                  v-bind="props"
                  size="small"
                >
                  <span>Filter</span>
                  <div class="ml-1">
                    <v-img v-if="!filtersAreApplied" class="filter-icon" src="../../../../assets/icons/filter.svg" />
                    <v-img
                      v-if="filtersAreApplied"
                      class="filter-icon"
                      src="../../../../assets/icons/filter-light.svg"
                    />
                  </div>
                </v-btn>
              </template>
              <!-- Filter Menu -->
              <BenchmarkFilterTable
                :dateFilters="true"
                :filters="filters"
                :initFilters="appliedFilters"
                @apply="(e) => onFiltersApplied(e)"
              ></BenchmarkFilterTable>
            </v-menu>
          </div>
        </div>
        <VFMIndustryCompareChart
          v-if="chartData"
          :data="chartData"
          :providerDataQuality="providerDataQuality"
          :providerName="providerName"
          :providerId="providerId"
          @expandGraph="expandGraph"
        />
      </div>

      <div class="footer">
        <div></div>
        <!-- <div class="page-box">
            <div class="pageno">Page 2/4</div>
            </div> -->
        <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import VFMIndustryCompareChart from '@/components/charts/VFMIndustryCompareChart.vue';
import KeyStats from '@/components/KeyStats.vue';
import BenchmarkFilterTable from './BenchmarkFilter.vue';

@Options({
  components: {
    VFMIndustryCompareChart,
    KeyStats,
    BenchmarkFilterTable,
  },
})
export default class VFMCompare extends Vue {
  @Prop()
  public data?: any;

  @Prop()
  public providerName?: string;

  @Prop()
  public providerId?: string;

  public filterIsVisible = false;
  public appliedFilters = {
    tax_year: [],
    scheme: [],
    pathway: [],
    data_type: [],
    consolidator: [],
    asset_size: [],
  };
  public initialFilters = {
    tax_year: ['2019-2020', '2020-2021', '2021-2022'],
    scheme: [
      'Master Trusts',
      'HMRC Group Personal Pension',
      'Occupational Pension',
      'Employer Scheme in a GPP',
      'Employer Scheme in a Master Trust',
      'Investment Pathway',
    ],
    pathway: ['Accumulation', 'Drawdown', 'Cash', 'Annuity', 'Remain Invested'],
    data_type: ['Member Experience', 'Synthetic'],
    consolidator: ['Accepting Full Consolidation', 'Accepting Partial Consolidation', 'Not Accepting Consolidation'],
    asset_size: { min: 20, max: 100 },
  };

  public chartData = this.getChartData();

  mounted() {
    this.chartData = this.getChartData();
    this.setInitFilter([0, 5000000000]);
  }

  public getChartData() {
    if (!this.checkAppliedFilters) {
      return this.data;
    }

    const filteredChart = this.data.filter((item: any) => {
      let isInRange = false;
      const navInRange = this.checkNavInRange(item);
      const schemeFound = this.checkScheme(item);
      const taxYearFound = this.checkTaxYear(item);
      const pathwayFound = this.checkPathway(item);
      const dataTypeFound = this.checkDatatype(item);
      const consolidatorFound = this.checkConsolidator(item);

      if (navInRange && schemeFound && taxYearFound && pathwayFound && dataTypeFound && consolidatorFound) {
        isInRange = true;
      }
      return isInRange;
    });
    return filteredChart;
  }

  public setInitFilter(size: number[]) {
    const filters: any = { ...this.appliedFilters };
    filters.asset_size = size;
    this.appliedFilters = filters;
    this.filterIsVisible = true;
    this.onFiltersApplied(filters);
  }

  get moduleIsReady() {
    return true;
  }

  public checkNavInRange(item: any) {
    const filterData = this.appliedFilters;
    if (!filterData.asset_size.length) {
      return true;
    }
    if (+item.total_nav <= filterData.asset_size[1] && +item.total_nav >= filterData.asset_size[0]) {
      return true;
    }
    return false;
  }

  public checkScheme(item: any) {
    const filterData = this.appliedFilters;
    if (!filterData.scheme.length) {
      return true;
    }

    if (filterData.scheme.find((element) => element == item.scheme)) {
      return true;
    }
    return false;
  }

  public checkTaxYear(item: any) {
    const filterData = this.appliedFilters;
    if (!filterData.tax_year.length) {
      return true;
    }
    if (filterData.tax_year.find((element) => element == item.tax_year)) {
      return true;
    }
    return false;
  }

  public checkPathway(item: any) {
    const filterData = this.appliedFilters;
    if (!filterData.pathway.length) {
      return true;
    }
    if (filterData.pathway.find((element) => element == item.pathway)) {
      return true;
    }
    return false;
  }

  public checkDatatype(item: any) {
    const filterData = this.appliedFilters;
    if (!filterData.data_type.length) {
      return true;
    }
    if (filterData.data_type.find((element) => element == item.data_type)) {
      return true;
    }
    return false;
  }
  public checkConsolidator(item: { consolidator: string | number }) {
    const filterData = this.appliedFilters;
    if (!filterData.consolidator.length) {
      return true;
    }
    const mapConsolidator = {
      'Accepting Full Consolidation': 'yes',
      'Not Accepting Consolidation': 'no',
      'Accepting Partial Consolidation': 'partial',
    };
    if (filterData.consolidator.find((element) => mapConsolidator[element] == item.consolidator)) {
      return true;
    }
    return false;
  }

  public get checkAppliedFilters() {
    if (
      this.appliedFilters.asset_size.length > 0 ||
      this.appliedFilters.scheme.length > 0 ||
      this.appliedFilters.tax_year.length > 0 ||
      this.appliedFilters.pathway.length > 0 ||
      this.appliedFilters.data_type.length > 0 ||
      this.appliedFilters.consolidator.length > 0
    ) {
      return true;
    }
    return false;
  }

  public get filtersAreApplied() {
    return !!(this.appliedFilters && Object.keys(this.appliedFilters).length);
  }

  public get fetchingBechmarkingReports() {
    return this.$store.getters.fetchingBechmarkingReports;
  }

  public get fetchingDataQuality() {
    return this.$store.getters.fetchingDataQuality;
  }

  public get providerDataQuality() {
    return this.$store.getters.providerDataQuality;
  }

  public get filters() {
    this.initialFilters.asset_size.min = Math.min.apply(
      Math,
      this.data.map(function (o: { total_nav: number }) {
        return +o.total_nav;
      })
    );
    this.initialFilters.asset_size.max = Math.max.apply(
      Math,
      this.data.map(function (o: { total_nav: number }) {
        return +o.total_nav;
      })
    );
    return {
      ...this.initialFilters,
    };
  }

  public get metrics() {
    return {
      stat1_title: +this.providerDataQuality.score,
      stat2_title: this.providerDataQuality.avg_irr,
      stat3_title: this.providerDataQuality.benchmark_irr,
      stat4_title: this.providerDataQuality.asset_size,
      stat1_text: 'AgeWage Score',
      stat2_text: 'Average IRR',
      stat3_text: 'Average Benchmark IRR',
      stat4_text: `Assets analysed`,
    };
  }

  public onFiltersApplied(filters: any = {}) {
    this.appliedFilters = filters || {};
    this.filterIsVisible = false;
    this.chartData = this.getChartData();
  }

  public expandGraph() {
    this.setInitFilter([]);
  }
}
</script>
<style lang="scss">
.benchmark-filter-menu-wrapper {
  min-width: 75% !important;
  max-width: 90% !important;
  right: 7% !important;
  left: unset !important;
}
</style>
