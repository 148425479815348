import { createVuetify } from 'vuetify';
import { fa } from 'vuetify/iconsets/fa';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { VDataTable, VDataTableServer } from 'vuetify/labs/VDataTable';
import { VDatePicker } from 'vuetify/labs/VDatePicker';
import 'vuetify/styles';
import '../../sass/bootstrap.scss';
import type { ThemeDefinition } from 'vuetify';

const lightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#300078',
    secondary: '#1F2041',
    accent: '#976fea',
    error: '#FF5454',
    info: '#77c3ca',
    success: '#3bb16c',
    warning: '#F9C418',
    highlight: '#FF8D4E',
  },
};

export default createVuetify({
  components: {
    ...components,
    VDataTable,
    VDataTableServer,
    VDatePicker,
  },
  directives,
  theme: {
    defaultTheme: 'lightTheme',
    themes: {
      lightTheme,
    },
  },
  icons: {
    defaultSet: 'mdi',
    sets: {
      fa,
      mdi,
    },
  },
});
