<template>
  <div data-testid="add-b2c-provider-page" class="page add-b2c-provider-page">
    <v-container fluid>
      <v-layout class="mt-4 pa-0">
        <div class="w-100">
          <v-card>
            <v-form @submit.prevent="addProvider" class="py-5">
              <v-card-title class="d-flex align-center justify-space-between">
                <span class="text-h5"> Add Provider </span>
              </v-card-title>
              <v-card-text class="mt-6">
                <div v-show="true">
                  <div>
                    <v-label>Provider Name</v-label>
                    <v-text-field
                      hide-details
                      variant="underlined"
                      :persistent-hint="false"
                      placeholder="Provider Name"
                      v-model="pName"
                      class="mb-5"
                    />
                  </div>
                </div>
                <v-container grid-list-md>
                  <v-layout data-testid="Upload-Container" v-if="!dataUploaded" wrap>
                    <v-row>
                      <v-col :cols="12" class="text-center mt-2">
                        <AppUploader
                          data-testid="FileUpload"
                          class="d-flex"
                          @app-uploader="handleFile"
                          :disabled="updatingProvider"
                          fileType="image/*"
                        />
                      </v-col>
                    </v-row>
                  </v-layout>
                  <v-layout v-if="dataUploaded" wrap>
                    <v-row>
                      <v-col>
                        <v-alert v-model="dataUploaded" closable type="success" class="mb-4" prominent
                          >Provider added successfully.</v-alert
                        >
                      </v-col>
                    </v-row>
                  </v-layout>
                  <v-layout v-if="dataUploadError" wrap>
                    <v-row>
                      <v-col>
                        <v-alert
                          data-testid="FileUploadError"
                          v-model="dataUploadError"
                          closable
                          type="error"
                          class="mb-4"
                          prominent
                          >{{ validationError }}</v-alert
                        >
                      </v-col>
                    </v-row>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  data-testid="SubmitUploadBtn"
                  :color="updatingProvider || dataUploaded ? 'disabled' : 'success'"
                  type="submit"
                  variant="flat"
                  :loading="updatingProvider"
                  :disabled="updatingProvider || dataUploaded"
                  >Add</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Vue, Watch } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppUploader from '@/components/AppUploader.vue';
import { FETCH_PROVIDER, EDIT_PROVIDER } from '@/modules/b2c-panel/store/actionTypes';
import { SET_NAV_HEADER } from '@/store/actionTypes';

@Options({
  components: {
    AppUploader,
  },
})
export default class ProvidersPage extends Vue {
  public dataUploadError = false;
  public validationError = '';
  public dataUploaded = false;
  public file: any = null;
  public pName = '';

  @Watch('updatingProvider')
  public userUpdated() {
    if (this.updatingProvider === false) {
      this.$router.push({ name: 'dashboard.b2cpanel.providers' });
    }
  }

  get updatingProvider() {
    return this.$store.getters.updatingProvider;
  }

  created() {
    this.bootstrap();
  }

  public bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Providers', tooltip: 'Add Provider' });
  }

  public handleFile(data: any) {
    this.file = data.file;
  }

  public addProvider() {
    this.dataUploadError = false;
    this.validationError = '';
    if (!this.pName || this.pName.trim() === '') {
      this.dataUploadError = true;
      this.validationError = 'Please fill name';
      return;
    } else if (!this.file) {
      this.dataUploadError = true;
      this.validationError = "Please make sure you're uploading an image file.";
      return;
    }
    if (this.dataUploadError === false) {
      let formData = new FormData();
      const name = this.pName ? this.pName : '';
      formData.append('file', this.file, this.file.name);
      formData.append('name', `${name}`);
      this.$store.dispatch(EDIT_PROVIDER, formData);
    }
  }
}
</script>
<style lang="scss">
.app-uploader__uploader-container {
  width: 100%;
}
</style>
