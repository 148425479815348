import { IRootState } from '@/store/state';
import { IProviderAnalysisState } from '@/modules/providers-analysis/store/state';
import { GetterTree } from 'vuex';

/**
 * --------------------------------------------------
 *	ProviderAnalysis Getters
 * --------------------------------------------------
 **/
export const ProviderAnalysisGetters: IProviderAnalysisGetters = {
  analysis: (state: any) => state.analysis,
  chartData: (state: any) => state.chartData,
  pdfLink: (state: any) => state.pdfLink,
  fetchingAnalysis: (state: any) => state.fetchingAnalysis,
  fetchingChartData: (state: any) => state.fetchingChartData,
  generatingPdf: (state: any) => state.generatingPdf
};

/**
 * --------------------------------------------------
 *	ProviderAnalysis Getters Interface
 * --------------------------------------------------
 **/
export interface IProviderAnalysisGetters extends GetterTree<IProviderAnalysisState, IRootState> {}
