<template>
  <div class="report-template">
    <Watermark :isSampleReport="isSampleReport" />
    <v-card class="report-page-card" outlined flat>
      <div class="section">
        <KeyStats
          class="mt-4"
          :data="metrics"
          :type="(iavData && 'vfm') || ''"
          :valueSign="metrics.stat3_sign"
          :tooltipVisibility="false"
        ></KeyStats>
      </div>

      <div v-if="filters.awDist" class="section">
        <div class="section-title">
          <div class="section-badge"></div>
          AgeWage Score Distribution
        </div>
        <div class="section-content">
          The AgeWage score is calculated by comparing the Actual Internal Rate of Return (IRR) of individual savers
          with the Benchmark IRRs, generated by investing in the AgeWage benchmark fund prepared in collaboration with
          Hymans Robertson. The AgeWage Score distribution below shows the number of pots in various score ranges. The
          average AgeWage score for {{ chartData.saversCount }} Savers is {{ metrics.stat1_title.toFixed(0) }}.
          <!-- The Net
          Asset Value {{datasetMetrics.assetPerformanceVal > 0 ? 'enhanced' : 'diminished'}} by £{{
            formatNumber(Math.abs(datasetMetrics.assetPerformanceVal), '0.00a')
          }} compared to the benchmark as a result of {{ datasetMetrics.assetPerformance }}. -->
        </div>
      </div>
      <div v-if="!fetchingChartData && filters.awDist" class="report-chart">
        <ScoreDistChart
          data-testid="ScoreDistChart"
          v-if="scoresData"
          :chartData="scoresData"
          :barColor="barColor"
          :provider="filters.provider"
          :priceTrack="filters.priceTrackObject"
          :isSampleReport="isSampleReport"
          :reportfilterRanges="reportfilterRanges"
        ></ScoreDistChart>
      </div>

      <div class="footer">
        <div></div>
        <!-- <div class="page-box">
          <div class="pageno">Page 2/4</div>
        </div> -->
        <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
      </div>
    </v-card>
  </div>
</template>
<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { FETCH_MEMBERS, FETCH_CONTRIBUTION_HISTORY } from '@/modules/members/store/actionTypes';
import ScoreDistChart from '@/components/charts/ScoreDistChart.vue';
import AVBChart from '@/components/charts/AVBChart.vue';
import KeyStats from '@/components/KeyStats.vue';
import Watermark from './Watermark.vue';
import Utils from '../../../../utils/Utils';
import { ref } from 'vue';

@Options({
  components: {
    ScoreDistChart,
    AVBChart,
    KeyStats,
    Watermark,
  },
})
export default class ScoreDist extends Vue {
  created() {
    this.bootstrap();
  }
  @Prop()
  public chartData?: any;

  @Prop({
    default: () => ({}),
  })
  reportfilterRanges?: any;

  @Prop()
  public data?: any;

  @Prop()
  public iavData?: any;

  @Prop({
    default: () => ({
      awDist: true,
      yearSeg: true,
      Irr: true,
      avb: true,
      schemeSeg: true,
      outliers: true,
      startDate: null,
      endDate: null,
    }),
  })
  filters?: any;

  @Prop() isSampleReport!: boolean;

  public barColor: any = {
    primary: '#a085bc',
    secondary: '#cbafd5',
  };

  public plotterColor: any = {
    primary: '#756287',
    secondary: '#cbafd5',
    shadow: '#eee6f1',
  };

  get moduleIsReady() {
    return true;
  }

  public get scoresData() {
    return this.chartData.scores;
  }

  public get fetchingSampleReport() {
    return this.$store.getters.fetchingSampleReport;
  }

  public get sampleReport() {
    return this.$store.getters.sampleReport;
  }

  public get fetchingChartData() {
    return this.$store.getters.fetchingChartData;
  }

  public get memberAnalysis(): any[] | null {
    return this.$store.getters.memberAnalysis && this.$store.getters.memberAnalysis.data;
  }

  private get filterRanges() {
    if (this.isSampleReport) {
      return this.sampleReport && this.sampleReport.filterRanges.data;
    }
    return this.$store.getters.memberFilterRanges;
  }

  private get getAssetPerformance() {
    if (this.filterRanges && this.filterRanges.potRange) {
      const getDiff =
        parseFloat(this.filterRanges.potRange.total) - parseFloat(this.filterRanges.potRange.benchmarkNavAmount);
      if (getDiff > 0) {
        return 'over performance';
      } else {
        return 'under performance';
      }
    }
  }

  private get getAssetPerformanceVal() {
    if (this.filterRanges && this.filterRanges.potRange) {
      const getDiff =
        parseFloat(this.filterRanges.potRange.total) - parseFloat(this.filterRanges.potRange.benchmarkNavAmount);
      return getDiff;
    }
  }

  public formatNumber(n: number, format: string) {
    return Utils.formatNumber(n, format);
  }

  public get iavChartData() {
    const diff: any[] = [];
    this.iavData &&
      this.iavData.iav &&
      this.iavData.iav.forEach((element: any) => {
        if (element.total_nav > element.total_benchmarkNav) {
          const diffVal = element.total_nav - element.total_benchmarkNav;
          diff.push({
            value: diffVal,
            itemStyle: {
              color: 'green',
            },
          });
        } else {
          const diffVal = element.total_benchmarkNav - element.total_nav;
          diff.push({
            value: diffVal,
            itemStyle: {
              color: 'red',
            },
          });
        }
      });
    return { diff };
  }

  public get iavStateValues() {
    if (this.iavChartData.diff && this.iavChartData.diff.length > 0) {
      let compare1 = this.iavChartData.diff[0];
      let compare2 = this.iavChartData.diff[1];
      if (this.iavChartData.diff[0] && this.iavChartData.diff[0].itemStyle.color === 'green') {
        compare1 = this.iavChartData.diff[0];
        compare2 = this.iavChartData.diff[1];
      } else {
        compare1 = this.iavChartData.diff[1];
        compare2 = this.iavChartData.diff[0];
      }
      let netDiffVal = 0;
      let diffStatus = '';
      let performanceStatus = '';
      if (compare1.value > compare2.value) {
        diffStatus = '+';
        netDiffVal = compare1.value - compare2.value;
      } else if (compare1.value < compare2.value) {
        diffStatus = '-';
        netDiffVal = compare2.value - compare1.value;
      }
      const data = {
        netDiffVal: +netDiffVal.toFixed(0),
        diffStatus,
      };
      return data;
    }
    const data = {
      netDiffVal: 0,
      diffStatus: '',
    };
    return data;
  }

  /**
   * Returns stats/metrics for the whole dataset
   */
  public get datasetMetrics() {
    if (!this.filterRanges) {
      return {
        score: 0,
        irr: 0,
        birr: 0,
        potSize: 0,
        iavStateValues: { netDiffVal: 0, diffStatus: '' },
        assetPerformance: '',
      };
    }
    return {
      score: this.filterRanges.scoreRange && this.filterRanges.scoreRange.avg,
      irr: this.filterRanges.irrRange && this.filterRanges.irrRange.avg,
      birr: this.filterRanges.birrRange && this.filterRanges.birrRange.avg,
      potSize: this.filterRanges.potRange && this.filterRanges.potRange.total,
      assetPerformance: this.getAssetPerformance,
      assetPerformanceVal: this.getAssetPerformanceVal,
      iavStateValues: this.iavStateValues,
    };
  }

  public get metrics() {
    return {
      stat1_title: this.datasetMetrics.score,
      stat2_title: this.datasetMetrics.irr,
      stat3_title: this.datasetMetrics.iavStateValues.netDiffVal,
      stat3_sign: this.datasetMetrics.iavStateValues.diffStatus,
      stat4_title: this.datasetMetrics.potSize,
      stat1_text: 'AgeWage Score',
      stat2_text: 'Average IRR',
      stat3_text: this.iavData ? 'Impact on asset value' : 'Average Benchmark IRR',
      stat4_text: `Assets analysed`,
    };
  }

  bootstrap() {
    //
  }
}
</script>
<style lang="scss">
.keyStat-template {
  display: flex;

  .statCard {
    width: 100%;
    min-height: 10rem;
    margin: 2rem;
    border-radius: 0.57rem;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: #fff;
    -webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.05), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
      0px 1px 14px 0px rgba(0, 0, 0, 0.06) !important;
    box-shadow: 1px 3px 4px -1px rgba(0, 0, 0, 0.05), 0px 5px 8px 0px rgba(0, 0, 0, 0.07),
      0px 1px 14px 0px rgba(0, 0, 0, 0.06) !important;

    &-1 {
      background-image: linear-gradient(307deg, #fe7598, #fea1be);
    }
    &-2 {
      background-image: linear-gradient(307deg, #ba84ff, #d8aefe);
    }
    &-3 {
      background-image: linear-gradient(307deg, #63cd9e, #8ae4c1);
    }

    &-4 {
      background-image: linear-gradient(127deg, #ffcf5e, #f4b411);
    }

    .card-text {
      margin: 0 auto;
      color: #fff;
      justify-content: center;
    }
    .card-feature-text {
      font-weight: 700;
      font-size: 2rem;
      text-align: center;
    }
    .stat-icon {
      color: #fff !important;
      font-size: 3rem !important;
      margin: 0 auto;
    }
    .stat-icon-img {
      font-size: 3rem !important;
      width: 4rem;
      margin: 0 auto;
    }
  }
}
.keystat {
  max-width: 80%;
  width: 100%;
  margin: auto;
}
.keystat-title {
  font-size: 2.5rem;
  font-weight: 500;
  padding-top: 2rem;
  display: flex;
  .section-badge {
    height: 2.5rem !important;
    width: 0.5rem !important;
    margin-right: 0.5rem;
    margin-top: 1rem;
    background-color: #796581;
  }
}
</style>
