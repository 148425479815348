import * as AT from '@/modules/benchmarks/store/actionTypes';
import * as MT from '@/modules/benchmarks/store/mutationTypes';
import { IRootState } from '@/store/state';
import { IBenchmarkState } from '@/modules/benchmarks/store/state';
import { ActionContext, ActionTree } from 'vuex';
import { BenchmarkService } from '@/modules/benchmarks/services';
import { SET_MEMBER_FILTER_RANGES, SET_MEMBER_ANALYSIS } from '@/modules/members/store/mutationTypes';
import { SET_CHARTDATA } from '@/modules/providers-analysis/store/mutationTypes';
import { FETCHING_CHARTDATA } from '@/modules/providers-analysis/store/actionTypes';

/**
 * --------------------------------------------------
 *  Benchmark Actions
 * --------------------------------------------------
 **/
export const BenchmarkActions: IBenchmarksActions = {
  /**
   * --------------------------------------------------
   *  Fetch All the Benchmarks
   * --------------------------------------------------
   **/
  [AT.FETCH_BENCHMARKS]: ({ commit, dispatch }: any) => {
    return BenchmarkService.getBenchmarks()
      .then(benchmarks => {
        commit(MT.SET_BENCHMARKS, benchmarks);
        return benchmarks;
      });
  },
  [AT.FETCH_BENCHMARK]: ({ commit, dispatch }: any, id: string) => {
    return BenchmarkService.getBenchmark(id)
      .then(benchmark => {
        commit(MT.SET_BENCHMARK, benchmark);
        return benchmark;
      });
  },
  [AT.ADD_BENCHMARK]: ({ commit, dispatch }: any, data: any) => {
      commit(MT.ADDING_BENCHMARK, true);
      return BenchmarkService.addBenchmark(data)
      .then(resp => {
        return resp;
      })
      .finally(() => {
        commit(MT.ADDING_BENCHMARK, false);
      });
  }
};

/**
 * --------------------------------------------------
 *  Benchmark Action Context Interface
 * --------------------------------------------------
 **/
export interface IBenchmarkActionContext extends ActionContext<IBenchmarkState, IRootState> {}

/**
 * --------------------------------------------------
 *  Benchmark Actions Interface
 * --------------------------------------------------
 **/
export interface IBenchmarksActions extends ActionTree<IBenchmarkState, IRootState> {}
