<template>
  <div class="report-template">
    <v-card class="report-page-card" outlined flat>
        <div
            v-if="filters.avb"
            class="section"
        >
            <div class="section-title">
                <div class="section-badge"></div>
                Actual vs Benchmark IRR
            </div>
            <div class="section-content">
                The dispersion of Actual vs. Benchmark IRR can be seen below.
                The size of the bubbles in the chart indicates the population of savers while the position represents their Actual and Benchmark IRRs.
                The green bubbles indicate an average AgeWage score above 50 outperforming the benchmark on average, while red bubbles have scores below 50 underperforming the benchmark.
                The chart can not only help understand the performance of the majority of savers but also help to identify outliers who have significantly out/under-performed.
            </div>
        </div>
        <div
            v-if="!fetchingChartData && filters.avb"
            class="report-chart"
        >
            <AVBChart
            v-if="avbData"
            :chartData="avbData"
            :plotterColor="plotterColor"
            ></AVBChart>
        </div>

        <div class="footer">
            <div></div>
            <!-- <div class="page-box">
            <div class="pageno">Page 2/4</div>
            </div> -->
            <v-img
            class="footer-img"
            src="../../../../assets/aw-footer-logo.jpg"
            ></v-img>
        </div>
      </v-card>
    </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { FETCH_MEMBERS, FETCH_CONTRIBUTION_HISTORY } from '@/modules/members/store/actionTypes';
import AIRRChart from '@/components/charts/AIRRChart.vue';
import AVBChart from '@/components/charts/AVBChart.vue';

@Options({
  components: {
    AIRRChart,
    AVBChart
  }
})
export default class IrrAndAvb extends Vue {
  @Prop()
  public chartData?: any;

  @Prop()
  public data?: any;

  @Prop({
    default: () => ({
      coverPage: true,
      awDist: true,
      yearSeg: true,
      Irr: true,
      avb: true,
      schemeSeg: true,
      outliers: true,
      startDate: null,
      endDate: null,
      provider: null,
    })
  })
  filters?: any;

  public barColor: any = {
    primary: '#a085bc',
    secondary: '#cbafd5'
  };

  public plotterColor: any = {
    primary: '#756287',
    secondary: '#cbafd5',
    shadow: '#eee6f1'
  };

  get moduleIsReady() {
    return true;
  }

  public get avbData() {
    return this.chartData && this.chartData.avb;
  }

  public get fetchingChartData() {
    return this.$store.getters.fetchingChartData;
  }
}
</script>
