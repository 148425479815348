import Auth from '@/packages/Auth';
import { Middleware, MiddlewareNext } from '@/router/middlewares/types';
import { RouteLocationNormalized } from 'vue-router';

export const GuestMiddleware: Middleware = {
  handle(to: RouteLocationNormalized, from: RouteLocationNormalized, next: MiddlewareNext, data?: any) {
    if (Auth.isAuthenticated()) {
      return next({
        name: 'dashboard',
      });
    }
  },
};
