import * as MT from '@/modules/members/store/mutationTypes';
import { IMemberState } from '@/modules/members/store/state';
import { MutationTree } from 'vuex';
import Member from '@/models/Member';

/*--------------------------------------------------/
 * Fund Mutations
 *--------------------------------------------------*/
export const MemberMutations: IMemberMutations = {
  [MT.SET_MEMBERS](state: IMemberState, members: Member[]) {
    state.members = members;
  },
  [MT.SET_MEMBER_HISTORY](state: IMemberState, data: any[]) {
    state.memberHistory = data;
  },
  [MT.SET_OUTLIER_ANALYSIS](state: IMemberState, data: any[]) {
    state.outlierData = data;
  },
  [MT.SET_CONTRIBUTION_HISTORY](state: IMemberState, data: any[]) {
    state.contributionHistory = data;
  },
  [MT.SET_MEMBER_ANALYSIS](state: IMemberState, data: any[]) {
    state.memberAnalysis = data;
  },
  [MT.SET_FETCHING_MEMBERS](state: IMemberState, value: boolean) {
    state.fetchingMembers = value;
  },
  [MT.SET_MEMBER_FILTER_RANGES](state: IMemberState, value: boolean) {
    state.memberFilterRanges = value;
  },
  [MT.SET_OUTLIER_REASON_ANALYSIS](state: IMemberState, data: any[]) {
    state.outlierReasonAnalysis = data;
  },
  [MT.SET_SAMPLE_MEMBER_ANALYSIS](state: IMemberState, data: any[]) {
    state.sampleMemberAnalysis = data;
  },
};

/*--------------------------------------------------/
 * Fund Mutations Tree Interface
 *--------------------------------------------------*/
export interface IMemberMutations extends MutationTree<IMemberState> {}
