<template>
  <div data-testid="ReportsModule">
    <router-view v-if="moduleIsReady" />
    <div v-else>Loading Reports...</div>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';

export default class ReportsModule extends Vue {
  created() {
    this.bootstrap();
  }

  get moduleIsReady() {
    return true;
  }

  bootstrap() {
    // this.$store.dispatch(FETCH_FUNDS);
  }
}
</script>
