import PriceTrackModule from './PriceTrackModule.vue';
import PriceTrackList from './pages/PriceTrackList.vue';
import PriceTrackAdd from './pages/PriceTrackAdd.vue';
import PriceTrackReport from './pages/PriceTrackReport.vue';

export const PriceTrackRoutes = [
  {
    path: '/price-track/list',
    component: PriceTrackModule,
    children: [
      {
        path: '',
        name: 'dashboard.price-track.list',
        component: PriceTrackList,
      },
    ],
  },
  {
    path: '/price-track/add',
    component: PriceTrackModule,
    children: [
      {
        path: '',
        name: 'dashboard.price-track.add',
        component: PriceTrackAdd,
      },
    ],
  },
  {
    path: '/price-track/reports',
    component: PriceTrackModule,
    children: [
      {
        path: '',
        name: 'dashboard.price-track.reports',
        component: PriceTrackReport,
      },
    ],
  },
];
