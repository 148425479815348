import * as MT from '@/modules/b2c-panel/store/mutationTypes';
import { IB2CState } from '@/modules/b2c-panel/store/state';
import { MutationTree } from 'vuex';
import ProviderB2C from '@/models/ProviderB2C';
import UserB2C from '@/models/UserB2C';
import ImportB2C from '@/models/ImportB2C';

/*--------------------------------------------------/
 * ProviderB2C Mutations
 *--------------------------------------------------*/
export const B2CMutations: IB2CMutations = {
  [MT.SET_PROVIDERS](state: IB2CState, providersB2C: ProviderB2C[]) {
    state.providersB2C = providersB2C;
  },
  [MT.SET_PROVIDER](state: IB2CState, currentProvider: ProviderB2C) {
    state.currentProvider = currentProvider;
  },
  [MT.UPDATING_PROVIDER](state: IB2CState, updatingProvider: boolean) {
    state.updatingProvider = updatingProvider;
  },
  [MT.SET_USERS](state: IB2CState, usersB2C: UserB2C[]) {
    state.usersB2C = usersB2C;
  },
  [MT.SET_USER](state: IB2CState, currentB2CUser: UserB2C) {
    state.currentB2CUser = currentB2CUser;
  },
  [MT.UPDATING_USER](state: IB2CState, updatingUser: boolean) {
    state.updatingUser = updatingUser;
  },
  [MT.ADDING_SCORE](state: IB2CState, addingScore: boolean) {
    state.addingScore = addingScore;
  },
  [MT.SET_IMPORTS](state: IB2CState, importsB2C: ImportB2C[]) {
    state.importsB2C = importsB2C;
  },
  [MT.GETTING_B2C_CONTRIBUTIONS](state: IB2CState, gettingContributionsData: boolean) {
    state.gettingContributionsData = gettingContributionsData;
  },
  [MT.EXPORT_USERS](state: IB2CState, exportingUsers: boolean) {
    state.exportingUsers = exportingUsers;
  },

};

/*--------------------------------------------------/
 * ProviderB2C Mutations Tree Interface
 *--------------------------------------------------*/
export interface IB2CMutations extends MutationTree<IB2CState> {}
