export const FETCH_PROVIDERS = '/b2c/providers';
export const FETCH_PROVIDER = (id: String) =>  `/b2c/provider/${id}`;
export const EDIT_PROVIDER = '/b2c/provider/edit';
export const FETCH_USERS = '/b2c/users';
export const FETCH_USER = (id: String) =>  `/b2c/user/${id}`;
export const EDIT_USER = '/b2c/user/edit';
export const ADD_SCORE_BULK = '/b2c/user/add-score-bulk';
export const FETCH_IMPORTS = '/b2c/imports';
export const EXPORT_B2C_CONTRIBUTIONS = (id: number) => `/b2c/contributions/export/${id}`;
export const EXPORT_USERS = '/b2c/users/export';
