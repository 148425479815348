export const FETCH_REPORTS = '/reports';
export const EXPORT_REPORT = (providerId: any) => `/pdf/generate/${providerId}`;
export const EXPORT_PRICE_TRACK_REPORT = (priceTrackId: number) => `/price-tracks/pdf/generate/${priceTrackId}`;
export const GET_PDF_URL = (id: any) => `/pdf/url/${id}`;
export const GET_PA_PDF_URL = (providerId: any) => `/performance-attribution/pdf/url/${providerId}`;
export const GET_EXPORT_JOB = (id: any) => `/public/export-jobs/${id}`;
//export const FETCH_REPORT_DATA = '/pdf/reports/data';
export const FETCH_OUTLIER_ANALYSIS = (id: number) => `/analysis/members/outliers/${id}`;
export const FETCH_PERFORMANCE_ATTRIBUTION = (id: number) => `/public/analysis/performance-attribution/${id}`;
export const UPDATE_SEGMENT_LABEL_DATA = '/analysis/members/segmentation';
//export const FETCH_USER = (id: number) => /users/${id};
export const ADD_PA_FREE_TEXT = (id: number) => `/public/analysis/performance-attribution/${id}`;
export const RUN_PA_SEPARATELY = (id: number) => `/performance-attribution/trigger/${id}`;
export const FETCH_SAMPLE_REPORT = '/pdf/sample-report';
