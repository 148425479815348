<template>
  <v-toolbar class="dashboard-toolbar" height="100" color="transparent" flat>
    <div class="w-100 h-100 d-flex align-center justify-space-between overflow-y-hidden">
      <div class="content-title d-flex align-center">
        <v-btn class="ml-2 hamburger rounded-circle" @click="toggleDrawer" icon="fa fa-bars" color="#717073"> </v-btn>
        <v-toolbar-title>{{ currentHeader.title }}</v-toolbar-title>
        <v-tooltip location="end" content-class="tooltip-text" class="content-title-tooltip">
          <template v-slot:activator="{ props }">
            <v-icon color="rgba(140, 140, 140, 0.68)" small v-bind="props" class="d-block mt-1 ml-2"
              >fa fa-info-circle</v-icon
            >
          </template>
          <span>{{ currentHeader.tooltip }}</span>
        </v-tooltip>
      </div>
      <transition name="roller">
        <v-img src="@/assets/AgeWage-Icon.svg" class="logo-icon" v-if="!navDrawerIsVisible"></v-img>
      </transition>
      <div class="d-flex align-center jusity-space-between">
        <v-btn
          size="small"
          data-testid="UploadDataBtn"
          color="success"
          variant="flat"
          class="upload-data-btn ml-2 mr-4 d-none d-md-block"
          v-if="showUploadButton"
          @click="toggleDialog"
        >
          <v-icon left small class="mr-2">fa fa-plus</v-icon>
          <span>Upload</span>
        </v-btn>

        <v-btn
          size="small"
          color="success"
          variant="flat"
          class="upload-data-btn ml-2 mr-4 d-none d-md-block"
          v-if="showUploadButton"
          @click="toggleProviderDialog"
        >
          <span>Manage Master List</span>
        </v-btn>

        <v-btn
          v-if="showUploadButton"
          class="elevation-4 mr-3 pa-3"
          :ripple="false"
          color="primary"
          data-testid="ProfileBtn"
          variant="text"
          to="/users"
        >
          Manage Users<v-icon small class="px-2 ml-2">fas fa-user-alt</v-icon>
        </v-btn>

        <v-menu location="bottom">
          <template v-slot:activator="{ props }">
            <v-btn
              class="profile-rectangle elevation-6 mr-3"
              :ripple="false"
              data-testid="ProfileBtn"
              variant="text"
              v-bind="props"
            >
              <v-icon small>fas fa-user-alt</v-icon>
            </v-btn>
          </template>
          <v-list dense :min-width="200">
            <v-list-item>
              <span>{{ user.name }}</span>
            </v-list-item>
            <v-divider />
            <v-list-item data-testid="changePassBtn" @click="showChangePasswordDialog = true">
              <v-list-item-title>Change Password</v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item data-testid="LogoutBtn" @click="logout">
              <v-list-item-title>Log out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-dialog v-model="showChangePasswordDialog" width="600px">
        <v-card style="padding: 10px">
          <v-card-title class="text-h5">Please enter the passwords</v-card-title>
          <v-divider />
          <br />

          <v-card-text class="change-pass-form pb-6">
            <v-form v-model="validForm">
              <v-text-field
                v-model="oldPass"
                label="Old Password"
                variant="filled"
                :type="show1 ? 'text' : 'password'"
                :append-inner-icon="show1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                @click:append-inner="show1 = !show1"
              ></v-text-field>

              <v-text-field
                v-model="newPass"
                label="New Password"
                variant="filled"
                :type="show2 ? 'text' : 'password'"
                :rules="[rules.required, rules.min, rules.passRequireMents]"
                :append-inner-icon="show2 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                @click:append-inner="show2 = !show2"
              ></v-text-field>

              <v-text-field
                v-model="newPassCheck"
                label="Confirm New Password"
                variant="filled"
                :type="show3 ? 'text' : 'password'"
                :append-inner-icon="show3 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                :rules="[rules.required, rules.passMatch(newPass, newPassCheck)]"
                @click:append-inner="show3 = !show3"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-progress-circular v-if="changingPassword" :size="40" color="purple" :width="4" indeterminate />
            <v-btn v-else class="mx-auto" color="blue-darken-1" variant="text" @click="changePassword"
              >Change Password</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showProviderDialog" max-width="80vw">
        <v-card class="pa-4">
          <v-card-title class="d-flex align-center justify-space-between">
            <div>
              <div>AgeWage Master List</div>
            </div>
            <div>
              <v-btn @click="addProviderModal = true" size="small" variant="outlined" color="primary" class="mr-6">
                <v-icon class="mr-2" size="small" left>fas fa-plus</v-icon>
                <span>Add Provider</span>
              </v-btn>
              <v-btn small density="comfortable" variant="flat" icon @click="showProviderDialog = false">
                <v-icon small color="##0000008a">fa fa-times</v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <AppTable
              :table-data="providerTableData"
              v-on:removeItemClick="(e) => removeProvider(e)"
              :disablePagination="true"
            />
          </v-card-text>
          <div class="text-center">
            <v-pagination
              v-if="totalPage > 1"
              v-model="currPage"
              :length="totalPage"
              active-color="primary"
              prev-icon="fa fa-caret-left"
              next-icon="fa fa-caret-right"
              @next="currPage + 1"
              density="comfortable"
              @previous="currPage - 1"
              :total-visible="7"
            ></v-pagination>
          </div>
          <v-card-actions>
            <v-btn color="grey-darken-1" variant="text" @click="showProviderDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addProviderModal" persistent max-width="30vw">
        <v-card>
          <v-card-title class="d-flex align-center justify-space-between">
            <span class="text-h5">
              {{ 'Add Provider' }}
            </span>
          </v-card-title>
          <v-card-text>
            <div>
              <v-label>Select a Provider</v-label>
              <v-autocomplete
                data-testid="Upload-Provider-List"
                v-model="selectedProvider"
                :loading="fetchingProviders"
                :items="filteredProviders"
                item-title="name"
                item-value="id"
                class="mx-0 mt-2"
                type="text"
                hide-no-data
                hide-details
                label="Provider"
                variant="filled"
              />
              <v-btn
                block
                type="submit"
                :color="!selectedProvider || addingProvider ? 'disabled' : 'success'"
                class="mt-5"
                :loading="addingProvider"
                :disabled="!selectedProvider || addingProvider"
                @click="addProvider"
              >
                Add
              </v-btn>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="grey-darken-1" variant="text" @click="addProviderModal = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar.model" :timeout="snackbar.timeout" :color="snackbar.success ? 'green' : 'red'" top>
        {{ snackbar.text }}
      </v-snackbar>
    </div>
    <AppLoader v-if="fetchingProviders || removingProvider" />
  </v-toolbar>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import User from '@/models/User';
import AppTable from '@/components/AppTable.vue';
import Provider from '@/models/Provider';
import { Table, TableColumn, TableRow, TableColumnType, DataTableHeader } from '@/types';
import { TOGGLE_DATA_UPLOAD_DIALOG, TOGGLE_NAV_DRAWER, CHANGE_PASSWORD } from '@/store/actionTypes';
import { FETCH_PROVIDER_BY_USERID, FETCH_PROVIDER_MASTER_LIST } from '@/modules/providers/store/actionTypes';
import { LOG_USER_OUT } from '@/modules/auth/store/actionTypes';
import { mount } from '@vue/test-utils';
import { HTTPServiceClient } from '@/packages/http/service';
import AppLoader from '@/components/AppLoder.vue';
import { any } from 'bluebird';
import { remove } from 'lodash';

var SPECIAL_CHAR_REGEX = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
var UPPER_CASE_CHAR_REGEX = /[A-Z]/;
var LOWER_CASE_CHAR_REGEX = /[a-z]/;
var NUMBER_CHAR_REGEX = /[0-9]/;

@Options({
  components: {
    AppTable,
    AppLoader,
  },
})
export default class DashboardToolbar extends Vue {
  @Prop({
    required: true,
  })
  public user!: User;

  public oldPass: string = '';
  public newPass: string = '';
  public newPassCheck: string = '';
  public showChangePasswordDialog: boolean = false;
  public show1: boolean = false;
  public show2: boolean = false;
  public show3: boolean = false;
  public validForm: boolean = false;
  public changingPassword: boolean = false;
  public defaultSortKey = { key: 'id' };
  public snackbar: any = {
    model: false,
    text: '',
    success: false,
    timeout: 2000,
  };
  public rules: any = {
    required: (value: any) => !!value || 'Required.',
    min: (v: any) => v.length >= 8 || 'Min 8 characters',
    passRequireMents: (v: any) => {
      if (!UPPER_CASE_CHAR_REGEX.test(v)) {
        return 'Your password needs to have atleast one upper case character.';
      }
      if (!LOWER_CASE_CHAR_REGEX.test(v)) {
        return 'Your password needs to have atleast one lower case character.';
      }
      if (!NUMBER_CHAR_REGEX.test(v)) {
        return 'Your password needs to have atleast one number.';
      }
      if (!SPECIAL_CHAR_REGEX.test(v)) {
        return 'Your password needs to have atleast one special character.';
      }
      return true;
    },
    passMatch: (v: any, v2: any) => (v === v2 ? true : 'Passwords entered do not match'),
  };

  public providerSearch = '';

  public selectedProvider: number | null = null;

  public selectedUserId = 0;
  public addProviderModal = false;
  public addingProvider = false;
  public removingProvider = false;
  public currPage = 1;

  @Prop({
    required: false,
    default: false,
  })
  public showUploadButton!: boolean;

  public showProviderDialog: boolean = false;

  public logout() {
    this.$store.dispatch(LOG_USER_OUT);
    this.$router.push('/');
  }

  public get isDialogVisible() {
    return this.$store.getters.isDataUploadDialogVisible;
  }

  public get currentHeader() {
    return this.$store.getters.navHeader;
  }

  public get navDrawerIsVisible() {
    return this.$store.getters.navDrawerIsVisible;
  }

  public toggleDialog() {
    this.$store.dispatch(TOGGLE_DATA_UPLOAD_DIALOG, true);
  }

  public toggleDrawer() {
    this.$store.dispatch(TOGGLE_NAV_DRAWER);
  }

  public async addProvider() {
    this.addingProvider = true;
    await HTTPServiceClient.post('/masterProviders/update-master-list', {
      providerId: this.selectedProvider,
      userId: this.user.id,
      type: 'add',
    });
    this.clearProviderFields();
    await this.$store.dispatch(FETCH_PROVIDER_MASTER_LIST, this.user.id);
  }

  public async removeProvider(id: number) {
    this.removingProvider = true;
    await HTTPServiceClient.post('/masterProviders/update-master-list', {
      providerId: id,
      userId: this.user.id,
      type: 'remove',
    });
    this.clearProviderFields();
    await this.$store.dispatch(FETCH_PROVIDER_MASTER_LIST, this.user.id);
  }

  public clearProviderFields() {
    this.addProviderModal = false;
    this.addingProvider = false;
    this.removingProvider = false;
    this.selectedProvider = null;
  }

  public async changePassword() {
    if (!this.validForm) {
      return;
    }
    const resp = await this.$store.dispatch(CHANGE_PASSWORD, { oldPass: this.oldPass, newPass: this.newPass });
    if (!resp.user) {
      this.snackbar.model = true;
      this.snackbar.text = 'Password change failed. Please check your older password.';
      this.snackbar.timeout = 7000;
    } else {
      this.snackbar.model = true;
      this.snackbar.success = true;
      this.snackbar.text = 'Password changed successfully.';
      this.snackbar.timeout = 7000;
    }
    this.showChangePasswordDialog = false;
    this.newPass = '';
    this.oldPass = '';
    this.newPassCheck = '';
  }

  public async toggleProviderDialog() {
    this.showProviderDialog = true;
    await this.$store.dispatch(FETCH_PROVIDER_BY_USERID, this.user.id);
    await this.$store.dispatch(FETCH_PROVIDER_MASTER_LIST, this.user.id);
  }

  public get providers() {
    const providers = this.$store.getters.masterProviders;
    if (providers) {
      let pageEnd = this.currPage * 15;
      const PageStart = pageEnd - 15;
      if (pageEnd > providers) {
        pageEnd = providers;
      }
      return providers.slice(PageStart, pageEnd);
    }
    return providers;
  }

  public get totalPage() {
    const providerList = this.$store.getters.masterProviders;
    if (providerList && providerList.length) {
      return Math.ceil(providerList.length / 15);
    }
    return 1;
  }

  public get filteredProviders() {
    const providerList = this.$store.getters.providers;
    const masterProviders = this.$store.getters.masterProviders;
    const data = providerList
      ? providerList.filter((it: any) => {
          const result = masterProviders && masterProviders.find((p: any) => p.providerId === it.id);
          return !result;
        })
      : null;
    return data;
  }

  public get fetchingProviders() {
    return this.$store.getters.fetchingMasterProviders;
  }

  public get providerTableData(): Table {
    const rows = this.providers ? this.providers : [];
    const columns: DataTableHeader[] = [
      {
        value: 'providerId',
        title: 'Provider ID',
        type: TableColumnType.NUMBER,
        align: 'center',
      },
      {
        value: 'provider_name',
        title: 'Provider Name',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'email',
        title: 'Provider E-Mail',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'remove',
        title: 'Remove Provider',
        type: TableColumnType.ACTIONS,
        align: 'center',
      },
    ];
    return {
      columns,
      rows,
    };
  }
}
</script>

<style lang="scss">
.content-title .hamburger .v-icon {
  height: 24px;
  font-size: 24px !important;
  width: 24px;
}

.change-pass-form {
  .v-field__input,
  .v-field-label {
    font-size: 16px;
  }
}

.dashboard-toolbar.v-toolbar {
  .v-toolbar-title__placeholder {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .logo-icon {
    max-width: 40px;
    max-height: 40px;
    width: 100%;
    height: auto;

    &.roller-enter {
      transform: translateY(150px);
    }

    &.roller-enter-to {
      transform: translateY(0px);
    }

    &.roller-enter-active,
    &.roller-leave-active {
      transition: all 0.5s ease-in-out;
    }

    &.roller-leave-to {
      transform: translateY(-100px);
    }
  }

  .profile-rectangle {
    position: relative;
    border-radius: 10px !important;
    background-color: #ffffff;
    padding: 0px !important;
    min-width: 40px !important;
    min-height: 40px !important;

    &:visited {
      background-color: #ffffff;
    }
  }
}
</style>
