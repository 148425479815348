import { BenchmarkActions } from '@/modules/benchmarks/store/actions';
import { BenchmarkGetters } from '@/modules/benchmarks/store/getters';
import { BenchmarkMutations } from '@/modules/benchmarks/store/mutations';
import { BenchmarkState, IBenchmarkState } from '@/modules/benchmarks/store/state';
import { IRootState } from '@/store/state';
import { Module } from 'vuex';

export const BenchmarkStore: IBenchmarkStore = {
  state: BenchmarkState,
  actions: BenchmarkActions,
  getters: BenchmarkGetters,
  mutations: BenchmarkMutations
};

/**
 * --------------------------------------------------
 *	Benchmark Store Interface
 * --------------------------------------------------
 **/
export interface IBenchmarkStore extends Module<IBenchmarkState, IRootState> {}
