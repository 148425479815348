<template>
  <div data-testid="edit-b2c-user-page" class="page edit-b2c-user-page">
    <v-container fluid>
      <v-layout class="mt-4 pa-0">
        <div class="w-100">
          <v-tabs
            v-if="pensionStatus && Object.keys(pensionStatus).length > 0"
            v-model="tabs"
            color="primary"
            center-active
            centered
            grow
            bg-color="#fff"
            show-arrows
            class="mt-5 mb-12"
          >
            <v-tab v-for="(card, id) in pensionStatus" :key="id" @click="changeCurrentProvider(id)">
              <v-tooltip location="bottom">
                <template v-slot:activator="{ props }">
                  <pre v-bind="props">{{
                    `${card.name.length > 30 ? `${card.name.substring(0, 30)}..` : card.name}${
                      card.deletedByUser ? '(deleted)' : ''
                    }`
                  }}</pre>
                </template>
                <span>{{ `${card.name}${card.providerName ? `(${card.providerName})` : ''}` }}</span>
              </v-tooltip>
            </v-tab>
          </v-tabs>
          <v-window v-model="tabs">
            <v-window-item v-for="(card, id) in pensionStatus" :key="id">
              <v-card v-if="currentB2CUser.id !== ''">
                <v-form @submit.prevent="editUser" class="py-5">
                  <v-card-title class="d-flex align-center justify-space-between my-4">
                    <span class="text-h5"> Edit User </span>
                  </v-card-title>
                  <v-card-text v-if="pensionStatus">
                    <div v-show="true">
                      <div>
                        <v-label>User Email</v-label>
                        <v-text-field
                          hide-details
                          :persistent-hint="false"
                          placeholder="Email"
                          v-model="currentB2CUser.email"
                          :disabled="true"
                          class="mb-5"
                        />
                      </div>

                      <div>
                        <v-btn @click="showingDownloadModal = true"> View Documents </v-btn>
                      </div>
                    </div>
                    <v-container grid-list-md>
                      <v-layout data-testid="Upload-Container" v-if="!dataUploaded" wrap>
                        <v-row>
                          <v-col :cols="12" class="text-center mt-2">
                            <AppUploader
                              data-testid="FileUpload"
                              @app-uploader="handleFile"
                              :disabled="updatingUser"
                              fileType="*"
                            />
                          </v-col>
                        </v-row>
                      </v-layout>
                      <v-layout v-if="dataUploaded" wrap>
                        <v-row>
                          <v-col>
                            <v-alert v-model="dataUploaded" closable type="success" class="mb-4" prominent
                              >Provider updated successfully.</v-alert
                            >
                          </v-col>
                        </v-row>
                      </v-layout>
                      <v-layout v-if="dataUploadError" wrap>
                        <v-row>
                          <v-col>
                            <v-alert
                              data-testid="FileUploadError"
                              v-model="dataUploadError"
                              closable
                              type="error"
                              class="mb-4"
                              prominent
                              >{{ validationError }}</v-alert
                            >
                          </v-col>
                        </v-row>
                      </v-layout>
                    </v-container>

                    <v-col
                      v-for="(card, id) in filterPensionStatus(pensionStatus)"
                      v-bind:card="card"
                      v-bind:id="id"
                      v-bind:key="id"
                    >
                      <v-row dense>
                        <v-col cols="12">
                          <v-card :elevation="6">
                            <v-row>
                              <v-col cols="12">
                                <v-img
                                  :src="
                                    card.img
                                      ? card.img
                                      : 'https://storage.googleapis.com/download/storage/v1/b/aw-onboard-test.appspot.com/o/images%2Fdefault-pension.jpg?generation=1594894259066708&alt=media'
                                  "
                                  max-height="100px"
                                  min-height="100px"
                                  max-width="100%"
                                />
                              </v-col>
                            </v-row>
                            <v-card-title style="display: grid"> </v-card-title>
                            <v-card-text style="font-size: 30px; color: black">
                              <div>
                                <v-label class="text-left">Pension ID</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :persistent-hint="false"
                                  :value="currentProviderUUIDForUser"
                                  :readonly="true"
                                  class="mb-5"
                                />
                              </div>
                              <div>
                                <v-label>Pension Type</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :persistent-hint="false"
                                  :value="card.type === 'pen' ? 'Pension Provider' : 'Employer Details'"
                                  :disabled="true"
                                  class="mb-5"
                                />
                              </div>

                              <div>
                                <v-label>Name</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :persistent-hint="false"
                                  :value="card.name"
                                  class="mb-5"
                                />
                              </div>

                              <div v-if="card.type === 'pen'">
                                <v-label>Provider pays into pension?</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :value="card.employerPaysIntoThisPension ? 'Yes' : 'No'"
                                  :persistent-hint="false"
                                  :disabled="true"
                                  class="mb-5"
                                />
                              </div>

                              <div v-else>
                                <v-label>Provider</v-label>
                                <v-autocomplete
                                  hide-details
                                  :items="
                                    providers.map((p) => {
                                      return { title: p.name, value: p.id };
                                    })
                                  "
                                  :persistent-hint="false"
                                  v-model="card.providerId"
                                  @change="providerChange(card.providerId)"
                                  class="mb-5"
                                  variant="underlined"
                                />
                                <v-label>Provider Policy ID</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :persistent-hint="false"
                                  v-model="card.providerPolicyID"
                                  class="mb-5"
                                />
                                <v-label>Start Year</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :value="card.startYear"
                                  :persistent-hint="false"
                                  :disabled="true"
                                  class="mb-5"
                                />
                                <v-label>End Year</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :value="card.endYear"
                                  :persistent-hint="false"
                                  :disabled="true"
                                  class="mb-5"
                                />
                                <v-label>Still Works Here?</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :value="card.stillWorksHere ? 'Yes' : 'No'"
                                  :persistent-hint="false"
                                  :disabled="true"
                                  class="mb-5"
                                />
                              </div>
                              <div>
                                <v-label>Policy Number</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :persistent-hint="false"
                                  v-model="card.pensionPolicy"
                                  :disabled="true"
                                  type="text"
                                  class="mb-5"
                                />
                              </div>
                              <div>
                                <v-label>Agewage Score</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :persistent-hint="false"
                                  v-model="card.score"
                                  type="number"
                                  class="mb-5"
                                />
                              </div>

                              <div>
                                <v-label>Return %</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :persistent-hint="false"
                                  v-model="card.return"
                                  type="number"
                                  class="mb-5"
                                />
                              </div>
                              <div>
                                <v-label>Average Return</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :persistent-hint="false"
                                  v-model="card.avgReturn"
                                  type="number"
                                  class="mb-5"
                                />
                              </div>
                              <div>
                                <v-label>Current NAV value</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :persistent-hint="false"
                                  v-model="card.currVal"
                                  type="number"
                                  class="mb-5"
                                />
                              </div>
                              <div>
                                <v-label>Benchmark NAV value</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :persistent-hint="false"
                                  v-model="card.benchmarkNAV"
                                  type="number"
                                  class="mb-5"
                                />
                              </div>
                              <div>
                                <v-label>Total Contribution</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :persistent-hint="false"
                                  v-model="card.totalContribution"
                                  type="number"
                                  class="mb-5"
                                />
                              </div>
                              <div>
                                <v-label>Contribution Period(YYYY-YYYY)</v-label>
                                <v-text-field
                                  hide-details
                                  variant="underlined"
                                  :persistent-hint="false"
                                  v-model="card.contributionPeriod"
                                  class="mb-5"
                                />
                              </div>
                              <div>
                                <v-switch
                                  color="accent"
                                  hide-details
                                  :disabled="dbPension[currentProviderUUIDForUser]"
                                  :label="'DB Pension'"
                                  v-model="dbPension[currentProviderUUIDForUser]"
                                  class="mb-5"
                                />
                              </div>
                            </v-card-text>
                          </v-card>
                          <v-divider dark />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      data-testid="SubmitUploadBtn"
                      :color="updatingUser ? 'disabled' : 'success'"
                      type="submit"
                      variant="flat"
                      :loading="updatingUser"
                      :disabled="updatingUser"
                      >{{ currentB2CUser.id ? 'Edit' : 'Add' }}</v-btn
                    >
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-window-item>
          </v-window>
          <v-alert v-model="alerts.contributionPeriod" closable elevation="2" type="error">
            You've entered the wrong format for the contribution year for one of the pensions!.
          </v-alert>
        </div>
      </v-layout>

      <v-dialog v-model="showingDownloadModal" max-width="600px">
        <v-card style="padding: 20px">
          <v-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="float-left">Name</th>
                  <th class="float-right">Upload Time</th>
                </tr>
              </thead>
              <tbody
                v-if="
                  currentB2CUser &&
                  currentB2CUser.otherData &&
                  currentB2CUser.otherData.documents.filter((eachDoc) => eachDoc.uuid === currentProviderUUIDForUser)
                    .length > 0
                "
              >
                <tr
                  v-for="(item, id) in currentB2CUser.otherData.documents.filter(
                    (eachDoc) => eachDoc.uuid === currentProviderUUIDForUser
                  )"
                  :key="id"
                >
                  <td>
                    <a :href="item.src" target="_blank">{{ item.name }}</a>
                  </td>
                  <td>{{ item.uploadTime }}</td>
                </tr>
              </tbody>
              <tbody v-else class="text-center">
                No documents related to this pension found
              </tbody>
            </template>
          </v-table>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Vue, Watch } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppUploader from '@/components/AppUploader.vue';
import ProviderB2C from '@/models/ProviderB2C';
import { FETCH_USER, EDIT_USER, FETCH_PROVIDERS } from '@/modules/b2c-panel/store/actionTypes';
import { SET_NAV_HEADER } from '@/store/actionTypes';

interface FILE {
  file: File;
  id: string;
}

@Options({
  components: {
    AppUploader,
  },
})
export default class EditUserPage extends Vue {
  public dataUploadError = false;
  public validationError = '';
  public dataUploaded = false;
  public files: FILE[] = [];
  public pensionStatus: any = {};
  public tabs: any = null;
  public currentProviderForUser: any = {};
  public currentProviderUUIDForUser = '';
  public providersMap: any = {};
  public showingDownloadModal: boolean = false;
  public dbPension: any = {};
  public alerts = {
    contributionPeriod: false,
  };

  get providers() {
    this.$store.getters.providersB2C.map((p: ProviderB2C) => {
      this.providersMap[p.id] = p;
    });
    return this.$store.getters.providersB2C;
  }

  created() {
    this.bootstrap();
  }

  public bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Users', tooltip: 'Edit User' });
    this.fetchUser();
    this.fetchProviders();
  }

  @Watch('currentB2CUser')
  public userChanged() {
    this.pensionStatus =
      this.currentB2CUser.otherData && this.currentB2CUser.otherData.pensionStatus
        ? this.currentB2CUser.otherData.pensionStatus
        : {};
    if (Object.keys(this.pensionStatus).length > 0) {
      this.currentProviderForUser = this.pensionStatus[Object.keys(this.pensionStatus)[0]];
      this.currentProviderUUIDForUser = Object.keys(this.pensionStatus)[0];
    }
    Object.keys(this.pensionStatus).forEach((eachPension) => {
      if (
        this.pensionStatus[eachPension].isOutlier &&
        this.pensionStatus[eachPension].outlierReason === 'Defined Benefit Pension'
      ) {
        this.dbPension[eachPension] = true;
      } else {
        this.dbPension[eachPension] = false;
      }
    });
    this.tabs = 0;
  }

  get currentB2CUser() {
    return this.$store.getters.currentB2CUser;
  }

  @Watch('updatingUser')
  public userUpdated() {
    if (this.updatingUser === false) {
      this.$router.push({ name: 'dashboard.b2cpanel.users' });
    }
  }

  get updatingUser() {
    return this.$store.getters.updatingUser;
  }

  public fetchUser(data: any = {}) {
    this.$store.dispatch(FETCH_USER, this.$route.params.id);
  }

  public fetchProviders(data: any = {}) {
    this.$store.dispatch(FETCH_PROVIDERS, data);
  }

  public handleFile(data: any) {
    this.files.push({
      id: this.currentProviderUUIDForUser,
      file: data.file,
    });
  }

  public filterPensionStatus(pensionStatus: any) {
    const list: any = [];
    Object.keys(pensionStatus).map((id: any) => {
      if (this.currentProviderUUIDForUser === id) {
        list.push(pensionStatus[id]);
      }
    });
    return list;
  }

  public checkPensionStatusData() {
    let error = false;
    Object.keys(this.pensionStatus).forEach((id) => {
      const currPension = this.pensionStatus[id];
      //check contribution year format
      if (currPension.contributionPeriod && !currPension.contributionPeriod.match(/^\d{4}-\d{4}$/)) {
        error = true;
        this.alerts.contributionPeriod = true;
      }
    });
    return error;
  }

  public changeCurrentProvider(uuid: string) {
    this.currentProviderForUser = this.pensionStatus[uuid] ? this.pensionStatus[uuid] : {};
    this.currentProviderUUIDForUser = uuid;
  }

  public editUser() {
    this.dataUploadError = false;
    this.validationError = '';
    if (this.checkPensionStatusData()) {
      return;
    }
    if (this.dataUploadError === false) {
      let formData = new FormData();
      this.files.forEach((eachFile) => {
        formData.append('files[]', eachFile.file, `${eachFile.id}:;:;:${eachFile.file.name}`);
      });
      formData.append('pensionStatus', JSON.stringify(this.pensionStatus));
      formData.append('id', this.currentB2CUser.id);
      formData.append('email', this.currentB2CUser.email);
      formData.append('dbPension', JSON.stringify(this.dbPension));
      this.$store.dispatch(EDIT_USER, formData);
    }
  }

  public providerChange(pid: string) {
    this.currentProviderForUser.providerId = pid;
    this.currentProviderForUser.providerName = this.providersMap[pid] ? this.providersMap[pid].name : '';
    this.currentProviderForUser.img = this.providersMap[pid] ? this.providersMap[pid].img : '';
  }
}
</script>
