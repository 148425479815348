<template>
  <div>
    <div class="outlier-wrapper">
      <div class="elevation-1 chartWrapper outlier-card">
        <div class="chart-title d-block text-center">Data Quality</div>
        <base-chart :options="chartOptions" :scroll-options="chartSize" @click="(e) => chartClick(e)" />
      </div>
    </div>
    <v-snackbar v-model="noDataAlert" :timeout="3000" color="primary" location="top">
      <h3>No data available for this range</h3>
      <v-btn color="blue" class="d-flex ml-auto" variant="text" @click="noDataAlert = false"> Close </v-btn>
    </v-snackbar>
    <v-snackbar v-model="pdfTimeoutError" :timeout="3000" color="primary" location="top">
      <h3>Error downloading requested pdf. Please try again.</h3>
      <v-btn color="blue" class="d-flex ml-auto" variant="text" @click="pdfTimeoutError = false"> Close </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="membersDialog"
      @click:outside="membersDialog = false"
      persistent
      max-width="80vw"
      max-height="90%"
    >
      <v-card class="py-4">
        <v-card-title class="d-flex align-center justify-space-between">
          <div>
            <div>Member Analysis for the selected range:</div>
          </div>
          <div>
            <v-btn
              left
              color="primary"
              class="mr-4"
              :disabled="!providerId || isSampleReport"
              :loading="processingCSV"
              @click="exportMemberCSV()"
            >
              <v-icon small class="mr-3">fas fas fa-file-download</v-icon>Download
            </v-btn>

            <v-btn rounded size="small" density="comfortable" variant="flat" icon @click="membersDialog = false">
              <v-icon small>fa fa-times</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <AppTable
            :table-data="membersTableData"
            :disableSort="true"
            :disablePagination="true"
            :default-sort-column="[{ key: 'dataId', order: 'asc' }]"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey-darken-1" variant="text" @click="membersDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AppLoader v-if="fetchingMembersAnalysis" />
  </div>
</template>

<script>
import BaseChart from '@/components/charts/BaseChart.vue';
import Utils from '../../utils/Utils';
import * as AT from '@/modules/members/store/actionTypes';
import { Table, TableColumn, TableRow, TableColumnType } from '@/types';
import AppTable from '@/components/AppTable.vue';
import { GET_EXPORT_JOB_BY_ID } from '@/modules/reports/store/actionTypes';
import { GET_CSV_URL } from '@/modules/members/store/actionTypes';
import AppLoader from '@/components/AppLoder.vue';

export default {
  components: {
    BaseChart,
    AppTable,
    AppLoader,
  },
  props: ['data', 'memberAnalysedPercent', 'outlierPercent', 'providerId', 'isSampleReport'],
  data() {
    return {
      chartData: {},
      chartSize: {
        chartWidth: 70,
        chartHeight: 30,
        unit: 'rem',
      },
      chartOptions: {},
      membersDialog: false,
      fetchingMembersAnalysis: false,
      noDataAlert: false,
      processingCSV: false,
      pagination: {
        page: 1,
        limit: 15,
      },
      pdfTimeoutError: false,
      membersTableData: {},
    };
  },
  mounted() {
    this.setChartData();
    this.setOption();
  },
  methods: {
    setChartData() {
      const totalmembers = this.data.totalMemberCount + this.data.totalOutlier;
      const shortContHistory = this.data.outlierGroups['Short Contribution History'] || 0;
      const outlierCount = this.data.totalOutlier - shortContHistory;
      const shortContHistoryPercent = ((shortContHistory * 100) / totalmembers).toFixed(1);
      this.chartData = {
        data: [
          {
            name: 'Data analysed & Scored',
            value: this.memberAnalysedPercent,
            memberCount: this.data.totalMemberCount,
            label: {
              show: true,
              position: 'outside',
              color: '#00B050',
              formatter: (v) => {
                return ` ${v.value}% ${v.name} \n ${Utils.numberWithCommas(v.data.memberCount)} Members`;
              },
            },
          },
          {
            name: 'Short Contribution History',
            value: shortContHistoryPercent,
            memberCount: shortContHistory,
            label: {
              show: true,
              position: 'outside',
              color: '#AFABAB',
              formatter: (v) => {
                return ` ${v.value}% ${v.name} \n ${Utils.numberWithCommas(v.data.memberCount)} Members`;
              },
            },
          },
          {
            name: 'Outliers',
            value: this.outlierPercent,
            memberCount: outlierCount,
            label: {
              show: true,
              color: '#DC1C13',
              position: 'outside',
              formatter: (v) => {
                return ` ${v.value}% ${v.name} \n ${Utils.numberWithCommas(v.data.memberCount)} Members`;
              },
            },
          },
        ],
      };
    },
    setOption() {
      this.chartOptions = {
        color: ['#00B050', '#AFABAB', '#DC1C13'],
        tooltip: {
          trigger: 'item',
          formatter: (v) => {
            return `${v.value}% ${v.name} <br /> ${v.data.memberCount} Members`;
          },
        },
        legend: {
          orient: 'vertical',
          right: 10,
          top: 40,
        },
        series: [
          {
            type: 'pie',
            radius: '65%',
            center: ['45%', '55%'],
            data: this.chartData.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
    },
    async fetchMembersAnalysis(data) {
      this.fetchingMembersAnalysis = true;
      if (data.outlierReason) {
        const analysis = await this.$store.dispatch(AT.FETCH_OUTLIER_REASON_ANALYSIS, data);
        this.fetchingMembersAnalysis = false;
        return analysis;
      } else if (data.allOutlier) {
        const analysis = await this.$store.dispatch(AT.FETCH_OUTLIERS_ANALYSIS, {
          page: 1,
          limit: 15,
          providerId: data.providers[0],
          excludeReason: 'Short Contribution History',
        });
        this.fetchingMembersAnalysis = false;
        return analysis.data;
      } else {
        let analysis = null;
        if (this.isSampleReport) {
          analysis = await this.$store.dispatch(AT.FETCH_SAMPLE_MEMBERS_ANALYSIS, data);
        } else {
          analysis = await this.$store.dispatch(AT.FETCH_MEMBERS_ANALYSIS, data);
        }
        this.fetchingMembersAnalysis = false;
        return analysis;
      }
    },
    async chartClick(e) {
      if (e.dataIndex !== undefined) {
        this.noDataAlert = false;
        const index = await e.dataIndex;
        let appliedFilters = {};
        if (e.dataIndex == 1) {
          appliedFilters = {
            providers: [this.providerId],
            outlierReason: e.name,
          };
        } else if (e.dataIndex == 2) {
          appliedFilters = {
            providers: [this.providerId],
            allOutlier: true,
          };
        } else {
          appliedFilters = {
            providers: [this.providerId],
            memberAnalysed: true,
          };
        }
        this.currentFilter = {
          ...this.pagination,
          ...appliedFilters,
        };
        const analysis = await this.fetchMembersAnalysis(this.currentFilter);
        this.setMemberTableData(analysis);
        analysis.length ? (this.membersDialog = true) : (this.noDataAlert = true);
      }
    },
    setMemberTableData(memberAnalysis) {
      let rows = memberAnalysis || [];
      let columns = [];
      if (this.currentFilter && this.currentFilter.memberAnalysed) {
        rows = rows.map((it) => {
          return {
            dataId: it.dataId,
            firstContributionDate: it.firstContributionDate,
            // fundName: it.fund.name,
            score: it.scores.score || it.scores.score === 0 ? parseInt(it.scores.score) : '-',
            totalContributionAmount: it.totalContributionAmount,
            navAmount: it.analysis.navAmount,
            navDate: it.analysis.navDate,
            irr: it.analysis.irr,
            benchmarkIrr: it.analysis.benchmarkIrr,
            benchmarkNavAmount: it.analysis.benchmarkNavAmount,
          };
        });
        columns = [
          {
            value: 'dataId',
            title: 'Saver ID',
            type: TableColumnType.NUMBER,
            align: 'center',
          },
          {
            value: 'firstContributionDate',
            title: 'First Contribution Date',
            type: TableColumnType.DATE,
            formatter: (v) => Utils.formatDate(new Date(v), 'MMM dd, yyyy'),
            align: 'center',
          },
          {
            value: 'navDate',
            title: 'NAV Date',
            type: TableColumnType.DATE,
            formatter: (v) => Utils.formatDate(new Date(v), 'MMM dd, yyyy'),
            align: 'center',
          },
          {
            value: 'score',
            title: 'AW Score',
            type: TableColumnType.NUMBER,
            align: 'center',
          },
          {
            value: 'totalContributionAmount',
            title: 'Total Contribution Amount',
            type: TableColumnType.NUMBER,
            formatter: (n) => Utils.formatNumber(n, '$0.00a'),
            align: 'center',
          },
          {
            value: 'navAmount',
            title: 'NAV Amount',
            type: TableColumnType.NUMBER,
            formatter: (n) => Utils.formatNumber(n, '$0.00a'),
            align: 'center',
          },
          {
            value: 'benchmarkNavAmount',
            title: 'Benchmark Nav Amount',
            type: TableColumnType.NUMBER,
            formatter: (n) => Utils.formatNumber(n, '$0.00a'),
            align: 'center',
          },
          {
            value: 'irr',
            title: 'IRR (%)',
            type: TableColumnType.NUMBER,
            formatter: (v) => (v * 100).toFixed(2),
            align: 'center',
          },
          {
            value: 'benchmarkIrr',
            title: 'Benchmark IRR (%)',
            type: TableColumnType.NUMBER,
            formatter: (v) => (v * 100).toFixed(2),
            align: 'center',
          },
        ];
      } else {
        columns = [
          {
            value: 'dataId',
            title: 'Saver ID',
            type: TableColumnType.STRING,
            align: 'center',
          },
          {
            value: 'contributionAmount',
            title: 'Total Contribution Amount',
            type: TableColumnType.NUMBER,
            formatter: (n) => Utils.formatNumber(n, '$0.00a'),
            align: 'center',
          },
          {
            value: 'navAmount',
            title: 'NAV Amount',
            type: TableColumnType.NUMBER,
            formatter: (n) => Utils.formatNumber(n, '$0.00a'),
            align: 'center',
          },
          {
            value: 'navDate',
            title: 'NAV Date',
            type: TableColumnType.DATE,
            formatter: (v) => Utils.formatDate(new Date(v), 'MMM dd, yyyy'),
            align: 'center',
          },
          {
            value: 'irr',
            title: 'IRR (%)',
            type: TableColumnType.NUMBER,
            align: 'center',
          },
          {
            value: 'benchmarkIrr',
            title: 'Benchmark IRR (%)',
            type: TableColumnType.NUMBER,
            align: 'center',
          },
          {
            value: 'reason',
            title: 'Error',
            type: TableColumnType.STRING,
            align: 'center',
          },
        ];
      }
      this.membersTableData = {
        columns,
        rows,
      };
    },
    async exportMemberCSV() {
      this.processingCSV = true;

      if (this.currentFilter.memberAnalysed) {
        let job = { id: -1 };
        this.processingCSV = true;
        job = await this.$store.dispatch(AT.EXPORT_MEMBERS_ANALYSIS, {
          page: 1,
          limit: -1,
          providers: this.currentFilter.providers,
        });

        this.getCSVURL(job);
      } else {
        const data = {
          page: 1,
          limit: -1,
          providers: this.currentFilter.providers,
        };
        if (this.currentFilter.outlierReason) {
          data.outlierReason = this.currentFilter.outlierReason;
        } else {
          data.excludeReason = 'Short Contribution History';
        }
        const job = await this.$store.dispatch(AT.EXPORT_OUTLIER_REASON_ANALYSIS, data);
        this.getCSVURL(job);
      }
    },
    async getCSVURL(job) {
      let pdfFetchInterval = setInterval(async () => {
        let response = await this.$store.dispatch(GET_EXPORT_JOB_BY_ID, job.id);
        if (response[0].status === 'completed') {
          const url = await this.$store.dispatch(GET_CSV_URL, job.id);
          await window.open(url);
          this.processingCSV = false;
          clearInterval(pdfFetchInterval);
        }
        return response;
      }, Utils.pdfInterval);
      setTimeout(() => {
        clearInterval(pdfFetchInterval);
        if (this.processingCSV) {
          this.pdfTimeoutError = true;
        }
        this.processingCSV = false;
      }, Utils.pdfTimeout);
    },
  },
};
</script>
<style lang="scss">
.outlier-wrapper {
  padding: 1rem 0rem !important;
  .outlier-card {
    padding-bottom: 3rem !important;
  }
}
</style>
