import ReportsModule from './ReportsModule.vue';
import ReportGenPage from './pages/ReportGenPage.vue';

export const BenchmarkingRoutes = [
  {
    path: '/benchmarking',
    component: ReportsModule,
    children: [
      {
        path: '/benchmarking/reports',
        name: 'benchmarking.reports.gen',
        // component: ListReportsPage
        component: ReportGenPage
      }
    ]
  }
];
