import ProviderB2C from '@/models/ProviderB2C';
import UserB2C from '@/models/UserB2C';
import ImportB2C from '@/models/ImportB2C';
/**
 * --------------------------------------------------
 *	B2CProvider State
 * --------------------------------------------------
 **/

export const B2CState: IB2CState = {
  providersB2C: [],
  currentProvider: {
    id: '',
    name: ''
  },
  updatingProvider: false,
  currentB2CUser: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    otherData: {

    }
  },
  usersB2C: [],
  fetchingUsers: false,
  exportingUsers: false,
  updatingUser: false,
  addingScore: false,
  importsB2C: [],
  gettingContributionsData: false
};

export interface IB2CState {
  providersB2C: ProviderB2C[];
  currentProvider: ProviderB2C;
  updatingProvider: boolean;
  currentB2CUser: UserB2C;
  usersB2C: UserB2C[];
  fetchingUsers: Boolean;
  exportingUsers: Boolean;
  updatingUser: Boolean;
  addingScore: Boolean;
  importsB2C: ImportB2C[];
  gettingContributionsData: boolean;
}
