import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "elevation-10 chartWrapper" }
const _hoisted_2 = { class: "chart-title text-center pb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseChart = _resolveComponent("BaseChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString($props.title), 1),
    _createVNode(_component_BaseChart, {
      options: $props.chartData,
      scrollOptions: $data.scrollOptions
    }, null, 8, ["options", "scrollOptions"])
  ]))
}