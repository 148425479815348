import * as MT from '@/modules/users/store/mutationTypes';
import { IUserState } from '@/modules/users/store/state';
import { MutationTree } from 'vuex';
import User from '@/models/User';

/*--------------------------------------------------/
 * User Mutations
 *--------------------------------------------------*/
export const UserMutations: IUserMutations = {
  [MT.SET_USERS](state: any, users: User[]) {
    state.users = users;
  },
  [MT.SET_FETCHING_USERS](state: any, value: boolean) {
    state.fetchingUsers = value;
  }
};

/*--------------------------------------------------/
 * User Mutations Tree Interface
 *--------------------------------------------------*/
export interface IUserMutations extends MutationTree<IUserState> {}
