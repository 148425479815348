import ProviderAnalysisModule from '@/modules/providers-analysis/ProviderAnalysisModule.vue';
import ProvidersAnalysisPage from '@/modules/providers-analysis/pages/ProvidersAnalysisPage.vue';

export const ProviderAnalysisRoutes = [
  {
    path: '/providers/:id/analysis',
    component: ProviderAnalysisModule,
    props: true,
    children: [
      {
        path: '',
        name: 'dashboard.providers.analysis',
        component: ProvidersAnalysisPage
      }
    ]
  }
];
