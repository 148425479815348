<template>
  <div>
    <v-flex data-testid="ProviderAnalysisModule" class="text-xs-left hidden-sm-and-down">
      <v-btn text class="back-btn" @click="redirectToListProvidersPage">
        <v-icon class="v-icon--small" left>fa-chevron-left</v-icon>
        <span>Go back</span>
      </v-btn>
    </v-flex>
    <router-view v-if="moduleIsReady" />
    <div v-else>Loading Analysis...</div>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { FETCH_PROVIDER_BY_ID } from '@/modules/providers/store/actionTypes';
import { FETCH_ANALYSIS_BY_ID, FETCH_CHARTDATA } from '@/modules/providers-analysis/store/actionTypes';

export default class ProviderAnalysisModule extends Vue {
  @Prop()
  public id!: number;

  created() {
    this.bootstrap();
  }
  get provider() {
    return this.$store.getters.provider;
  }
  get analysis() {
    return this.$store.getters.analysis;
  }
  get chartData() {
    return this.$store.getters.chartData;
  }
  public get fetchingChartData() {
    return this.$store.getters.fetchingChartData;
  }

  get moduleIsReady() {
    if (this.analysis != null && this.provider != null && this.fetchingChartData === false) return true;
    else return false;
  }
  public redirectToListProvidersPage() {
    this.$router.push({ name: 'dashboard.providers.list' });
  }
  bootstrap() {
    this.$store.dispatch(FETCH_PROVIDER_BY_ID, this.id);
    this.$store.dispatch(FETCH_ANALYSIS_BY_ID, this.id);
    this.$store.dispatch(FETCH_CHARTDATA);
  }
}
</script>
<style lang="scss">
.back-btn {
	background-color: #ffffff !important;
	margin: 0.6em;
}
</style>
