<template>
  <v-container>
    <div v-if="provider">
      <v-snackbar :value="generatingPdf" :timeout="5000" :top="true">
        Please wait while PDF is being generated
        <v-btn variant="text" @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-alert
        v-model="pdfAlert"
        :dismissible="true"
        :outlined="false"
        colored-border
        elevation="2"
        border="start"
        color="#673AB7"
        class="mb-4"
      >
        PDF URL:
        <a :href="pdfUrl" target="_blank">{{ pdfUrl }}</a>
      </v-alert>
      <v-layout justify-space-between>
        <v-flex>
          <h1>Analysis for {{ provider.name }}</h1>
        </v-flex>
        <v-flex class="text-right">
          <v-btn @click="exportPdf" small color="primary">Export</v-btn>
        </v-flex>
      </v-layout>
      <v-divider />
      <v-card class="mt-4">
        <AppTable data-testid="ProviderAnalysisTable" v-if="tableData" :table-data="tableData" :hide-actions="false" />
      </v-card>
    </div>
    <Analysis v-if="chartData && !fetchingChartData" :id="provider.id" :chartData="chartData"></Analysis>
  </v-container>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppTable from '@/components/AppTable.vue';
import { HTTPServiceClient } from '@/packages/http/service';
import Provider from '@/models/Provider';
import Utils from '@/utils/Utils';
import { FETCH_PROVIDER_BY_ID } from '@/modules/providers/store/actionTypes';
import { FETCH_ANALYSIS_BY_ID, GENERATE_PDF, GENERATING_PDF } from '@/modules/providers-analysis/store/actionTypes';
import Analysis from '@/modules/providers-analysis/pages/ProviderAnalysisCharts.vue';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_fbApiKey,
  authDomain: process.env.VUE_APP_fbAuthDomain,
  databaseURL: process.env.VUE_APP_fbDatabaseURL,
  projectId: process.env.VUE_APP_fbProjectId,
  storageBucket: process.env.VUE_APP_fbStorageBucket,
  messagingSenderId: process.env.VUE_APP_fbMessagingSenderId,
  appId: process.env.VUE_APP_fbAppId,
};
// // Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// const db = firebase.firestore();

@Options({
  components: {
    AppTable,
    Analysis,
  },
})
export default class ProvidersAnalysisPage extends Vue {
  public pdfAlert = false;
  public pdfJobId = 0;
  public pdfUrl = null;

  get fetchingProviders() {
    return this.$store.getters.fetchingProviders;
  }
  get fetchingAnalysis() {
    return this.$store.getters.fetchingAnalysis;
  }
  get fetchingChartData() {
    return this.$store.getters.fetchingChartData;
  }
  get generatingPdf() {
    return this.$store.getters.generatingPdf;
  }

  async exportPdf() {
    this.pdfAlert = false;
    var id = this.provider.id;
    const res = await this.$store.dispatch(GENERATE_PDF, id);
    this.pdfJobId = res.id;
    // this.getPdfLink();
  }

  get PdfLink() {
    return this.$store.getters.pdfLink;
  }
  get provider() {
    return this.$store.getters.provider;
  }
  get analysis() {
    return this.$store.getters.analysis;
  }
  get chartData() {
    return this.$store.getters.chartData;
  }

  public get rows() {
    // if (!this.analysis) {
    // 	return [];
    // }
    return Object.values(this.analysis);
  }

  public get columns() {
    if (!this.analysis) {
      return [];
    }
    return [
      {
        value: 'id',
        title: 'Identifier',
      },
      {
        value: 'scheme',
        title: 'Scheme',
      },
      {
        value: 'fund',
        title: 'Fund',
      },
      {
        value: 'xirr',
        title: 'Actual IRR',
        formatter: (v: any) => Utils.formatNumber(v, '0.00') + '%',
      },
      {
        value: 'benchmarkXirr',
        title: 'Benchmark IRR',
        formatter: (v: any) => Utils.formatNumber(v, '0.00') + '%',
      },
      {
        value: 'firstContributionDate',
        title: 'Date of First Contribution',
        formatter: (v: any) => new Date(v).toDateString(),
      },
      {
        value: 'lastContributionDate',
        title: 'Date of Last Contribution',
        formatter: (v: any) => new Date(v).toDateString(),
      },
      {
        value: 'nav',
        title: 'Actual NAV',
        formatter: (v: any) => Utils.formatNumber(v, '$0.0a'),
      },
      {
        value: 'benchmarkNav',
        title: 'Benchmark NAV',
        formatter: (v: any) => Utils.formatNumber(v, '$0.0a'),
      },
      {
        value: 'nav_date',
        title: 'Date of NAV',
        formatter: (v: any) => new Date(v).toDateString(),
      },
      {
        value: 'totalContribution',
        title: 'Total Contribution',
        formatter: (v: any) => Utils.formatNumber(v, '$0.0a'),
      },
    ];
  }

  public get tableData() {
    return {
      rows: this.rows,
      columns: this.columns,
    };
  }
}
</script>

<style lang="scss">
.v-alert__icon {
  align-self: auto !important;
}
</style>
