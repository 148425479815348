import * as AT from '@/modules/providers/store/actionTypes';
import * as MT from '@/modules/providers/store/mutationTypes';
import { IRootState } from '@/store/state';
import { IProviderState } from '@/modules/providers/store/state';
import { ActionContext, ActionTree } from 'vuex';
import { ProviderService } from '@/modules/providers/services';
import Provider from '@/models/Provider';
/**
 * --------------------------------------------------
 *  Provider Actions
 * --------------------------------------------------
 **/
export const ProviderActions: IProviderActions = {
  //

  // Fetch All the Providers
  [AT.FETCH_ALL_PROVIDERS]: ({ commit, dispatch }: any) => {
    dispatch(AT.FETCHING_PROVIDERS, true);
    return ProviderService.getAllProviders()
      .then(providers => {
        commit(MT.SET_ALL_PROVIDERS, providers);
        return providers;
      })
      .finally(() => dispatch(AT.FETCHING_PROVIDERS, false));
  },
  // Fetch All the Providers by userId
  [AT.FETCH_PROVIDERS]: ({ commit, dispatch }: any) => {
    dispatch(AT.FETCHING_PROVIDERS, true);
    return ProviderService.getProviders()
      .then(providers => {
        commit(MT.SET_PROVIDERS, providers);
        return providers;
      })
      .finally(() => dispatch(AT.FETCHING_PROVIDERS, false));
  },
  // Fetch All the Providers with performance attribution
  [AT.FETCH_PROVIDERS_PA]: ({ commit, dispatch }: any) => {
    dispatch(AT.FETCHING_PROVIDERS, true);
    return ProviderService.getProvidersWithPA()
      .then(providers => {
        commit(MT.SET_PROVIDERS_PA, providers);
        return providers;
      })
      .finally(() => dispatch(AT.FETCHING_PROVIDERS, false));
  },
  //
  // Fetch All the Providers
  [AT.FETCH_PROVIDER_BY_ID]: ({ commit, dispatch }: any, id: number) => {
    dispatch(AT.FETCHING_PROVIDER, true);
    return ProviderService.getProviderById(id)
      .then(provider => {
        commit(MT.SET_PROVIDER, provider);
        return provider;
      })
      .finally(() => dispatch(AT.FETCHING_PROVIDER, false));
  },
  //
  // Fetch All the Providers by UserId
  [AT.FETCH_PROVIDER_BY_USERID]: ({ commit, dispatch }: any, id: number) => {
    dispatch(AT.FETCHING_PROVIDER, true);
    return ProviderService.getProviderByUserId(id)
      .then(providers => {
        commit(MT.SET_PROVIDERS, providers);
        return providers;
      })
      .finally(() => dispatch(AT.FETCHING_PROVIDER, false));
  },
  //
  // Add Provider
  [AT.ADD_PROVIDER]: ({ commit, dispatch }: any, provider: Provider) => {
    dispatch(AT.FETCHING_PROVIDERS, true);
    return ProviderService.addProvider(provider)
      .then(() => dispatch(AT.FETCH_PROVIDERS))
      .finally(() => dispatch(AT.FETCHING_PROVIDERS, false));
  },
  // Remove Provider
  [AT.REMOVE_PROVIDER]: ({ commit, dispatch }: any, provider: Provider) => {
    dispatch(AT.FETCHING_PROVIDERS, true);
    return ProviderService.removeProvider(provider)
      .then(() => dispatch(AT.FETCH_PROVIDERS))
      .finally(() => dispatch(AT.FETCHING_PROVIDERS, false));
  },
  //
  // Set Fetching Providers Loader
  [AT.FETCHING_PROVIDERS]: ({ commit }: any, value: boolean) => commit(MT.SET_FETCHING_PROVIDERS, value),
  // Set Fetching Provider Loader
  [AT.FETCHING_PROVIDER]: ({ commit }: any, value: boolean) => commit(MT.SET_FETCHING_PROVIDER, value),

  [AT.FETCHING_PROVIDER_DATA_QUALITY]: ({ commit }: any, value: boolean) => commit(MT.SET_FETCHING_DATA_QUALITY, value),

  [AT.FETCH_PROVIDER_DATA_QUALITY]: ({ commit, dispatch }: any, data: any) => {
    dispatch(AT.FETCHING_PROVIDER_DATA_QUALITY, true);
    return ProviderService.getProvidersDataQuality(data.providerId)
      .then(providerDataQuality => {
        commit(MT.SET_PROVIDER_DATA_QUALITY, providerDataQuality);
        return providerDataQuality;
      })
      .finally(() => dispatch(AT.FETCHING_PROVIDER_DATA_QUALITY, false));
  },
  [AT.FETCH_PROVIDER_MASTER_LIST]: ({ commit, dispatch }: any, id: number) => {
    dispatch(AT.FETCHING_MASTER_PROVIDER, true);
    return ProviderService.getMasterProviders(id)
      .then((providers: any) => {
        commit(MT.SET_MASTER_PROVIDERS, providers);
        return providers;
      })
      .finally(() => dispatch(AT.FETCHING_MASTER_PROVIDER, false));
  },

  [AT.FETCHING_MASTER_PROVIDER]: ({ commit }: any, value: boolean) => commit(MT.SET_FETCHING_MASTER_PROVIDER, value),

};

/**
 * --------------------------------------------------
 *  Provider Action Context Interface
 * --------------------------------------------------
 **/
export interface IProviderActionContext extends ActionContext<IProviderState, IRootState> {}

/**
 * --------------------------------------------------
 *  Provider Actions Interface
 * --------------------------------------------------
 **/
export interface IProviderActions extends ActionTree<IProviderState, IRootState> {}
