<template>
  <div>
    <v-card class="filter-card benchmark">
      <v-card-text>
        <v-row align="end">
          <v-col cols="12" :xs="6" :md="4" class="pb-0 px-7 providerFilter">
            <v-layout>
              <v-autocomplete
                :model-value="currentFilters.tax_year"
                :items="filters.tax_year"
                :hide-details="true"
                variant="underlined"
                chips
                :clearable="true"
                :allow-overflow="false"
                color="primary"
                label="Tax Year"
                item-title="name"
                item-value="id"
                multiple
                @update:modelValue="
                  (e) => {
                    updateTaxYear(e);
                  }
                "
              >
                <template v-slot:chip="{ props, item }">
                  <v-chip v-bind="props" close class="filter-chips mx-1 my-1" size="default">
                    <div>{{ item.raw }}</div>
                  </v-chip>
                </template>

                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props" :title="item.raw"> </v-list-item>
                </template>
              </v-autocomplete>
            </v-layout>
          </v-col>
          <v-col cols="12" :xs="6" :md="4" class="pb-0 px-7 providerFilter">
            <v-layout>
              <v-autocomplete
                :model-value="currentFilters.scheme"
                :items="filters.scheme"
                :hide-details="true"
                variant="underlined"
                chips
                :clearable="true"
                :allow-overflow="false"
                color="primary"
                label="Type of Scheme"
                item-title="name"
                item-value="id"
                multiple
                @update:modelValue="
                  (e) => {
                    updateScheme(e);
                  }
                "
              >
                <template v-slot:chip="{ props, item }">
                  <v-chip v-bind="props" close class="filter-chips mx-1 my-1" size="default">
                    <div>{{ item.raw }}</div>
                  </v-chip>
                </template>

                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props" :title="item.raw"> </v-list-item>
                </template>
              </v-autocomplete>
            </v-layout>
          </v-col>
          <v-col cols="12" :xs="6" :md="4" class="pb-0 px-7 providerFilter">
            <v-layout>
              <v-autocomplete
                :model-value="currentFilters.pathway"
                :items="filters.pathway"
                :hide-details="true"
                variant="underlined"
                chips
                :clearable="true"
                :allow-overflow="false"
                color="primary"
                label="Pathway"
                item-title="name"
                item-value="id"
                multiple
                @update:modelValue="
                  (e) => {
                    updatePathWay(e);
                  }
                "
              >
                <template v-slot:chip="{ props, item }">
                  <v-chip v-bind="props" close class="filter-chips mx-1 my-1" size="default">
                    <div>{{ item.raw }}</div>
                  </v-chip>
                </template>

                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props" :title="item.raw"> </v-list-item>
                </template>
              </v-autocomplete>
            </v-layout>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" :xs="6" :md="4" class="pb-0 px-7 providerFilter">
            <v-layout>
              <v-autocomplete
                :model-value="currentFilters.data_type"
                :items="filters.data_type"
                :hide-details="true"
                variant="underlined"
                chips
                :clearable="true"
                :allow-overflow="false"
                color="primary"
                label="Data Type"
                item-title="name"
                item-value="id"
                multiple
                @update:modelValue="
                  (e) => {
                    updateDataType(e);
                  }
                "
              >
                <template v-slot:chip="{ props, item }">
                  <v-chip v-bind="props" close class="filter-chips mx-1 my-1" size="default">
                    <div>{{ item.raw }}</div>
                  </v-chip>
                </template>

                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props" :title="item.raw"> </v-list-item>
                </template>
              </v-autocomplete>
            </v-layout>
          </v-col>
          <v-col cols="12" :xs="6" :md="4" class="pb-0 px-7 providerFilter">
            <v-layout>
              <v-autocomplete
                :model-value="currentFilters.consolidator"
                :items="filters.consolidator"
                :hide-details="true"
                variant="underlined"
                chips
                :clearable="true"
                :allow-overflow="false"
                color="primary"
                label="Consolidator"
                item-title="name"
                item-value="id"
                multiple
                @update:modelValue="
                  (e) => {
                    updateConsolidator(e);
                  }
                "
              >
                <template v-slot:chip="{ props, item }">
                  <v-chip v-bind="props" close class="filter-chips mx-1 my-1" size="default">
                    <div>{{ item.raw }}</div>
                  </v-chip>
                </template>

                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props" :title="item.raw"> </v-list-item>
                </template>
              </v-autocomplete>
            </v-layout>
          </v-col>
          <v-col cols="12" :xs="6" :md="4" class="pb-0 px-7 providerFilter">
            <v-layout align-center justify-space-between class="filter-header">
              <v-layout>
                <v-icon color="primary" class="mr-3">fas fa-coins</v-icon>
                <span>Pot Size</span>
              </v-layout>
              <v-btn
                v-show="potRangeIsDirty"
                flat
                size="small"
                icon
                class="reset-filter-btn"
                @click.stop="(e) => resetFilter('asset_size')"
              >
                <v-icon small>fas fa-times</v-icon>
              </v-btn>
            </v-layout>
            <v-range-slider
              :model-value="currentFilters.asset_size || []"
              :max="filters.asset_size.max"
              :min="filters.asset_size.min"
              hide-details
              :track-size="1"
              :thumb-size="12"
              track-color="#e4dbf1"
              color="primary"
              class="align-center mt-2 px-3"
              @update:modelValue="(e) => updateAssetSize(e)"
            ></v-range-slider>
            <v-layout class="filter-value mx-5" v-if="currentFilters.asset_size">
              <v-menu
                v-model="minmenu"
                :close-on-content-click="false"
                :close-on-click="false"
                content-class="filter-edit-menus"
                offset="10"
                location="right"
              >
                <template v-slot:activator="{ props }">
                  <div v-bind="props">
                    <b>{{ formatNumber(currentFilters.asset_size[0], '$0.0a') }}</b>
                    <v-icon color="primary" size="10" class="ml-1 mb-1">fas fa-pen</v-icon>
                  </div>
                </template>

                <div class="d-flex white align-center justify-space-between pa-2">
                  <v-text-field
                    v-model="currentFilters.asset_size[0]"
                    :height="14"
                    variant="outlined"
                    density="compact"
                    dense
                    hide-details
                    type="number"
                  >
                  </v-text-field>
                  <v-btn @click="minmenu = false" color="secondary" class="ml-1">OK</v-btn>
                </div>
              </v-menu>
              <v-spacer></v-spacer>
              <v-menu
                v-model="maxmenu"
                :close-on-content-click="false"
                :close-on-click="false"
                content-class="filter-edit-menus"
                offset="10"
                location="right"
              >
                <template v-slot:activator="{ props }">
                  <div v-bind="props">
                    <b>{{ formatNumber(currentFilters.asset_size[1], '$0.0a') }}</b>
                    <v-icon color="primary" size="10" class="ml-1 mb-1">fas fa-pen</v-icon>
                  </div>
                </template>

                <div class="d-flex white align-center justify-space-between pa-2">
                  <v-text-field
                    v-model="currentFilters.asset_size[1]"
                    :height="14"
                    flat
                    variant="outlined"
                    density="compact"
                    hide-details
                    type="number"
                  >
                  </v-text-field>
                  <v-btn @click="maxmenu = false" color="secondary" class="ml-1">OK</v-btn>
                </div>
              </v-menu>
            </v-layout>
          </v-col>
        </v-row>

        <v-row align="end">
          <v-col :xs="12" class="filter-col mb-4">
            <v-layout align-end class="d-flex justify-end mr-5">
              <v-btn variant="text" depressed light @click="resetFilters">Reset</v-btn>
              <v-btn
                @click.stop="applyFilter"
                :color="!filtersAreDirty || !(isVFMUser || isSuperUser) ? 'disbaled' : 'success'"
                class="ml-3"
                :disabled="!filtersAreDirty || !(isVFMUser || isSuperUser)"
                >Apply</v-btn
              >
            </v-layout>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Watch } from 'vue-property-decorator';
import Utils from '../../../../utils/Utils';
import { FETCH_FILTERED_MEMBERS_ANALYSIS } from '@/modules/members/store/actionTypes';
import { TOGGLE_FILTER_TABLE } from '@/store/actionTypes';
import Provider from '../../../../models/Provider';
import User from '@/models/User';

export default class BenchmarkFilterTable extends Vue {
  @Prop({
    default: false,
  })
  public dateFilters!: boolean;

  @Prop({
    default: true,
  })
  public providerFilter!: boolean;

  @Prop({
    type: Object,
    default: () => ({
      tax_year: null,
      scheme: null,
      pathway: null,
      data_type: null,
      consolidator: null,
      asset_size: { min: 20, max: 100 },
    }),
  })
  public filters!: any;

  @Prop()
  public initFilters!: any;

  public slider = 5;

  public appliedFilters: any = {
    tax_year: [],
    scheme: [],
    pathway: [],
    data_type: [],
    consolidator: [],
    asset_size: [],
  };

  public currentFilters: any = {
    tax_year: [],
    scheme: [],
    pathway: [],
    data_type: [],
    consolidator: [],
    asset_size: [],
  };

  public maxmenu = false;

  public minmenu = false;

  public maxBirrmenu = false;

  public minBirrmenu = false;

  public maxIrrmenu = false;

  public minIrrmenu = false;

  public startDatePicker = false;

  public endDatePicker = false;

  public isEditing = true;

  public filtersCanBeReset = false;

  created() {
    const filterData = this.filters;
    this.initializeFilters(filterData);
    if (this.initFilters.asset_size.length !== 0) {
      this.currentFilters.asset_size = [...this.initFilters.asset_size];
    }
    if (this.initFilters.tax_year.length !== 0) {
      this.currentFilters.tax_year = [...this.initFilters.tax_year];
    }
    if (this.initFilters.scheme.length !== 0) {
      this.currentFilters.scheme = [...this.initFilters.scheme];
    }
    if (this.initFilters.pathway.length !== 0) {
      this.currentFilters.pathway = [...this.initFilters.pathway];
    }
    if (this.initFilters.consolidator.length !== 0) {
      this.currentFilters.consolidator = [...this.initFilters.consolidator];
    }
    if (this.initFilters.data_type.length !== 0) {
      this.currentFilters.data_type = [...this.initFilters.data_type];
    }
  }

  @Watch('initFilters')
  filterchanged(initFilters: any) {
    if (this.initFilters.asset_size.length == 0) {
      this.currentFilters.asset_size = [...initFilters.asset_size];
      this.resetFilters();
    }
  }

  public updateTaxYear(range: number[]) {
    this.currentFilters.tax_year = range;
  }

  public updateScheme(range: number[]) {
    this.currentFilters.scheme = range;
  }

  public updatePathWay(range: number[]) {
    this.currentFilters.pathway = range;
  }

  public updateConsolidator(providers: number[]) {
    this.currentFilters.consolidator = providers;
  }

  public updateAssetSize(size: number[]) {
    this.currentFilters.asset_size = size;
  }

  public updateDataType(type: any) {
    this.currentFilters.data_type = type;
  }

  public get filtersAreDirty() {
    return [
      this.potRangeIsDirty,
      this.dataTypeIsDirty,
      this.consolidatorIsDirty,
      this.pathwayIsDirty,
      this.taxYearIsDirty,
      this.schemeIsDirty,
    ].some((i) => i);
  }

  public get user(): User {
    return this.$store.getters.currentUser;
  }

  public get isVFMUser() {
    return this.user && this.user.role === 'vfm_user';
  }

  public get isSuperUser() {
    return this.user && this.user.role === 'superuser';
  }

  public get potRangeIsDirty() {
    if (!this.currentFilters.asset_size || !this.appliedFilters.asset_size) {
      return false;
    }

    return this.currentFilters.asset_size.some((n: number, i: number) => n !== this.appliedFilters.asset_size[i]);
  }

  public get schemeIsDirty() {
    if (!this.currentFilters.scheme || !this.appliedFilters.scheme) {
      return false;
    }

    const lengthIsDifferent = this.currentFilters.scheme.length !== this.appliedFilters.scheme.length;
    const itemsAreDifferent = this.currentFilters.scheme.some((n: number, i: number) => {
      return +n !== +this.appliedFilters.scheme[i];
    });
    return lengthIsDifferent || itemsAreDifferent;
  }

  public get taxYearIsDirty() {
    if (!this.currentFilters.tax_year || !this.appliedFilters.tax_year) {
      return false;
    }
    const lengthIsDifferent = this.currentFilters.tax_year.length !== this.appliedFilters.tax_year.length;
    const itemsAreDifferent = this.currentFilters.tax_year.some((n: number, i: number) => {
      return +n !== +this.appliedFilters.tax_year[i];
    });
    return lengthIsDifferent || itemsAreDifferent;
  }

  public get pathwayIsDirty() {
    if (!this.currentFilters.pathway || !this.appliedFilters.pathway) {
      return false;
    }

    const lengthIsDifferent = this.currentFilters.pathway.length !== this.appliedFilters.pathway.length;
    const itemsAreDifferent = this.currentFilters.pathway.some((n: number, i: number) => {
      return +n !== +this.appliedFilters.pathway[i];
    });
    return lengthIsDifferent || itemsAreDifferent;
  }

  public get consolidatorIsDirty() {
    if (!this.currentFilters.consolidator || !this.appliedFilters.consolidator) {
      return false;
    }

    const lengthIsDifferent = this.currentFilters.consolidator.length !== this.appliedFilters.consolidator.length;
    const itemsAreDifferent = this.currentFilters.consolidator.some((n: number, i: number) => {
      return +n !== +this.appliedFilters.consolidator[i];
    });
    return lengthIsDifferent || itemsAreDifferent;
  }

  public get dataTypeIsDirty() {
    if (!this.currentFilters.data_type || !this.appliedFilters.data_type) {
      return false;
    }

    const lengthIsDifferent = this.currentFilters.data_type.length !== this.appliedFilters.data_type.length;
    const itemsAreDifferent = this.currentFilters.data_type.some((n: number, i: number) => {
      return +n !== +this.appliedFilters.data_type[i];
    });
    return lengthIsDifferent || itemsAreDifferent;
  }

  public resetFilter(filter: string) {
    if (['asset_size'].includes(filter)) {
      this.currentFilters[filter] = [...this.appliedFilters[filter]];
      return true;
    }
    this.currentFilters[filter] = this.appliedFilters[filter];
    return true;
  }

  public async resetFilters() {
    this.filtersCanBeReset = false;
    this.currentFilters = {
      tax_year: [],
      scheme: [],
      pathway: [],
      data_type: [],
      consolidator: [],
      asset_size: [],
    };
    this.initializeFilters(this.filters);
    this.$emit('apply', this.currentFilters);
  }

  public async applyFilter(filtersCanBeReset = true) {
    this.filtersCanBeReset = filtersCanBeReset;
    Object.keys(this.currentFilters || {}).forEach((filter: string) => {
      if (['asset_size'].includes(filter)) {
        this.appliedFilters[filter] = [...this.currentFilters[filter]];
      } else {
        this.appliedFilters[filter] = this.currentFilters[filter];
      }
    });

    let filters: any = {
      asset_size: this.appliedFilters.asset_size,
      tax_year: this.appliedFilters.tax_year,
      scheme: this.appliedFilters.scheme,
      pathway: this.appliedFilters.pathway,
      data_type: this.appliedFilters.data_type,
      consolidator: this.appliedFilters.consolidator,
    };

    this.$emit('apply', filters);
  }

  public formatNumber(n: number, format = '0.0a') {
    return Utils.formatNumber(n, format);
  }

  private initializeFilters(filters: any) {
    if (!filters) {
      return;
    }

    if (filters.asset_size) {
      this.currentFilters.asset_size = [Math.ceil(filters.asset_size.min), Math.ceil(filters.asset_size.max)];
      this.appliedFilters.asset_size = [Math.ceil(filters.asset_size.min), Math.ceil(filters.asset_size.max)];
    }
  }
}
</script>

<style lang="scss">
@import '@/sass/bootstrap.scss';

.filter-card {
  .filter-col {
    margin: auto 0 !important;
    // .v-input__slot {
    //   margin-bottom: 0;
    // }
    // .v-text-field__details {
    //   display: none;
    // }
  }
}

.filter-header {
  font-weight: 700;
  font-size: 1rem;
  padding: 0.5rem;
  height: 32px;
  color: $brand-primary;
}

.filter-value {
  font-size: 0.85rem;
  color: lighten($brand-primary, 12%);
}

.benchmark .providerFilter {
  margin-bottom: 2.42rem !important;
  .count-text {
    font-size: 0.8rem;
  }
}
.truncate {
  width: 7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-edit-menus {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1), 0 8px 10px 1px rgba(0, 0, 0, 0.07), 0 3px 14px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.filter-chips {
  background-color: #300078 !important;
  color: white !important;
  .v-chip__underlay {
    opacity: 0 !important;
    background: unset !important;
  }
}

.v-chip__close.v-icon,
.v-btn--fab.v-size--default .v-icon {
  font-size: 1rem;
}

.reset-filter-btn {
  width: 24px !important;
  height: 24px !important;
  & .v-icon {
    font-size: 1rem !important;
  }
}
</style>
