import { UserActions } from '@/modules/users/store/actions';
import { UserGetters } from '@/modules/users/store/getters';
import { UserMutations } from '@/modules/users/store/mutations';
import { UserState, IUserState } from '@/modules/users/store/state';
import { IRootState } from '@/store/state';
import { Module } from 'vuex';

export const UserStore: IUserStore = {
  state: UserState,
  actions: UserActions,
  getters: UserGetters,
  mutations: UserMutations
};

/**
 * --------------------------------------------------
 *	User Store Interface
 * --------------------------------------------------
 **/
export interface IUserStore extends Module<IUserState, IRootState> {}
