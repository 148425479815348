import * as MT from '@/modules/price-track/store/mutationTypes';
import { IPriceTrackState } from '@/modules/price-track/store/state';
import { MutationTree } from 'vuex';
import { PriceTrackListItems, PriceTrackObject } from '@/models/PriceTrack';

/*--------------------------------------------------/
 * PriceTrack Mutations
 *--------------------------------------------------*/
export const PriceTrackMutations: IPriceTrackMutations = {
  [MT.SET_PRICE_TRACKS](state: IPriceTrackState, priceTracks: PriceTrackListItems[]) {
    state.priceTracks = priceTracks;
  },
  [MT.SET_PRICE_TRACK](state: IPriceTrackState, priceTrack: PriceTrackObject) {
    state.currentPriceTrack = priceTrack;
  },
  [MT.ADDING_PRICE_TRACK](state: IPriceTrackState, addingPriceTrack: boolean) {
    state.addingPriceTrack = addingPriceTrack;
  },
};

/*--------------------------------------------------/
 * PriceTrack Mutations Tree Interface
 *--------------------------------------------------*/
export interface IPriceTrackMutations extends MutationTree<IPriceTrackState> {}
