import { IRootState } from '@/store/state';
import { IReportState } from '@/modules/reports/store/state';
import { GetterTree } from 'vuex';

/**
 * --------------------------------------------------
 *	Report Getters
 * --------------------------------------------------
 **/
export const ReportGetters: IReportGetters = {
  reportOptions: (state: IReportState) => state.reportOptions,
  fetchingReports: (state: IReportState) => state.fetchingReports,
  reports: (state: IReportState) => state.reports,
  outliers: (state: IReportState) => state.outliers,
  performanceReport: (state: IReportState) => state.performanceAttribution,
  addingFreeText: (state: IReportState) => state.addingFreeText,
  fetchingOutliers: (state: IReportState) => state.fetchingOutliers,
  fetchingSampleReport: (state: IReportState) => state.fetchingSampleReport,
  sampleReport: (state: IReportState) => state.sampleReport
};

/**
 * --------------------------------------------------
 *	Report Getters Interface
 * --------------------------------------------------
 **/
export interface IReportGetters extends GetterTree<IReportState, IRootState> {}
