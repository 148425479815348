<template>
  <div data-testid="list-members-page" class="page list-members-page">
    <Watermark :isSampleReport="isBenchmarkUser" />
    <v-container fluid class="pos-relative">
      <!-- No data snackbar -->
      <v-snackbar v-model="noDataAlert" :timeout="80000" color="primary" location="top">
        <h3>No data available for this provider</h3>
        <v-btn color="blue" variant="text" class="d-flex ml-auto" @click="noDataAlert = false"> Close </v-btn>
      </v-snackbar>

      <KeyStats
        class="my-4 mb-10"
        :data="metrics"
        :selection-count="selectedItems ? selectedItems.length : 0"
        :total-count="memberAnalysis ? memberAnalysis.length : 0"
      ></KeyStats>

      <div ref="filterPanel" class="page-filters-wrapper">
        <div
          :threshold="filterPanelPosition"
          id="navbar"
          class="navbar page-filters-panel pos-relative justify-space-between pa-2"
          :class="scrolledAll ? 'vue-fixed-header--isFixed' : ''"
        >
          <v-layout>
            <v-row class="my-1">
              <v-col cols="4" align-self="center">
                <h2 class="section-title">
                  <!-- <span v-if="appliedFilters.providers">{{providerName}} SAVERS</span> -->
                  <span>ALL SAVERS</span>
                  <span v-if="selectedItems && selectedItems.length" class="pl-1 grey--text text--darken-1"
                    >({{ selectedItems.length }} out of {{ memberAnalysis.length }} selected)</span
                  >
                </h2>
              </v-col>
              <v-col v-if="!isBenchmarkUser" class="d-flex justify-end py-1">
                <!-- Outlier Button -->
                <v-menu
                  v-model="outlierIsVisible"
                  :close-on-content-click="false"
                  attach=".page-filters-wrapper"
                  left
                  max-width="300px"
                  content-class="filter-menu_buttons"
                  :offset="20"
                  transition="slide-y-transition"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      :disabled="fetchingMembersAnalysis && !memberAnalysis"
                      class="outlier-btn elevation-4 mt-0 mx-2"
                      data-testid="OutlierBtn"
                      v-bind="props"
                      variant="text"
                      size="small"
                    >
                      <span>Outliers</span>
                      <div class="ml-2">
                        <v-img class="filter-icon" src="../../../assets/icons/download-arrow.svg" />
                      </div>
                    </v-btn>
                  </template>
                  <div class="elevation-10 pa-3" style="background-color: #fff">
                    <v-select
                      v-model="outlierProvider"
                      item-title="name"
                      item-value="id"
                      :items="providers"
                      placeholder="Select Provider"
                      hide-details
                      color="primary"
                      density="compact"
                      variant="outlined"
                    />
                    <v-divider></v-divider>
                    <v-btn
                      variant="text"
                      left
                      width="100%"
                      class="mt-3"
                      :disabled="!outlierProvider"
                      :loading="fetchingOutliers"
                      @click="fetchOutliers"
                    >
                      <v-icon small class="mr-3">fas fas fa-eye</v-icon>Show
                    </v-btn>
                  </div>
                </v-menu>
                <!-- CSV Download -->
                <v-menu
                  v-model="downloadIsVisible"
                  :close-on-content-click="false"
                  attach=".page-filters-wrapper"
                  left
                  max-width="300px"
                  min-width="150px"
                  offset="20"
                  transition="slide-y-transition"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="csv-btn elevation-4 mt-0 mx-2"
                      :class="{ 'is-active': sortingIsApplied }"
                      :disabled="fetchingMembersAnalysis && !memberAnalysis"
                      data-testid="SortingBtn"
                      v-bind="props"
                      variant="text"
                      size="small"
                    >
                      <span>DOWNLOAD</span>
                      <div class="ml-2">
                        <v-img class="filter-icon" src="../../../assets/icons/download-arrow.svg" />
                      </div>
                    </v-btn>
                  </template>

                  <div class="elevation-10 pa-3" style="background-color: #fff">
                    <div>
                      <v-select
                        v-model="memberCSVProviders"
                        item-title="name"
                        item-value="id"
                        :items="providers"
                        placeholder="Select Provider"
                        hide-details
                        color="primary"
                        density="compact"
                        variant="outlined"
                      />
                      <v-divider></v-divider>
                      <v-btn
                        variant="text"
                        width="100%"
                        class="mt-3"
                        :disabled="!memberCSVProviders"
                        :loading="processingCSV"
                        @click="exportCSV(true)"
                      >
                        <v-icon small class="mr-3">fas fa-file-download</v-icon>Download All
                      </v-btn>
                    </div>
                    <v-divider class="my-2"></v-divider>
                    <v-btn
                      variant="text"
                      width="100%"
                      class="pa-2"
                      :loading="processingCurrentCSV"
                      @click="exportCSV(false)"
                    >
                      <v-icon small class="mr-3">fas fa-file-download</v-icon>Download Current View
                    </v-btn>
                  </div>
                </v-menu>
                <!-- Sorting Btn -->
                <v-menu
                  v-model="sortingIsVisible"
                  :close-on-content-click="false"
                  attach=".page-filters-wrapper"
                  left
                  max-width="300px"
                  min-width="150px"
                  offset="20"
                  transition="slide-y-transition"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="sorting-btn elevation-6 mt-0 mx-2"
                      :class="{ 'is-active': sortingIsApplied }"
                      :disabled="fetchingMembersAnalysis && !memberAnalysis"
                      data-testid="SortingBtn"
                      v-bind="props"
                      variant="text"
                      size="small"
                    >
                      <span>Sort</span>
                      <v-icon small right class="ml-2">fas fa-sort-amount-down-alt</v-icon>
                    </v-btn>
                  </template>
                  <SortingPanel
                    :items="sortByItems"
                    :default-sorting="defaultSorting"
                    @reset="resetSorting"
                    :isSortingApplied="isSortingApplied"
                    @apply="applySorting"
                  />
                </v-menu>
                <!-- Filter Btn -->
                <v-menu
                  v-model="filterIsVisible"
                  :close-on-content-click="false"
                  attach=".page-filters-wrapper"
                  content-class="page-filter-main"
                  max-width="100%"
                  min-width="100%"
                  offset="20"
                  transition="slide-y-transition"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="filter-btn elevation-6 mt-0 mx-2"
                      :class="{ 'is-active': filtersAreApplied }"
                      :disabled="fetchingMembersAnalysis && !memberAnalysis"
                      data-testid="FilterBtn"
                      variant="text"
                      v-bind="props"
                      size="small"
                    >
                      <span>Filter</span>
                      <div class="ml-2">
                        <v-img v-if="!filtersAreApplied" class="filter-icon" src="../../../assets/icons/filter.svg" />
                        <v-img
                          v-if="filtersAreApplied"
                          class="filter-icon"
                          src="../../../assets/icons/filter-light.svg"
                        />
                      </div>
                    </v-btn>
                  </template>
                  <!-- Filter Menu -->
                  <FilterTable
                    :dateFilters="true"
                    :showProviderFilters="true"
                    :filters="filters"
                    :appliedFiltersParent="appliedFilters"
                    @apply="(e) => onFiltersApplied(e)"
                  ></FilterTable>
                </v-menu>
              </v-col>
            </v-row>
          </v-layout>
        </div>
      </div>
      <!-- Outliers Modal -->
      <v-dialog
        v-model="outliersDialog"
        @click:outside="outliersDialog = false"
        v-if="outlierData && !fetchingOutliers && outlierProviderName"
        persistent
        max-width="80vw"
        max-height="90%"
      >
        <v-card class="pa-2">
          <v-card-title class="d-flex align-center justify-space-between">
            <div>
              <div>
                Outliers of Provider #<b>{{ outlierProviderName }}</b>
              </div>
              <!-- <small>Percent of Outliers: {{ outlierPercent }}%</small> -->
              <div>
                <small> Suspect data : {{ outlierSuspectCount }}% </small> <br />
                <small> Short Contribution History : {{ outlierShortCont }}% </small> <br />
                <small> 0 NAV : {{ outlierNAVCount }}% </small>
              </div>
            </div>
            <div>
              <v-btn
                left
                color="primary"
                class="mr-4"
                :disabled="!outlierProvider"
                :loading="processingOutlier"
                @click="exportOutlierCSV"
              >
                <v-icon small class="mr-3">fas fa-file-download</v-icon>Download
              </v-btn>

              <v-btn rounded small variant="flat" icon @click="outliersDialog = false">
                <v-icon small>fa fa-times</v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <AppTable
              :table-data="outliersTableData"
              :disableSort="true"
              :disablePagination="true"
              :default-sort-column="[{ key: 'potSize', order: 'asc' }]"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn color="grey-darken-1" variant="text" @click="outliersDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Members Table -->
      <v-card v-if="!fetchingMembersAnalysis || memberAnalysis" class="mt-2">
        <AppTable
          :table-data="tableData"
          :pagination="paginateOptions"
          :show-select="true"
          :hide-actions="false"
          :loading="fetchingMembersAnalysis"
          :disable-sort="true"
          :striped="true"
          v-if="memberAnalysis && memberAnalysis.length"
          @page="onPageChange"
          :disablePagination="isBenchmarkUser"
          @itemsPerPage="onItemsPerPageChange"
        >
        </AppTable>

        <div v-if="!memberAnalysis || !memberAnalysis.length" class="d-flex align-center justify-center text-center">
          <div class="pa-10">
            <v-icon>fa fa-box</v-icon>
            <h2 class="heading">No data available.</h2>
          </div>
        </div>
      </v-card>
      <div v-if="fetchingMembersAnalysis && !memberAnalysis" class="d-flex align-center justify-center pt-5 mt-5">
        <v-progress-circular indeterminate color="grey" />
      </div>
      <AppLoader v-if="fetchingOutliers" />
    </v-container>
    <v-snackbar v-model="pdfTimeoutError" :timeout="3000" color="primary" location="top">
      <h3>Error downloading requested pdf. Please try again.</h3>
      <v-btn color="blue" variant="text" class="d-flex ml-auto" @click="pdfTimeoutError = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppTable from '@/components/AppTable.vue';
import SortingPanel from '@/components/SortingPanel.vue';
import { Table, DataTableHeader, TableRow, TableColumnType } from '@/types';
import Member from '@/models/Member';
import ContributionHistory from '@/models/ContributionHistory';
import { HTTPServiceClient } from '@/packages/http/service';
import * as AT from '@/modules/members/store/actionTypes';
import { SET_NAV_HEADER } from '@/store/actionTypes';
import { GET_EXPORT_JOB_BY_ID } from '@/modules/reports/store/actionTypes';
import { TOGGLE_FILTER_TABLE } from '@/store/actionTypes';
import { GET_CSV_URL } from '@/modules/members/store/actionTypes';
import KeyStats from '@/components/KeyStats.vue';
import FilterTable from '@/components/FilterTable.vue';
import Utils from '../../../utils/Utils';
import option from '../../../packages/stubs/echarts/bar';
// import FixedHeader from 'vue-fixed-header';
import Provider from '@/models/Provider';
import AppLoader from '@/components/AppLoder.vue';
import User from '@/models/User';
import Watermark from '@/modules/reports/pages/templates/Watermark.vue';

@Options({
  components: {
    AppTable,
    KeyStats,
    FilterTable,
    // FixedHeader,
    SortingPanel,
    AppLoader,
    Watermark,
  },
})
export default class ListMembersPage extends Vue {
  public outliersDialog = false;

  public filterPanelPosition = 500;

  public outlierPercent = null;

  public outlierProvider: any = null;

  public outlierProviderName: any = null;

  public outlierSuspectCount: any = null;

  public outlierShortCont: any = null;

  public outlierNAVCount: any = null;

  public memberCSVProviders: any = null;

  public processingCSV = false;

  public processingCurrentCSV = false;

  public processingOutlier = false;

  public fetchingMembersAnalysis = false;

  public fetchingOutliers = false;

  public memberHistoryRow: any = null;

  public fetchingMemberHistory = false;

  public filterIsVisible = false;

  public downloadIsVisible = false;

  public noDataAlert = false;

  public outlierIsVisible = false;

  public sortingIsVisible = false;

  public appliedFilters = {};

  public initialFilters = {};

  public appliedSorting: any = {};

  public isSortingApplied: Boolean = false;

  public defaultSorting: any = {
    sortBy: 'potSize',
    sortDescending: true,
  };

  public pdfTimeoutError = false;

  public pagination = {
    page: 1,
    limit: 15,
  };

  public scrolledAll: Boolean = false;

  created() {
    this.bootstrap();
  }

  public async bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Savers', tooltip: 'Individual Savers' });

    await this.fetchMembersAnalysis(this.pagination);
    // await this.$store.dispatch(FETCH_FUNDS, {});
    // await this.$store.dispatch(FETCH_SCHEMES);
    if (!this.isBenchmarkUser) {
      this.initialFilters = await this.$store.dispatch(AT.FETCH_MEMBER_FILTER_RANGES);
    }
  }

  mounted() {
    if (this.$refs.filterPanel && this.$refs.filterPanel instanceof Element) {
      this.filterPanelPosition = this.$refs.filterPanel.getBoundingClientRect().top;
    }
  }

  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  public get sortByItems(): any[] {
    return this.columns.filter(
      (c: DataTableHeader) => c.type && c.value !== 'dataId' && ![TableColumnType.ACTIONS].includes(c.type)
    );
  }

  public get selectedItems(): any[] | null {
    return this.$store.getters.selectedItems;
  }

  public get memberHistory(): any[] | null {
    return this.$store.getters.memberHistory;
  }
  public get outlierData(): any[] | null {
    return this.$store.getters.outlierData;
  }

  public get filters() {
    return {
      providers: this.providers || [],
      ...this.initialFilters,
    };
  }

  public get providers() {
    return this.$store.getters.providers;
  }

  public get user(): User {
    return this.$store.getters.currentUser;
  }

  public get isBenchmarkUser() {
    return this.user && this.user.role === 'bm_user';
  }
  // public get providerName(){
  //   if(this.appliedFilters){
  //     const providers = this.providers.filter(it => this.appliedFilters.providers.includes(it.id));
  //     let names = '';
  //     providers.map(it => {
  //       names = names + it.name + '/';
  //       return it.name;
  //     });
  //     return names;

  //   }
  //   return;
  // }

  public get fetchContibutionHistory(): boolean {
    return this.$store.getters.contributionHistory;
  }

  private async fetchMembersAnalysis(data: any = {}) {
    this.fetchingMembersAnalysis = true;
    let analysis;
    if (this.isBenchmarkUser) {
      analysis = await this.$store.dispatch(AT.FETCH_SAMPLE_MEMBER_DATA, data);
    } else {
      analysis = await this.$store.dispatch(AT.FETCH_MEMBERS_ANALYSIS, data);
    }
    this.fetchingMembersAnalysis = false;
    return analysis;
  }

  public get filterRanges() {
    if (this.isBenchmarkUser) {
      return this.sampleMemberAnalysis.filterRanges.data;
    }
    return this.$store.getters.memberFilterRanges;
  }

  public isFetchingHistory(row: any) {
    return this.memberHistoryRow && this.memberHistoryRow.id === row.id && this.fetchingMemberHistory;
  }

  public async getProviderNameById(id: number) {
    if (id) {
      const provider = await this.providers.find((item: any) => {
        return item.id === id;
      });
      return provider.name;
    }
    return '';
  }

  public toggleFilter() {
    this.$store.dispatch(TOGGLE_FILTER_TABLE);
  }

  public async fetchOutliers() {
    this.fetchingOutliers = true;
    const { data, count, totalOutlier, outlierGroups, totalMemberCount } = await this.$store.dispatch(
      AT.FETCH_OUTLIERS_ANALYSIS,
      {
        page: 1,
        limit: 15,
        providerId: this.outlierProvider,
      }
    );
    this.outlierPercent = count;
    this.fetchingOutliers = false;
    if (data && data.length) {
      this.outlierProviderName = await this.getProviderNameById(this.outlierProvider);
      this.outlierSuspectCount = Utils.getoutlierSuspectData(outlierGroups, totalMemberCount + totalOutlier);
      this.outlierShortCont = Utils.getoutlierShortContData(outlierGroups, totalMemberCount + totalOutlier);
      this.outlierNAVCount = Utils.getoutlierNavData(outlierGroups, totalMemberCount + totalOutlier);
      this.outliersDialog = true;
    } else {
      this.noDataAlert = true;
    }
  }

  // public async fetchHistory(row: any) {
  //   this.memberHistoryRow = row;
  //   this.fetchingMemberHistory = true;
  //   await this.$store.dispatch(AT.FETCH_MEMBER_HISTORY, row.id);
  //   this.fetchingMemberHistory = false;
  //   this.historyDialog = true;
  // }

  public get sampleMemberAnalysis(): any | null {
    return this.$store.getters.sampleMemberAnalysis && this.$store.getters.sampleMemberAnalysis;
  }

  public get memberAnalysis(): any | null {
    if (this.isBenchmarkUser) {
      return this.sampleMemberAnalysis && this.sampleMemberAnalysis.memberAnalysis.data;
    }
    return this.$store.getters.memberAnalysis && this.$store.getters.memberAnalysis.data;
  }

  public get paginateOptions(): any | null {
    if (this.isBenchmarkUser) {
      return { itemsPerPage: 15, page: 1, serverItemsLength: 15 };
    }
    const serverPagination = this.$store.getters.memberAnalysis && this.$store.getters.memberAnalysis.pagination;
    const itemsPerPage = this.pagination.limit || serverPagination.itemsPerPage || 15;
    const serverItemsLength = serverPagination && (serverPagination.serverItemsLength || itemsPerPage);
    const page = this.pagination.page || serverPagination.page || 1;
    return { itemsPerPage, page, serverItemsLength };
  }

  /**
   * Returns stats/metrics for the selected items ELSE
   * for the whole dataset (when no items are selected).
   */
  public get datasetMetrics() {
    const items =
      this.selectedItems && this.selectedItems.length
        ? this.selectedItems.map((id) => {
            return this.memberAnalysis && this.memberAnalysis.find((item: { id: any }) => item.id === id);
          })
        : this.memberAnalysis;

    if (!items || !items.length) {
      return {
        score: 0,
        irr: 0,
        birr: 0,
        potSize: 0,
      };
    }

    const totalStats = items.reduce(
      (collector: any, item: any) => {
        if (!item) {
          return collector;
        }

        collector.score += item.scores && +item.scores.score;
        collector.irr += +item.analysis.irr;
        collector.birr += +item.analysis.benchmarkIrr;
        collector.potSize += +item.analysis.navAmount;
        return collector;
      },
      {
        score: 0,
        irr: 0,
        birr: 0,
        potSize: 0,
      }
    );

    if (!this.filterRanges) {
      return {
        score: 0,
        irr: 0,
        birr: 0,
        potSize: 0,
      };
    }

    return {
      score: this.filterRanges.scoreRange && this.filterRanges.scoreRange.avg,
      irr: this.filterRanges.irrRange && this.filterRanges.irrRange.avg,
      birr: this.filterRanges.birrRange && this.filterRanges.birrRange.avg,
      potSize: this.filterRanges.potRange && this.filterRanges.potRange.total,
    };
  }

  public get metrics() {
    return {
      stat1_title: this.datasetMetrics.score,
      stat2_title: this.datasetMetrics.irr,
      stat3_title: this.datasetMetrics.birr,
      stat4_title: this.datasetMetrics.potSize,
      stat1_text: 'AgeWage Score',
      stat2_text: 'Average IRR',
      stat3_text: 'Average Benchmark IRR',
      stat4_text: 'Assets Analysed',
    };
  }

  public get columns(): DataTableHeader[] {
    return [
      {
        value: 'dataId',
        title: 'Saver ID',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'score',
        title: 'AW Score',
        type: TableColumnType.NUMBER,
        formatter: (n) => Utils.formatNumber(n, '0'),
        align: 'center',
      },
      {
        value: 'irr',
        title: 'IRR',
        type: TableColumnType.NUMBER,
        formatter: (n) => Utils.formatNumber(n, '0.00%'),
        align: 'center',
      },
      {
        value: 'birr',
        title: 'Benchmark IRR',
        type: TableColumnType.NUMBER,
        formatter: (n) => Utils.formatNumber(n, '0.00%'),
        align: 'center',
      },
      {
        value: 'potSize',
        title: 'Pot Size',
        type: TableColumnType.NUMBER,
        formatter: (n) => Utils.formatNumber(n, '$0.00a'),
        align: 'center',
      },
      {
        value: 'firstContributionDate',
        title: 'First Contribution Date',
        type: TableColumnType.DATE,
        formatter: (v: any) => Utils.formatDate(new Date(v), 'MMM dd, yyyy'),
        align: 'center',
      },
      {
        value: 'lastContributionDate',
        title: 'Last Contribution Date',
        type: TableColumnType.DATE,
        formatter: (v: any) => Utils.formatDate(new Date(v), 'MMM dd, yyyy'),
        align: 'center',
      },
    ];
  }

  public get tableData(): Table {
    return {
      columns: this.columns,
      rows: this.rows,
    };
  }

  public get rows(): TableRow[] {
    if (!this.memberAnalysis) {
      return [];
    }
    return this.memberAnalysis.map((member: any) => {
      return {
        value: false,
        id: +member.id,
        dataId: member.dataId,
        score: member.scores ? +member.scores.score || 0 : 0,
        irr: +member.analysis.irr,
        birr: +member.analysis.benchmarkIrr,
        potSize: +member.analysis.navAmount,
        firstContributionDate: member.firstContributionDate,
        lastContributionDate: member.lastContributionDate,
        action: `View History of member ${member.dataId}`,
      };
    });
  }

  public get outliersTableData(): Table {
    const rows = this.outlierData ? this.outlierData : [];
    return {
      columns: [
        {
          value: 'dataId',
          title: 'Saver ID',
          type: TableColumnType.STRING,
          align: 'center',
        },
        // {
        //   value: 'schemeName',
        //   title: 'Data Set',
        //   type: TableColumnType.STRING
        // },
        // {
        //   value: 'fundName',
        //   title: 'Fund',
        //   type: TableColumnType.STRING
        // },
        {
          value: 'contributionAmount',
          title: 'Total Contribution Amount',
          type: TableColumnType.NUMBER,
          formatter: (n) => Utils.formatNumber(n, '$0.00a'),
          align: 'center',
        },
        {
          value: 'navAmount',
          title: 'NAV Amount',
          type: TableColumnType.NUMBER,
          formatter: (n) => Utils.formatNumber(n, '$0.00a'),
          align: 'center',
        },
        {
          value: 'navDate',
          title: 'NAV Date',
          type: TableColumnType.DATE,
          formatter: (v: any) => Utils.formatDate(new Date(v), 'MMM dd, yyyy'),
          align: 'center',
        },
        {
          value: 'irr',
          title: 'IRR (%)',
          type: TableColumnType.NUMBER,
          align: 'center',
        },
        {
          value: 'benchmarkIrr',
          title: 'Benchmark IRR (%)',
          type: TableColumnType.NUMBER,
          align: 'center',
        },
        {
          value: 'reason',
          title: 'Error',
          type: TableColumnType.STRING,
          align: 'center',
        },
      ],
      rows,
    };
  }

  public get filtersAreApplied() {
    return !!(this.appliedFilters && Object.keys(this.appliedFilters).length);
  }

  public get sortingIsApplied() {
    return (
      Object.keys(this.appliedSorting || {}).some((k: string) => this.appliedSorting[k] !== this.defaultSorting[k]) ||
      false
    );
  }

  public onFiltersApplied(filters: any = {}) {
    this.appliedFilters = filters || {};
    this.filterIsVisible = false;
    this.$store.dispatch(AT.FETCH_MEMBER_FILTER_RANGES, {
      ...this.pagination,
      ...this.appliedFilters,
      ...this.appliedSorting,
    });
    this.fetchMembersAnalysis({ ...this.pagination, ...this.appliedFilters, ...this.appliedSorting });
  }

  public async exportCSV(exportAll: Boolean) {
    //PDF Export Polling with 2sec interval and 16sec Timout
    let job = { id: -1 };
    if (exportAll) {
      this.processingCSV = true;
      job = await this.$store.dispatch(AT.EXPORT_MEMBERS_ANALYSIS, {
        ...{ page: 1, limit: -1, providers: [this.memberCSVProviders] },
      });
    } else {
      this.processingCurrentCSV = true;
      job = await this.$store.dispatch(AT.EXPORT_MEMBERS_ANALYSIS, {
        ...this.pagination,
        ...this.appliedFilters,
        ...this.appliedSorting,
      });
    }

    this.getCSVURL(job);
  }

  async getCSVURL(job: any) {
    let pdfFetchInterval = setInterval(async () => {
      let response = await this.$store.dispatch(GET_EXPORT_JOB_BY_ID, job.id);
      if (response[0].status === 'completed') {
        const url = await this.$store.dispatch(GET_CSV_URL, job.id);
        await window.open(url);
        this.processingCSV = false;
        this.processingCurrentCSV = false;
        this.processingOutlier = false;
        clearInterval(pdfFetchInterval);
      }
      return response;
    }, Utils.pdfInterval);
    setTimeout(() => {
      clearInterval(pdfFetchInterval);
      if (this.processingCSV || this.processingCurrentCSV || this.processingOutlier) {
        this.pdfTimeoutError = true;
      }
      this.processingCSV = false;
      this.processingCurrentCSV = false;
      this.processingOutlier = false;
    }, Utils.pdfTimeout);
  }

  public async exportOutlierCSV() {
    this.processingOutlier = true;

    let job = { id: -1 };
    this.processingCSV = true;
    job = await this.$store.dispatch(AT.EXPORT_OUTLIER_ANALYSIS, this.outlierProvider);

    this.getCSVURL(job);
  }

  public async onPageChange(e: any) {
    this.pagination.page = e;
    this.fetchMembersAnalysis({ ...this.pagination, ...this.appliedFilters, ...this.appliedSorting });
  }

  public async onItemsPerPageChange(e: any) {
    if (this.pagination && this.pagination.limit === e) {
      return;
    }
    this.pagination.limit = e;
    this.fetchMembersAnalysis({ ...this.pagination, ...this.appliedFilters, ...this.appliedSorting });
  }

  public applySorting(sorting: any, isFromReset: Boolean) {
    this.appliedSorting = sorting;
    this.sortingIsVisible = false;
    if (!isFromReset) {
      this.isSortingApplied = true;
    }
    this.fetchMembersAnalysis({ ...this.pagination, ...this.appliedFilters, ...this.appliedSorting });
  }

  public resetSorting(sorting: any) {
    this.isSortingApplied = false;
    this.applySorting(sorting, true);
  }

  handleScroll() {
    if (this.$refs.filterPanel && this.$refs.filterPanel instanceof Element) {
      const topPosition = this.$refs.filterPanel.getBoundingClientRect().top;
      if (topPosition == 0) {
        this.scrolledAll = true;
      } else {
        this.scrolledAll = false;
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/sass/bootstrap.scss';

.page-filters-wrapper {
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  position: sticky !important;
}

.page-filter-main {
  min-width: 100% !important;
  max-width: 100% !important;
  left: unset !important;
}
.filter-menu_buttons {
  min-width: 250px !important;
}

.page-filters-panel {
  &.vue-fixed-header--isFixed {
    background: #fff;
    margin-top: 0 !important;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  }
}
.csv-download-btn {
  justify-content: left !important;
}

.filter-btn {
  position: relative;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  //min-width: 40px !important;
  min-height: 40px !important;

  &.is-active {
    color: #ffffff;
    background-color: desaturate($brand-accent, 5%) !important;
  }
}

.sorting-btn {
  position: relative;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  min-height: 40px !important;

  &.is-active {
    color: #ffffff;
    background-color: desaturate($brand-accent, 5%) !important;
  }
}
.outlier-btn {
  position: relative;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  min-height: 40px !important;
}
.csv-btn {
  position: relative;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  min-height: 40px !important;
}
.filter-icon {
  height: 1rem;
  width: 1rem;
}
.outlier-icon {
  height: 2rem;
  width: 2rem;
}
</style>
