import * as AT from '@/modules/providers-analysis/store/actionTypes';
import * as MT from '@/modules/providers-analysis/store/mutationTypes';
import { IRootState } from '@/store/state';
import { IProviderAnalysisState } from '@/modules/providers-analysis/store/state';
import { ActionContext, ActionTree } from 'vuex';
import { ProviderAnalysisService } from '@/modules/providers-analysis/services';

/**
 * --------------------------------------------------
 *  ProviderAnalysis Actions
 * --------------------------------------------------
 **/
export const ProviderAnalysisActions: IProviderAnalysisActions = {
  /**
   * --------------------------------------------------
   *  Fetch All the ProviderAnalysiss
   * --------------------------------------------------
   **/
  [AT.FETCH_ANALYSIS_BY_ID]: ({ commit, dispatch }: any, id: number) => {
    dispatch(AT.FETCHING_ANALYSIS, true);
    return ProviderAnalysisService.getAnalysisById(id)
      .then(analysis => {
        commit(MT.SET_ANALYSIS, analysis);
        return analysis;
      })
      .finally(() => dispatch(AT.FETCHING_ANALYSIS, false));
  },
  [AT.FETCH_CHARTDATA]: ({ commit, dispatch }: any, data: any) => {
    dispatch(AT.FETCHING_CHARTDATA, true);
    commit(MT.SET_CHARTDATA, {});
    return ProviderAnalysisService.getChartData({ providerId: data.providerId }, data)
      .then((chartdata: any) => {
        commit(MT.SET_CHARTDATA, chartdata);
        return chartdata;
      })
      .finally(() => dispatch(AT.FETCHING_CHARTDATA, false));
  },
  [AT.GENERATE_PDF]: ({ commit, dispatch }: any, id: number) => {
    dispatch(AT.GENERATING_PDF, true);
    return ProviderAnalysisService.generatePdf(id);
  },
  [AT.GENERATING_PDF]: ({ commit }: any, value: boolean) => commit(MT.SET_GENERATING_PDF, value),
  [AT.PDF_LINK]: ({ commit }: any, url: string) => commit(MT.SET_PDF_LINK, url),
  [AT.FETCHING_ANALYSIS]: ({ commit }: any, value: boolean) => commit(MT.SET_FETCHING_ANALYSIS, value),
  [AT.FETCHING_CHARTDATA]: ({ commit }: any, value: boolean) => commit(MT.SET_FETCHING_CHARTDATA, value)
};

/**
 * --------------------------------------------------
 *  ProviderAnalysis Action Context Interface
 * --------------------------------------------------
 **/
export interface IProviderAnalysisActionContext extends ActionContext<IProviderAnalysisState, IRootState> {}

/**
 * --------------------------------------------------
 *  ProviderAnalysis Actions Interface
 * --------------------------------------------------
 **/
export interface IProviderAnalysisActions extends ActionTree<IProviderAnalysisState, IRootState> {}
