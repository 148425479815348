<template>
  <v-navigation-drawer :model-value="isVisible" data-testid="Nav-Drawer" app clipped floating class="drawer dashboard-drawer">
    <v-list data-testid="nav-list" variant="plain">
      <div class="logo">
        <v-img src="@/assets/AgeWage-Icon.svg" class="logo-icon"></v-img>
        <v-img src="@/assets/AgeWage.svg" class="logo-text"></v-img>
      </div>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :ripple="false"
        link
        :to="item.route"
        class="my-3 nav-list-items"
      >
        <div class="curve-div"></div>
        <template v-slot:prepend>
          <div v-if="item.img" class="key-stat-illustration">
            <img class="key-stat-illustration__image" :src="item.img" />
          </div>
          <v-icon v-if="item.icon" small>{{ item.icon }}</v-icon>
        </template>

        <v-list-item-title class="nav-item-text">{{ item.title }}</v-list-item-title>

        <div class="curve-div-bottom"></div>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { LOG_USER_OUT } from '@/modules/auth/store/actionTypes';
import User from '@/models/User';
import Provider from '@/models/Provider';

export default class DashboardDrawer extends Vue {
  public get isVisible() {
    return this.$store.getters.navDrawerIsVisible;
  }

  public get isSuperUser() {
    return this.providers && this.user && this.user.role === 'superuser';
  }

  public get isBenchmarkUser() {
    return this.providers && this.user && this.user.role === 'bm_user';
  }

  public get isPilotUser() {
    return this.providers && this.user && this.user.role === 'pilot';
  }

  public get isVFMUser() {
    return this.providers && this.user && this.user.role === 'vfm_user';
  }

  public get providers(): Provider[] {
    return this.$store.getters.providers;
  }

  public get user(): User {
    return this.$store.getters.currentUser;
  }

  public get items(): any {
    const itemsArr: object[] = [];

    if (this.isBenchmarkUser) {
      itemsArr.push({
        title: 'Benchmarking',
        icon: 'fa fa-chart-pie',
        route: { name: 'benchmarking.reports.gen' },
      });
      itemsArr.push({
        title: 'Sample Report',
        icon: 'fa fa-chart-pie',
        route: { name: 'sample.reports.gen' },
      });
      itemsArr.push({
        title: 'Savers',
        icon: 'fa fa-user-friends',
        route: { name: 'dashboard.members.list' },
      });
    } else if (this.isVFMUser) {
      itemsArr.push({
        title: 'Savers',
        icon: 'fa fa-user-friends',
        route: { name: 'dashboard.members.list' },
      });
      itemsArr.push({
        title: 'Reports',
        icon: 'fa fa-chart-pie',
        route: { name: 'dashboard.reports.gen' },
      });
      itemsArr.push({
        title: 'Benchmarking',
        icon: 'fa fa-chart-pie',
        route: { name: 'benchmarking.reports.gen' },
      });
    } else if (this.isPilotUser) {
      itemsArr.push({
        title: 'Savers',
        icon: 'fa fa-user-friends',
        route: { name: 'dashboard.members.list' },
      });
      itemsArr.push({
        title: 'Reports',
        icon: 'fa fa-chart-pie',
        route: { name: 'dashboard.reports.gen' },
      });
    } else if (this.isSuperUser) {
      itemsArr.push({
        title: 'Savers',
        icon: 'fa fa-user-friends',
        route: { name: 'dashboard.members.list' },
      });
      itemsArr.push({
        title: 'Reports',
        icon: 'fa fa-chart-pie',
        route: { name: 'dashboard.reports.gen' },
      });
      itemsArr.push({
        title: 'Benchmarking',
        icon: 'fa fa-chart-pie',
        route: { name: 'benchmarking.reports.gen' },
      });
      itemsArr.push({
        title: 'Sample Report',
        icon: 'fa fa-chart-pie',
        route: { name: 'sample.reports.gen' },
      });
      itemsArr.push({
        title: 'Performance Attribution Reports',
        icon: 'fa fa-chart-pie',
        route: { name: 'dashboard.reports.pa' },
      });
      itemsArr.push({
        title: 'Providers',
        icon: 'fa fa-building',
        route: { name: 'dashboard.providers.list' },
      });
      itemsArr.push({
        title: 'Imports',
        icon: 'fa fa-upload',
        route: { name: 'dashboard.imports.list' },
      });
      itemsArr.push({
        title: 'Simulations',
        icon: 'fa fa-user-friends',
        route: { name: 'dashboard.simulations.list' },
      });
      itemsArr.push({
        title: 'B2C Providers',
        icon: 'fa fa-university',
        route: { name: 'dashboard.b2cpanel.providers' },
      });
      itemsArr.push({
        title: 'B2C Users',
        icon: 'fa fa-user-friends',
        route: { name: 'dashboard.b2cpanel.users' },
      });
      itemsArr.push({
        title: 'B2C Imports',
        icon: 'fa fa-upload',
        route: { name: 'dashboard.b2cpanel.imports' },
      });
      itemsArr.push({
        title: 'Price Tracks',
        icon: 'fa fa-upload',
        route: { name: 'dashboard.price-track.list' },
      });
      itemsArr.push({
        title: 'Price Track Reports',
        icon: 'fa fa-chart-pie',
        route: { name: 'dashboard.price-track.reports' },
      });
      itemsArr.push({
        title: 'Benchmark',
        icon: 'fa fa-upload',
        route: { name: 'dashboard.benchmark.list' },
      });
    }
    return itemsArr;
  }

  public logout() {
    this.$store.dispatch(LOG_USER_OUT);
    this.$router.push('/');
  }
}
</script>
<style lang="scss">
.dashboard-drawer {
  background: rgb(48, 0, 120) !important;
  font-weight: 600 !important;

  .logo {
    max-width: 150px;
    margin: 110px auto 90px auto;
    .logo-icon {
      max-width: 40px;
      margin: 3px auto;
    }
    .logo-text {
      max-width: 130px;
      margin: 0 auto;
    }
  }

  .v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
    background-color: rgba(255, 255, 255, 0);
  }

  .v-navigation-drawer__content {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      opacity: 0.5;
      background: #300078;
      background: linear-gradient(108deg, #401886 50%, #300078 50%, #300078 100%);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }
  .nav-icon-img {
    width: 2rem;
  }

  .nav-illustration {
    width: 2rem;
    margin: 0 auto;

    &__image {
      height: 100%;
      width: 100%;
    }
  }

  .nav-illustration__image {
    filter: invert(0%) sepia(81%) saturate(4301%) hue-rotate(54deg) brightness(94%) contrast(98%);
  }

  .v-list-item--variant-plain,
  .v-list-item__prepend > .v-icon {
    opacity: 1 !important;
  }

  .v-list-item__prepend {
    max-width: 25px;
    margin-right: 1rem !important;
  }

  .v-list-item--active {
    border-radius: 30px 0px 0px 30px !important;
    background: #f8f5fc;

    .v-icon,
    .v-list-item__content {
      color: #300078;
    }
  }

  .nav-list-items.v-list-item.v-list-item--link {
    padding-left: 2rem;
    margin-left: 1rem;
  }

  .nav-list-items.v-list-item:hover {
    border-radius: 30px 0px 0px 30px !important;
  }

  .v-list-item--active:hover::before,
  .v-list-item--active::before,
  .v-list-item:focus::before,
  .v-list-item:hover::before {
    opacity: 0 !important;
  }

  .v-icon {
    color: rgb(255, 255, 255);
  }

  .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #af93d9 !important;
  }

  .v-list-item[data-v-56cf8a48]:not(.v-list-item--active):not(.v-list-item--disabled):hover,
  .v-list-item[data-v-56cf8a48]:not(.v-list-item--active):not(.v-list-item--disabled):focus {
    border-radius: 30px 0px 0px 30px !important;
    background-color: #a78cd01a !important;
  }

  //Curve
  .nav-list-items.v-list-item--active .curve-div:after {
    top: -20px;
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    background: #300078;
    border-radius: 200px;
    left: -40px;
    position: absolute;
    z-index: -1;
  }

  .nav-list-items.v-list-item--active .curve-div {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 20px 20px;
    border-color: transparent transparent #f8f5fc transparent;
    content: '';
    position: absolute;
    top: -20px;
    right: 0;
    z-index: -1;
  }

  .nav-list-items.v-list-item--active .curve-div-bottom:after {
    bottom: -20px;
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    background: #300078;
    border-radius: 200px;
    left: -40px;
    position: absolute;
  }

  .nav-item-text {
    font-weight: 600 !important;
  }

  .nav-list-items.v-list-item--active .curve-div-bottom {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 0 20px;
    border-color: transparent transparent #f8f5fc transparent;
    content: '';
    background: #f8f5fc;
    position: absolute;
    bottom: -20px;
    right: 0;
  }
  .logout {
    position: absolute;
    bottom: 1.5rem;
    width: 100%;
    text-align: center;
    color: #fff;
    .logout-btn {
      color: #af93d9 !important;
      line-height: 1.2rem;
      font-size: 1rem !important;
    }
  }
}
</style>
