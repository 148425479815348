<template>
  <div class="elevation-10 chartWrapper">
    <div class="chart-title text-center pb-8">AgeWage Score- Single Contribution</div>
    <BaseChart :options="chartData" :scrollOptions="scrollOptions" />
  </div>
</template>

<script>
import BaseChart from './BaseChart.vue';
export default {
  components: {
    BaseChart
  },
   props: ['chartData'],
  data() {
    return {
      scrollOptions: {
        chartHeight: '25',
        unit: 'rem'
      }    
    };
  }
};
</script>
