<template>
  <div class="avbWrapper">
    <div class="elevation-1 chartWrapper avbCard">
      <div class="chart-title d-block text-center">Actual vs Benchmark IRR</div>
      <base-chart :options="chartOptions" :scroll-options="chartSize" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import BaseChart from '@/components/charts/BaseChart.vue';
import data from '@/packages/stubs/echarts/rangeArray';
import { graphic } from 'echarts';

@Options({
  components: {
    BaseChart,
  },
})
export default class AVBChart extends Vue {
  @Prop({
    default: () => ({}),
  })
  chartData!: any;

  @Prop({
    default: () => ({ primary: '#fe7598', secondary: '#fea1be', shadow: '#eee6f1' }),
  })
  plotterColor!: any;

  minCount: number = 0;
  maxCount: number = 0;

  created() {
    if (this.chartData && this.chartData instanceof Array) {
      this.chartData.forEach((data, index) => {
        if (index == 0) {
          this.minCount = this.maxCount = data[2];
        } else {
          if (data[2] < this.minCount) {
            this.minCount = data[2];
          }
          if (data[2] > this.maxCount) {
            this.maxCount = data[2];
          }
        }
      });
    }
  }

  getComputeSymbolSize() {
    return (data: any) =>
      this.maxCount > this.minCount ? (30 * (data[2] - this.minCount)) / (this.maxCount - this.minCount) + 10 : 40;
  }

  getComputedChartData() {
    return this.chartData.map((data: any) => {
      let secondary, primary;
      if (data[0] > data[1]) {
        secondary = '#92dac2';
        primary = '#4ac29a';
      } else {
        secondary = '#fea1be';
        primary = '#fe7598';
      }
      return {
        value: data,
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: secondary },
            { offset: 1, color: primary },
          ]),
        },
      };
    });
  }

  public chartSize: any = {
    chartWidth: 50,
    chartHeight: 30,
    unit: 'rem',
  };

  public get chartOptions() {
    return {
      tooltip: { show: false },
      xAxis: {
        name: 'Actual IRR',
        nameLocation: 'middle',
        nameTextStyle: { fontFamily: 'Open Sans', color: '#9cd4bd', padding: 20, fontWeight: 'bold' },
        axisLabel: {
          padding: [0, 0, 0, 0],
          formatter: '{value}%',
          fontWeight: '600',
          fontFamily: 'Open Sans',
          color: '#6f6c76',
          interval: false,
        },
        axisLine: { show: true, lineStyle: { color: '#ccc' } },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#eee',
          },
        },
      },
      yAxis: {
        name: 'Benchmark IRR',
        nameLocation: 'middle',
        nameRotate: 90,
        nameTextStyle: { fontFamily: 'Open Sans', color: '#a78cc0', padding: 30, fontWeight: 'bold' },
        axisLabel: {
          formatter: '{value}%',
          fontWeight: '600',
          fontFamily: 'Open Sans',
          color: '#6f6c76',
          interval: false,
        },
        axisLine: { show: true, lineStyle: { color: '#ccc' } },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#eee',
          },
        },
        scale: true,
      },
      series: [
        {
          data: this.getComputedChartData(),
          type: 'scatter',
          symbolSize: this.getComputeSymbolSize(),
          // prepare for every chart differently
          label: {
            emphasis: {
              show: true,
              formatter: (v: any) =>
                `Count: ${v.value[2]}
Avg. Actual IRR: ${v.value[0].toFixed(2)}
Avg. Benchmark IRR: ${v.value[1].toFixed(2)}`,
              position: 'top',
              align: 'left',
              backgroundColor: '#ede8ef',
              color: '#614d73',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              padding: 10,
              borderRadius: 4,
            },
          },
          itemStyle: {
            normal: {
              shadowBlur: 10,
              shadowColor: this.plotterColor.shadow,
              shadowOffsetY: 5,
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: this.plotterColor.secondary },
                { offset: 1, color: this.plotterColor.primary },
              ]),
            },
          },
        },
      ],
    };
  }
}
</script>
<style lang="scss">
.avbWrapper {
  padding: 1rem 0rem !important;
  .avbCard {
    padding-bottom: 3rem !important;
  }
}
</style>
