<template>
  <div>
    <v-overlay :z-index="2" :value="true">
      <v-progress-circular :size="60" :width="6" color="primary" indeterminate></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppLoader',
});
</script>
