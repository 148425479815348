import * as AT from '@/store/actionTypes';
import * as MT from '@/store/mutationTypes';
import { IRootState } from '@/store/state';
import { AppService } from '@/store/services';
import { AuthService } from '@/modules/auth/services';
import Auth from '@/packages/Auth';
import * as AuthAT from '@/modules/auth/store/actionTypes';
import * as AuthMT from '@/modules/auth/store/mutationTypes';
import { LoginResponseData } from '@/modules/auth/types';
import { ActionContext, ActionTree } from 'vuex';

/**
 * --------------------------------------------------
 *	Root Actions
 * --------------------------------------------------
 **/
export const RootActions: ActionTree<IRootState, IRootState> = {
  [AT.TOGGLE_THEME]: (context: IRootActionContext, status: boolean): void => context.commit(MT.SET_THEME, status),

  [AT.TOGGLE_DATA_UPLOAD_DIALOG]: (context: IRootActionContext, status: boolean): void => {
    context.commit(MT.SET_DATA_UPLOAD_DIALOG_VISIBILITY, status);
  },
  [AT.TOGGLE_NAV_DRAWER]: (context: IRootActionContext): void => {
    context.commit(MT.SET_NAV_DRAWER_VISIBILITY);
  },
  [AT.TOGGLE_FILTER_TABLE]: (context: IRootActionContext, status: boolean): void => {
    context.commit(MT.SET_FILTER_VISIBILITY, status);
  },
  [AT.SET_TABLE_SELECTED]: (context: IRootActionContext, values: number[]) => {
    context.commit(MT.SET_TABLE_SELECTED, values);
  },

  [AT.SET_NAV_HEADER]: (context: IRootActionContext, value: string) => {
    context.commit(MT.SET_NAV_HEADER, value);
  },
  [AT.CHANGE_PASSWORD]: async ({ commit, dispatch }, data: any) => {
    // AppService.changePassWord(data);

    commit(AuthMT.SET_LOGGING_IN, true);
    commit(AuthMT.SET_LOGIN_ERROR, false);
    // Try to Log the user in
    return AppService.changePassWord(data)
      .then((res: LoginResponseData) => {
        // Set the user
        if(res.user) {
          Auth.setUser(res.user);
          // Set the token
          Auth.setToken(res.token);
          dispatch(AuthAT.LOGIN_USER_SUCCESS, res.user);
          dispatch(AuthAT.SESSION_TIME_OUT, false);
        }
        return res;
      })
      .catch(error => dispatch(AuthAT.LOGIN_USER_ERROR, error))
      .finally(() => commit(AuthMT.SET_LOGGING_IN, false));
  }
};
/**
 * --------------------------------------------------
 *	Root Action Context Interface
 * --------------------------------------------------
 **/
export interface IRootActionContext extends ActionContext<IRootState, IRootState> {}
/**
 * --------------------------------------------------
 *	Root Action (Tree) Interface
 * --------------------------------------------------
 **/
export interface IRootActions extends ActionTree<IRootState, IRootState> {
  [AT.TOGGLE_THEME](context: IRootActionContext, status: boolean): void;
  [AT.TOGGLE_DATA_UPLOAD_DIALOG](context: IRootActionContext): void;
  [AT.TOGGLE_NAV_DRAWER](context: IRootActionContext): void;
}
