<template>
  <div class="app-table" :class="{ 'app-table--striped': striped }">
    <!-- {{pagination}} -->
    <v-data-table-server
      :items="tableItems"
      :headers="tableData.columns"
      :hide-default-footer="hideActions"
      v-model="selectAll"
      :show-select="false"
      :sort-by="defaultSortColumn"
      :disable-sort="disableSort || loading"
      :disable-filtering="loading"
      :custom-sort="tableSort"
      :itemsLength="pagination.serverItemsLength || ''"
      :items-per-page-options="[
        { value: 15, title: '15' },
        { value: 25, title: '25' },
        { value: 50, title: '50' },
        { value: 100, title: '100' },
      ]"
      items-per-page-text="Rows per Page:"
      :items-per-page="pagination.itemsPerPage"
      class="app-table__data-table"
      @update:page="(e) => $emit('page', e)"
      @pagination="(e) => $emit('pagination', e)"
      @update:options="(e) => $emit('update:options', e)"
      @update:items-per-page="(e) => $emit('itemsPerPage', e)"
      @update:sort-by="(e) => handleSortBy(e)"
      @update:sort-desc="(e) => handleSortDesc(e)"
    >
      <template #item="{ item, columns }">
        <tr>
          <td
            v-for="column in columns"
            :key="column.key"
            :class="generateClasses(column, item)"
            :title="getTooltipValue(column, item) || getCellDisplayValue(column, item)"
          >
            <!-- if slot is not present in the column -->
            <template
              v-if="column.value !== 'data-table-select' && column.value !== 'view' && column.value !== 'remove'"
              >{{ getCellDisplayValue(column, item) }}</template
            >
            <v-btn
              v-if="column.value === 'view'"
              class="mx-2"
              color="success"
              size="small"
              variant="text"
              @click="onViewClick(item.id)"
            >
              View
            </v-btn>
            <v-btn
              v-if="column.value === 'remove'"
              class="mx-2"
              color="error"
              size="small"
              variant="text"
              @click="onRemoveClick(item.id)"
            >
              Remove
            </v-btn>
          </td>
        </tr>
      </template>
      <template #bottom v-if="disablePagination"></template>
    </v-data-table-server>
    <div class="app-table__loader" v-show="loading">
      <v-progress-circular indeterminate color="secondary" size="64" />
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Prop, Watch } from 'vue-property-decorator';
import { Table, TableColumn, TableColumnType, TableRow } from '@/types';
import { SET_TABLE_SELECTED } from '../store/actionTypes';

export default class AppTable extends Vue {
  @Prop() tableData!: Table;

  @Prop({
    default: false,
  })
  striped!: boolean;

  @Prop({
    default: false,
  })
  disableSort!: boolean;

  @Prop({
    default: () => [],
  })
  defaultSortColumn!: any;

  @Prop({
    default: () => ({
      page: 1,
      itemsPerPage: 15,
    }),
  })
  pagination: any;

  @Prop({ default: false })
  isDescending!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: false })
  showSelect!: boolean;

  @Prop({ default: false })
  disablePagination!: boolean;

  @Prop({ default: true })
  hideActions!: boolean;

  @Prop({ default: '-' })
  emptyValue!: string;

  public selected: any[] = [];

  public paginateOpt = {
    page: 1,
    itemsPerPage: 15,
  };

  public selectAll: any[] = [];

  public sortedBy: string[] | null = null;

  public sortDescending: boolean[] | undefined = [false];

  @Watch('selectAll')
  async onselectAllChanged(value: string[], oldValue: string[]) {
    if (this.selectAll.length > 0) {
      await this.fetchAllIds();
    } else {
      this.selected = [];
    }
    await this.updateSelected();
  }

  created() {
    this.sortedBy = null;
  }

  public get tableItems() {
    if (this.sortDescending !== undefined && this.sortDescending !== null) {
      return this.tableSort(this.tableData.rows, this.sortedBy, this.sortDescending);
    }

    return this.tableData.rows;
  }

  public get selectedItems() {
    return this.$store.getters.selectedItems;
  }

  public get screenWidth(): number {
    return this.$vuetify.display.width;
  }
  public fetchAllIds() {
    if (this.selectAll.length > 0) {
      this.selected = [];
      this.selectAll.forEach((item: any) => {
        this.selected.push(item.id);
      });
    }
    return this.selected;
  }

  public onViewClick(item: any) {
    this.$emit('viewItemClick', item);
  }
  public onRemoveClick(item: any) {
    this.$emit('removeItemClick', item);
  }

  public updateSelected(id?: number, state?: boolean) {
    if (state) {
      this.selected.push(id);
    } else {
      this.selected = this.selected.filter((item) => item !== id);
    }
    return this.$store.dispatch(SET_TABLE_SELECTED, this.selected);
  }

  public generateClasses(column: any, row: any) {
    const classValues: any = {};
    const cell = row[column.value];

    if (column['align']) {
      const align = column['align'];
      classValues[`text-xs-${align}`] = true;
    }

    // Apply a width restricting class if cell has a "width: fixed" property
    if (column['width'] && column['width'] === 'fixed') {
      // Keep width limited to 10vw if screen size is between 1264px and 1430px
      if (this.screenWidth > 1264 && this.screenWidth < 1430) classValues['restrict-width-10vw'] = true;
      // Keep widths to 12vw if screen size is between 1430px and 1600px
      else if (this.screenWidth >= 1430 && this.screenWidth < 1600) classValues['restrict-width-12vw'] = true;
      // Keep width to 15vw over 1600px and let screen overflow freely below 1264px
      else if (this.screenWidth > 1600) classValues['restrict-width-15vw'] = true;
    }

    // Apply { text-transform: none } if column passses a { tranform: 'none' } property or column type is 'email'
    if (column['textTransform'] === 'none' || column['type'] === TableColumnType.EMAIL) {
      classValues['text-none'] = true;
    }

    if (column && column['class'] && typeof column['class'] === 'function') {
      const cellValue = this.getCellValue(column, row);
      const columnClass = column['class'].call(this, cellValue, cell);
      classValues[`${columnClass}`] = true;
    }

    if (row && row['class']) {
      classValues[`${row['class']}`] = true;
    }

    if (!cell) {
      return '-';
    }

    if (typeof cell === 'object' && cell['class']) {
      let cellClass = cell['class'];

      if (typeof cellClass === 'function') {
        const cellValue = this.getCellValue(column, row);
        cellClass = cellClass.call(this, cellValue, cell);
      }

      classValues[`${cellClass}`] = true;
    }
    return classValues;
  }

  public generateCellKey(column: any, item: any) {
    return `${column.value}_${item.id}`;
  }

  public getCellValue(column: any, row: any, formatted?: any) {
    let cell = row[column.value];
    if (!cell && cell !== 0) {
      if (column.defaultValue !== undefined) {
        return column.defaultValue;
      }
      return this.emptyValue;
    }

    let cellValue = cell;

    // When cell does not have primitive value

    if (!this.cellHasPrimitiveValue(cell)) {
      // When cell is an object whose cell valu eis undefined or null

      if (cell.value === undefined || cell.value === null) {
        cell = { value: '' };
      }

      cellValue = cell.value;
    }

    if (!formatted) {
      return cellValue;
    }

    if (cell.displayValue) {
      return cell.displayValue;
    }

    if (column.formatter) {
      return column.formatter(cellValue, column, row, this.tableData);
    }

    return cellValue;
  }

  public getCellDisplayValue(column: any, row: any) {
    return this.getCellValue(column, row, true);
  }

  public getTooltipValue(column: any, item: any) {
    if (column.value === 'data-table-select') {
      return item.dataId;
    }

    return this.cellHasPrimitiveValue(item)
      ? item
      : item.tooltip || this.getCellDisplayValue(column, item) || column.title;
  }

  public cellHasPrimitiveValue(cell: any) {
    const primitives = ['string', 'number', 'boolean'];
    return primitives.includes(typeof cell);
  }

  public tableSort(rows: any[], columns: string[] | null = null, isDesc: boolean[] = [false]) {
    const column = columns && columns.length ? columns[0] : null;
    const isDescending = isDesc && isDesc.length ? isDesc[0] : false;

    if (!column) {
      return rows;
    }

    const sorter = (row: any, nextRow: any) => {
      let result = 0;
      let cell = row[column];
      let nextCell = nextRow[column];

      if (column === TableColumnType.ACTIONS) {
        cell = cell.title;
        nextCell = nextCell.title;
      }

      // When cell does not have primitive value
      if (!this.cellHasPrimitiveValue(cell)) {
        // When cell is an object whose cell valu eis undefined or null
        if (cell === undefined || cell === null) {
          cell = { value: '' };
        }

        if (nextCell == undefined || nextCell == null) {
          nextCell = { value: '' };
        }

        cell = cell.value;
        nextCell = nextCell.value;
      }

      if (typeof cell === 'number') {
        // if type of cell is number and nextCell type is also a string
        // in else  result =-1, if nextCell is string (considering in case of cell=number/boolean and nextCell="-")
        if (typeof nextCell === 'number') {
          result = cell - nextCell;
          if (isDescending) {
            result = nextCell - cell;
          }
        } else {
          result = -1;
        }
      }

      if (typeof cell === 'boolean') {
        result = cell && !nextCell ? -1 : 1;

        if (isDescending) {
          result = !cell && nextCell ? 1 : -1;
        }
      }

      if (typeof cell === 'string') {
        // if type of cell is string and nextCell type is also string
        // in else result = 1, if nextCell is number (considering in case of cell = "-" and nextCell = number/boolean )
        if (typeof nextCell === 'string') {
          let a = cell.toUpperCase();
          let b = nextCell.toUpperCase();

          if (isDescending) {
            const temp = a;
            a = b;
            b = temp;
          }

          if (a < b) {
            result = -1;
          }
          if (a > b) {
            result = 1;
          }
          if (a == b) {
            result = 0;
          }
        } else {
          result = 1;
        }
      }
      return result;
    };
    const _rows = rows.slice();
    _rows.sort(sorter);
    return _rows;
  }

  public handleSortBy(columns: string[]) {
    this.sortedBy = columns;
    this.$emit('update:sort-by', this.sortedBy);
  }

  public handleSortDesc(e: boolean[]) {
    this.sortDescending = e;
    this.$emit('update:sort-desc', this.sortDescending);
  }
}
</script>

<style lang="scss">
.app-table {
  width: 100%;
  position: relative;

  &--fat-font {
    font-weight: bold;
    font-size: 0.8rem;
  }
  .v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
  }
  .app-table__data-table.v-data-table {
    height: 100%;
    color: #555;

    .v-table__overflow {
      height: 100%;
      overflow: auto;
    }

    th,
    td {
      text-transform: capitalize;
      text-align: center;
      // text-overflow: ellipsis;
      word-break: break-word;
    }

    tr {
      border: none;

      &:not(:last-child) {
        border-bottom: none !important;
      }
    }

    thead {
      tr {
        height: auto;
      }

      th {
        padding: 1.5rem 0rem;

        color: rgba(35, 41, 47, 0.87);
        overflow: hidden;
        font-weight: bold;
        font-size: 1rem;
        text-align: center !important;

        &.sortable {
          padding-left: 0.6rem !important;
          i {
            margin-left: 0.5rem;
            vertical-align: middle;
          }
        }

        & > span {
          max-width: 120px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    tbody {
      .checkbox-action {
        margin: auto;
        margin-left: 1.25rem;
        text-align: center;
        align-items: center;
        padding: 0 2rem !important;
        // padding-right: 1rem;
      }
      .v-data-table-header__icon {
        margin: 0 !important;
      }
      td {
        height: auto;
        padding: 1.5rem 0rem;
        font-weight: 400;
        overflow: hidden;
      }

      .restrict-width-15vw {
        max-width: 15vw;
      }

      .restrict-width-12vw {
        max-width: 12vw;
      }

      .restrict-width-10vw {
        max-width: 10vw;
      }

      tr {
        background: #ffffff;

        &:hover {
          background-color: #f6f6fc;
        }
      }
    }
  }

  &--striped {
    .app-table__data-table.v-data-table {
      tbody {
        tr:nth-child(odd) {
          background-color: #f6f6fc;

          &:hover {
            background-color: darken(#f6f6fc, 3%);
          }
        }

        tr:nth-child(even) {
          background-color: #ffffff;

          &:hover {
            background-color: darken(#f6f6fc, 2%);
          }
        }
      }
    }
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.75);
  }
  .v-data-table-footer__pagination {
    display: none;
  }
  .v-data-table-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding: 8px;
    font-size: 12px;
    margin-right: 4rem;
  }
}
</style>
