import Provider from '@/models/Provider';

export interface IProviderState {
  currentProvider: Provider | null;
  providers: Provider[] | null;
  allProviders: Provider[] | null;
  fetchingProvider: boolean;
  fetchingProviders: boolean;
  providerWithPA: Provider[] | null;
  providerDataQuality: any;
  fetchingDataQuality: boolean;
  masterProviders: Provider[] | null;
  fetchingMasterProviders: boolean;
}

/**
 * --------------------------------------------------
 *	Provider State
 * --------------------------------------------------
 **/

export const ProviderState: IProviderState = {
  currentProvider: null,
  providers: null,
  allProviders: null,
  fetchingProvider: false,
  fetchingProviders: false,
  providerWithPA: null,
  providerDataQuality: {},
  fetchingDataQuality: false,
  masterProviders: null,
  fetchingMasterProviders: false
};
