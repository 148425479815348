<template>
  <div data-testid="list-price-track-page" class="page list-price-track-page">
    <v-container fluid>
      <v-layout class="mt-4 pa-0">
        <div style="align-self: center">
          <h2 class="section-title">
            <span>ALL Price Track Uploads</span>
          </h2>
        </div>
        <v-layout align-end class="py-2 justify-end">
          <v-btn @click="$router.push({ name: 'dashboard.price-track.add' })">
            <span>Add Price Track</span>
          </v-btn>
        </v-layout>
      </v-layout>
      <v-card v-if="priceTracks" class="mt-4">
        <AppTable
          :table-data="tableData"
          v-if="priceTracks && priceTracks.length"
          v-on:viewItemClick="(e) => getPriceTrackDetails(e)"
          :disablePagination="true"
        />
      </v-card>
      <div v-if="!priceTracks" class="d-flex align-center justify-center pt-5 mt-5">
        <v-progress-circular indeterminate color="grey" />
      </div>
    </v-container>
    <v-dialog v-model="showCurrentPriceTrack" width="50%">
      <v-card v-if="currentPriceTrack.id" class="pa-2">
        <v-card-title>
          {{ currentPriceTrack.id }}. {{ currentPriceTrack.name }}<br />{{
            currentPriceTrack.result && currentPriceTrack.result.stDev
              ? `Standard Deviation is '${currentPriceTrack.result.stDev} %'`
              : 'Still processing'
          }}
        </v-card-title>
        <v-card-text>
          <div v-for="(value, prop) in currentPriceTrack" v-bind:key="prop">{{ prop }} : {{ value }}</div>
        </v-card-text>
      </v-card>
      <v-card v-else> LOADING... </v-card>
    </v-dialog>
    <AppLoader v-if="fetchingPriceTracks" />
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Watch } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppTable from '@/components/AppTable.vue';
import { Table, DataTableHeader, TableRow, TableColumnType } from '@/types';
import Fund from '@/models/Fund';
import { HTTPServiceClient } from '@/packages/http/service';
import { FETCH_PRICE_TRACKS, FETCH_PRICE_TRACK } from '@/modules/price-track/store/actionTypes';
import { SET_NAV_HEADER } from '@/store/actionTypes';
import AppLoader from '@/components/AppLoder.vue';

@Options({
  components: {
    AppTable,
    AppLoader,
  },
})
export default class PriceTrackList extends Vue {
  public pagination = {
    page: 1,
    limit: 15,
  };

  public showCurrentPriceTrack = false;
  public fetchingPriceTracks = false;

  created() {
    this.bootstrap();
  }

  public bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Price Tracks', tooltip: 'All Price Tracks' });
    this.fetchPriceTracks(this.pagination);
  }

  get priceTracks() {
    return this.$store.getters.priceTracks;
  }

  get currentPriceTrack() {
    return this.$store.getters.currentPriceTrack;
  }

  public get tableData(): Table {
    const columns: DataTableHeader[] = [
      {
        value: 'id',
        title: 'ID',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'name',
        title: 'Name',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'status',
        title: 'Current Status',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'view',
        title: 'View Details',
        type: TableColumnType.ACTIONS,
        align: 'center',
      },
    ];
    return {
      columns,
      rows: this.rows,
    };
  }

  public get rows(): TableRow[] {
    return (
      this.priceTracks &&
      this.priceTracks.map((priceTrack: any, it: number) => {
        return {
          id: priceTrack.id,
          status: priceTrack.status,
          name: priceTrack.name,
        };
      })
    );
  }

  public fetchPriceTracks(data: any = {}) {
    this.$store.dispatch(FETCH_PRICE_TRACKS, data);
  }
  public async getPriceTrackDetails(id: String) {
    this.fetchingPriceTracks = true;
    await this.$store.dispatch(FETCH_PRICE_TRACK, id);
    this.showCurrentPriceTrack = true;
    this.fetchingPriceTracks = false;
  }
}
</script>
