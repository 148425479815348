<template>
  <div data-testid="DashboardModule" class="page dashboard-page pb-7" v-if="moduleIsReady">
    <DashboardDrawer />
    <v-main class="content-view">
      <v-container :fluid="navDrawerIsVisible">
        <DashboardToolbar :user="user" :show-upload-button="isAdmin" />
        <div class="px-4">
          <router-view />
        </div>
      </v-container>
    </v-main>
    <DataUploadDialog />
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import DashboardToolbar from '@/modules/dashboard/components/DashboardToolbar.vue';
import DataUploadDialog from '@/modules/dashboard/components/DataUploadDialog.vue';
import DashboardDrawer from '@/modules/dashboard/components/DashboardDrawer.vue';
import { FETCH_CURRENT_USER } from '@/modules/auth/store/actionTypes';
import User from '@/models/User';
import { FETCH_PROVIDERS, FETCH_PROVIDERS_PA } from '../providers/store/actionTypes';
import Provider from '../../models/Provider';

@Options({
  components: {
    DashboardToolbar,
    DataUploadDialog,
    DashboardDrawer,
  },
})
export default class Dashboard extends Vue {
  async created() {
    await this.bootstrap();
  }

  public async bootstrap() {
    this.$store.dispatch(FETCH_PROVIDERS);
    this.$store.dispatch(FETCH_PROVIDERS_PA);
    this.$store.dispatch(FETCH_CURRENT_USER);
  }

  public get moduleIsReady() {
    return this.user && this.providers && this.providersWithPA;
  }

  public get user(): User {
    return this.$store.getters.currentUser;
  }

  public get isAdmin() {
    return this.providers && this.user && this.user.role === 'superuser';
  }

  public get providers(): Provider[] {
    return this.$store.getters.providers;
  }

  public get providersWithPA() {
    return this.$store.getters.providerWithPA;
  }

  public get navDrawerIsVisible() {
    return this.$store.getters.navDrawerIsVisible;
  }
}
</script>
<style lang="scss">
@import '@/sass/bootstrap.scss';

.dashboard-page {
  background: map-get($material-light, 'background');

  .content-view {
    .section-title {
      font-size: 14px;
      color: rgba(14, 12, 35, 0.7);
    }
  }
}
</style>
