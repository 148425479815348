import { IRootState } from '@/store/state';
import { IBenchmarkState } from '@/modules/benchmarks/store/state';
import { GetterTree } from 'vuex';
import { BenchmarkObject, BenchmarkListItems } from '@/models/Benchmark';

/**
 * --------------------------------------------------
 *	Benchmark Getters
 * --------------------------------------------------
 **/
export const BenchmarkGetters: IBenchmarkGetters = {
  benchmarks: (state: IBenchmarkState) => state.benchmarks,
  addingBenchmark: (state: IBenchmarkState) => state.addingBenchmark,
  currentBenchmark: (state: IBenchmarkState) => state.currentBenchmark,
};

/**
 * --------------------------------------------------
 *	Benchmark Getters Interface
 * --------------------------------------------------
 **/
export interface IBenchmarkGetters extends GetterTree<IBenchmarkState, IRootState> {
  benchmarks(state: IBenchmarkState): BenchmarkListItems[];
  addingBenchmark(state: IBenchmarkState): boolean;
  currentBenchmark(state: IBenchmarkState): BenchmarkObject;
}
