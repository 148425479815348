<template>
  <div
    id="splash-page"
    class="splash"
  >
    <v-container fluid>
      <h1>AgeWage</h1>
      <div class="mt-3">
        <div class="text-xs-center">
          <div class="mb-5">
            <h1 class="mb-3 font-weight-light">
              Page not found :(
            </h1>
            <p>Looks like you have followed a broken link.</p>
          </div>
          <v-btn
            :to="{ name: 'index' }"
            exact
            text
            small
          >
            <v-icon left>
              fa-long-arrow-alt-left
            </v-icon>
            <span>Back to Homepage</span>
          </v-btn>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
export default class Page404 extends Vue {}
</script>
