import SampleReportsModule from './ReportsModule.vue';
import ReportGenPage from './pages/SampleReportGenPage.vue';

export const SampleReportRoutes = [
  {
    path: '/sample-report',
    component: SampleReportsModule,
    children: [
      {
        path: '/sample-report',
        name: 'sample.reports.gen',
        component: ReportGenPage
      }
    ]
  }
];
