<template>
  <div class="elevation-10 chartWrapper">
    <div class="chart-title text-center pb-8">IRR - Single Contribution</div>
    <BaseChart :options="chartOptions" :scrollOptions="scrollOptions" />
  </div>
</template>

<script>
import BaseChart from './BaseChart.vue';
export default {
  components: {
    BaseChart
  },
  props: ['chartData'],
  data() {
    return {
      scrollOptions: {
        chartHeight: '42',
        unit: 'rem'
      },
      chartOptions: this.editChartData()
    };
  },
  methods: {
    editChartData() {
      const chartOptions = this.chartData;
      if (chartOptions) {
        chartOptions.yAxis.axisLabel.formatter = v => {
          v = parseInt(v);
          return Math.floor(v / 12);
        };
        chartOptions.tooltip = {
          show: true,
          formatter: v => {
            return `Date: ${v.name} <br> Investment Months: ${v.value[1]} <br> IRR: ${v.value[2]}%`;
          }
        };
      }
      return chartOptions;
    }
  }
};
</script>
