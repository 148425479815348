import LoginPage from './LoginPage.vue';
import ResetPassword from './ResetPassword.vue';
import AddNewPassword from './AddNewPassword.vue';

export const AuthRoutes = [
  {
    path: '',
    name: 'index',
    redirect: { name: 'auth.login' },
    meta: {
      middlewares: {
        requiresGuest: true,
      },
    },
  },
  {
    path: '/login',
    name: 'auth.login',
    component: LoginPage,
    meta: {
      middlewares: {
        requiresGuest: true,
      },
    },
  },
  {
    path: '/resetpass',
    name: 'auth.resetpass',
    component: ResetPassword,
    meta: {
      middlewares: {
        requiresGuest: true,
      },
    },
  },
  {
    path: '/addnewpass',
    name: 'auth.addnewpass',
    component: AddNewPassword,
    meta: {
      middlewares: {
        requiresGuest: true,
      },
    },
  },
];
