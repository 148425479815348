import Dashboard from './Dashboard.vue';

// Dashboard Routes
import { UserRoutes } from '@/modules/users/routes';
import { ProviderRoutes } from '@/modules/providers/routes';
// import { SchemeRoutes } from '@/modules/schemes/routes';
import { MemberRoutes } from '@/modules/members/routes';
// import { FundRoutes } from '@/modules/funds/routes';
import { ReportRoutes } from '@/modules/reports/routes';
import { B2CPanelRoutes } from '@/modules/b2c-panel/routes';
import { SimulationRoutes } from '@/modules/simulations/routes';
import { ImportRoutes } from '@/modules/imports/routes';
import { BenchmarkingRoutes } from '@/modules/benchmarking/routes';
import { SampleReportRoutes } from '@/modules/sample-report/routes';
import { PriceTrackRoutes } from '@/modules/price-track/routes';
import { BenchmarkRoutes } from '@/modules/benchmarks/routes';
import Auth from '@/packages/Auth';

const userObject:any = Auth.getUser();

export const DashboardRoutes = [
  {
    path: '/dashboard',
    component: Dashboard,
    meta: {
      middlewares: {
        requiresAuth: true
      }
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        redirect: Auth.isAuthenticated() && userObject.role === 'bm_user' ?
          { name: 'benchmarking.reports.gen' } : { name: 'dashboard.members.list' }
      },
      ...UserRoutes,
      ...ProviderRoutes,
      // ...SchemeRoutes,
      ...MemberRoutes,
      // ...FundRoutes,
      ...ReportRoutes,
      ...B2CPanelRoutes,
      ...SimulationRoutes,
      ...ImportRoutes,
      ...BenchmarkingRoutes,
      ...SampleReportRoutes,
      ...PriceTrackRoutes,
      ...BenchmarkRoutes
    ]
  }
];
