import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_overlay, {
      "z-index": 2,
      value: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_progress_circular, {
          size: 60,
          width: 6,
          color: "primary",
          indeterminate: ""
        })
      ]),
      _: 1
    })
  ]))
}