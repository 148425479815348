import { RouteLocation, RouteRecord, RouteLocationNormalized } from 'vue-router';

export type IFilterItem = {
  id: number;
  name: string;
};

export type ISwitchItem = {
  name: string;
  title: string;
  icon: string;
};

export enum TableColumnType {
  EMAIL = 'email',
  BOOLEAN = 'boolean',
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  ACTIONS = 'actions'
}

export type DataTableCompareFunction<T = any> = (a: T, b: T) => number;

export type SelectItemKey = boolean | null | undefined | string | (string | number)[] | ((item: Record<string, any>, fallback?: any) => any);

export type DataTableHeader = {
  key?: 'data-table-group' | 'data-table-select' | 'data-table-expand' | (string & {});
  value?: SelectItemKey;
  title: string;
  colspan?: number;
  rowspan?: number;
  fixed?: boolean;
  type?: TableColumnType;
  align?: 'start' | 'end' | 'center';
  width?: number | string;
  minWidth?: string;
  maxWidth?: string;
  sortable?: boolean;
  sort?: DataTableCompareFunction;
  formatter?: (v: any, col: TableColumn, row: TableRow, table: Table) => any;
  textTransform?: string;
};

export type TableColumn = {
  value: any;
  text: string;
  defaultValue?: any;
  formatter?: (v: any, col: TableColumn, row: TableRow, table: Table) => any;
  type?: TableColumnType;
  slot?: string;
  class?: string | Function;
  align?: 'left' | 'right' | 'center' | null;
  width?: string;
  textTransform?: string;
};

export type TableRow = {
  [key: string]: any;
};

export type Table = {
  columns: DataTableHeader[];
  rows: TableRow[];
};

export type DashboardDataWidgetPanelItems = {
  [key: string]: DashboardDataWidgetPanelItem;
};

// @TODO
export type DashboardDataWidgetPanelItem = {
  [key: string]: any;
};

export type InfoWidgetTitle = {
  main: string;
  sub: string;
};

export type InfoWidgetAction = {
  title: string;
  icon?: string;
  type?: string;
  flat?: boolean;
  small?: boolean;
  block?: boolean;
  primary?: boolean;
  outline?: boolean;
  classes?: any;
  route?: RouteLocation;
  click?: EventListener;
};

export type MenuItem = {
  slug: String;
  title: String;
  route?: RouteLocation;
  click?: EventListener;
  children?: MenuItem[];
};

export type RouteInfo = RouteLocationNormalized | RouteRecord | RouteLocation;

// To be used for all manually defined named items
export type GlobalNamedItem = {
  id: number | string;
  name: string;
  slug?: string;
  [key: string]: any;
};
