import B2CPanelModule from './B2CPanelModule.vue';
import ProvidersPage from './pages/ProvidersPage.vue';
import UsersPage from './pages/UsersPage.vue';
import EditProvider from './pages/EditProvider.vue';
import AddProvider from './pages/AddProvider.vue';
import EditUser from './pages/EditUser.vue';
import Imports from './pages/Imports.vue';

export const B2CPanelRoutes = [
  {
    path: '/b2cpanel/providers',
    component: B2CPanelModule,
    children: [
      {
        path: '',
        name: 'dashboard.b2cpanel.providers',
        component: ProvidersPage,
      },
    ],
  },
  {
    path: '/b2cpanel/provider/',
    component: B2CPanelModule,
    children: [
      {
        path: '/b2cpanel/provider/:id',
        name: 'dashboard.b2cpanel.providers.edit',
        component: EditProvider,
      },
      {
        path: '',
        name: 'dashboard.b2cpanel.providers.add',
        component: AddProvider,
      },
    ],
  },
  {
    path: '/b2cpanel/users/',
    component: B2CPanelModule,
    children: [
      {
        path: '',
        name: 'dashboard.b2cpanel.users',
        component: UsersPage,
      },
    ],
  },
  {
    path: '/b2cpanel/user/',
    component: B2CPanelModule,
    children: [
      {
        path: '/b2cpanel/user/:id',
        name: 'dashboard.b2cpanel.users.edit',
        component: EditUser,
      },
      {
        path: '',
        name: 'dashboard.b2cpanel.users.add',
        component: EditUser,
      },
    ],
  },
  {
    path: '/b2cpanel/imports',
    component: B2CPanelModule,
    children: [
      {
        path: '',
        name: 'dashboard.b2cpanel.imports',
        component: Imports,
      },
    ],
  },
];
