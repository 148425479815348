<template>
  <div class="report-template">
    <Watermark :isSampleReport="isSampleReport" />

    <v-card class="report-page-card" outlined flat>
      <div v-if="filters.ePotSize" class="section">
        <div class="section-title">
          <div class="section-badge"></div>
          Effect of Pot Size
        </div>
        <div class="section-content">
          The following graph highlights the effect of pot size on AgeWage scores. The pot size has been segmented as
          per
          <a href="https://www.fca.org.uk/data/retirement-income-market-data" class="text-primary" target="_blank"
            >FCA Retirement Income analysis</a
          >. Please note that the pot sizes are at different stages of life cycle and developed over different time
          frames. However, the AgeWage score represents a fair estimation of the effects of charges, fund returns and
          contribution frequency on the performance of the pots relative to the benchmark.
        </div>
      </div>

      <div v-if="!fetchingChartData && filters.ePotSize" class="report-chart">
        <PotEffectChart
          v-if="ePotData"
          :chartData="ePotData"
          :plotterColor="plotterColor"
          :provider="filters.provider"
          :priceTrack="filters.priceTrackObject"
          :isSampleReport="isSampleReport"
          :reportfilterRanges="reportfilterRanges"
        ></PotEffectChart>
      </div>

      <div class="footer">
        <div></div>
        <!-- <div class="page-box">
            <div class="pageno">Page 2/4</div>
            </div> -->
        <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { FETCH_MEMBERS, FETCH_CONTRIBUTION_HISTORY } from '@/modules/members/store/actionTypes';
import AIRRChart from '@/components/charts/AIRRChart.vue';
import PotEffectChart from '@/components/charts/PotEffectChart.vue';
import Watermark from './Watermark.vue';

@Options({
  components: {
    AIRRChart,
    PotEffectChart,
    Watermark,
  },
})
export default class IrrAndAvb extends Vue {
  @Prop()
  public chartData?: any;

  @Prop()
  public data?: any;

  @Prop()
  public reportfilterRanges?: any;

  @Prop({
    default: () => ({
      coverPage: true,
      awDist: true,
      yearSeg: true,
      Irr: true,
      avb: true,
      schemeSeg: true,
      outliers: true,
      startDate: null,
      endDate: null,
      provider: null,
    }),
  })
  filters?: any;

  @Prop() isSampleReport!: boolean;

  public barColor: any = {
    primary: '#a085bc',
    secondary: '#cbafd5',
  };

  public plotterColor: any = {
    primary: '#756287',
    secondary: '#cbafd5',
    shadow: '#eee6f1',
  };

  get moduleIsReady() {
    return true;
  }

  public get ePotData() {
    return this.chartData && this.chartData.ePot;
  }

  public get fetchingChartData() {
    return this.$store.getters.fetchingChartData;
  }
}
</script>
