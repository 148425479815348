import { RouteInfo } from '@/types';
import { addMonths, differenceInMinutes, isPast, isSameMonth, isSameYear, startOfMonth } from 'date-fns';
import _format from 'date-fns/format';
import _capitalize from 'lodash/capitalize';
import _merge from 'lodash/mergeWith';
import _startCase from 'lodash/startCase';
import _toLower from 'lodash/toLower';
import _union from 'lodash/union';
import numeral from 'numeral';
import 'numeral/locales/en-gb';
import randomColor from 'randomcolor';
import SLUGIFY_STR from 'slugify';
import { AppliedFilters, UnitType } from './types';
numeral.options.currentLocale = 'en-gb';

export const EPSILON = 0.000001;

export default class Utils {
  // convert date to local time zone of user browser
  static convertDateToLocaleFormat(date: any) {
    // TODO Use a smaller library like `spacetime`
    return date;
  }

  static pdfTimeout = 60000;
  static pdfInterval = 1000;

  static merge(a: any, b: any, customizer?: any) {
    if (!customizer) {
      customizer = (obj1: any, obj2: any) => {
        if (Array.isArray(obj1) && Array.isArray(obj2)) {
          if (!Array.isArray(obj1[0]) && !Array.isArray(obj2[0])) {
            return obj2.map((o: any, i: number) => Utils.merge(obj1[i] || {}, o));
          }
          return _union(obj1, obj2);
        }
      };
    }

    return _merge({}, a, b, customizer);
  }

  static matchRoute(a: RouteInfo, b: RouteInfo): boolean {
    const pathMatch = a.path == b.path;
    const routeMatch = a.name == b.name;
    return pathMatch || routeMatch;
  }

  static reportingPeriod(date: any, timePeriod?: any) {
    let startOfCurrentMonth = startOfMonth(new Date());

    let endDate = null;
    if (timePeriod) {
      let end = timePeriod.end;
      endDate = isSameYear(startOfCurrentMonth, end) ? end : addMonths(end, +12);
    }

    date = !date ? new Date() : new Date(date);

    let endPeriod = addMonths(date, -12);

    if (!isSameMonth(startOfCurrentMonth, date) && isPast(date) && isSameYear(startOfCurrentMonth, date)) {
      endPeriod = date;
    }

    let startPeriod = addMonths(endPeriod, -11);

    return {
      startPeriod,
      endPeriod,
      endDate
    };
  }

  static dateFromFormat(input: string, format = 'dd/mm/yyyy') {
    let i = 0;
    const fmt: any = {};
    const parts: any = input.match(/(\d+)/g) || {};
    format.replace(/(yyyy|dd|mm)/g, part => (fmt[part] = `${i++}`));
    return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]);
  }

  static formatDate(date: any, format: string = 'Do MMM, YYYY', formatTz: boolean = true) {
    let dateObj;

    if (!date) {
      dateObj = new Date();
    }

    dateObj = typeof date == 'string' ? new Date(date) : date;

    if (!Utils.isValidDate(dateObj)) {
      dateObj = new Date(date.replace(/-/g, '/'));
    }

    if (formatTz) {
      dateObj = this.convertDateToLocaleFormat(date);
    }

    return _format(dateObj, format);
  }

  static differenceInDates(date1: any, date2?: any) {
    if (!date2) {
      date2 = new Date();
    }

    return differenceInMinutes(date1, date2);
  }

  static isValidDate(d: any) {
    return d instanceof Date && !isNaN(d.getTime());
  }

  static slugify(text: string) {
    return SLUGIFY_STR(text);
  }

  static formatNumber(value: any, format = '0.0a') {
    if (Math.abs(value) < EPSILON) {
      value = numeral(0).format(format);
    }
    return numeral(value).format(format);
  }

  static formatByUnitType(value: number, unit: AppliedFilters['unitType'] | string, format: string = '0.0') {
    const formats: any = {
      [UnitType.GBP]: '$0.[00]a',
      [UnitType.PERCENT]: '0.0[0][0]',
      [UnitType.BPS]: '0.[0][0]'
    };

    const unitType = typeof unit == 'string' ? unit : unit ? unit.value : '';
    const frmt = formats[unitType] || format;
    const formattedValue = Utils.formatNumber(value, frmt);

    if (unitType == UnitType.BPS) {
      return `${formattedValue} BPS`;
    }

    if (unitType == UnitType.PERCENT) {
      return `${formattedValue}%`;
    }

    return formattedValue;
  }

  static randomColors(count = 1): string[] {
    let colors = [
      '#3f5058',
      '#7f94a3',
      '#ef508a',
      '#f1a269',
      '#fe3',
      '#48cdcd',
      '#aa6',
      '#342',
      '#5256F0',
      '#814A96',
      '#3BA592',
      '#2837DC',
      '#A3C8DC',
      '#5DB92F',
      '#441FFA',
      '#EF7132',
      '#1FFAD5',
      '#A13A3A',
      '#0AABEF',
      '#B98B2F'
    ];

    // If count is more than number of defined colors
    // then use 'randomcolor' library to generate more colors
    if (count > colors.length) {
      const extraColorsCount = count - colors.length;
      const extraColors = randomColor({
        count: extraColorsCount
      });
      if (typeof extraColors === 'string') {
        colors.push(extraColors);
      } else {
        colors = [...colors, ...extraColors];
      }
    }
    return colors.slice(0, count);
  }

  static prepareFilters(appliedFilters: AppliedFilters | any) {
    const filters: any = {};
    if (appliedFilters['unitType']) {
      filters['unitType'] = appliedFilters.unitType.value;
    }
    if (appliedFilters['costTypes']) {
      filters['costTypes'] = appliedFilters.costTypes.map((ct: any) => ct.id);
    }
    if (appliedFilters['products'] && appliedFilters['products'].length) {
      filters['products'] = appliedFilters.products.map((ct: any) => ct.id);
    }
    if (appliedFilters['assetClasses']) {
      filters['assetClasses'] = appliedFilters.assetClasses.map((ct: any) => ct.id);
    }
    if (appliedFilters['managers'] && appliedFilters['managers'].length) {
      filters['managers'] = appliedFilters.managers.map((ct: any) => ct.id);
    }
    if (appliedFilters['aum']) {
      filters['aumStart'] = appliedFilters.aum.aumStart;
      filters['aumEnd'] = appliedFilters.aum.aumEnd;
    }
    if (appliedFilters['strategy']) {
      filters['strategy'] = appliedFilters.strategy.map((strategy: any) => strategy.id);
    }
    return filters;
  }

  static capitalize(text: string): string {
    return _capitalize(text);
  }

  static titleCase(text: string): string {
    return _startCase(_toLower(text));
  }

  /**
   * Scale a list of numbers between the given min-max range.
   * @param items List of numbers to project in a range.
   * @param scaledMin Minimum Range number.
   * @param scaledMax Maximum Range number.
   * @returns List of scaled numbers.
   */
  static scaleNumbersBetweenRange(items: number[], scaledMin: number, scaledMax: number): number[] {
    const max = Math.max(...items);
    const min = Math.min(...items);
    return items.map(num => {
      const anchor = (scaledMax - scaledMin) * (num - min);
      const diff = max - min;

      if (anchor == 0 || diff == 0) {
        return scaledMin;
      }

      return anchor / diff + scaledMin;
    });
  }

  static getInitials(str: string): string {
    const initials = str.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  static capitalizeLetter(data: string): string {
    if (!data || !data.length) {
      return '';
    }
    let str = data.split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].length > 0 ? str[i][0].toUpperCase() + str[i].substr(1) : str[i];
    }
    return str.join(' ');
  }

  // construct asset classes name to meet uppercase requirements; hard coded; remove this later
  static getAssetClassDisplayValues(name: string): string {
    const assetClassLabels: any = {
      'listed equities': 'Listed Equities',
      'debt instruments': 'Debt Instruments',
      'otc derivatives': 'OTC Derivatives',
      'exchange traded derivatives': 'Exchange Traded Derivatives',
      'physical assets': 'Physical Assets',
      'private markets': 'Private Markets',
      'pooled funds': 'Pooled Funds',
      'other instruments': 'Other Instruments',
      'fx contracts': 'FX Contracts',
      'cash instruments (inc fx.)': 'Cash Instruments (inc FX.)',
      'cash instruments': 'Cash Instruments'
    };
    return assetClassLabels[name] || name;
  }

  static getoutlierSuspectData(outlierGroups: any, totalOutlier: number) {
    if (totalOutlier <= 0) {
      return '--';
    }
    const countHighIRR = outlierGroups['High IRR'] || 0;
    const countLowIRR = outlierGroups['Low IRR'] || 0;
    const countHighLowIRR = outlierGroups['High/Low IRR'] || 0;
    const countContInACC = outlierGroups['Contribution Record Inaccurate'] || 0;
    return (((countHighIRR + countLowIRR + countContInACC + countHighLowIRR) * 100) / totalOutlier).toFixed(2);
  }

  static getoutlierShortContData(outlierGroups: any, totalOutlier: number) {
    if (totalOutlier <= 0) {
      return '--';
    }
    const countShortCont = outlierGroups['Short Contribution History'] || 0;
    return (((countShortCont) * 100) / totalOutlier).toFixed(2);
  }

  static getoutlierNavData(outlierGroups: any, totalOutlier: number) {
    if (totalOutlier <= 0) {
      return '--';
    }
    const countNAV = outlierGroups['0 NAV'] || 0;
    return (((countNAV) * 100) / totalOutlier).toFixed(2);
  }

  static numberWithCommas(x: any, setLimit: boolean) {
    if (!isNaN(x) && setLimit) {
      x = x.toFixed(2);
    }
    return (x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) || 0;
  }

  static convertMinMaxToArrayFilters(filterObject: any) {
    let convertedFilters: any = {};
    Object.keys(filterObject).forEach(eachKey => {
      if(['potRange', 'scoreRange', 'birrRange', 'irrRange'].indexOf(eachKey) !== -1){
        if(eachKey === 'birrRange' || eachKey === 'irrRange') {
          convertedFilters[eachKey] = [filterObject[eachKey].min/100, filterObject[eachKey].max/100];
        } 
        else if(eachKey === 'scoreRange' ) {
          convertedFilters[eachKey] = filterObject[eachKey];
        } 
        else {
          convertedFilters[eachKey] = [filterObject[eachKey].min, filterObject[eachKey].max];
        }
      }
    });
    return convertedFilters;
  }

}
