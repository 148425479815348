import * as AT from '@/modules/imports/store/actionTypes';
import * as MT from '@/modules/imports/store/mutationTypes';
import { IRootState } from '@/store/state';
import { IImportState } from '@/modules/imports/store/state';
import { ActionContext, ActionTree } from 'vuex';
import { ImportService } from '@/modules/imports/services';

/**
 * --------------------------------------------------
 *  Scheme Actions
 * --------------------------------------------------
 **/
export const ImportActions: IImportActions = {
  /**
   * --------------------------------------------------
   *  Fetch All the Schemes
   * --------------------------------------------------
   **/
  [AT.FETCH_IMPORTS]: ({ commit, dispatch }: any) => {
    commit(MT.SET_FETCHING_IMPORTS, true);
    return ImportService.getImports()
      .then(imports => {
        commit(MT.SET_IMPORTS, imports);
        return imports;
      })
      .finally(() => commit(MT.SET_FETCHING_IMPORTS, false));
  }
};

/**
 * --------------------------------------------------
 *  Import Action Context Interface
 * --------------------------------------------------
 **/
export interface IImportActionContext extends ActionContext<IImportState, IRootState> {}

/**
 * --------------------------------------------------
 *  Import Actions Interface
 * --------------------------------------------------
 **/
export interface IImportActions extends ActionTree<IImportState, IRootState> {}
