import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-h5" }
const _hoisted_2 = {
  key: 0,
  "data-testid": "Upload-Container",
  wrap: ""
}
const _hoisted_3 = { class: "text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_AppUploader = _resolveComponent("AppUploader")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.isDialogVisible,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.isDialogVisible) = $event)),
    persistent: "",
    "max-width": _ctx.showProviderForm ? '400px' : '600px',
    "max-height": "90%"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        "data-testid": "UploadDialog",
        class: "pa-2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            onSubmit: _withModifiers(_ctx.uploadData, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "d-flex align-center justify-space-between" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.showProviderForm ? 'Add Provider' : 'Upload Data'), 1),
                  _createVNode(_component_v_btn, {
                    onClick: _ctx.toggleAddProviderForm,
                    size: "small",
                    variant: "outlined",
                    color: "primary"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        class: "mr-2",
                        size: "small",
                        left: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.showProviderForm ? 'fas fa-chevron-left' : 'fas fa-plus'), 1)
                        ]),
                        _: 1
                      }),
                      _withDirectives(_createElementVNode("span", null, "Add Provider", 512), [
                        [_vShow, !_ctx.showProviderForm]
                      ]),
                      _withDirectives(_createElementVNode("span", null, "Go back", 512), [
                        [_vShow, _ctx.showProviderForm]
                      ])
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("div", null, [
                    _createVNode(_component_v_form, {
                      onSubmit: _withModifiers(_ctx.createProvider, ["prevent"]),
                      class: "py-5"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_v_text_field, {
                            "hide-details": "",
                            "persistent-hint": false,
                            placeholder: "Provider Name",
                            modelValue: _ctx.newProviderName,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newProviderName) = $event)),
                            class: "mb-5"
                          }, null, 8, ["modelValue"]),
                          _createVNode(_component_v_text_field, {
                            "hide-details": "",
                            "persistent-hint": false,
                            placeholder: "Provider Email",
                            modelValue: _ctx.newProviderEmail,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newProviderEmail) = $event)),
                            class: "mb-5"
                          }, null, 8, ["modelValue"]),
                          _createVNode(_component_v_btn, {
                            block: "",
                            type: "submit",
                            color: !_ctx.newProviderName || _ctx.addingProvider ? 'disabled' : 'success',
                            class: "mt-5",
                            loading: _ctx.addingProvider,
                            disabled: !_ctx.newProviderName || _ctx.addingProvider
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Add ")
                            ]),
                            _: 1
                          }, 8, ["color", "loading", "disabled"])
                        ])
                      ]),
                      _: 1
                    }, 8, ["onSubmit"])
                  ], 512), [
                    [_vShow, _ctx.showProviderForm]
                  ]),
                  _withDirectives(_createVNode(_component_v_container, { "grid-list-md": "" }, {
                    default: _withCtx(() => [
                      (!_ctx.dataUploaded)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(_component_v_row, { class: "mb-2" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Select a Provider")
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_autocomplete, {
                                      "data-testid": "Upload-Provider-List",
                                      modelValue: _ctx.selectedProvider,
                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedProvider) = $event)),
                                      loading: _ctx.fetchingProviders,
                                      items: _ctx.providers,
                                      "v-model:search-input": _ctx.providerSearch,
                                      "item-title": "name",
                                      "item-value": "id",
                                      class: "mx-0 mt-2",
                                      type: "text",
                                      "hide-no-data": "",
                                      "hide-details": "",
                                      label: "Provider",
                                      variant: "filled"
                                    }, null, 8, ["modelValue", "loading", "items", "v-model:search-input"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, { class: "mb-2" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Select Index")
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_autocomplete, {
                                      "data-testid": "Index-Type-List",
                                      modelValue: _ctx.indexType,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.indexType) = $event)),
                                      items: _ctx.indexTypes,
                                      "item-title": "name",
                                      "item-value": "id",
                                      class: "mx-0 mt-2",
                                      type: "text",
                                      "hide-no-data": "",
                                      "hide-details": "",
                                      label: "Index Type",
                                      variant: "filled"
                                    }, null, 8, ["modelValue", "items"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_container, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, {
                                              cols: "12",
                                              sm: "12"
                                            }, {
                                              default: _withCtx(() => [
                                                (_ctx.selectedProvider)
                                                  ? (_openBlock(), _createBlock(_component_v_switch, {
                                                      key: 0,
                                                      disabled: !(_ctx.selectedProvider && (!_ctx.providerHasExistingBatch || !_ctx.useExistingBatch)),
                                                      "hide-details": "",
                                                      "persistent-hint": false,
                                                      label: `Scoring Methodology: ${_ctx.scoringMethod ? 'New Test Method' : 'Old scoring method'}`,
                                                      modelValue: _ctx.scoringMethod,
                                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.scoringMethod) = $event)),
                                                      class: "mb-5"
                                                    }, null, 8, ["disabled", "label", "modelValue"]))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, {
                                              cols: "12",
                                              sm: "12"
                                            }, {
                                              default: _withCtx(() => [
                                                (_ctx.selectedProvider)
                                                  ? (_openBlock(), _createBlock(_component_v_switch, {
                                                      key: 0,
                                                      disabled: !_ctx.selectedProvider,
                                                      "hide-details": "",
                                                      "persistent-hint": false,
                                                      label: `Performance Attribution: ${_ctx.performanceAttribution ? 'Yes' : 'No'}`,
                                                      modelValue: _ctx.performanceAttribution,
                                                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.performanceAttribution) = $event)),
                                                      class: "mb-5"
                                                    }, null, 8, ["disabled", "label", "modelValue"]))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, {
                                              cols: "12",
                                              sm: "12"
                                            }, {
                                              default: _withCtx(() => [
                                                _withDirectives(_createVNode(_component_v_switch, {
                                                  modelValue: _ctx.useExistingBatch,
                                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.useExistingBatch) = $event)),
                                                  inset: "",
                                                  label: "Use existing batch"
                                                }, null, 8, ["modelValue"]), [
                                                  [_vShow, _ctx.selectedProvider && _ctx.providerHasExistingBatch]
                                                ])
                                              ]),
                                              _: 1
                                            }),
                                            _withDirectives(_createVNode(_component_v_col, {
                                              cols: "12",
                                              sm: "6"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_switch, {
                                                  modelValue: _ctx.editOutlierFlag,
                                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editOutlierFlag) = $event)),
                                                  inset: "",
                                                  disabled: _ctx.selectedProvider && _ctx.useExistingBatch && _ctx.providerHasExistingBatch,
                                                  label: "Use minimum contribution time"
                                                }, null, 8, ["modelValue", "disabled"])
                                              ]),
                                              _: 1
                                            }, 512), [
                                              [_vShow, _ctx.selectedProvider]
                                            ]),
                                            _createVNode(_component_v_col, {
                                              cols: "12",
                                              sm: "6"
                                            }, {
                                              default: _withCtx(() => [
                                                _withDirectives(_createVNode(_component_v_text_field, {
                                                  modelValue: _ctx.editOutlierNumber,
                                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editOutlierNumber) = $event)),
                                                  label: "Number of years",
                                                  disabled: _ctx.selectedProvider && _ctx.useExistingBatch && _ctx.providerHasExistingBatch,
                                                  "single-line": "",
                                                  type: "number"
                                                }, null, 8, ["modelValue", "disabled"]), [
                                                  [_vShow, _ctx.editOutlierFlag && _ctx.selectedProvider]
                                                ])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _withDirectives(_createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_alert, {
                                      type: _ctx.providerHasExistingBatch && !_ctx.useExistingBatch ? 'warning' : 'info',
                                      class: "mb-4",
                                      prominent: ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_3, [
                                          _createTextVNode(" A new batch will be created with this upload. "),
                                          _withDirectives(_createElementVNode("b", null, "This batch will overwrite the existing batch.", 512), [
                                            [_vShow, _ctx.providerHasExistingBatch && !_ctx.useExistingBatch]
                                          ])
                                        ])
                                      ]),
                                      _: 1
                                    }, 8, ["type"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 512), [
                              [_vShow, _ctx.selectedProvider && (!_ctx.providerHasExistingBatch || !_ctx.useExistingBatch)]
                            ]),
                            _createVNode(_component_v_divider, {
                              class: "d-flex",
                              horizontal: ""
                            }),
                            _createVNode(_component_v_row, { class: "text-center mt-2" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_AppUploader, {
                                      "data-testid": "FileUpload",
                                      onAppUploader: _ctx.handleFile,
                                      disabled: _ctx.uploadingData,
                                      fileType: '.csv'
                                    }, null, 8, ["onAppUploader", "disabled", "fileType"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.dataUploaded)
                        ? (_openBlock(), _createBlock(_component_v_layout, {
                            key: 1,
                            wrap: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_alert, {
                                        modelValue: _ctx.dataUploaded,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.dataUploaded) = $event)),
                                        closable: "",
                                        type: "success",
                                        class: "mb-4",
                                        prominent: ""
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Data uploaded successfully. We will notify you once we've processed it.")
                                        ]),
                                        _: 1
                                      }, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.dataUploadError)
                        ? (_openBlock(), _createBlock(_component_v_layout, {
                            key: 2,
                            wrap: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_alert, {
                                        "data-testid": "FileUploadError",
                                        modelValue: _ctx.dataUploadError,
                                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.dataUploadError) = $event)),
                                        closable: "",
                                        type: "error",
                                        class: "mb-4 white--text",
                                        prominent: ""
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.validationError), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, !_ctx.showProviderForm]
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    "data-testid": "CloseUploadDialog",
                    color: "grey darken-1",
                    variant: "text",
                    onClick: _ctx.toggleDialog
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Close")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_spacer),
                  (!_ctx.showProviderForm)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        "data-testid": "SubmitUploadBtn",
                        color: "success",
                        type: "submit",
                        loading: _ctx.uploadingData,
                        disabled: _ctx.uploadingData || _ctx.dataUploaded
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Upload")
                        ]),
                        _: 1
                      }, 8, ["loading", "disabled"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onSubmit"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "max-width"]))
}