import Provider from '@/models/Provider';
import { HTTPServiceClient } from '@/packages/http/service';
import * as ep from './endpoints';

export const ProviderAnalysisService = {
  /**
   *Analysis of Provider By Id
   */
  getAnalysisById(id: number): Promise<Provider[]> {
    return HTTPServiceClient.get(ep.FETCH_ANALYSIS_BY_ID(id)).then(({ data }) => data);
  },
  getChartData(data: any, params: any): Promise<Provider[]> {
    return HTTPServiceClient.get(ep.FETCH_CHARTDATA(data.providerId), { params }).then(({ data }) => data);
  },
  generatePdf(id: number): Promise<String> {
    return HTTPServiceClient.get(ep.GENERATE_PDF(id)).then(({ data }) => data);
  }
};
