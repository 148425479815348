<template>
  <div>
    <div class="outlier-wrapper">
      <div class="elevation-1 chartWrapper outlier-card">
        <div class="chart-title d-block text-center">Outlier Categories</div>
        <base-chart :options="chartOptions" :scroll-options="chartSize" class="mt-2" @click="(e) => chartClick(e)" />
      </div>
    </div>
    <v-snackbar v-model="pdfTimeoutError" :timeout="3000" color="primary" location="top">
      <h3>Error downloading requested pdf. Please try again.</h3>
      <v-btn color="blue" variant="text" class="d-flex ml-auto" @click="pdfTimeoutError = false"> Close </v-btn>
    </v-snackbar>
    <v-snackbar v-model="noDataAlert" :timeout="3000" color="primary" location="top">
      <h3>No data available for this range</h3>
      <v-btn color="blue" variant="text" class="d-flex ml-auto" @click="noDataAlert = false"> Close </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="membersDialog"
      @click:outside="membersDialog = false"
      persistent
      max-width="80vw"
      max-height="90%"
    >
      <v-card class="py-4">
        <v-card-title class="d-flex align-center justify-space-between">
          <div>
            <div>Member Analysis for the selected range:</div>
          </div>
          <div>
            <v-btn
              left
              color="primary"
              class="mr-4"
              :disabled="!providerId || isSampleReport"
              :loading="processingCSV"
              @click="exportMemberCSV()"
            >
              <v-icon small class="mr-3">fas fas fa-file-download</v-icon>Download
            </v-btn>

            <v-btn rounded size="small" density="comfortable" variant="flat" icon @click="membersDialog = false">
              <v-icon small>fa fa-times</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <AppTable
            :table-data="membersTableData"
            :disableSort="true"
            :disablePagination="true"
            :default-sort-column="[{ key: 'dataId', order: 'asc' }]"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey-darken-1" variant="text" @click="membersDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AppLoader v-if="fetchingMembersAnalysis" />
  </div>
</template>

<script>
import BaseChart from '@/components/charts/BaseChart.vue';
import Utils from '../../utils/Utils';
import * as AT from '@/modules/members/store/actionTypes';
import { Table, TableColumn, TableRow, TableColumnType } from '@/types';
import AppTable from '@/components/AppTable.vue';
import { GET_EXPORT_JOB_BY_ID } from '@/modules/reports/store/actionTypes';
import { GET_CSV_URL } from '@/modules/members/store/actionTypes';
import AppLoader from '@/components/AppLoder.vue';

export default {
  components: {
    BaseChart,
    AppTable,
    AppLoader,
  },
  props: ['data', 'providerId', 'isSampleReport'],
  data() {
    return {
      chartData: {},
      chartSize: {
        chartWidth: 70,
        chartHeight: 30,
        unit: 'rem',
      },
      chartOptions: {},
      membersDialog: false,
      fetchingMembersAnalysis: false,
      noDataAlert: false,
      processingCSV: false,
      pdfTimeoutError: false,
      pagination: {
        page: 1,
        limit: 15,
      },
      membersTableData: {},
    };
  },
  created() {
    this.setChartData();
    this.setOption();
  },
  methods: {
    setChartData() {
      const { outlierGroups } = this.data;
      const outlierSections = [];
      const countShortCont = outlierGroups['Short Contribution History'] || 0;
      const outliersCount = this.data.totalOutlier - countShortCont;
      Object.keys(outlierGroups).map((outlierReason) => {
        if (outlierReason !== 'Short Contribution History') {
          const itemColor = this.getSectionColor(outlierReason);
          outlierSections.push({
            name: outlierReason,
            value: ((outlierGroups[outlierReason] * 100) / outliersCount).toFixed(1),
            memberCount: outlierGroups[outlierReason],
            label: {
              show: true,
              color: itemColor,
              position: 'outside',
              formatter: (v) => {
                return ` ${v.value}% ${v.name} \n ${Utils.numberWithCommas(v.data.memberCount)} Members`;
              },
            },
            itemStyle: {
              color: itemColor,
            },
          });
        }
      });
      this.chartData = {
        data: outlierSections,
      };
    },
    setOption() {
      this.chartOptions = {
        grid: {
          left: '20%',
        },
        tooltip: {
          trigger: 'item',
          formatter: (v) => {
            return `${v.value}% ${v.name} <br /> ${v.data.memberCount} Members`;
          },
        },
        legend: {
          orient: 'vertical',
          right: 0,
          top: 40,
        },
        series: [
          {
            type: 'pie',
            radius: '65%',
            center: ['45%', '55%'],
            data: this.chartData.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
    },
    async fetchMembersAnalysis(data) {
      this.fetchingMembersAnalysis = true;
      let analysis = null;
      if (this.isSampleReport) {
        analysis = await this.$store.dispatch(AT.FETCH_SAMPLE_OUTLIER_REASON_ANALYSIS, data);
      } else {
        analysis = await this.$store.dispatch(AT.FETCH_OUTLIER_REASON_ANALYSIS, data);
      }
      this.fetchingMembersAnalysis = false;
      return analysis;
    },
    async chartClick(e) {
      if (e.dataIndex !== undefined) {
        this.noDataAlert = false;
        const index = await e.dataIndex;
        const appliedFilters = {
          providers: [this.providerId],
          outlierReason: e.name,
        };
        this.currentFilter = {
          ...this.pagination,
          ...appliedFilters,
        };
        const analysis = await this.fetchMembersAnalysis(this.currentFilter);
        this.setMemberTableData(analysis);
        analysis.length ? (this.membersDialog = true) : (this.noDataAlert = true);
      }
    },
    setMemberTableData(memberAnalysis) {
      const rows = memberAnalysis || [];
      this.membersTableData = {
        columns: [
          {
            value: 'dataId',
            title: 'Saver ID',
            type: TableColumnType.STRING,
            align: 'center',
          },
          {
            value: 'contributionAmount',
            title: 'Total Contribution Amount',
            type: TableColumnType.NUMBER,
            formatter: (n) => Utils.formatNumber(n, '$0.00a'),
            align: 'center',
          },
          {
            value: 'navAmount',
            title: 'NAV Amount',
            type: TableColumnType.NUMBER,
            formatter: (n) => Utils.formatNumber(n, '$0.00a'),
            align: 'center',
          },
          {
            value: 'navDate',
            title: 'NAV Date',
            type: TableColumnType.DATE,
            formatter: (v) => Utils.formatDate(new Date(v), 'MMM dd, yyyy'),
            align: 'center',
          },
          {
            value: 'irr',
            title: 'IRR (%)',
            type: TableColumnType.NUMBER,
            align: 'center',
          },
          {
            value: 'benchmarkIrr',
            title: 'Benchmark IRR (%)',
            type: TableColumnType.NUMBER,
            align: 'center',
          },
          {
            value: 'reason',
            title: 'Error',
            type: TableColumnType.STRING,
            align: 'center',
          },
        ],
        rows,
      };
    },
    memberAnalysis() {
      return this.$store.getters.outlierReasonAnalysis && this.$store.getters.outlierReasonAnalysis.data;
    },
    async exportMemberCSV() {
      this.processingCSV = true;

      const job = await this.$store.dispatch(AT.EXPORT_OUTLIER_REASON_ANALYSIS, {
        page: 1,
        limit: -1,
        providers: this.currentFilter.providers,
        outlierReason: this.currentFilter.outlierReason,
      });
      this.getCSVURL(job);
    },
    async getCSVURL(job) {
      let pdfFetchInterval = setInterval(async () => {
        let response = await this.$store.dispatch(GET_EXPORT_JOB_BY_ID, job.id);
        if (response[0].status === 'completed') {
          const url = await this.$store.dispatch(GET_CSV_URL, job.id);
          await window.open(url);
          this.processingCSV = false;
          clearInterval(pdfFetchInterval);
        }
        return response;
      }, Utils.pdfInterval);
      setTimeout(() => {
        clearInterval(pdfFetchInterval);
        if (this.processingCSV) {
          this.pdfTimeoutError = true;
        }
        this.processingCSV = false;
      }, Utils.pdfTimeout);
    },
    getSectionColor(sectionName) {
      if (sectionName == 'High IRR') {
        return '#203864';
      }

      if (sectionName == 'Low IRR') {
        return '#1789DF';
      }

      if (sectionName == 'Contribution Record Inaccurate') {
        return '#D38265';
      }

      if (sectionName == '0 NAV') {
        return '#C23431';
      }
    },
  },
};
</script>
<style lang="scss">
.outlier-wrapper {
  padding: 1rem 0rem !important;
  .outlier-card {
    padding-bottom: 3rem !important;
  }
}
</style>
