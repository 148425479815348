<template>
  <div data-testid="add-benchmark-page" class="page add-benchmark-page">
    <v-container fluid>
      <v-layout class="mt-4 pa-0">
        <div class="w-100">
          <v-card>
            <v-form @submit.prevent="addBenchmark" class="py-5">
              <v-card-title class="d-flex align-center justify-space-between mb-6">
                <span class="text-h5"> Add Benchmark </span>
              </v-card-title>
              <v-card-text>
                <!-- <v-divider/> -->

                <v-label>Name</v-label>
                <v-text-field
                  hide-details
                  :persistent-hint="false"
                  variant="underlined"
                  placeholder="Fund Name to appear in the report"
                  v-model="priceTrackObject.name"
                  class="mb-5"
                />
                <v-label>First Date (YYYY-MM-DD)</v-label>
                <v-text-field
                  hide-details
                  :persistent-hint="false"
                  variant="underlined"
                  placeholder="Start Date"
                  v-model="priceTrackObject.startDate"
                  class="mb-5"
                />

                <v-label>End Date (YYYY-MM-DD)</v-label>
                <v-text-field
                  hide-details
                  :persistent-hint="false"
                  variant="underlined"
                  placeholder="Should be present in the price track that you upload"
                  v-model="priceTrackObject.endDate"
                  class="mb-5"
                />

                <!-- </div> -->
                <!-- <v-label>Number of contributions/ Max number of contributions(if not fixed)</v-label>
              <v-text-field
                hide-details
                :persistent-hint="false"
                placeholder="Number of contributions/ Max number of contributions(if not fixed)"
                type="number"
                v-model="priceTrackObject.maxContributions"
                class="mb-5"
              /> -->

                <AppUploader
                  data-testid="FileUpload"
                  @app-uploader="handleFile"
                  :disabled="addingBenchmark"
                  :fileType="'.csv'"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  data-testid="SubmitUploadBtn"
                  :color="addingBenchmark ? 'disabled' : 'success'"
                  type="submit"
                  variant="flat"
                  :loading="addingBenchmark"
                  :disabled="addingBenchmark"
                  >Add</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Vue, Watch } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppUploader from '@/components/AppUploader.vue';
import { SET_NAV_HEADER } from '@/store/actionTypes';
import { HTTPServiceClient } from '@/packages/http/service';

@Options({
  components: {
    AppUploader,
  },
})
export default class BenchmarkAdd extends Vue {
  public validationError = '';
  public priceTrackObject = {
    name: '',
    startDate: '2015-01-01',
    endDate: '2022-02-28',
  };
  public file: any = null;
  public addingBenchmark = false;

  @Watch('addingBenchmark')
  public userUpdated() {
    if (this.addingBenchmark === false) {
      this.$router.push({ name: 'dashboard.benchmark.list' });
    }
  }

  public handleFile(data: any) {
    this.file = data.file;
  }

  created() {
    this.bootstrap();
  }

  public bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Benchmarks', tooltip: 'Add Benchmark' });
  }

  public addBenchmark() {
    if (!this.file) {
      return false;
    }
    let formData = new FormData();

    formData.append('file', this.file, this.file.name);
    formData.append('name', `${this.priceTrackObject.name}`);
    formData.append('startDate', `${this.priceTrackObject.startDate}`);
    formData.append('endDate', this.priceTrackObject.endDate);

    this.addingBenchmark = true;
    try {
      return HTTPServiceClient.post('/benchmarks/add', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((res) => {
          console.log('res', res);
        })
        .finally(() => {
          this.addingBenchmark = false;
          this.file = null;
        });
    } catch (err) {
      this.addingBenchmark = false;
      console.log('err', err);
    }
  }
}
</script>
