import { RouteRecordRaw } from 'vue-router';
import LoginPage from '@/modules/auth/LoginPage.vue';
import Page404 from '@/pages/Page404.vue';
// Module Routes
import { ModuleRoutes } from '@/modules/routes';

export const AppRoutes: RouteRecordRaw[] = [
  {
    // path: '*',
    path: '/:pathMatch(.*)*',
    name: '404',
    component: Page404
  },
  ...ModuleRoutes
];
