<template>
  <div>
    <!-- No data snackbar -->
    <v-snackbar v-model="noDataAlert" :timeout="3000" color="primary" location="top">
      <h3>No data available for this range</h3>
      <v-btn color="blue" class="d-flex ml-auto" variant="text" @click="noDataAlert = false"> Close </v-btn>
    </v-snackbar>
    <v-snackbar v-model="pdfTimeoutError" :timeout="3000" color="primary" location="top">
      <h3>Error downloading requested pdf. Please try again.</h3>
      <v-btn color="blue" variant="text" class="d-flex ml-auto" @click="pdfTimeoutError = false"> Close </v-btn>
    </v-snackbar>
    <!-- Chart -->
    <div class="airrWrapper">
      <div class="elevation-1 chartWrapper airrCard">
        <div class="chart-title text-center">Score vs Investment Start Date</div>
        <!-- <div style="height: 30rem; margin-top: 2rem;"> -->
        <base-chart :options="chartOptions" :scroll-options="chartSize" @click="(e) => chartClick(e)" />
      </div>
    </div>
    <!-- Members Modal -->
    <v-dialog
      v-model="membersDialog"
      @click:outside="membersDialog = false"
      persistent
      max-width="80vw"
      max-height="90%"
    >
      <v-card class="py-4">
        <v-card-title class="d-flex align-center justify-space-between">
          <div>
            <div>Member Analysis for the selected range:</div>
          </div>
          <div>
            <v-btn
              left
              color="primary"
              class="mr-4"
              :disabled="!provider || isSampleReport"
              :loading="processingCSV"
              @click="exportMemberCSV()"
            >
              <v-icon small class="mr-3">fas fas fa-file-download</v-icon>Download
            </v-btn>

            <v-btn rounded size="small" density="comfortable" variant="flat" icon @click="membersDialog = false">
              <v-icon small>fa fa-times</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <AppTable
            :table-data="membersTableData"
            :disableSort="true"
            :disablePagination="true"
            :default-sort-column="[{ key: 'dataId', order: 'asc' }]"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey-darken-1" variant="text" @click="membersDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AppLoader v-if="fetchingMembersAnalysis" />
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import BaseChart from '@/components/charts/BaseChart.vue';
import data from '@/packages/stubs/echarts/rangeArray';
import _ from 'lodash';
import Utils from '../../utils/Utils';
import * as AT from '@/modules/members/store/actionTypes';
import * as PTAT from '@/modules/price-track/store/actionTypes';
import { GET_EXPORT_JOB_BY_ID } from '@/modules/reports/store/actionTypes';
import { GET_CSV_URL } from '@/modules/members/store/actionTypes';
import { Table, TableColumn, TableRow, TableColumnType } from '@/types';
import AppTable from '@/components/AppTable.vue';
import AppLoader from '@/components/AppLoder.vue';
import { PriceTrackObject } from '@/models/PriceTrack';

@Options({
  components: {
    BaseChart,
    AppTable,
    AppLoader,
  },
})
export default class ScoreYearChart extends Vue {
  @Prop({
    default: () => ({}),
  })
  chartData!: any;

  @Prop({
    default: () => {},
  })
  reportfilterRanges!: any;

  @Prop({
    default: () => null,
  })
  provider!: number;

  @Prop({
    default: () => null,
  })
  priceTrack!: PriceTrackObject;

  @Prop() isSampleReport!: boolean;

  public chartSize: any = {
    chartWidth: 70,
    chartHeight: 30,
    unit: 'rem',
  };

  public pdfTimeoutError = false;

  public get isyData() {
    const sortedData = _.sortBy(this.chartData, 'year');
    const years = sortedData.map((item: any) => item.year);
    const score = sortedData.map((item: any) => item.score);

    const data = {
      years,
      score,
    };
    return data;
  }

  public get chartOptions() {
    return {
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 1,
            color: '#ffcf5e', // color at 0% position
          },
          {
            offset: 0,
            color: '#f4b411', // color at 100% position
          },
        ],
      },
      tooltip: {
        show: true,
        formatter: (params: any) =>
          `Savers: ${this.chartData[params.dataIndex].doc_count}<br>Score: ${Utils.formatNumber(
            params.value,
            '0.[00]'
          )}`,
      },
      xAxis: [
        {
          name: 'First Contribution Date',
          nameLocation: 'middle',
          nameTextStyle: { fontFamily: 'Open Sans', padding: 35, fontWeight: 'bolder' },
          type: 'category',
          axisTick: { show: false },
          axisLabel: {
            fontWeight: '600',
            fontFamily: 'Open Sans',
            color: '#6f6c76',
            interval: 0,
            rotate: 50,
          },
          axisLine: { show: true, lineStyle: { color: '#ccc' } },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#eee',
            },
          },
          data: this.isyData.years,
        },
      ],
      yAxis: {
        name: 'Score',
        nameLocation: 'middle',
        nameRotate: 90,
        nameTextStyle: { fontFamily: 'Open Sans', padding: 20, fontWeight: 'bolder' },
        type: 'value',
        axisLabel: {
          formatter: (v: any) => Utils.formatNumber(v, '0.[00]'),
          fontWeight: '600',
          fontFamily: 'Open Sans',
          color: '#6f6c76',
          interval: false,
        },
        axisLine: { show: true, lineStyle: { color: '#ccc' } },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#eee',
          },
        },
      },
      series: [
        {
          type: 'bar',
          data: this.isyData.score,
          label: {
            show: true,
            position: 'top',
            color: '#f4b411',
            rich: { name: { fontFamily: 'Open Sans', color: '#6f6c76' } },
            textBorderColor: '#fff',
            formatter: (params: any) => `${this.chartData[params.dataIndex].doc_count}`,
          },
        },
      ],
      barMaxWidth: 30,
      barGap: '30%',
    };
  }

  public membersDialog = false;

  public fetchingMembersAnalysis = false;

  public noDataAlert = false;

  public processingCSV = false;

  public selectedRange = [];

  public currentFilter: any = {};

  public pagination = {
    page: 1,
    limit: 15,
  };

  private async fetchMembersAnalysis(data: any = {}) {
    this.fetchingMembersAnalysis = true;
    let analysis = null;
    if (this.isSampleReport) {
      analysis = await this.$store.dispatch(AT.FETCH_SAMPLE_MEMBERS_ANALYSIS, data);
    } else if (this.provider) {
      analysis = await this.$store.dispatch(AT.FETCH_MEMBERS_ANALYSIS, data);
    } else if (this.priceTrack) {
      analysis = await this.$store.dispatch(PTAT.FETCH_MEMBERS_ANALYSIS_PRICE_TRACK, data);
    }
    this.fetchingMembersAnalysis = false;
    return analysis;
  }

  public async exportMemberCSV() {
    //PDF Export Polling with 2sec interval and 16sec Timout
    let job = { id: -1 };

    this.processingCSV = true;
    job = await this.$store.dispatch(AT.EXPORT_MEMBERS_ANALYSIS, {
      page: 1,
      limit: -1,
      providers: this.currentFilter.providers,
      firstContributionYear: this.currentFilter.firstContributionYear,
      scoreRange: this.currentFilter.scoreRange,
      irrRange: this.currentFilter.irrRange,
      birrRange: this.currentFilter.birrRange,
      potRange: this.currentFilter.potRange,
    });

    let pdfFetchInterval = setInterval(async () => {
      let response = await this.$store.dispatch(GET_EXPORT_JOB_BY_ID, job.id);
      if (response[0].status === 'completed') {
        const url = await this.$store.dispatch(GET_CSV_URL, job.id);
        await window.open(url);
        this.processingCSV = false;
        clearInterval(pdfFetchInterval);
      }
      return response;
    }, Utils.pdfInterval);
    setTimeout(() => {
      clearInterval(pdfFetchInterval);
      if (this.processingCSV) {
        this.pdfTimeoutError = true;
      }
      this.processingCSV = false;
    }, Utils.pdfTimeout);
  }

  public async chartClick(e: any) {
    if (e.dataIndex !== undefined) {
      this.noDataAlert = false;
      const index = await e.dataIndex;
      const firstContributionYear = this.chartData[index].year;
      const convertedFilters = Utils.convertMinMaxToArrayFilters(this.reportfilterRanges);
      const appliedFilters: any = {
        firstContributionYear,
      };
      if (this.provider) {
        appliedFilters.providers = [this.provider];
      } else if (this.priceTrack) {
        appliedFilters.dataBatchIds = [this.priceTrack.dataBatchId];
      }
      this.currentFilter = {
        ...convertedFilters,
        ...this.pagination,
        ...appliedFilters,
      };
      const analysis = await this.fetchMembersAnalysis(this.currentFilter);
      analysis.length ? (this.membersDialog = true) : (this.noDataAlert = true);
    }
  }

  public get memberAnalysis(): any[] | null {
    return this.$store.getters.memberAnalysis && this.$store.getters.memberAnalysis.data;
  }

  public get membersTableData(): Table {
    const data = this.memberAnalysis ? this.memberAnalysis : [];
    const rows = data.map((it) => {
      return {
        dataId: it.dataId,
        score: it.scores.score || it.scores.score === 0 ? parseInt(it.scores.score) : '-',
        firstContributionDate: it.firstContributionDate,
        // fundName: it.fund.name,
        totalContributionAmount: it.totalContributionAmount,
        navAmount: it.analysis.navAmount,
        navDate: it.analysis.navDate,
        irr: it.analysis.irr,
        benchmarkIrr: it.analysis.benchmarkIrr,
        benchmarkNavAmount: it.analysis.benchmarkNavAmount,
      };
    });
    return {
      columns: [
        {
          value: 'dataId',
          title: 'Saver ID',
          type: TableColumnType.NUMBER,
          align: 'center',
        },
        // {
        //   value: 'fundName',
        //   text: 'Fund Name',
        //   type: TableColumnType.STRING
        // },
        {
          value: 'firstContributionDate',
          title: 'First Contribution Date',
          type: TableColumnType.DATE,
          formatter: (v: any) => Utils.formatDate(new Date(v), 'MMM dd, yyyy'),
          align: 'center',
        },
        {
          value: 'navDate',
          title: 'NAV Date',
          type: TableColumnType.DATE,
          formatter: (v: any) => Utils.formatDate(new Date(v), 'MMM dd, yyyy'),
          align: 'center',
        },
        {
          value: 'score',
          title: 'AW Score',
          type: TableColumnType.NUMBER,
          align: 'center',
        },
        // {
        //   value: 'totalContributionAmount',
        //   text: 'Total Contribution Amount',
        //   type: TableColumnType.NUMBER,
        //   formatter: n => Utils.formatNumber(n, '$0.00a')
        // },
        {
          value: 'navAmount',
          title: 'NAV Amount',
          type: TableColumnType.NUMBER,
          formatter: (n) => Utils.formatNumber(n, '$0.00a'),
          align: 'center',
        },
        {
          value: 'benchmarkNavAmount',
          title: 'Benchmark Nav Amount',
          type: TableColumnType.NUMBER,
          formatter: (n) => Utils.formatNumber(n, '$0.00a'),
          align: 'center',
        },
        {
          value: 'irr',
          title: 'IRR (%)',
          type: TableColumnType.NUMBER,
          formatter: (v: any) => (v * 100).toFixed(2),
          align: 'center',
        },
        {
          value: 'benchmarkIrr',
          title: 'Benchmark IRR (%)',
          type: TableColumnType.NUMBER,
          formatter: (v: any) => (v * 100).toFixed(2),
          align: 'center',
        },
      ],
      rows,
    };
  }
}
</script>
