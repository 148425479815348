<template>
  <div>
    <v-card class="filter-card">
      <v-card-text>
        <v-row class="px-1">
          <v-col :sm="12" :md="4" cols="12" class="filter-col px-3">
            <v-layout align-center justify-space-between class="filter-header">
              <v-layout>
                <v-icon color="primary" class="mr-3">fas fa-coins</v-icon>
                <span>Pot Size</span>
              </v-layout>
              <v-btn
                size="small"
                flat
                icon
                class="reset-filter-btn"
                v-show="potRangeIsDirty"
                @click.stop="(e) => resetFilter('potRange')"
              >
                <v-icon small>fas fa-times</v-icon>
              </v-btn>
            </v-layout>
            <v-range-slider
              :model-value="currentFilters.potRange || []"
              :max="filters.potRange && filters.potRange.max"
              :min="filters.potRange && filters.potRange.min"
              hide-details
              :track-size="1"
              :thumb-size="12"
              color="primary"
              density="compact"
              class="align-center mt-2 px-3"
              @update:modelValue="(e) => updatePotRange(e)"
            ></v-range-slider>
            <v-layout class="filter-value mx-5" v-if="currentFilters.potRange">
              <v-menu
                v-model="minmenu"
                content-class="filter-edit-menus"
                :close-on-content-click="false"
                :close-on-click="false"
                offset="10"
                location="right"
              >
                <template v-slot:activator="{ props }">
                  <div v-bind="props">
                    <b>{{ formatNumber(currentFilters.potRange[0], '$0.0a') }}</b>
                    <v-icon color="primary" size="10" class="ml-1 mb-1">fas fa-pen</v-icon>
                  </div>
                </template>

                <div class="d-flex bg-white align-center justify-space-between pa-2">
                  <v-text-field
                    v-model="currentFilters.potRange[0]"
                    :height="14"
                    flat
                    variant="outlined"
                    density="compact"
                    hide-details
                    type="number"
                  >
                  </v-text-field>
                  <v-btn @click="minmenu = false" color="secondary" class="ml-1">OK</v-btn>
                </div>
              </v-menu>
              <v-spacer></v-spacer>
              <v-menu
                v-model="maxmenu"
                content-class="filter-edit-menus"
                :close-on-content-click="false"
                :close-on-click="false"
                offset="10"
                location="bottom"
              >
                <template v-slot:activator="{ props }">
                  <div v-bind="props">
                    <b>{{ formatNumber(currentFilters.potRange[1], '$0.0a') }}</b>
                    <v-icon color="primary" size="10" class="ml-1 mb-1">fas fa-pen</v-icon>
                  </div>
                </template>

                <div class="d-flex white align-center justify-space-between pa-2">
                  <v-text-field
                    v-model="currentFilters.potRange[1]"
                    :height="14"
                    flat
                    variant="outlined"
                    density="compact"
                    hide-details
                    type="number"
                  >
                  </v-text-field>
                  <v-btn @click="maxmenu = false" color="secondary" class="ml-1">OK</v-btn>
                </div>
              </v-menu>
            </v-layout>
          </v-col>

          <v-col :sm="12" :md="4" cols="12" class="filter-col px-3">
            <v-layout align-center justify-space-between class="filter-header">
              <v-layout>
                <v-icon color="primary" class="mr-3">fas fa-coins</v-icon>
                <span>Score</span>
              </v-layout>
              <v-btn
                size="small"
                icon
                flat
                class="reset-filter-btn"
                v-show="scoreRangeIsDirty"
                @click.stop="(e) => resetFilter('scoreRange')"
              >
                <v-icon small>fas fa-times</v-icon>
              </v-btn>
            </v-layout>
            <v-range-slider
              :model-value="currentFilters.scoreRange || []"
              :max="filters.scoreRange && Math.ceil(filters.scoreRange.max)"
              :min="filters.scoreRange && Math.floor(filters.scoreRange.min)"
              hide-details
              :step="1"
              :track-size="1"
              :thumb-size="12"
              color="primary"
              density="compact"
              class="align-center mt-2 px-3"
              @update:modelValue="
                (e) => {
                  updateScoreRange(e);
                }
              "
            ></v-range-slider>
            <v-layout class="filter-value mx-5" v-if="currentFilters.scoreRange">
              <v-menu
                v-model="minScoreMenu"
                content-class="filter-edit-menus"
                :close-on-content-click="false"
                :close-on-click="false"
                offset="10"
                location="bottom"
              >
                <template v-slot:activator="{ props }">
                  <div v-bind="props">
                    <b>{{ currentFilters.scoreRange[0] }}</b>
                    <v-icon color="primary" size="10" class="ml-1 mb-1">fas fa-pen</v-icon>
                  </div>
                </template>

                <div class="d-flex bg-white align-center justify-space-between pa-2">
                  <v-text-field
                    v-model="currentFilters.scoreRange[0]"
                    :height="14"
                    flat
                    variant="outlined"
                    density="compact"
                    hide-details
                    type="number"
                  >
                  </v-text-field>
                  <v-btn @click="minScoreMenu = false" color="secondary" class="ml-1">OK</v-btn>
                </div>
              </v-menu>
              <v-spacer></v-spacer>
              <v-menu
                v-model="maxScoreMenu"
                content-class="filter-edit-menus"
                :close-on-content-click="false"
                :close-on-click="false"
                offset="10"
                location="bottom"
              >
                <template v-slot:activator="{ props }">
                  <div v-bind="props">
                    <b>{{ currentFilters.scoreRange[1] }}</b>
                    <v-icon color="primary" size="10" class="ml-1 mb-1">fas fa-pen</v-icon>
                  </div>
                </template>

                <div class="d-flex bg-white align-center justify-space-between pa-2">
                  <v-text-field
                    v-model="currentFilters.scoreRange[1]"
                    :height="14"
                    flat
                    variant="outlined"
                    density="compact"
                    hide-details
                    type="number"
                  >
                  </v-text-field>
                  <v-btn @click="maxScoreMenu = false" color="secondary" class="ml-1">OK</v-btn>
                </div>
              </v-menu>
            </v-layout>
          </v-col>

          <v-col :sm="6" :md="4" cols="12" class="filter-col px-3">
            <v-layout align-center justify-space-between class="filter-header">
              <v-layout>
                <v-icon color="primary" class="mr-3">fas fa-percentage</v-icon>
                <span>IRR</span>
              </v-layout>
              <v-btn
                size="small"
                flat
                icon
                class="reset-filter-btn"
                v-show="irrRangeIsDirty"
                @click.stop="(e) => resetFilter('irrRange')"
              >
                <v-icon small>fas fa-times</v-icon>
              </v-btn>
            </v-layout>

            <v-range-slider
              :model-value="currentFilters.irrRange || []"
              :max="filters.irrRange && filters.irrRange.max"
              :min="filters.irrRange && filters.irrRange.min"
              hide-details
              :track-size="1"
              :thumb-size="12"
              color="primary"
              density="compact"
              class="align-center mt-2 px-3"
              @update:modelValue="
                (e) => {
                  updateIrrRange(e);
                }
              "
            ></v-range-slider>

            <v-layout class="filter-value mx-5" v-if="currentFilters.irrRange">
              <v-menu
                v-model="minIrrmenu"
                content-class="filter-edit-menus"
                :close-on-content-click="false"
                :close-on-click="false"
                offset="10"
                location="bottom"
              >
                <template v-slot:activator="{ props }">
                  <div v-bind="props">
                    <b>{{ formatNumber(currentFilters.irrRange[0], '0.0a') }}%</b>
                    <v-icon color="primary" size="10" class="ml-1 mb-1">fas fa-pen</v-icon>
                  </div>
                </template>

                <div class="d-flex bg-white align-center justify-space-between pa-2">
                  <v-text-field
                    v-model="currentFilters.irrRange[0]"
                    :height="14"
                    flat
                    variant="outlined"
                    density="compact"
                    hide-details
                    type="number"
                  >
                  </v-text-field>
                  <v-btn @click="minIrrmenu = false" color="secondary" class="ml-1">OK</v-btn>
                </div>
              </v-menu>
              <v-spacer></v-spacer>
              <v-menu
                v-model="maxIrrmenu"
                content-class="filter-edit-menus"
                :close-on-content-click="false"
                :close-on-click="false"
                offset="10"
                location="bottom"
              >
                <template v-slot:activator="{ props }">
                  <div v-bind="props">
                    <b>{{ formatNumber(currentFilters.irrRange[1], '0.0a') }}%</b>
                    <v-icon color="primary" size="10" class="ml-1 mb-1">fas fa-pen</v-icon>
                  </div>
                </template>

                <div class="d-flex bg-white align-center justify-space-between pa-2">
                  <v-text-field
                    v-model="currentFilters.irrRange[1]"
                    :height="14"
                    flat
                    variant="outlined"
                    density="compact"
                    hide-details
                    type="number"
                  >
                  </v-text-field>
                  <v-btn @click="maxIrrmenu = false" color="secondary" class="ml-1">OK</v-btn>
                </div>
              </v-menu>
            </v-layout>
          </v-col>
          <v-col :sm="6" :md="4" cols="12" class="filter-col px-3">
            <v-layout align-center justify-space-between class="filter-header">
              <v-layout>
                <v-icon color="primary" class="mr-3">fas fa-chart-line</v-icon>
                <span>Benchmark IRR</span>
              </v-layout>
              <v-btn
                size="small"
                flat
                icon
                class="reset-filter-btn"
                v-show="birrRangeIsDirty"
                @click.stop="(e) => resetFilter('birrRange')"
              >
                <v-icon small>fas fa-times</v-icon>
              </v-btn>
            </v-layout>

            <v-range-slider
              :model-value="currentFilters.birrRange || []"
              :max="filters.birrRange && filters.birrRange.max"
              :min="filters.birrRange && filters.birrRange.min"
              hide-details
              :track-size="1"
              :thumb-size="12"
              color="primary"
              density="compact"
              class="align-center mt-2 px-3"
              @update:modelValue="
                (e) => {
                  updateBirrRange(e);
                }
              "
            ></v-range-slider>
            <v-layout class="filter-value mx-5" v-if="currentFilters.birrRange">
              <v-menu
                v-model="minBirrmenu"
                content-class="filter-edit-menus"
                :close-on-content-click="false"
                :close-on-click="false"
                offset="10"
                location="bottom"
              >
                <template v-slot:activator="{ props }">
                  <div v-bind="props">
                    <b>{{ formatNumber(currentFilters.birrRange[0], '0.0a') }}%</b>
                    <v-icon color="primary" size="10" class="ml-1 mb-1">fas fa-pen</v-icon>
                  </div>
                </template>

                <div class="d-flex bg-white align-center justify-space-between pa-2">
                  <v-text-field
                    v-model="currentFilters.birrRange[0]"
                    :height="14"
                    flat
                    variant="outlined"
                    density="compact"
                    hide-details
                    type="number"
                  >
                  </v-text-field>
                  <v-btn @click="minBirrmenu = false" color="secondary" class="ml-1">OK</v-btn>
                </div>
              </v-menu>
              <v-spacer></v-spacer>
              <v-menu
                v-model="maxBirrmenu"
                content-class="filter-edit-menus"
                :close-on-content-click="false"
                :close-on-click="false"
                offset="10"
                location="bottom"
              >
                <template v-slot:activator="{ props }">
                  <div v-bind="props">
                    <b>{{ formatNumber(currentFilters.birrRange[1], '0.0a') }}%</b>
                    <v-icon color="primary" size="10" class="ml-1 mb-1">fas fa-pen</v-icon>
                  </div>
                </template>

                <div class="d-flex bg-white align-center justify-space-between pa-2">
                  <v-text-field
                    v-model="currentFilters.birrRange[1]"
                    :height="14"
                    flat
                    variant="outlined"
                    density="compact"
                    hide-details
                    type="number"
                  >
                  </v-text-field>
                  <v-btn @click="maxBirrmenu = false" color="secondary" class="ml-1">OK</v-btn>
                </div>
              </v-menu>
            </v-layout>
          </v-col>
          <v-col
            cols="12"
            :xs="6"
            :md="4"
            v-if="showStringFilters && filters.dataPoints"
            class="px-7 my-auto providerFilter"
          >
            <v-layout>
              <v-autocomplete
                :model-value="currentFilters.stringVals"
                :items="filters.stringFilterDataPoints"
                :hide-details="true"
                chips
                closable-chips
                variant="underlined"
                :clearable="true"
                :allow-overflow="false"
                color="primary"
                label="Select String Values"
                multiple
                return-object
                @update:modelValue="
                  (e) => {
                    updateStringFilters(e);
                  }
                "
              >
                <template v-slot:chip="{ props, item }">
                  <v-chip
                    v-bind="props"
                    close
                    class="filter-chips mx-1 my-1"
                    size="default"
                    close-icon="fa fa-times-circle"
                    @click:close="removeProvider(item.raw)"
                  >
                    <div v-if="item.raw?.val.length > 15" class="truncate">{{ item.raw?.val }}</div>
                    <div v-else>{{ item.raw?.val }}</div>
                  </v-chip>
                </template>

                <template #item="{ item, props }">
                  <v-divider v-if="'divider' in item.raw" />
                  <v-list-subheader v-else-if="'header' in item.raw" :title="item.raw.header" />
                  <v-list-item v-else v-bind="props" :title="item.raw?.val" :value="item.raw?.val" />
                </template>
              </v-autocomplete>
            </v-layout>
          </v-col>
        </v-row>
        <v-row align="end">
          <v-col cols="12" :xs="6" :md="4" align-self="end" v-if="dateFilters" class="filter-col">
            <v-layout align-center justify-space-between class="filter-header">
              <v-layout>
                <v-icon color="primary" class="mr-3">fas fa-calendar-day</v-icon>
                <span>Contribution Date</span>
              </v-layout>
              <v-btn
                icon
                class="reset-filter-btn"
                size="small"
                flat
                v-show="startDateIsDirty || endDateIsDirty"
                @click.stop="(e) => resetFilter('startDate') && resetFilter('endDate')"
              >
                <v-icon small>fas fa-times</v-icon>
              </v-btn>
            </v-layout>
            <v-layout class="px-3">
              <v-menu
                v-model="startDatePicker"
                :close-on-content-click="false"
                :hide-details="true"
                transition="scale-transition"
                min-width="100px"
              >
                <template v-slot:activator="{ props }">
                  <v-layout d-flex>
                    <v-text-field
                      v-model="longStartDate"
                      variant="underlined"
                      color="primary"
                      label="Start Date"
                      readonly
                      hide-details
                      v-bind="props"
                    ></v-text-field>
                  </v-layout>
                </template>
                <v-date-picker
                  id="startDatepicker"
                  scrollable
                  prev-icon="fa fa-chevron-left"
                  next-icon="fa fa-chevron-right"
                  mode-icon="fa fa-chevron-down"
                  color="primary"
                  :model-value="currentFilters.startDate"
                  @click:cancel="startDatePicker = false"
                  @click:save="startDatePicker = false"
                  @update:modelValue="
                    (e) => {
                      updateStartDate(e);
                    }
                  "
                >
                  <template v-slot:header="{ header }"> </template>
                </v-date-picker>
              </v-menu>

              <v-menu
                v-model="endDatePicker"
                :close-on-content-click="false"
                :hide-details="true"
                transition="scale-transition"
                offset-y
                min-width="100px"
              >
                <template v-slot:activator="{ props }">
                  <v-layout d-flex pl-3>
                    <v-text-field
                      v-model="longEndDate"
                      class="ml-2"
                      variant="underlined"
                      color="primary"
                      hide-details
                      label="End Date"
                      readonly
                      v-bind="props"
                    ></v-text-field>
                  </v-layout>
                </template>
                <v-date-picker
                  id="endDatepicker"
                  scrollable
                  prev-icon="fa fa-chevron-left"
                  next-icon="fa fa-chevron-right"
                  mode-icon="fa fa-chevron-down"
                  color="primary"
                  :model-value="currentFilters.endDate"
                  @click:cancel="endDatePicker = false"
                  @click:save="endDatePicker = false"
                  @update:modelValue="
                    (e) => {
                      updateEndDate(e);
                    }
                  "
                >
                  <template v-slot:header="{ header }"> </template>
                </v-date-picker>
              </v-menu>
            </v-layout>
          </v-col>

          <v-col cols="12" :xs="6" :md="4" v-if="showProviderFilters" class="pb-0 px-7 providerFilter">
            <v-layout v-if="providerFilter">
              <v-autocomplete
                :model-value="currentFilters.providers"
                :items="filters.providers"
                :hide-details="true"
                chips
                closable-chips
                :clearable="true"
                :allow-overflow="false"
                color="primary"
                label="Select Providers"
                item-title="name"
                item-value="id"
                variant="underlined"
                multiple
                @update:modelValue="
                  (e) => {
                    updateProviders(e);
                  }
                "
              >
                <template v-slot:chip="{ props, item }">
                  <v-chip
                    v-bind="props"
                    close
                    class="filter-chips mx-1 my-1"
                    size="default"
                    close-icon="fa fa-times-circle"
                    @click:close="removeProvider(item.raw)"
                  >
                    <div v-if="item.raw?.name.length > 15" class="truncate">{{ item.raw?.name }}</div>
                    <div v-else>{{ item.raw?.name }}</div>
                  </v-chip>
                </template>

                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props" :title="item.raw?.name"> </v-list-item>
                </template>
              </v-autocomplete>
            </v-layout>
          </v-col>
        </v-row>

        <v-row align="end">
          <v-col :xs="12" class="filter-col mb-4">
            <v-layout align-end class="d-flex justify-end mr-5">
              <v-btn variant="text" depressed light v-show="filtersCanBeReset" @click="resetFilters()">Reset</v-btn>
              <v-btn @click.stop="applyFilter" color="success" class="ml-3" :disabled="!filtersAreDirty">Apply</v-btn>
            </v-layout>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import Utils from '../utils/Utils';
import { TOGGLE_FILTER_TABLE } from '@/store/actionTypes';
import Provider from '../models/Provider';

export default class FilterTable extends Vue {
  @Prop({
    default: false,
  })
  public dateFilters!: boolean;

  @Prop({
    default: false,
  })
  public showProviderFilters!: boolean;

  @Prop({
    default: false,
  })
  public showStringFilters!: boolean;

  @Prop({
    default: true,
  })
  public providerFilter!: boolean;

  @Prop({
    type: Object,
    default: () => ({
      potRange: { min: 20, max: 100 },
      scoreRange: { min: 20, max: 100 },
      irrRange: { min: -10, max: 100 },
      birrRange: { min: 1, max: 5 },
      contributionDates: { start: new Date().toISOString().substr(0, 10), end: new Date().toISOString().substr(0, 10) },
    }),
  })
  public filters!: any;

  public slider = 5;

  public appliedFilters: any = {
    potRange: null,
    scoreRange: [],
    irrRange: null,
    birrRange: null,
    startDate: null,
    endDate: null,
    providers: [],
    stringVals: [],
  };

  @Prop()
  public appliedFiltersParent!: any;

  public currentFilters: any = {
    potRange: [],
    scoreRange: [],
    irrRange: [],
    birrRange: [],
    providers: [],
    startDate: null,
    endDate: null,
    stringVals: [],
  };

  public maxmenu = false;

  public minmenu = false;

  public minScoreMenu = false;

  public maxScoreMenu = false;

  public maxBirrmenu = false;

  public minBirrmenu = false;

  public maxIrrmenu = false;

  public minIrrmenu = false;

  public startDatePicker = false;

  public endDatePicker = false;

  public isEditing = true;

  public filtersCanBeReset = false;

  created() {
    this.initializeFilters(this.filters);
    if (this.appliedFiltersParent.birrRange && this.appliedFiltersParent.birrRange.length !== 0) {
      this.filtersCanBeReset = true;
      this.currentFilters.birrRange = [...this.appliedFilters.birrRange];
    }
    if (this.appliedFiltersParent.irrRange && this.appliedFiltersParent.irrRange.length !== 0) {
      this.filtersCanBeReset = true;
      this.currentFilters.irrRange = [...this.appliedFilters.irrRange];
    }
    if (this.appliedFiltersParent.potRange && this.appliedFiltersParent.potRange.length !== 0) {
      this.filtersCanBeReset = true;
      this.currentFilters.potRange = [...this.appliedFiltersParent.potRange];
    }
    if (this.appliedFiltersParent.providers && this.appliedFiltersParent.providers.length !== 0) {
      this.filtersCanBeReset = true;
      this.currentFilters.providers = [...this.appliedFiltersParent.providers];
    }
    if (this.appliedFiltersParent.scoreRange && this.appliedFiltersParent.scoreRange.length !== 0) {
      this.filtersCanBeReset = true;
      this.currentFilters.scoreRange = [...this.appliedFiltersParent.scoreRange];
    }

    if (this.appliedFiltersParent.stringVals && this.appliedFiltersParent.stringVals.length !== 0) {
      this.filtersCanBeReset = true;
      this.currentFilters.stringVals = [...this.appliedFiltersParent.stringVals];
    }

    if (this.appliedFiltersParent.startDate) {
      this.filtersCanBeReset = true;
      this.currentFilters.startDate = this.appliedFiltersParent.startDate;
    }
    if (this.appliedFiltersParent.endDate) {
      this.filtersCanBeReset = true;
      this.currentFilters.endDate = this.appliedFiltersParent.endDate;
    }
  }

  public get longStartDate() {
    return this.currentFilters.startDate ? this.toLongDate(this.currentFilters.startDate) : '';
  }
  public get longEndDate() {
    return this.currentFilters.endDate ? this.toLongDate(this.currentFilters.endDate) : '';
  }

  public toggleFilter() {
    return this.$store.dispatch(TOGGLE_FILTER_TABLE);
  }

  public removeProvider(item: Provider) {
    const index = this.currentFilters.providers.indexOf(item.id);
    if (index >= 0) {
      this.currentFilters.providers.splice(index, 1);
    }
  }

  public removeStrings(item: Provider) {
    const filteredArr = this.currentFilters.stringVals.filter((elm: any) => elm.id !== item.id);
    this.currentFilters.stringVals = filteredArr;
  }

  public toLongDate(date: string) {
    return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  }

  public updatePotRange(range: number[]) {
    this.currentFilters.potRange = range;
  }

  public updateScoreRange(range: number[]) {
    this.currentFilters.scoreRange = range;
  }

  public updateIrrRange(range: number[]) {
    this.currentFilters.irrRange = range;
  }

  public updateBirrRange(range: number[]) {
    this.currentFilters.birrRange = range;
  }

  public updateProviders(providers: number[]) {
    this.currentFilters.providers = providers;
  }

  public updateStringFilters(values: any[]) {
    this.currentFilters.stringVals = values;
  }

  public updateStartDate(date: any) {
    this.currentFilters.startDate = Utils.formatDate(new Date(date), 'yyyy-MM-dd');
  }

  public updateEndDate(date: any) {
    this.currentFilters.endDate = Utils.formatDate(new Date(date), 'yyyy-MM-dd');
  }

  public get filtersAreDirty() {
    return [
      this.irrRangeIsDirty,
      this.birrRangeIsDirty,
      this.potRangeIsDirty,
      this.scoreRangeIsDirty,
      this.providersAreDirty,
      this.startDateIsDirty,
      this.endDateIsDirty,
      this.stringsAreDirty,
    ].some((i) => i);
  }

  public get irrRangeIsDirty() {
    if (!this.currentFilters.irrRange || !this.appliedFilters.irrRange) {
      return false;
    }

    return this.currentFilters.irrRange.some((n: number, i: number) => n !== this.appliedFilters.irrRange[i]);
  }

  public get birrRangeIsDirty() {
    if (!this.currentFilters.birrRange || !this.appliedFilters.birrRange) {
      return false;
    }

    return this.currentFilters.birrRange.some((n: number, i: number) => n !== this.appliedFilters.birrRange[i]);
  }

  public get potRangeIsDirty() {
    if (!this.currentFilters.potRange || !this.appliedFilters.potRange) {
      return false;
    }

    return this.currentFilters.potRange.some((n: number, i: number) => n !== this.appliedFilters.potRange[i]);
  }

  public get scoreRangeIsDirty() {
    if (!this.currentFilters.scoreRange || !this.appliedFilters.scoreRange) {
      return false;
    }

    return this.currentFilters.scoreRange.some((n: number, i: number) => n !== this.appliedFilters.scoreRange[i]);
  }

  public get providersAreDirty() {
    if (!this.currentFilters.providers || !this.appliedFilters.providers) {
      return false;
    }
    const lengthIsDifferent = this.currentFilters.providers.length !== this.appliedFilters.providers.length;
    const itemsAreDifferent = this.currentFilters.providers.some((n: number, i: number) => {
      return +n !== +this.appliedFilters.providers[i];
    });
    return lengthIsDifferent || itemsAreDifferent;
  }

  public get stringsAreDirty() {
    if (!this.currentFilters.stringVals || !this.appliedFilters.stringVals) {
      return false;
    }
    const lengthIsDifferent = this.currentFilters.stringVals.length !== this.appliedFilters.stringVals.length;
    const itemsAreDifferent = this.currentFilters.stringVals.some((n: number, i: number) => {
      return +n !== +this.appliedFilters.stringVals[i];
    });
    return lengthIsDifferent || itemsAreDifferent;
  }

  public get startDateIsDirty() {
    if (!this.currentFilters.startDate || !this.appliedFilters.startDate) {
      return false;
    }

    return this.currentFilters.startDate !== this.appliedFilters.startDate;
  }

  public get endDateIsDirty() {
    if (!this.currentFilters.endDate || !this.appliedFilters.endDate) {
      return false;
    }

    return this.currentFilters.endDate !== this.appliedFilters.endDate;
  }

  public resetFilter(filter: string) {
    if (['potRange', 'scoreRange', 'irrRange', 'birrRange', 'providers', 'stringVals'].includes(filter)) {
      this.currentFilters[filter] = [...this.appliedFilters[filter]];
      return true;
    }
    this.currentFilters[filter] = this.appliedFilters[filter];
    return true;
  }

  public async resetFilters(fromParent = false) {
    this.initializeFilters(this.filters);
    this.filtersCanBeReset = false;
    if (!fromParent) {
      this.$emit('apply', {});
    }
  }

  public async applyFilter(filtersCanBeReset = true) {
    this.filtersCanBeReset = filtersCanBeReset;
    Object.keys(this.currentFilters || {}).forEach((filter: string) => {
      if (['potRange', 'scoreRange', 'irrRange', 'birrRange', 'providers', 'stringVals'].includes(filter)) {
        this.appliedFilters[filter] = [...this.currentFilters[filter]];
      } else {
        this.appliedFilters[filter] = this.currentFilters[filter];
      }
    });

    let filters: any = {
      potRange: this.appliedFilters.potRange,
      scoreRange: this.appliedFilters.scoreRange,
      irrRange: this.appliedFilters.irrRange.map((i: number) => i / 100),
      birrRange: this.appliedFilters.birrRange.map((i: number) => i / 100),
    };

    if (this.dateFilters) {
      filters = {
        ...filters,
        startDate: this.appliedFilters.startDate,
        endDate: this.appliedFilters.endDate,
      };
    }

    if (this.showProviderFilters) {
      filters = {
        ...filters,
        providers: this.appliedFilters.providers,
      };
    }

    if (this.showStringFilters) {
      filters = {
        ...filters,
        stringVals: this.appliedFilters.stringVals,
      };
    }
    // console.log('sending filters from filtertable', filters);
    this.$emit('apply', filters);
  }

  public formatNumber(n: number, format = '0.0a') {
    return Utils.formatNumber(n, format);
  }

  private initializeFilters(filters: any) {
    console.log('initializeFilters', filters);
    if (!filters) {
      return;
    }

    if (filters.irrRange) {
      this.currentFilters.irrRange = [Math.ceil(filters.irrRange.min), Math.ceil(filters.irrRange.max)];
      this.appliedFilters.irrRange = [Math.ceil(filters.irrRange.min), Math.ceil(filters.irrRange.max)];
    }

    if (filters.birrRange) {
      this.currentFilters.birrRange = [Math.ceil(filters.birrRange.min), Math.ceil(filters.birrRange.max)];
      this.appliedFilters.birrRange = [Math.ceil(filters.birrRange.min), Math.ceil(filters.birrRange.max)];
    }

    if (filters.potRange) {
      this.currentFilters.potRange = [Math.ceil(filters.potRange.min), Math.ceil(filters.potRange.max)];
      this.appliedFilters.potRange = [Math.ceil(filters.potRange.min), Math.ceil(filters.potRange.max)];
    }

    if (filters.scoreRange) {
      this.currentFilters.scoreRange = [Math.floor(filters.scoreRange.min), Math.ceil(filters.scoreRange.max)];
      this.appliedFilters.scoreRange = [Math.floor(filters.scoreRange.min), Math.ceil(filters.scoreRange.max)];
    }

    if (filters.contributionDates) {
      this.currentFilters.startDate = filters.contributionDates.start;
      this.appliedFilters.startDate = filters.contributionDates.start;
      this.currentFilters.endDate = filters.contributionDates.end;
      this.appliedFilters.endDate = filters.contributionDates.end;
    }
    this.currentFilters.providers = [];
    this.currentFilters.stringVals = [];
  }
}
</script>

<style lang="scss">
@import '@/sass/bootstrap.scss';

.filter-card {
  .filter-col {
    margin: auto 0 !important;
    // .v-input__slot {
    //   margin-bottom: 0;
    // }
    // .v-text-field__details {
    //   display: none;
    // }
  }
}

.filter-header {
  font-weight: 700;
  font-size: 1rem;
  padding: 0.5rem;
  height: 32px;
  color: $brand-primary;
}

.v-picker-title {
  display: none;
}

.filter-value {
  font-size: 0.85rem;
  color: lighten($brand-primary, 12%);
}

.filter-chips {
  background-color: #300078 !important;
  color: white !important;
  .v-chip__underlay {
    opacity: 0 !important;
    background: unset !important;
  }
}

.filter-edit-menus {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1), 0 8px 10px 1px rgba(0, 0, 0, 0.07), 0 3px 14px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.providerFilter {
  margin-bottom: 1rem !important;
  .count-text {
    font-size: 0.8rem;
  }
}
.truncate {
  width: 7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-chip__close.v-icon,
.v-btn--fab.v-size--default .v-icon {
  font-size: 1rem;
}

.reset-filter-btn {
  width: 24px !important;
  height: 24px !important;
  & .v-icon {
    font-size: 1rem !important;
  }
}
</style>
