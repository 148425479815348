import * as MT from '@/store/mutationTypes';
import { IRootState } from '@/store/state';
import { MutationTree, Store } from 'vuex';
/**
 * --------------------------------------------------
 *	Root Mutations
 * --------------------------------------------------
 **/
export const RootMutations: IRootMutations = {
  [MT.SET_THEME]: (state: IRootState, status: boolean) => (state.dark = status),
  [MT.SET_DATA_UPLOAD_DIALOG_VISIBILITY]: (state: IRootState, status: boolean) =>
    (state.dataUploadDialogIsVisible = status),
  [MT.SET_NAV_DRAWER_VISIBILITY]: (state: IRootState) => {
    state.navDrawerIsVisible = !state.navDrawerIsVisible;
  },
  [MT.SET_FILTER_VISIBILITY]: (state: IRootState) => {
    state.filterIsVisible = !state.filterIsVisible;
  },
  [MT.SET_TABLE_SELECTED](state: any, value: boolean) {
    state.selectedItems = value;
  },
  [MT.SET_NAV_HEADER](state: any, value: string) {
    state.navHeader = value;
  }
};

/**
 * --------------------------------------------------
 *	Root Mutations Interface
 * --------------------------------------------------
 **/
export interface IRootMutations extends MutationTree<IRootState> {}
