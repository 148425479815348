import { ReportActions } from '@/modules/benchmarking/store/actions';
import { ReportGetters } from '@/modules/benchmarking/store/getters';
import { ReportMutations } from '@/modules/benchmarking/store/mutations';
import { ReportState, IReportState } from '@/modules/benchmarking/store/state';
import { IRootState } from '@/store/state';
import { Module } from 'vuex';

export const BenchmarkingStore: IReportStore = {
  state: ReportState,
  actions: ReportActions,
  getters: ReportGetters,
  mutations: ReportMutations
};

/**
 * --------------------------------------------------
 *	Reports Store Interface
 * --------------------------------------------------
 **/
export interface IReportStore extends Module<IReportState, IRootState> {}
