declare var window: Window & typeof globalThis & {
  [key: string]: any;
};
let lastTime = 0;
const prefixes = 'webkit moz ms o'.split(' ');

let requestAnimationFrame;
let cancelAnimationFrame;

const isServer = typeof window === 'undefined';
if (isServer) {
  requestAnimationFrame = () => {
    return;
  };
  cancelAnimationFrame = () => {
    return;
  };
} else {
  requestAnimationFrame = window.requestAnimationFrame;
  cancelAnimationFrame = window.cancelAnimationFrame;

  // for testing
  // for (let i = 0; i < prefixes.length; i++) {
  //   if (requestAnimationFrame && cancelAnimationFrame) {
  //     break;
  //   }
  //   const prefix = prefixes[i];
  //   requestAnimationFrame = requestAnimationFrame || window[prefix + 'RequestAnimationFrame'];
  //   cancelAnimationFrame =
  //     cancelAnimationFrame || window[prefix + 'CancelAnimationFrame'] || window[prefix + 'CancelRequestAnimationFrame'];
  // }

  if (!requestAnimationFrame || !cancelAnimationFrame) {
    requestAnimationFrame = (callback: Function) => {
      const currTime = new Date().getTime();
      const timeToCall = Math.max(0, 16 - (currTime - lastTime));
      const id = window.setTimeout(() => {
        callback(currTime + timeToCall);
      }, timeToCall);
      lastTime = currTime + timeToCall;
      return id;
    };

    cancelAnimationFrame = (id: number) => {
      window.clearTimeout(id);
    };
  }
}

export { requestAnimationFrame, cancelAnimationFrame };
