export type FilterProp = {
  unitType?: boolean;
  strategy?: boolean;
  costTypes?: boolean;
  managers?: boolean;
  overviewProducts?: boolean;
  products?: boolean;
  assetClasses?: boolean;
  aum?: boolean;
};

export type AppliedFilters = {
  unitType?: {
    label: string;
    value: string;
  };
  strategy?: {
    id: number;
    name: string;
    slug: string;
  };
  costTypes?: any[] | undefined;
  managers?: any[];
  products?: any[];
  assetClasses?: any[];
  aum?: {
    aumStart: number;
    aumEnd: number;
  }
  [key: string]: any;
};

export type FilterSwitch = {
  property: string;
  data: any;
};

export type CostType = {
  id: number;
  name: string;
  parent_id: number;
};

export type AuM = {
  id: number;
  name: string;
  aum_amount: number;
  closing_amount: number;
  opening_amount: number;
};

export type ProductAuMBreakdown = {
  id: number;
  name: number;
  asset_classes: AuM[];
}

export type ProductCompliance = {
  id: number;
  name: string;
  is_compliant: boolean;
  updated_at: string;
};

export type CostTree = {
  id: number;
  name: string;
  entity: string;
  amount: number;
  parent_id?: number | null;
  costs?: CostTree[] | null;
  asset_costs?: CostTree[] | null;
};

export enum UnitType {
  GBP = 'gbp',
  PERCENT = 'percent',
  BPS = 'bps'
}
