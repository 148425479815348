import * as MT from '@/modules/benchmarks/store/mutationTypes';
import { IBenchmarkState } from '@/modules/benchmarks/store/state';
import { MutationTree } from 'vuex';
import { BenchmarkListItems, BenchmarkObject } from '@/models/Benchmark';

/*--------------------------------------------------/
 * Benchmark Mutations
 *--------------------------------------------------*/
export const BenchmarkMutations: IBenchmarkMutations = {
  [MT.SET_BENCHMARKS](state: IBenchmarkState, benchmarks: BenchmarkListItems[]) {
    state.benchmarks = benchmarks;
  },
  [MT.SET_BENCHMARK](state: IBenchmarkState, benchmark: BenchmarkObject) {
    state.currentBenchmark = benchmark;
  },
  [MT.ADDING_BENCHMARK](state: IBenchmarkState, addingBenchmark: boolean) {
    state.addingBenchmark = addingBenchmark;
  },
};

/*--------------------------------------------------/
 * Benchmark Mutations Tree Interface
 *--------------------------------------------------*/
export interface IBenchmarkMutations extends MutationTree<IBenchmarkState> {}
