import ProviderModule from '@/modules/providers/ProviderModule.vue';
import { ProviderAnalysisRoutes } from '@/modules/providers-analysis/routes';

import ListProvidersPage from '@/modules/providers/pages/ListProvidersPage.vue';

export const ProviderRoutes = [
  {
    path: '/providers',
    component: ProviderModule,
    children: [
      {
        path: '',
        name: 'dashboard.providers.list',
        component: ListProvidersPage
      }
    ]
  },
  ...ProviderAnalysisRoutes
];
