import { IRootState } from '@/store/state';
import { IReportState } from '@/modules/benchmarking/store/state';
import { GetterTree } from 'vuex';

/**
 * --------------------------------------------------
 *	Report Getters
 * --------------------------------------------------
 **/
export const ReportGetters: IReportGetters = {
         fetchingBechmarkingReports: (state: IReportState) => state.fetchingBechmarkingReports,
         bechmarkingReports: (state: IReportState) => state.bechmarkingReports,
       };

/**
 * --------------------------------------------------
 *	Report Getters Interface
 * --------------------------------------------------
 **/
export interface IReportGetters extends GetterTree<IReportState, IRootState> {}
