import { IRootState } from '@/store/state';
import { IUserState } from '@/modules/users/store/state';
import { GetterTree } from 'vuex';

/**
 * --------------------------------------------------
 *	User Getters
 * --------------------------------------------------
 **/
export const UserGetters: IUserGetters = {
  users: (state: any) => state.users,
  fetchingUsers: (state: any) => state.fetchingUsers
};

/**
 * --------------------------------------------------
 *	User Getters Interface
 * --------------------------------------------------
 **/
export interface IUserGetters extends GetterTree<IUserState, IRootState> {}
