<template>
  <div>
    <div class="report-template">
      <v-card class="report-page-card top-right-color-triangle" outlined flat>
        <div class="top-triangle">
          <div class="top-right-color-triangle"></div>
          <div class="top-right-grey-triangle"></div>
        </div>
        <div class="card-data">
          <div style="font-size: 30px; margin-top: 5rem; font-weight: 500; text-align: center">
            Private and Confidential
          </div>
          <div class="main-page-feature">
            <div class="feature-title">{{ reportData.providerName }}</div>
            <div class="feature-sub-header">Performance Attribution Report</div>
            <div class="feature-sub-title">Analysing “value for risk taken”</div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>
    <div class="report-template">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="page-content" style="margin-top: 2rem">
            <div class="page-content-text">
              The AgeWage value for money (VFM) report provides a snapshot of the performance achieved by members of a
              DC pension scheme, both at scheme level and individually. VFM talks to “what” has been through AgeWage
              scores but doesn’t explain the causes of high or low scores. This Performance Attribution report aims to
              answer the question “can there be a good low score or a bad high score?”. It suggests that good execution
              may be a more sustainable way of creating value than taking bets on the market. It gives insights based on
              the sequencing of risks and offers deeper analysis of the impact of external events in exposed markets.
              While AgeWage VFM reporting is helpful in understanding absolute returns, this Performance Attribution
              report lets the data tell us the value received for the risk taken.
            </div>

            <div class="page-content-title">Benchmark Index</div>
            <div class="page-content-text">
              The benchmark index is used for all AgeWage scores and is the means by which we standardise VFM so that
              one score can be compared to another – at individual and scheme level. The benchmark index has been
              constructed by Hymans Robertson with varying asset allocations over time, as detailed in the following
              table:
            </div>

            <div>
              <div style="margin: 20px; display: flex; justify-content: center; margin-left: auto; margin-right: auto">
                <table style="width: 100%">
                  <tr>
                    <th>Effective Date</th>
                    <th>Overseas Equity</th>
                    <th>UK Equity</th>
                    <th>Emerging Equity</th>
                    <th>Fixed Income</th>
                    <th>Cash</th>
                    <th>Property & Alternatives</th>
                  </tr>
                  <tr>
                    <td>Dec 1998</td>
                    <td>45%</td>
                    <td>35%</td>
                    <td></td>
                    <td>15%</td>
                    <td>5%</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Dec 2013</td>
                    <td>45%</td>
                    <td>32.5%</td>
                    <td>2.5%</td>
                    <td>10%</td>
                    <td>5%</td>
                    <td>5%</td>
                  </tr>
                  <tr>
                    <td>Dec 2016</td>
                    <td>40%</td>
                    <td>25%</td>
                    <td>5%</td>
                    <td>20%</td>
                    <td>5%</td>
                    <td>5%</td>
                  </tr>
                  <tr>
                    <td>Dec 2018</td>
                    <td>50%</td>
                    <td>10%</td>
                    <td>5%</td>
                    <td>20%</td>
                    <td>5%</td>
                    <td>10%</td>
                  </tr>
                  <tr>
                    <td>Dec 2020</td>
                    <td>52.2%</td>
                    <td>6%</td>
                    <td>1.8%</td>
                    <td>26.8%</td>
                    <td>7.9%</td>
                    <td>5.3%</td>
                  </tr>
                  <tr>
                    <td>Dec 2021</td>
                    <td>55%</td>
                    <td>4.2%</td>
                    <td>2%</td>
                    <td>25.5%</td>
                    <td>7.1%</td>
                    <td>6.2%</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="page-content-text">
              The index is rebalanced on a quarterly basis and the following charges have been integrated with the fund
              prices to make the index investable:
            </div>
            <div style="margin: 20px; display: flex; justify-content: center">
              <table style="width: 50%">
                <tr>
                  <th>Time period</th>
                  <th>Charges (bps)</th>
                </tr>
                <tr>
                  <td>June 1980 - Mar 2001</td>
                  <td>150</td>
                </tr>
                <tr>
                  <td>Apr 2001 - Mar 2015</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>Apr 2015 - present</td>
                  <td>75</td>
                </tr>
              </table>
            </div>
            <div class="page-content-text">
              The composition of the index and the charges create a benchmark Internal Rate of Return for each
              contribution history analysed. The benchmark returns are compared with the portfolio returns to determine
              the level of risk taken in any fund used by members and particularly, the default fund.
            </div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>
    <div class="report-template">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="page-content">
            <div class="page-content-title">
              Key innovation – “experienced performance” incorporates all risks taken
            </div>
            <div class="page-content-text">
              The key innovation of this analysis is that it looks at experienced performance, after costs and charges
              and incorporates the specific risks experienced by the member. These risks can include the sequencing risk
              of the timing and incidence of contributions, the risks from hidden transaction costs within the funds as
              well as the risks experienced from discretionary decisions on asset allocation and stock selection. By
              analysing experienced data, the performance attribution looks at the value for risk taken, from the
              perspective of the members. Insights are created with very limited information. By working just with
              current pot values and contribution histories, data integrity and costs are controlled without sacrificing
              valuable insights.
            </div>
            <div class="page-content-title">Who needs to analyse “value for risk taken”?</div>
            <div class="page-content-text">
              AgeWage has generated its performance attribution service as a result of feedback from Trustees and Master
              Trust providers for whom we have analysed more than 2mn pension pots. AgeWage envisions those who will
              commission its attribution analysis service will primarily be workplace pension fiduciaries (IGCs, GAAs,
              Trustees and their investment advisers). AgeWage also sees this analysis as helpful to platform and fund
              managers charged with producing Value Assessments. The outputs of the analysis are designed to be shared
              with participating sponsors and AgeWage will work with the sponsors to communicate the results to members
              if needed.
            </div>
            <div class="page-content-title">Creating an “experienced price index” for each data set</div>
            <div class="page-content-text">
              Where a specific fund is being analysed (typically the default), we create an experienced price index
              which is different from the gross fund return series as it takes into account the experience of the
              members in the data set. Using a minimal amount of data (contribution histories and pot values) and no
              information on funds, fees and expenses, AgeWage identifies a cohort of members who appear to have been
              exposed to the same investment strategy. The portfolio index prices experienced by members on various
              contribution dates is calculated using an exhaustive iteration technique. The portfolio index is then
              compared with the benchmark index to generate the risk adjusted outcome enhancement or outcome shortfall.
            </div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>
    <div class="report-template">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="page-content">
            <div class="page-content-title">What factors affecting member outcomes do we focus on?</div>
            <div class="page-content-text">
              Divergence of the portfolio performance from the benchmark index could be related to costs, fees, asset
              allocation, stock selection, bid offer spread or other variables inherent to members’ net asset values.
              Figure 1 represents factors which would cause deviation from the benchmark index. This report focuses on
              the effect of strategic asset allocation and sequential risks and to some extent duration of investments.
            </div>
            <br />
            <div class="page-content-text">
              We confine our analysis to what the data tells us and this excludes some factors from our analysis. For
              instance, We have not segregated the effect of charges because it cannot be ascertained from the data.
              While fees and charges can be gathered from Trustee and IGC chair statements, their reporting is subject
              to differences in calculation methodology. Our analysis is based strictly on data received and is
              therefore consistent and indisputable.
            </div>
            <div style="margin-top: 3rem">
              <v-img src="../../../../assets/pa-analysis-img.jpg" />
            </div>
            <div class="chart-legend">Figure 1: Factors affecting member outcome</div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>
    <div class="report-template">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="page-content">
            <div class="page-content-title sub-header-title">{{ reportData.providerName }} Attribution Analysis</div>
            <div class="page-content-text">
              The following results are from the analysis conducted for a cohort of
              {{ numberWithCommas(reportData.memberCount) }} members over the time period of
              {{ reportData.timePeriod }}.
              <br />
            </div>

            <div class="page-content-title">Level of Risk Taken</div>
            <div class="page-content-text">
              The level of risk taken is represented by Beta (ß) which indicates the relative returns of the fund
              compared to the AgeWage benchmark index:
            </div>

            <div class="page-content-title text-center">Beta (ß) = {{ reportData.Beta }}</div>

            <div class="page-content-text">
              The above Beta (ß) indicates that for a 1% benchmark return, the portfolio should have returned
              {{ reportData.Beta }}% on a risk-adjusted basis. Hence if the portfolio returned more than
              {{ reportData.Beta }} times the benchmark returns, it enhanced the outcome compared to the benchmark. As
              the Beta is {{ +reportData.Beta > 1 ? 'over' : 'under' }} 1, savers have been exposed to
              {{ reportData.riskCompare }} risk than benchmark and should be expecting a
              {{ reportData.riskCompare }} return {{ reportData.returnCompare }} the benchmark index over time.
            </div>

            <div class="page-content-title">Risk Adjusted Outcome</div>
            <div class="page-content-text">
              The risk-adjusted outcome represents the impact of factors such as costs, fees, stock selection, bid offer
              spread and other variables on the investment portfolio returns. The experienced portfolio index is
              compared to the benchmark index to understand the level of risk taken. It is subsequently used to
              calculate the outcome enhancement / shortfall based on the level of risk taken. Based on the data analysis
              conducted for {{ numberWithCommas(reportData.memberCount) }}
              members, the following portfolio outcome was determined (using the benchmark index as a comparison):
            </div>

            <div class="page-content-title text-center">
              Risk Adjusted Annual Outcome
              {{ reportData.SAAValue && reportData.SAAValue > 0 ? 'Enhancement' : 'Shortfall' }} =
              {{ reportData.SAAValue && Math.abs(reportData.SAAValue) }}%
            </div>

            <div class="page-content-text">
              The calculation indicates that <b> after adjusting for risks</b>, the {{ reportData.providerName }} fund,
              on average, has generated {{ reportData.SAAValue && Math.abs(reportData.SAAValue) }}%
              {{ reportData.benchmarkCompare }} returns {{ reportData.returnCompare }} the benchmark index annually from
              {{ reportData.timePeriod }}
            </div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>
    <div class="report-template" v-if="currentFilters.priceTrackChart">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="page-content">
            <div class="page-content-title">Price Track Comparison</div>
            <div class="page-content-text">
              Figure {{ getPriceTrackFigCount() }} shows how the portfolio price index has changed over time compared to
              the benchmark index. The difference between the indexes highlights the effects of asset allocation, fees
              and charges, stock selection, sequential risk and other variables on the portfolio's performance.
            </div>
            <div class="report-chart">
              <PriceTrackChart :chartData="priceTrackOptions()" />
            </div>
            <div class="chart-legend">Figure {{ getPriceTrackFigCount() }}: Portfolio vs Benchmark price track</div>
            <div class="page-content-text" style="margin-top: 10px">
              <v-textarea
                variant="solo"
                class="free-text"
                style="font-size: 14px"
                placeholder="Enter Text..."
                color="primary"
                append-inner-icon="fa fa-check-circle"
                id="freeTextPriceTrack"
                @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
                @click:append="endEdit('freeTextPriceTrack')"
                v-model="freeTextPriceTrack"
                auto-grow
                :loading="
                  $store.getters.addingFreeText.status && $store.getters.addingFreeText.key === 'freeTextPriceTrack'
                "
                :rules="[(v) => v.length < freeTextPriceTrackLimit || 'Max character limit exceeded.']"
              />
            </div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>

    <div class="report-template" v-if="currentFilters.irrSingle">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="page-content">
            <div class="page-content-title">Sequencing Risk</div>
            <div class="page-content-text">
              Sequencing risk is the risk that the timing and incidence of contributions leads to unfavourable
              investment returns and less money in the eventual retirement pot. While it is impossible to completely
              safeguard against sequencing risk, regular contributions to the portfolio and diversification of assets
              can help reduce its impact.
              <br />
              <br />
              The following analysis shows how the sequencing risk has impacted returns over time using two different
              contribution histories invested in the experienced portfolio. It compares the outcome from a single
              contribution in the portfolio to the outcome of regular contributions over the same timeframe to highlight
              the effects of sequencing risk on savers’ outcomes.
            </div>

            <div class="page-content-title">Single Contribution</div>
            <div class="page-content-text">
              The following graph is created for a member with a single contribution of £1,000 made in
              {{ reportData.sContributionStart }} in the portfolio index.The £1,000 is allowed to stay invested in the
              portfolio without any further contributions. Using the portfolio index prices generated via the
              contribution history, the asset values for the member is calculated at different points in time to
              determine portfolio IRRs. The portfolio IRRs are compared with the benchmark IRRs for the same
              contribution profile
            </div>
            <div class="report-chart">
              <IRRSingleContributionChart :chartData="IRRSingleOptions()" />
            </div>
            <div class="chart-legend">
              Figure {{ getIrrSingleFigCount() }}: IRRs on various dates with single contribution on
              {{ reportData.irrSingleStartDate }}
            </div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>

    <div class="report-template" v-if="currentFilters.irrSingleHeatmap">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="page-content">
            <!-- <div class="page-content-text">
              The valuations of the contribution simply mirrored the fortunes of the portfolio index relative to the
              benchmark without being able to take advantage of pound cost averaging.
            </div> -->
            <div class="page-content-text" v-if="currentFilters.irrSingle">
              <v-textarea
                variant="solo"
                class="free-text"
                style="font-size: 14px"
                placeholder="Enter Text..."
                append-inner-icon="fa fa-check-circle"
                id="freeTextIrrSingle"
                @click:append="endEdit('freeTextIrrSingle')"
                @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
                v-model="freeTextIrrSingle"
                auto-grow
                :loading="
                  $store.getters.addingFreeText.status && $store.getters.addingFreeText.key === 'freeTextIrrSingle'
                "
                :rules="[(v) => v.length < freeTextIrrSingleLimit || 'Max character limit exceeded.']"
              />
            </div>
            <br />
            <div class="page-content-text">
              The above methodology is then extended to understand the impact of duration and timing of investments for
              single contribution members in the portfolio. The figures below show the IRRs achieved as a function of
              contribution date and time period of investment.
            </div>
          </div>
          <div class="report-chart">
            <IRRSingleHeatmap :chartData="reportData.irrSingleHeatmap" />
          </div>
          <div class="chart-legend">
            Figure {{ getIrrSingleHeatmapFigCount() }}: Heat map illustrating IRR as a function of single contribution
            date and duration of investment
          </div>
          <div class="page-content" style="margin-top: 10px !important">
            <div class="page-content-text">
              <v-textarea
                variant="solo"
                class="free-text"
                style="font-size: 14px"
                placeholder="Enter Text..."
                append-inner-icon="fa fa-check-circle"
                id="freeTextIrrSingleHeatmap"
                @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
                @click:append="endEdit('freeTextIrrSingleHeatmap')"
                v-model="freeTextIrrSingleHeatmap"
                auto-grow
                :loading="
                  $store.getters.addingFreeText.status &&
                  $store.getters.addingFreeText.key === 'freeTextIrrSingleHeatmap'
                "
                :rules="[(v) => v.length < freeTextIrrSingleHeatmapLimit || 'Max character limit exceeded.']"
              />
            </div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>

    <div class="report-template" v-if="currentFilters.irrSingle3d">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="report-chart">
            <IRRSingleScatter :chartData="irrSingleScatter()" />
          </div>
          <div class="chart-legend">
            Figure {{ getIrrSingle3dFigCount() }}: 3D map illustrating IRR as a function of single contribution date and
            duration of
            <br />
            investment
          </div>
          <div class="page-content">
            <div class="page-content-text" style="margin-top: 10px">
              <v-textarea
                variant="solo"
                class="free-text"
                style="font-size: 14px"
                placeholder="Enter Text..."
                append-inner-icon="fa fa-check-circle"
                id="freeTextIrrSingleScatter"
                @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
                @click:append="endEdit('freeTextIrrSingleScatter')"
                v-model="freeTextIrrSingleScatter"
                auto-grow
                :loading="
                  $store.getters.addingFreeText.status &&
                  $store.getters.addingFreeText.key === 'freeTextIrrSingleScatter'
                "
                :rules="[(v) => v.length < freeTextIrrSingleScatterLimit || 'Max character limit exceeded.']"
              />
            </div>
            <!-- <br />
            <div class="page-content-text">
              The heat map and 3-D graph show the variability in returns with single contribution. The maximum and minimum returns for a single
              contribution made at any point between
              {{ reportData.irrSingleYearRange }} for a minimum period of 1 year is
              {{ reportData.irrSingleValRange }} respectively, illustrating the variability in returns.
            </div> -->
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>

    <div class="report-template" v-if="currentFilters.irrRegular">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="page-content">
            <div class="page-content-title">Regular Contributions</div>
            <div class="page-content-text">
              The following graph has been created for a member with regular contributions of £100 made every
              {{ reportData.dateSelection }} from {{ reportData.rContributionStart }} until
              {{ reportData.rContributionEnd }} in the portfolio index. It uses the portfolio index prices generated
              from the contribution history, the asset values for the member are calculated at different points in time
              (using the contributions up to that point) to calculate portfolio IRRs. The portfolio IRRs are compared
              with the benchmark IRRs calculated using the benchmark index for the same contribution profile.
            </div>
          </div>
          <div class="report-chart">
            <IRRRegularContributionChart :chartData="IRRRegularOptions()" />
          </div>
          <div class="chart-legend">
            Figure {{ getIrrRegularFigCount() }}: IRRs on various dates with regular contribution starting from
            {{ reportData.irrRegularStartDate }}
          </div>
          <div class="page-content">
            <div class="page-content-text">
              Here the graph shows the IRRs getting closer to the benchmark as a result of pound cost averaging, which
              reduces the variability of the portfolio returns.
            </div>
            <div class="page-content-text" style="margin-top: 10px">
              <v-textarea
                variant="solo"
                class="free-text"
                style="font-size: 14px"
                placeholder="Enter Text..."
                append-inner-icon="fa fa-check-circle"
                id="freeTextIrrRegular"
                @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
                @click:append="endEdit('freeTextIrrRegular')"
                v-model="freeTextIrrRegular"
                auto-grow
                :loading="
                  $store.getters.addingFreeText.status && $store.getters.addingFreeText.key === 'freeTextIrrRegular'
                "
                :rules="[(v) => v.length < freeTextIrrRegularLimit || 'Max character limit exceeded.']"
              />
            </div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>
    <div class="report-template" v-if="currentFilters.irrRegularHeatmap">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="page-content">
            <div class="page-content-text">
              The above methodology can now be extended to understand the impact of duration and timing of investments
              for regular contribution members in the portfolio. The figures below show the IRRs achieved as a function
              of contribution dates and time period of investments.
            </div>
          </div>
          <div class="report-chart">
            <IRRRegularHeatmap :chartData="reportData.irrRegularHeatmap" />
          </div>
          <div class="chart-legend">
            Figure {{ getIrrRegularHeatmapFigCount() }}: Heat map illustrating IRR as a function of regular contribution
            start date and <br />
            duration of investment
          </div>
          <div class="page-content">
            <div class="page-content-text">
              <v-textarea
                variant="solo"
                class="free-text"
                style="font-size: 14px"
                placeholder="Enter Text..."
                append-inner-icon="fa fa-check-circle"
                id="freeTextIrrRegularHeatmap"
                @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
                @click:append="endEdit('freeTextIrrRegularHeatmap')"
                v-model="freeTextIrrRegularHeatmap"
                auto-grow
                :loading="
                  $store.getters.addingFreeText.status &&
                  $store.getters.addingFreeText.key === 'freeTextIrrRegularHeatmap'
                "
                :rules="[(v) => v.length < freeTextIrrRegularHeatmapLimit || 'Max character limit exceeded.']"
              />
            </div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>

    <div class="report-template" v-if="currentFilters.irrRegular3d">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="report-chart">
            <IRRRegularScatter :chartData="irrRegularScatter()" />
          </div>
          <div class="chart-legend">
            Figure {{ getIrrRegular3dFigCount() }}: 3D map illustrating IRR as a function of regular contribution start
            date and <br />
            duration of investment
          </div>
          <div class="page-content">
            <div class="page-content-text" style="margin-top: 10px">
              <v-textarea
                variant="solo"
                class="free-text"
                style="font-size: 14px"
                placeholder="Enter Text..."
                append-inner-icon="fa fa-check-circle"
                id="freeTextIrrRegularScatter"
                @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
                @click:append="endEdit('freeTextIrrRegularScatter')"
                v-model="freeTextIrrRegularScatter"
                auto-grow
                :loading="
                  $store.getters.addingFreeText.status &&
                  $store.getters.addingFreeText.key === 'freeTextIrrRegularScatter'
                "
                :rules="[(v) => v.length < freeTextIrrRegularScatterLimit || 'Max character limit exceeded.']"
              />
            </div>
            <!-- <br />
            <div class="page-content-text">
              The regular saver has benefited from a smoother ride relative to the single contributor, having relatively
              consistent performance over a number of durations rather than the patchy intensity of the single
              contributor. This aligns with the performance charts which show the more consistent experience of the
              regular saver compared with the benchmark. The maximum and minimum returns for regular contributions made
              at any point between
              {{ reportData.irrRegularYearRange }} for a minimum period of 1 year is
              {{ reportData.irrRegularValRange }} respectively, illustrating the relative consistency in returns
            </div> -->
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>

    <div class="report-template" v-if="currentFilters.effectOfContrib">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="page-content">
            <div class="page-content-title">Effect of Increasing Contributions</div>

            <div class="report-chart">
              <EffectOfContributionChart :chartData="reportData.effectOfContributionOptions" />
            </div>
            <div class="chart-legend">
              Figure {{ getEffectOfContribFigCount() }}: Stability of IRRs as a function of No. of contributions
            </div>
            <div class="page-content-text" style="margin-top: 10px">
              <v-textarea
                variant="solo"
                class="free-text"
                style="font-size: 14px"
                placeholder="Enter Text..."
                append-inner-icon="fa fa-check-circle"
                id="freeTextEffectofContrib"
                @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
                @click:append="endEdit('freeTextEffectofContrib')"
                v-model="freeTextEffectofContrib"
                auto-grow
                :loading="
                  $store.getters.addingFreeText.status &&
                  $store.getters.addingFreeText.key === 'freeTextEffectofContrib'
                "
                :rules="[(v) => v.length < freeTextEffectofContribLimit || 'Max character limit exceeded.']"
              />
            </div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>
    <div class="report-template" v-if="currentFilters.actualIrrChart">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="page-content">
            <div class="page-content-title">IRR Distribution</div>
            <div class="page-content-text">
              The following figures show the frequency and range of IRRs generated in the portfolio analysed. It
              highlights the effects of contribution histories and time periods of investment on the returns generated
              within the same portfolio. This emphasises the variability in the members’ experience
            </div>
            <div class="report-chart">
              <IRRChart :chartData="reportData.IRROptions" />
            </div>
            <div class="chart-legend">
              Figure {{ getActualIRRFigCount() }}: Histogram showing the range of IRRs experienced by members in the
              same <br />
              portfolio
            </div>

            <div class="page-content-text" style="margin-top: 10px">
              <v-textarea
                variant="solo"
                class="free-text"
                style="font-size: 14px"
                placeholder="Enter Text..."
                append-inner-icon="fa fa-check-circle"
                id="freeTextActualIrr"
                @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
                @click:append="endEdit('freeTextActualIrr')"
                v-model="freeTextActualIrr"
                auto-grow
                :loading="
                  $store.getters.addingFreeText.status && $store.getters.addingFreeText.key === 'freeTextActualIrr'
                "
                :rules="[(v) => v.length < freeTextActualIrrLimit || 'Max character limit exceeded.']"
              />
            </div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>

    <!-- <div class="report-template">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="page-content">
            <div class="report-chart">
              <IRR3dBar :chartData="reportData.IRR3dOptions" />
            </div>
            <div class="chart-legend">
              Figure 11: 3D map illustrating experienced IRR of members in the portfolio as
              <br />
              a function of contribution start date and duration of investment
            </div>
            <div class="page-content-text" style="margin-top: 10px;">
              <v-textarea
                variant="solo"
                class="free-text"
                style="font-size:14px;"
                label="Enter Text..."
                append-icon="fa-check-circle"
                id="freeTextActualIrr3d"
                @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
                @click:append="endEdit('freeTextActualIrr3d')"
                v-model="freeTextActualIrr3d"
                auto-grow
                :loading="
                  $store.getters.addingFreeText.status && $store.getters.addingFreeText.key === 'freeTextActualIrr3d'
                "
                :rules="[v => v.length < freeTextActualIrr3dLimit || 'Max character limit exceeded.']"
              />
            </div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div> -->

    <div class="report-template" v-if="currentFilters.regressionChart">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="page-content">
            <div class="page-content-title">Appendix I</div>
            <div class="page-content-title">Technical analysis; Prediction through Regression</div>
            <div class="page-content-text">
              The following graph shows the regression analysis between the portfolio and the benchmark index. The R
              squared for the regression is {{ reportData.RSquare }} which indicates a high level of portfolio
              predictability using the benchmark data set. Approximately {{ reportData.contributionHistoryCount }}% of
              the portfolio price index is calculated through exhaustive iteration of the contribution history but due
              to a lack of a deeper data set {{ reportData.regressionModelCount }}% of the data is estimated using the
              regression model. The model is described by the following equation and should only be used to estimate
              values within the range of the data set. It is not advised to use it as a forecasting tool.
            </div>

            <div class="page-content-text" style="text-align: center">
              Portfolio Price = {{ reportData.regExpression }}
            </div>
            <div class="report-chart">
              <RegressionChart :chartData="reportData.regressionOptions" />
            </div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>
    <div class="report-template">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="page-content">
            <div class="page-content-title">Appendix II</div>
            <div class="page-content-text" style="padding-bottom: 1rem">
              <span style="font-size: 1.2rem; font-weight: 700"> Methodology </span>
            </div>
            <div class="page-content-text" style="padding-bottom: 1rem">
              We are conducting the attribution analysis without the knowledge of the funds we are analysing. This
              approach eliminates prejudices we may have about named funds, ABI categories or of the fund’s asset
              allocation. We are testing if a methodology can be created that works universally and does not have to be
              refined for each fund or dynamic fund matrix.
              <br />
              The following steps are taken in the analysis which requires contribution histories and asset values of
              the members as input data.
            </div>
            <div class="page-content-text">
              <span style="font-weight: 700"> Step 1: Grouping savers into different cohorts </span>
            </div>

            <div class="page-content-text" style="padding-bottom: 1rem">
              1A: Group individual savers into cohorts based on contributions and returns; individuals with extreme
              returns will be grouped separately.
              <br />
              1B: Savers with life-styling may get grouped together or (if age is given) eliminated from the cohort (if
              they get grouped wrongly, this will be identified in Step 2).
            </div>

            <div class="page-content-text">
              <span style="font-weight: 700"> Step 2: Creating a price index for the default group </span>
            </div>
            <div class="page-content-text" style="padding-bottom: 1rem">
              2A: Create a price tracker using the contribution histories of savers within the largest group. The price
              tracker will have no values on dates without contributions.
              <br />
              2B: Compare the price tracker with the benchmark index. The depth of the data set will determine if
              additional calculations are needed to generate more results.
              <br />
              2C: Members who have been de-risked (life-styled) generate abnormal index prices in early stages.
              Eliminate or group those members separately (if they are falsely identified in Step 1).
            </div>

            <div class="page-content-text">
              <span style="font-weight: 700"> Step 3: Performance Attribution </span>
            </div>
            <div class="page-content-text">
              3A: Using the price tracker for the cohort, calculate Beta comparing the portfolio price index to the
              benchmark index and perform a regression analysis.
              <br />
              3B: The co-efficient of regression will give an indication of the level of risk savers have taken, and the
              drag will indicate the realised outcome enhancement or outcome shortfall compared with that made in the
              benchmark index.
            </div>

            <div class="page-content-title">Limitations</div>
            <div class="page-content-text">
              This methodology is meant for fiduciaries and providers to analyse the performance of their scheme in a
              detailed manner. It includes approximations and assumptions where there is a lack of detailed data (such
              as age, funds etc.) but it gives a reasonable estimation of overall performance based on risk levels. A
              more accurate analysis can be performed if a greater depth of data is provided.
            </div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>
    <div class="report-template">
      <v-card class="report-page-card" outlined flat>
        <div class="card-data">
          <div class="page-content">
            <div class="page-content-title">Appendix III</div>
            <div class="page-content-title">Further Applications</div>
            <div class="page-content-text">
              The use of the performance attribution tool has wider applications. AgeWage has the benchmark data set
              going back to 1980. If we are supplied with a contribution history for any fund, we can give it an AgeWage
              score using all or part of the data supplied.
            </div>
            <div class="page-content-title">Road Testing Products</div>
            <div class="page-content-text">
              This is particularly useful for fund managers looking to see how their fund would have performed over
              particular periods both in terms of AgeWage scores and performance attribution.
              <br />
              <br />
              For instance, a platform might be looking to road test a variety of different funds for a particular
              mandate. We can provide bespoke reporting or simply report using existing templates depending on the
              services required
            </div>
            <div class="page-content-title">Conducting Fund Value Assessments</div>
            <div class="page-content-text">
              Typically, fund value assessments assume point to point performance analysis (3, 5 or 10 years). The
              difficulties arise when reporting on experienced performance that fall outside of standard parameters,
              time or contribution patterns.
              <br />
              <br />
              Where those conducting assessments want to go beyond the compliance minima and explore the impact of funds
              on investor experience, then this service can be used – using records kept by the assessor or from our
              proprietary data set.
            </div>

            <div class="page-content-title">The Impact of Sequencing Risk on Drawdown</div>
            <div class="page-content-text">
              Many providers are looking to introduce investment pathways that include drawdown. Our scores provide an
              impact analysis of sequencing risk in drawdown and an easily understandable measure for people looking to
              assess risk for themselves.
              <br />
              <br />
              The performance attribution service provides governance and regulatory evidence supporting decisions taken
              when choosing investment solutions. If there are further insights that can be useful, AgeWage will be
              happy to provide detailed analysis on the feedback received from the clients.
            </div>
          </div>
        </div>
        <div class="footer">
          <div></div>
          <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import PriceTrackChart from '../../../../components/charts/PriceTrackChart.vue';
import SingleContributionChart from '../../../../components/charts/AwSingleContributionChart.vue';
import RegularContributionChart from '../../../../components/charts/AWRegularContributionChart.vue';
import IRRSingleContributionChart from '../../../../components/charts/IRRSingleContributionChart.vue';
import IRRRegularContributionChart from '../../../../components/charts/IRRRegularContributionChart.vue';
import IRRChart from '../../../../components/charts/IRRChart.vue';
import EffectOfContributionChart from '../../../../components/charts/EffectOfContributionChart.vue';
import RegressionChart from '../../../../components/charts/RegressionChart.vue';
import IRRSingleHeatmap from '../../../../components/charts/IRRSingleHeatmap.vue';
import IRRRegularHeatmap from '../../../../components/charts/IRRRegularHeatmap.vue';
import IRRSingleScatter from '../../../../components/charts/IRRSingleScatter.vue';
import IRRRegularScatter from '../../../../components/charts/IRRRegularScatter.vue';
import IRR3dBar from '../../../../components/charts/IRR3dbar.vue';
import { ADD_PA_FREE_TEXT } from '../../store/actionTypes';

export default {
  components: {
    PriceTrackChart,
    SingleContributionChart,
    RegularContributionChart,
    IRRSingleContributionChart,
    IRRRegularContributionChart,
    IRRChart,
    EffectOfContributionChart,
    RegressionChart,
    IRRSingleHeatmap,
    IRRRegularHeatmap,
    IRRSingleScatter,
    IRRRegularScatter,
    IRR3dBar,
  },
  props: ['reportData', 'providerId', 'currentFilters'],
  data() {
    return {
      freeTextPriceTrack: this.getFreeText('freeTextPriceTrack'),
      freeTextIrrSingle: this.getFreeText('freeTextIrrSingle'),
      freeTextIrrSingleHeatmap: this.getFreeText('freeTextIrrSingleHeatmap'),
      freeTextIrrSingleScatter: this.getFreeText('freeTextIrrSingleScatter'),
      freeTextIrrRegular: this.getFreeText('freeTextIrrRegular'),
      freeTextIrrRegularHeatmap: this.getFreeText('freeTextIrrRegularHeatmap'),
      freeTextIrrRegularScatter: this.getFreeText('freeTextIrrRegularScatter'),
      freeTextActualIrr: this.getFreeText('freeTextActualIrr'),
      freeTextActualIrr3d: this.getFreeText('freeTextActualIrr3d'),
      freeTextEffectofContrib: this.getFreeText('freeTextEffectofContrib'),
      freeTextPriceTrackLimit: 585,
      freeTextIrrSingleLimit: 363,
      freeTextIrrSingleHeatmapLimit: 377,
      freeTextIrrSingleScatterLimit: 304,
      freeTextIrrRegularLimit: 522,
      freeTextIrrRegularHeatmapLimit: 470,
      freeTextIrrRegularScatterLimit: 287,
      freeTextActualIrrLimit: 550,
      freeTextActualIrr3dLimit: 524,
      freeTextEffectofContribLimit: 700,
    };
  },
  methods: {
    endEdit(id) {
      const freeText = this[id];
      if (freeText.length <= this[`${id}Limit`]) {
        this.$store.dispatch(ADD_PA_FREE_TEXT, { providerID: this.providerId, key: id, value: freeText });
      }
    },
    priceTrackOptions() {
      const options = this.reportData.priceTrackOptions;
      if (options) {
        options.series[0].color = '#c23431';
        options.series[1].color = '#334b5c';
      }

      return options;
    },
    IRRSingleOptions() {
      const options = this.reportData.IRRSingleOptions;
      if (options) {
        options.series[0].color = '#c23431';
        options.series[1].color = '#334b5c';
      }

      return options;
    },
    IRRRegularOptions() {
      const options = this.reportData.IRRRegularOptions;
      if (options) {
        options.series[0].color = '#c23431';
        options.series[1].color = '#334b5c';
      }

      return options;
    },
    irrSingleScatter() {
      const options = this.reportData.irrSingleScatter;
      if (options) {
        options.series[0].itemStyle = { color: '#c23431' };
      }

      return options;
    },
    irrRegularScatter() {
      const options = this.reportData.irrRegularScatter;
      if (options) {
        options.series[0].itemStyle = { color: '#c23431' };
      }

      return options;
    },
    getFreeText(id) {
      return (this.reportData && this.reportData[id]) || '';
    },
    numberWithCommas(x) {
      if (!x) return '';
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    getPriceTrackFigCount() {
      return 2;
    },
    getIrrSingleFigCount() {
      return (!!this.currentFilters.irrSingle && this.getPriceTrackFigCount() + 1) || this.getPriceTrackFigCount();
    },
    getIrrSingleHeatmapFigCount() {
      return (!!this.currentFilters.irrSingleHeatmap && this.getIrrSingleFigCount() + 1) || this.getIrrSingleFigCount();
    },
    getIrrSingle3dFigCount() {
      return (
        (!!this.currentFilters.irrSingle3d && this.getIrrSingleHeatmapFigCount() + 1) ||
        this.getIrrSingleHeatmapFigCount()
      );
    },
    getIrrRegularFigCount() {
      return (!!this.currentFilters.irrRegular && this.getIrrSingle3dFigCount() + 1) || this.getIrrSingle3dFigCount();
    },
    getIrrRegularHeatmapFigCount() {
      return (
        (!!this.currentFilters.irrRegularHeatmap && this.getIrrRegularFigCount() + 1) || this.getIrrRegularFigCount()
      );
    },
    getIrrRegular3dFigCount() {
      return (
        (!!this.currentFilters.irrRegular3d && this.getIrrRegularHeatmapFigCount() + 1) ||
        this.getIrrRegularHeatmapFigCount()
      );
    },
    getEffectOfContribFigCount() {
      return (
        (!!this.currentFilters.effectOfContrib && this.getIrrRegular3dFigCount() + 1) || this.getIrrRegular3dFigCount()
      );
    },
    getActualIRRFigCount() {
      return (
        (!!this.currentFilters.actualIrrChart && this.getEffectOfContribFigCount() + 1) ||
        this.getEffectOfContribFigCount()
      );
    },
  },
};
</script>

<style scoped lang="scss">
.report-template .report-page-card .main-page-feature {
  width: 100%;
  margin: auto;
  max-width: 85%;
  margin-top: 15rem;
  margin-left: 5rem;
}
.report-template .report-page-card .main-page-feature .feature-title {
  font-size: 3.5rem;
  font-weight: 500;
}

.report-template .report-page-card .main-page-feature .feature-sub-title {
  font-size: 1.3rem;
  font-weight: 500;
  color: #e2af06;
}

.report-template .report-page-card .main-page-feature .feature-sub-header {
  font-size: 2.5rem;
  font-weight: 500;
  color: #e2af06;
}

.report-template .report-page-card .page-content {
  width: 100%;
  margin: auto;
  max-width: 85%;
  margin-top: 1rem;
  text-align: center;
}

.report-template .report-page-card .page-content .page-content-title {
  font-size: 1.2rem;
  font-weight: 700;
  padding-top: 2rem;
  padding-bottom: 1rem;
  text-align: left;
}

.report-template .report-page-card .page-content .page-content-text {
  font-size: 14px;
  padding-bottom: 0rem;
  text-align: justify;
  color: #463b4a;
  line-height: 2rem;
}
.sub-header-title {
  text-align: center !important;
  font-size: 1.7rem !important;
}
.chartWrapper {
  height: 27rem;
  max-width: 90% !important;
  border-radius: 0.2rem;
  background: #fff;
  margin: 0 auto;
}
.report-chart {
  margin: 0 auto;
  width: 100%;
  padding: 2rem 0rem;
  margin: 2rem 0 !important;
  background: #dac5e221;
}

.chart-title {
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center !important;
  padding: 1rem 0 0 2rem;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

th,
td {
  padding: 5px;
  text-align: left;
}
.chart-legend {
  text-align: center;
  color: #463b4aad;
  font-weight: 600;
}
.free-text textarea {
  line-height: 2rem !important;
}
.v-input__icon .v-input__icon--append {
  cursor: pointer;
}
</style>
