<template>
  <div :class="{ 'app-uploader--disabled': disabled, 'app-uploader': true }">
    <!-- Uploader -->
    <div
      class="app-uploader__uploader-container d-flex align-center justify-center flex xs12"
      :class="{ 'app-uploader__uploader-container--highlight': isDragActive }"
    >
      <div class="app-uploader__uploader-container__uploader pa-5 d-flex align-center flex xs12">
        <div v-show="!isFileSelected">
          <!-- Uploader Title -->
          <slot name="title">
            <div class="text-h5 font-weight-bold">Upload a sheet</div>
          </slot>
          <!-- Uploader Icon -->
          <slot name="icon" class="my-4">
            <v-icon large> fas fa-file-upload </v-icon>
          </slot>
          <!-- Uploader Description -->
          <slot name="description">
            <div class="font-weight-bold">Drag and drop to upload</div>
            <div class="font-weight-bold">
              or
              <span class="app-uploader__uploader-container__uploader__click-button"> browse to choose a file </span>
            </div>
          </slot>
          <!-- Uploader Footer -->
          <slot name="footer">
            <div class="caption pt-3">Please upload a <b>.csv</b> file only.</div>
          </slot>
          <div>
            <!-- {{ this.$validator.errors.first('fileInput') }} -->
          </div>
        </div>
        <!-- Selected File Preview -->
        <slot v-if="isFileSelected" name="preview-instructions">
          <div class="pb-4 title app-uploader__uploader-container__uploader__preview-instructions">
            <v-icon large> fas fa-clipboard-check </v-icon>
            <div class="my-2 text-h6">File is ready to be uploaded</div>
            <div class="font-weight-bold body-1 mt-3 text-grey">
              {{ fileName }}
            </div>
          </div>
        </slot>

        <div
          v-show="isDragActive"
          class="app-uploader__overlay-content"
          :class="{ 'app-uploader__overlay-content--is-active': isDragActive }"
        >
          <slot name="active-overlay">
            <h1>Drop file to upload</h1>
          </slot>
        </div>
        <div
          class="app-uploader__overlay"
          @click="pickFile"
          @dragover.prevent="onDragOver"
          @drop.prevent="onUpload($event, 'drop')"
          @dragleave.prevent="onDragLeave"
        />
      </div>
      <input
        ref="fileUpload"
        type="file"
        style="display: none"
        v-bind:accept="fileType ? fileType : ''"
        @change="onUpload($event, 'browse')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Prop, Emit } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';

export default class AppUploader extends Vue {
  public fileName = '';

  public file = '';

  public isFileSelected = false;

  public isDragActive = false;

  @Prop() fileType!: string;

  @Prop() disabled!: boolean;

  public get hasErrors(): boolean {
    return !this.fileName;
  }

  public getFileUploadRef(): any {
    return this.$refs.fileUpload;
  }

  public pickFile() {
    if (!this.disabled) {
      this.getFileUploadRef().click();
    }
  }

  public onDragOver() {
    this.isDragActive = true;
  }

  public onDragLeave() {
    this.isDragActive = false;
  }

  public onUpload(event: any, mode: string) {
    this.isDragActive = false;
    if (!this.disabled) {
      this.isFileSelected = true;
      const files = mode === 'browse' ? event.target.files : event.dataTransfer.files;
      if (files[0] !== undefined) {
        this.fileName = files[0].name;
        if (this.fileName.lastIndexOf('.') <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener('load', () => {
          this.file = files[0];
          this.emitData();
        });
      } else {
        this.fileName = '';
        this.file = '';
        this.emitData();
      }
    }
  }

  @Emit('app-uploader')
  public reSubmit() {
    if (!this.disabled) {
      this.isFileSelected = false;
      return {
        file: '',
        hasError: this.hasErrors,
      };
    }
  }

  @Emit('app-uploader')
  public emitData() {
    return {
      file: this.file,
      hasError: this.hasErrors,
    };
  }
}
</script>

<style lang="scss">
.app-uploader {
  &__uploader-container {
    cursor: pointer;
    background: #fff;
    border: dashed 2px transparent;

    &--highlight {
      background: transparent;
      border: dashed 2px #ddd;
    }

    &__uploader {
      position: relative;
      flex-direction: column;

      .v-icon {
        margin: 1rem 0;
        font-size: 3rem !important;
      }

      &__click-button,
      &__preview-instructions {
        color: #976fea;
      }
    }

    &__filename {
      flex-direction: column;
    }
  }

  &--disabled {
    cursor: default;
    opacity: 0.4;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 3;
  }

  &__overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    &--is-active {
      background: #f5f5f5;
    }
  }
}
</style>
