import * as AT from '@/modules/users/store/actionTypes';
import * as MT from '@/modules/users/store/mutationTypes';
import { IRootState } from '@/store/state';
import { IUserState } from '@/modules/users/store/state';
import { ActionContext, ActionTree } from 'vuex';
import { UserService } from '@/modules/users/services';
import User from '@/models/User';

/**
 * --------------------------------------------------
 *  User Actions
 * --------------------------------------------------
 **/
export const UserActions: IUserActions = {
  /**
   * --------------------------------------------------
   *  Fetch All the Users
   * --------------------------------------------------
   **/
  [AT.FETCH_USERS]: ({ commit, dispatch }: any) => {
    dispatch(AT.TOGGLE_FETCHING_USERS);
    return UserService.getUsers()
      .then(users => {
        commit(MT.SET_USERS, users);
        return users;
      })
      .finally(() => dispatch(AT.TOGGLE_FETCHING_USERS));
  },
  //TOGGLE
  [AT.TOGGLE_FETCHING_USERS]: ({ commit, state }: any, value: boolean) => {
    if (value != null) {
      if (state.fetchingUsers == true) commit(MT.SET_FETCHING_USERS, false);
      else commit(MT.SET_FETCHING_USERS, false);
    } else commit(MT.SET_FETCHING_USERS, value);
  },

  [AT.ADD_USER]: ({ commit, dispatch }: any, data: any) => {
    dispatch(AT.TOGGLE_FETCHING_USERS);
    return UserService.addUser(data)
      .then((user: User) => user)
      .finally(() => dispatch(AT.TOGGLE_FETCHING_USERS));
  },

  [AT.REMOVE_USER]: ({ commit, dispatch }: any, id: number) => {
    dispatch(AT.TOGGLE_FETCHING_USERS);
    return UserService.removeUser(id)
      .then((user: User) => user)
      .finally(() => dispatch(AT.TOGGLE_FETCHING_USERS));
  },
  [AT.REMOVE_USER_PROVIDER_MAPPING]: ({ commit, dispatch }: any, data) => {
    dispatch(AT.TOGGLE_FETCHING_USERS);
    return UserService.removeUserProviderMap(data)
      .then((user: User) => user)
      .finally(() => dispatch(AT.TOGGLE_FETCHING_USERS));
  }
};

/**
 * --------------------------------------------------
 *  User Action Context Interface
 * --------------------------------------------------
 **/
export interface IUserActionContext extends ActionContext<IUserState, IRootState> {}

/**
 * --------------------------------------------------
 *  User Actions Interface
 * --------------------------------------------------
 **/
export interface IUserActions extends ActionTree<IUserState, IRootState> {}
