<template>
  <v-card flat class="sorting-panel">
    <v-card-text>
      <v-row>
        <v-col align-self="center">
          <v-select
            v-model="sorting.sortBy"
            item-title="title"
            item-value="value"
            :items="items"
            color="primary"
            hide-details
            density="compact"
            variant="outlined"
          />

          <v-btn-toggle v-model="sorting.sortDescending" class="w-100 justify-center mt-4">
            <v-btn size="small" variant="flat" density="compact" :value="false">
              <v-icon left small class="mr-2">fa fa-arrow-up</v-icon>
              <span>Ascending</span>
            </v-btn>
            <v-btn size="small" variant="flat" density="compact" :value="true">
              <v-icon left small class="mr-2">fa fa-arrow-down</v-icon>
              <span>Descending</span>
            </v-btn>
          </v-btn-toggle>
          <v-btn
            size="small"
            block
            density="compact"
            variant="text"
            class="mt-4"
            @click="resetSorting"
            v-if="isSortingApplied"
          >
            Reset
          </v-btn>

          <v-btn
            :color="!isSortingDirty ? 'disabled' : 'primary'"
            small
            @click="applySorting"
            block
            class="mt-4"
            :disabled="!isSortingDirty"
          >
            Apply
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
export default class SortingPanel extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  public items!: any[];

  @Prop({
    type: Object,
    default: () => ({}),
  })
  public defaultSorting!: any;

  public sorting: any = {};

  @Prop()
  public isSortingApplied!: Boolean;

  public appliedSorting: any = {};

  created() {
    this.sorting = { ...this.defaultSorting };
    this.appliedSorting = { ...this.sorting };
  }

  public get isSortingDirty(): boolean {
    if (!this.appliedSorting || !this.sorting) {
      return false;
    }
    const sortByIsDirty = this.appliedSorting.sortBy !== this.sorting.sortBy;
    const sortDescIsDirty = this.appliedSorting.sortDescending !== this.sorting.sortDescending;
    return sortByIsDirty || sortDescIsDirty;
  }

  public applySorting() {
    this.appliedSorting = { ...this.sorting };
    this.$emit('apply', this.appliedSorting);
  }

  public resetSorting() {
    this.appliedSorting = { ...this.defaultSorting };
    this.$emit('reset', this.appliedSorting);
  }
}
</script>

<style lang="scss"></style>
