<template>
  <div class="report-template">
    <v-card class="report-page-card top-right-color-triangle" outlined flat>
      <div class="report-template ">
        <div class="report-page-card temp-class">
          <div class="row">
            <div class="section">
              <div class="section-title">
                <div class="section-badge"></div>
                Further Services
              </div>
              <div class="section-content">
                <br />
                <div class="bold-700">Meeting the DWP’s statutory guidance on small DC scheme consolidation</div>
                The DWP in its consultation response on improving member outcomes makes it clear that <br />
                <br />
                “trustees may use measures such as the individual rate of return or risk adjusted returns if they
                consider this will help explain performance more easily in addition to the net returns disclosure the
                guidance sets out”
                <br />
                <br />
                <div class="bold-700">Submission of Scheme returns</div>
                To comply with the DWP’s guidance to small schemes conducting Value for Member Assessments, net
                performance comparisons will need to be completed for relevant DC schemes as part of the scheme return.
                <br />
                <br />
                <div class="bold-700">Working with consultants</div>
                We recognise that the majority of larger DC schemes have existing consultancy agreements and that such
                schemes will either complete their own VFM assessments or use their existing advisers. Our work is
                designed to complement rather than compete with such services, “explaining performance more easily”.
                <br />
                <br />
                <div class="bold-700">Introducing consultants</div>
                We also recognise that some smaller schemes do not have consultants and may require a fully compliant
                service. If asked to, AgeWage will offer both IRR comparisons and the net performance comparisons
                required to be returned to the Pensions Regulator. Where there is no consultant in place, we will make
                introductions.
                <br />
                <br />
                <div class="bold-700">Resolving conflicts</div>
                Some consultants may prefer to use AgeWage’s service where they are the founder of a consolidating
                master trust and wish to be a consolidating scheme. Using this independent assessment service will help
                trustees and consultants avoid conflicts.
                <br />
                <br />
                <div class="bold-700">Market testing</div>
                Where requested, we will introduce trustees to a range of consolidators, providing analysis on each.
                AgeWage already works with several insurers and their IGCs, with several master trusts and with
                participating employers in multi-employer arrangements both trust and contract based. We intend to list
                all types of schemes by type (contract based, bundled and unbundled single employer, master-trust). We
                may in time publish league tables in terms of performance, data quality and risk-adjusted performance
                but only with the individual consent of consolidators.
                <br />
                <br />
                Where further ratings are available (for instance on ESG and Quality of Service) we may introduce these
                in time.
                <br />
                <br />
                <div class="bold-700">Due Diligence</div>
                Where a consolidator wants an assessment of data quality or of the scheme demographics and the scheme to
                be consolidated consents, AgeWage will provide a data assessment to the consolidator (with the ceding
                trustee's consent)
                <br />
                <br />
                <div class="bold-700">Ongoing reportings</div>
                AgeWage will provide ongoing VFM reporting to participating employers in multi-employer schemes and to
                the trustees of single occupational schemes. This is a separate service offered on commercial terms
                available on request from AgeWage.
              </div>
            </div>
            <div v-if="isBenchmarkUser" class="my-8 px-8 sample-img-container" style="position: relative;">
              <img src="../../../../assets/sample-report.jpg" width="100%" />
              <div class="call-action-btns">
                <v-btn class="filter-btn  call-action-btn elevation-6 mt-0 mx-4" text @click="redirectToSampleReport">
                  <span class="call-btn-text">View sample VFM report</span>
                </v-btn>
                <v-btn class="filter-btn  call-action-btn elevation-6 mt-0 mx-4" text @click="getVFMReport">
                  <span class="call-btn-text">Request my VFM report</span>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer">
        <div></div>
        <!-- <div class="page-box">
          <div class="pageno">Page 4/4</div>
        </div> -->
        <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
      </div>
    </v-card>
  </div>
</template>
<script lang="ts">
import { Prop, Vue } from 'vue-property-decorator';
import User from '@/models/User';
import Provider from '@/models/Provider';

export default class ReportStaticText extends Vue {
  created() {
    this.bootstrap();
  }

  bootstrap() {
    //
  }

  @Prop()
  public getVFMReport!: Function;

  public redirectToSampleReport() {
    this.$router.push({ name: 'sample.reports.gen' });
  }

  public get isBenchmarkUser() {
    return this.providers && this.user && this.user.role === 'bm_user';
  }

  public get providers(): Provider[] {
    return this.$store.getters.providers;
  }

  public get user(): User {
    return this.$store.getters.currentUser;
  }
}
</script>

<style lang="scss" scoped>
.column {
  float: left;
  width: 50%;
  padding: 10px;
}
.bold-700 {
  font-weight: 700;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}
.call-btn-text {
  font-weight: 600;
}
.call-action-btns {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(#fff, 0.4);
}
.sample-img-container:hover .call-action-btns {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
