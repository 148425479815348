import { PriceTrackObject, PriceTrackListItems } from '@/models/PriceTrack';
import { HTTPServiceClient } from '@/packages/http/service';
import * as ep from './endpoints';

export const PriceTrackService = {
  /**
   * Description:
   * @param {}
   * @returns { Promise<PriceTrackListItems[]> }
   */
  getPriceTracks(): Promise<PriceTrackListItems[]> {
    return HTTPServiceClient.get(ep.FETCH_PRICE_TRACKS).then(({ data }) => data);
  },
  getPriceTrack(id: string): Promise<PriceTrackObject> {
    return HTTPServiceClient.get(ep.FETCH_PRICE_TRACK(id)).then(({ data }) => data);
  },
  addPriceTrack(data: any) {
    return HTTPServiceClient.post(ep.ADD_PRICE_TRACK, data);
  },
  getPriceTrackFilterRanges(params?: any): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_FILTERED_MEMBERS_ANALYSIS_PRICE_TRACK, { params }).then(({ data }) => data);
  },
  getPriceTrackFilterRangesPastPerformance(id: string): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_FILTERED_MEMBERS_ANALYSIS_PAST_PERFORMANCE_PRICE_TRACK(id))
      .then(({ data }) => data);
  },
  getChartDataPriceTrack(data: any): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_CHARTDATA_PRICE_TRACK(data.priceTrackId)).then(({ data }) => data);
  },
  getMemberAnalysisByPagePriceTrack(params: any): Promise<any> {
    return HTTPServiceClient.get(ep.FETCH_MEMBERS_ANALYSIS_PRICE_TRACK, { params }).then(({ data }) => data);
  },
};
