<template>
  <div data-testid="list-users-page" class="page list-users-page">
    <v-container fluid>
      <v-layout class="mt-4 pa-0">
        <div style="align-self: center">
          <h2 class="section-title">
            <span>ALL Users</span>
          </h2>
        </div>
        <v-layout align-end class="py-2 justify-end">
          <v-btn
            class="filter-btn elevation-4 mt-0 mx-2"
            data-testid="FilterBtn"
            :ripple="false"
            variant="text"
            size="small"
            @click="createUserModal = true"
          >
            <span> Add User</span>
            <v-icon small class="px-2 ml-2">fas fa-plus</v-icon>
          </v-btn>
        </v-layout>
      </v-layout>

      <v-card v-if="!fetchingUsers && users" class="mt-4">
        <AppTable
          data-testid="UserTable"
          :table-data="tableData"
          :loading="fetchingUsers"
          :striped="true"
          v-on:viewItemClick="(e) => viewProvidersModal(e)"
          v-on:removeItemClick="(e) => removeUser(e)"
          :disablePagination="true"
        />
        <div v-if="totalUserPage > 1" class="text-center list-pagination">
          <v-pagination
            v-if="totalUserPage > 1"
            v-model="currUserPage"
            :length="totalUserPage"
            active-color="primary"
            density="comfortable"
            prev-icon="fa fa-caret-left"
            next-icon="fa fa-caret-right"
            @next="currUserPage + 1"
            @previous="currUserPage - 1"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-card>
      <div v-if="fetchingUsers && !users" class="d-flex align-center justify-center pt-5 mt-5">
        <v-progress-circular indeterminate color="grey" />
      </div>
    </v-container>

    <!-- View Providers Modal -->
    <v-dialog
      v-model="providerModal"
      @click:outside="
        providerModal = false;
        currPage = 1;
      "
      v-if="providers && !fetchingProviders"
      persistent
      max-width="80vw"
    >
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <div>
            <div class="text-h6">
              Providers associated with user: <b>{{ userName }}</b>
            </div>
          </div>
          <div>
            <v-btn @click="fetchAllProviders()" variant="outlined" color="primary" class="mr-3">
              <v-icon small left>fas fa-plus</v-icon>
              <span class="ml-2">Add Provider</span>
            </v-btn>
            <v-btn
              rounded
              size="small"
              variant="flat"
              icon
              @click="
                providerModal = false;
                currPage = 1;
              "
            >
              <v-icon small>fa fa-times</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <AppTable
            :table-data="providerTableData"
            :disableSort="true"
            :disablePagination="true"
            :default-sort-column="[{ key: 'id', order: 'asc' }]"
            v-on:removeItemClick="(e) => removeProvider(e)"
          />
        </v-card-text>
        <div class="text-center" v-if="totalPage > 1">
          <v-pagination
            v-if="totalPage > 1"
            v-model="currPage"
            :length="totalPage"
            density="comfortable"
            active-color="primary"
            prev-icon="fa fa-caret-left"
            next-icon="fa fa-caret-right"
            @next="currPage + 1"
            @previous="currPage - 1"
            :total-visible="7"
          ></v-pagination>
        </div>
        <v-card-actions>
          <v-btn
            color="grey-darken-1"
            variant="text"
            @click="
              providerModal = false;
              currPage = 1;
            "
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- New User Modal -->
    <v-dialog v-model="createUserModal" @click:outside="createUserModal = false" persistent max-width="30vw">
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <div>
            <div>Create a new user:</div>
          </div>
          <div>
            <v-btn rounded size="small" variant="flat" icon @click="createUserModal = false">
              <v-icon small>fa fa-times</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="createUser" class="py-5">
            <div>
              <v-text-field
                hide-details
                :persistent-hint="false"
                placeholder="User Name"
                v-model="newUserName"
                class="mb-5"
              />
              <v-text-field
                hide-details
                :persistent-hint="false"
                placeholder="User Email"
                v-model="newUserEmail"
                class="mb-5"
              />
              <v-text-field
                hide-details
                :persistent-hint="false"
                placeholder="Password"
                v-model="newUserPassword"
                class="mb-5"
              />
              <v-btn
                block
                type="submit"
                :color="!newUserName || !newUserEmail || !newUserPassword || addingUser ? 'disabled' : 'success'"
                class="mt-5"
                :loading="addingUser"
                :disabled="!newUserName || !newUserEmail || !newUserPassword || addingUser"
              >
                Add
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey-darken-1" variant="text" @click="createUserModal = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- New Provider Modal -->
    <v-dialog v-model="addProviderModal" persistent max-width="30vw">
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <span class="text-h5">
            {{ showProviderForm ? 'Add Provider' : 'Select Provider' }}
          </span>
          <v-btn @click="toggleAddProviderForm" small variant="outlined" color="primary">
            <v-icon small left class="mr-2">
              {{ showProviderForm ? 'fas fa-chevron-left' : 'fas fa-plus' }}
            </v-icon>
            <span v-show="!showProviderForm">Create Provider</span>
            <span v-show="showProviderForm">Go back</span>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-show="!showProviderForm">
            <v-label>Select a Provider</v-label>
            <v-autocomplete
              data-testid="Upload-Provider-List"
              v-model="selectedProvider"
              :loading="fetchingProviders"
              :items="filteredProviders"
              item-title="name"
              item-value="id"
              class="mx-0 mt-2"
              type="text"
              hide-no-data
              hide-details
              label="Provider"
              variant="filled"
            />
            <v-btn
              block
              type="submit"
              :color="!selectedProvider || addingProvider ? 'disabled' : 'success'"
              class="mt-5"
              :loading="addingProvider"
              :disabled="!selectedProvider || addingProvider"
              @click="addProvider"
            >
              Add
            </v-btn>
          </div>
          <div v-show="showProviderForm">
            <v-form @submit.prevent="createProvider" class="py-5">
              <div>
                <v-text-field
                  hide-details
                  :persistent-hint="false"
                  placeholder="Provider Name"
                  v-model="newProviderName"
                  class="mb-5"
                />
                <v-text-field
                  hide-details
                  :persistent-hint="false"
                  placeholder="Provider Email"
                  v-model="newProviderEmail"
                  class="mb-5"
                />
                <v-btn
                  block
                  type="submit"
                  :color="!newProviderName || !newProviderEmail || addingProvider ? 'disabled' : 'success'"
                  class="mt-5"
                  :loading="addingProvider"
                  :disabled="!newProviderName || !newProviderEmail || addingProvider"
                >
                  Add
                </v-btn>
              </div>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey-darken-1" variant="text" @click="addProviderModal = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AppLoader v-if="fetchingProviders" />
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppTable from '@/components/AppTable.vue';
import { Table, DataTableHeader, TableRow, TableColumnType } from '@/types';
import User from '@/models/User';
import Provider from '@/models/Provider';
import { FETCH_PROVIDER_BY_USERID, FETCH_ALL_PROVIDERS, REMOVE_PROVIDER } from '@/modules/providers/store/actionTypes';
import { ADD_USER, REMOVE_USER } from '@/modules/users/store/actionTypes';
import { SET_NAV_HEADER } from '@/store/actionTypes';
import { FETCH_USERS, REMOVE_USER_PROVIDER_MAPPING } from '@/modules/users/store/actionTypes';
import { HTTPServiceClient } from '@/packages/http/service';
import AppLoader from '@/components/AppLoder.vue';

@Options({
  components: {
    AppTable,
    AppLoader,
  },
})
export default class ListUsersPage extends Vue {
  created() {
    console.log('herere');
    this.bootstrap();
  }

  public bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'User Management', tooltip: 'All Users' });
  }

  public userName = '';

  public providerSearch = '';

  public selectedProvider: number | null = null;

  public selectedUserId = 0;

  public providerModal = false;

  public createUserModal = false;

  public addProviderModal = false;

  public showProviderForm = false;

  public addingUser = false;
  public newUserName = null;
  public newUserEmail = null;
  public newUserPassword = null;

  public addingProvider = false;
  public newProviderName = null;
  public newProviderEmail = null;

  public removingProvider = false;
  public currPage = 1;
  public currUserPage = 1;

  get fetchingUsers() {
    return this.$store.getters.fetchingUsers;
  }

  get users() {
    return this.$store.getters.users;
  }

  public get tableData(): Table {
    const columns: DataTableHeader[] = [
      {
        value: 'id',
        title: '#',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'name',
        title: 'Name',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'email',
        title: 'Email',
        type: TableColumnType.EMAIL,
        align: 'center',
      },
      {
        value: 'role',
        title: 'Role',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'view',
        title: 'Associated Providers',
        type: TableColumnType.ACTIONS,
        align: 'center',
      },
    ];

    return {
      columns,
      rows: this.rows,
    };
  }

  public get rows(): TableRow[] {
    return (
      this.usersData &&
      this.usersData.map((user: User, it: number) => {
        return {
          id: user.id,
          name: user.name,
          email: user.email,
          role: user.role,
          createdAt: user.createdAt,
          updatedAt: user.updatedAt,
        };
      })
    );
  }

  public get usersData() {
    const userList = this.users;
    if (userList) {
      let pageEnd = this.currUserPage * 20;
      const PageStart = pageEnd - 20;
      if (pageEnd > userList.length) {
        pageEnd = userList.length;
      }
      return userList.slice(PageStart, pageEnd);
    }
    return userList;
  }

  public get totalUserPage() {
    const totalUser = this.users;
    if (totalUser) {
      return Math.ceil(totalUser.length / 20);
    }
    return 1;
  }

  public async fetchAllProviders() {
    await this.$store.dispatch(FETCH_ALL_PROVIDERS);
    await this.$store.dispatch(FETCH_PROVIDER_BY_USERID, this.selectedUserId);
    if (!this.filteredProviders.length) {
      this.showProviderForm = true;
    }
    this.addProviderModal = true;
  }

  public async createUser() {
    this.addingUser = false;
    const formData = {
      name: this.newUserName,
      email: this.newUserEmail,
      password: this.newUserPassword,
    };
    const result = await this.$store.dispatch(ADD_USER, formData);
    this.clearUserFields();
    this.$store.dispatch(FETCH_USERS);
  }

  public async createProvider() {
    this.addingProvider = true;
    const formData = {
      name: this.newProviderName,
      email: this.newProviderEmail,
      userId: this.selectedUserId,
    };
    await HTTPServiceClient.post('/providers', formData);
    this.clearProviderFields();
    this.$store.dispatch(FETCH_PROVIDER_BY_USERID, this.selectedUserId);
  }

  public async addProvider() {
    this.addingProvider = true;
    await HTTPServiceClient.post('/providers/addMap', {
      providerId: this.selectedProvider,
      userId: this.selectedUserId,
    });
    this.clearProviderFields();
    this.$store.dispatch(FETCH_PROVIDER_BY_USERID, this.selectedUserId);
  }

  public async removeProvider(id: number) {
    this.$store.dispatch(FETCH_PROVIDER_BY_USERID, this.selectedUserId);
    const provider = this.providers.filter((it: Provider) => it.id === id);

    if (provider && provider.length) {
      this.removingProvider = true;
      await this.$store.dispatch(REMOVE_USER_PROVIDER_MAPPING, {
        providerId: provider[0].id,
        userId: this.selectedUserId,
      });
      this.$store.dispatch(FETCH_PROVIDER_BY_USERID, this.selectedUserId);
      this.removingProvider = false;
    }
  }

  public async removeUser(id: number) {
    const user = this.users.filter((it: User) => it.id === id);
    if (user && user.length) {
      this.removingProvider = true;
      await this.$store.dispatch(REMOVE_USER, user[0].id);
      this.removingProvider = false;
    }
    this.$store.dispatch(FETCH_USERS);
  }

  public clearUserFields() {
    this.createUserModal = false;
    this.addingUser = false;
    this.newUserName = null;
    this.newUserEmail = null;
    this.newUserPassword = null;
  }

  public clearProviderFields() {
    this.addProviderModal = false;
    this.addingProvider = false;
    this.newProviderName = null;
    this.newProviderEmail = null;
    this.showProviderForm = false;
    this.selectedProvider = null;
  }

  public toggleAddProviderForm() {
    this.showProviderForm = !this.showProviderForm;
  }

  public get providers() {
    const providerList = this.$store.getters.providers;
    if (providerList) {
      let pageEnd = this.currPage * 15;
      const PageStart = pageEnd - 15;
      if (pageEnd > providerList) {
        pageEnd = providerList;
      }
      return providerList.slice(PageStart, pageEnd);
    }
    return providerList;
  }

  public get totalPage() {
    const totalProvider = this.$store.getters.providers;
    if (totalProvider) {
      return Math.ceil(totalProvider.length / 15);
    }
    return 1;
  }

  public get allProviders() {
    return this.$store.getters.allProviders;
  }

  public get filteredProviders() {
    const providerList = this.$store.getters.providers;
    const mappedProviders = providerList.map((it: Provider) => it.id);
    const data = this.allProviders
      ? this.allProviders.filter((it: Provider) => {
          return !mappedProviders.includes(it.id);
        })
      : null;
    return data;
  }

  public get fetchingProviders() {
    return this.$store.getters.fetchingProviders;
  }

  public viewProvidersModal(userId: number) {
    const user = this.users.filter((it: User) => it.id === userId);
    this.userName = user[0].name;
    this.selectedUserId = user[0].id;
    this.$store.dispatch(FETCH_PROVIDER_BY_USERID, userId);
    this.providerModal = true;
  }

  public get providerTableData(): Table {
    const rows = this.providers ? this.providers : [];
    return {
      columns: [
        {
          value: 'id',
          title: 'Provider ID',
          type: TableColumnType.NUMBER,
          align: 'center',
        },
        {
          value: 'name',
          title: 'Provider Name',
          type: TableColumnType.STRING,
          align: 'center',
        },
        {
          value: 'email',
          title: 'Provider E-Mail',
          type: TableColumnType.STRING,
          align: 'center',
        },
        {
          value: 'remove',
          title: 'Remove Provider',
          type: TableColumnType.ACTIONS,
          align: 'center',
        },
      ],
      rows,
    };
  }
}
</script>
<style lang="scss">
.v-pagination__navigation .v-icon {
  font-size: 18px !important;
}
</style>
