import { ImportActions } from '@/modules/imports/store/actions';
import { ImportGetters } from '@/modules/imports/store/getters';
import { ImportMutations } from '@/modules/imports/store/mutations';
import { ImportState, IImportState } from '@/modules/imports/store/state';
import { IRootState } from '@/store/state';
import { Module } from 'vuex';

export const ImportStore: IImportStore = {
  state: ImportState,
  actions: ImportActions,
  getters: ImportGetters,
  mutations: ImportMutations
};

/**
 * --------------------------------------------------
 *	Scheme Store Interface
 * --------------------------------------------------
 **/
export interface IImportStore extends Module<IImportState, IRootState> {}
