<template>
  <div data-testid="list-b2c-providers-page" class="page list-b2c-providers-page">
    <v-container fluid>
      <v-layout class="mt-4 pa-0">
        <div style="align-self: center">
          <h2 class="section-title">
            <span>ALL PROVIDERS</span>
          </h2>
        </div>
        <v-layout align-end class="py-2 justify-end">
          <v-btn @click="$router.push({ name: 'dashboard.b2cpanel.providers.add' })">
            <span>Add Provider</span>
          </v-btn>
        </v-layout>
      </v-layout>
      <v-card v-if="!fetchingProviders || providers" class="mt-4">
        <AppTable
          :table-data="tableData"
          :loading="fetchingProviders"
          v-if="providers && providers.length"
          v-on:viewItemClick="(e) => editProvider(e)"
          :disablePagination="true"
        />
      </v-card>
      <div v-if="fetchingProviders && !providers" class="d-flex align-center justify-center pt-5 mt-5">
        <v-progress-circular indeterminate color="grey" />
      </div>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppTable from '@/components/AppTable.vue';
import { Table, DataTableHeader, TableRow, TableColumnType } from '@/types';
import { FETCH_PROVIDERS } from '@/modules/b2c-panel/store/actionTypes';
import { SET_NAV_HEADER } from '@/store/actionTypes';

@Options({
  components: {
    AppTable,
  },
})
export default class ProvidersPage extends Vue {
  public pagination = {
    page: 1,
    limit: 15,
  };

  public get paginateOptions(): any | null {
    const serverPagination = this.$store.getters.providers && this.$store.getters.providers.pagination;
    const itemsPerPage = this.pagination.limit || serverPagination.itemsPerPage || 15;
    const serverItemsLength = serverPagination && (serverPagination.serverItemsLength || itemsPerPage);
    const page = this.pagination.page || serverPagination.page || 1;
    return { itemsPerPage, page, serverItemsLength };
  }

  created() {
    this.bootstrap();
  }

  public bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Providers', tooltip: 'All Providers' });
    this.fetchProviders(this.pagination);
  }

  get fetchingProviders() {
    return this.$store.getters.fetchingProviders;
  }

  get providers() {
    return this.$store.getters.providersB2C;
  }

  public get tableData(): Table {
    const columns: DataTableHeader[] = [
      {
        value: 'id',
        title: 'ID',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'name',
        title: 'Name',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'img',
        title: 'Logo Provided',
        type: TableColumnType.STRING,
        formatter: (val) => (val ? 'Yes' : 'No'),
        align: 'center',
      },
      {
        value: 'view',
        title: 'View',
        type: TableColumnType.ACTIONS,
        align: 'center',
      },
    ];
    return {
      columns,
      rows: this.rows,
    };
  }

  public get rows(): TableRow[] {
    return (
      this.providers &&
      this.providers.map((provider: any, it: number) => {
        return {
          id: provider.id,
          name: provider.name,
          img: provider.img,
        };
      })
    );
  }

  public get filterVisibility(): boolean {
    return this.$store.getters.filterIsVisible;
  }

  public fetchProviders(data: any = {}) {
    this.$store.dispatch(FETCH_PROVIDERS, data);
  }
  public editProvider(id: String) {
    this.$router.push(`/b2cpanel/provider/${id}`);
  }
}
</script>
