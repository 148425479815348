/**
 * --------------------------------------------------
 *	Root State
 * --------------------------------------------------
 **/
export const RootState: IRootState = {
  dark: false,
  dataUploadDialogIsVisible: false,
  filterIsVisible: false,
  navDrawerIsVisible: true,
  navHeader: {},
  selectedItems: []
};

/**
 * --------------------------------------------------
 *	Root State Interface
 * --------------------------------------------------
 **/
export interface IRootState {
  dark: boolean;
  dataUploadDialogIsVisible: boolean;
  filterIsVisible: boolean;
  navHeader: Object;
  navDrawerIsVisible: boolean;
  selectedItems: number[];
}
