import { Simulation, SimulationFull } from '@/models/Simulation';
/**
 * --------------------------------------------------
 *	Simulation State
 * --------------------------------------------------
 **/

export const SimulationState: ISimulationState = {
  simulations: [],
  addingSimulation: false,
  currentSimulation: {
    id: '',
    status: '',
    maxUsers: 0,
    startDate: '',
    startDateType: '',
    endDate: '',
    maxContributions: -1,
    maxAmount: -1,
    fixedContributions: false,
    fixedAmounts: false,
    result: {
      stDev: -1,
      benchmarkXirr: []
    },
    simulationsPerContribution: 20 
  },
  gettingStandardDeviations: false
};

export interface ISimulationState {
  simulations: Simulation[];
  addingSimulation: boolean;
  currentSimulation: SimulationFull;
  gettingStandardDeviations: boolean;
}
