export const FETCH_MEMBERS = '/members';
export const FETCH_CONTRIBUTION_HISTORY = '/contribution-analysis/';
export const FETCH_MEMBERS_ANALYSIS = '/analysis/members';
export const EXPORT_MEMBERS_ANALYSIS = '/analysis/members/export';
export const FETCH_FILTERED_MEMBERS_ANALYSIS = '/analysis/members';
export const FETCH_MEMBER_FILTER_RANGES = '/analysis/members/filters';
export const FETCH_MEMBER_HISTORY = (id: number) => `/analysis/members/${id}/history`;
export const EXPORT_OUTLIER_ANALYSIS = (id: number) => `/analysis/members/outliers/export/${id}`;
export const FETCH_OUTLIER_ANALYSIS = (id: number) => `/analysis/members/outliers/${id}`;
export const EXPORT_OUTLIER_REASON_ANALYSIS = (id: number)  => `/analysis/members/outliers/export/${id}`;
export const GET_CSV_URL = (id: any) => `/analysis/members/url/${id}`;
export const FETCH_SAMPLE_MEMBER_DATA = '/analysis/members/sample-data';
export const FETCH_SAMPLE_MEMBERS_ANALYSIS = '/analysis/sample/members';
