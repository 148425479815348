<template>
  <div data-testid="list-reports-page" class="page list-reports-page">
    <div>
      <div ref="filterPanel" class="mt-10 page-filters-wrapper mb-4">
        <div
          :threshold="filterPanelPosition"
          class="navbar page-filters-panel pos-relative justify-space-between"
          :class="scrolledAll ? 'vue-fixed-header--isFixed' : ''"
        >
          <v-layout>
            <v-row no-gutters align="end" justify="space-between">
              <!-- Date Filter -->
              <v-col class="d-flex" cols="12" sm="6">
                <!-- Provider Filter -->
                <v-row>
                  <v-col cols="3" align-self="center">
                    <v-label>Provider</v-label>
                    <v-autocomplete
                      :model-value="currentFilters.provider"
                      :items="providers"
                      item-title="name"
                      item-value="id"
                      label="Provider"
                      variant="solo"
                      :disabled="fetchingChartData"
                      hide-details
                      density="compact"
                      class="mt-2 filter-btn-active"
                      single-line
                      @update:modelValue="
                        (e) => {
                          onProviderChange(e);
                        }
                      "
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <!-- End Buttons -->
              <v-col class="d-flex justify-end my-2" cols="12" sm="3">
                <v-btn
                  class="filter-btn back-btn elevation-6 mt-0 mr-2"
                  :ripple="false"
                  v-if="false"
                  variant="text"
                  to="/reports"
                >
                  <v-icon small>fas fa-arrow-left</v-icon>
                </v-btn>

                <v-btn
                  class="filter-btn elevation-6 mt-0 mx-2"
                  :ripple="false"
                  data-testid="ExportReportBtn"
                  variant="text"
                  v-if="isSuperUser"
                  :disabled="fetchingChartData || triggeringPA"
                  :loading="processingReport || fetchingChartData || triggeringPA"
                  @click="runPASeparately"
                >
                  <div class="mx-2">Run Performance Attribution</div>
                  <!-- <v-icon small>fas fa-file-pdf</v-icon> -->
                </v-btn>
                <v-btn
                  class="filter-btn elevation-6 mt-0 mx-2"
                  :ripple="false"
                  data-testid="ExportReportBtn"
                  variant="text"
                  :disabled="fetchingChartData"
                  :loading="processingReport"
                  @click="exportReport"
                >
                  <div class="mx-2">Export</div>
                  <v-icon small>fas fa-file-pdf</v-icon>
                </v-btn>
                <!-- <v-menu
                  v-model="filterIsVisible"
                  :close-on-content-click="false"
                  attach=".page-filters-wrapper"
                  content-class="page-filter-main"
                  max-width="100%"
                  min-width="100%"
                  offset="20"
                  transition="slide-y-transition"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="filter-btn elevation-6 mt-0 mx-2"
                      :class="{ 'is-active': isReportFiltered }"
                      :disabled="processingReport || fetchingChartData || triggeringPA"
                      data-testid="FilterBtn"
                      variant="text"
                      v-bind="props"
                      size="small"
                    >
                      <span>Filter</span>
                      <div class="ml-1">
                        <v-img v-if="!isReportFiltered" class="filter-icon" src="../../../assets/icons/filter.svg" />
                        <v-img
                          v-if="isReportFiltered"
                          class="filter-icon"
                          src="../../../assets/icons/filter-light.svg"
                        />
                      </div>
                    </v-btn>
                  </template>
                  <FilterTable
                    ref="reportFilterRef"
                    :dateFilters="false"
                    :filters="filters"
                    :showProviderFilters="false"
                    :showStringFilters="true"
                    :appliedFiltersParent="appliedFilters"
                    @apply="(e) => onFiltersApplied(e)"
                  ></FilterTable>
                </v-menu> -->
              </v-col>
            </v-row>
          </v-layout>
        </div>
      </div>

      <div>
        <div v-if="!fetchingChartData && !fetchingOutliers" height="100%">
          <ReportPageCover
            v-if="currentFilters.coverPage"
            :data="coverPageData"
            :chartData="chartData"
          ></ReportPageCover>
          <ScoreDist
            v-if="currentFilters.awDist"
            :data="page2Data"
            :chartData="chartData"
            :filters="currentFilters"
            :iavData="irrAndIavData"
            :reportfilterRanges="currentFiltersRanges"
          ></ScoreDist>
          <IrrAndIav
            v-if="currentFilters.iAsset || currentFilters.Irr"
            :data="page2Data"
            :reportfilterRanges="currentFiltersRanges"
            :chartData="irrAndIavData"
            :filters="currentFilters"
          ></IrrAndIav>
          <EPS
            v-if="currentFilters.ePotSize"
            :data="page2Data"
            :chartData="ePotData"
            :filters="currentFilters"
            :reportfilterRanges="currentFiltersRanges"
          ></EPS>
          <div v-if="segmentData">
            <DataSetSegmentation
              v-for="(val, key) in segmentData.numberGraphs"
              :id="key"
              :key="key"
              :title="capitalizeFirstLetterEveryWord(key)"
              type="number"
              :chartData="val"
              :filters="currentFilters"
              :reportfilterRanges="currentFiltersRanges"
            >
            </DataSetSegmentation>

            <DataSetSegmentation
              v-for="(val, key) in segmentData.stringGraphs"
              :id="key"
              :key="key"
              :title="capitalizeFirstLetterEveryWord(key)"
              type="string"
              :chartData="val"
              :filters="currentFilters"
              :reportfilterRanges="currentFiltersRanges"
            >
            </DataSetSegmentation>
          </div>
          <SccAndIsy
            v-if="(sccAndIsyData && currentFilters.schemeSeg) || currentFilters.yearSeg"
            :data="page2Data"
            :chartData="sccAndIsyData"
            :filters="currentFilters"
            :reportfilterRanges="currentFiltersRanges"
          ></SccAndIsy>
          <Outliers
            v-if="currentFilters.outliers && outliers && outliers.length"
            :data="outlierPageData"
            :providerId="currentFilters.provider"
          ></Outliers>
          <ReportStaticText> </ReportStaticText>
        </div>
        <div v-if="fetchingChartData || fetchingOutliers" class="d-flex align-center justify-center pt-5 mt-5">
          <v-progress-circular indeterminate color="grey" />
        </div>
      </div>
    </div>
    <v-snackbar v-model="pdfTimeoutError" :timeout="3000" color="primary" location="top">
      <h3>Error downloading requested pdf. Please try again.</h3>
      <v-btn color="blue" variant="text" class="d-flex ml-auto" @click="pdfTimeoutError = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { SET_NAV_HEADER } from '@/store/actionTypes';
import FilterTable from '@/components/FilterTable.vue';
import { FETCH_CHARTDATA } from '@/modules/providers-analysis/store/actionTypes';
import {
  GENERATE_REPORT,
  FETCH_OUTLIER_ANALYSIS,
  GET_PDF_URL,
  GET_EXPORT_JOB_BY_ID,
  RUN_PA_SEPARATELY,
} from '@/modules/reports/store/actionTypes';
import { FETCH_CURRENT_USER } from '@/modules/auth/store/actionTypes';
import User from '@/models/User';
import * as AT from '@/modules/members/store/actionTypes';
import ReportPageCover from './templates/ReportPageCover.vue';
import SccAndIsy from './templates/SccAndIsy.vue';
import Outliers from './templates/Outliers.vue';
import DataSetSegmentation from './templates/DataSetSegmentation.vue';
import IrrAndIav from './templates/IrrAndIav.vue';
import EPS from './templates/EPS.vue';
import AVB from './templates/AVB.vue';
import ScoreDist from './templates/ScoreDist.vue';
import ReportStaticText from './templates/ReportStaticText.vue';
import PerformanceAttribution from './templates/PerformanceAttribution.vue';
import Utils from '@/utils/Utils';

@Options({
  components: {
    ReportPageCover,
    IrrAndIav,
    EPS,
    AVB,
    SccAndIsy,
    FilterTable,
    Outliers,
    ReportStaticText,
    // FixedHeader,
    ScoreDist,
    PerformanceAttribution,
    DataSetSegmentation,
  },
})
export default class ReportGenPage extends Vue {
  created() {
    this.bootstrap();
  }

  public startDatePicker = false;
  public endDatePicker = false;

  public fetchingReport = false;

  public processingReport = false;

  public triggeringPA = false;

  public filterIsVisible = false;

  public isReportFiltered: boolean = false;

  public fetchingMembersAnalysis = false;

  public initialFilters: any = {};

  public currentFiltersRanges: any = {};

  public backendFilters: any = {};

  public filterPanelPosition = 200;

  public pdfTimeoutError = false;

  public scrolledAll: Boolean = false;

  public appliedFilters = {};

  public capitalizeFirstLetterEveryWord = Utils.capitalizeLetter;

  public currentFilters: any = {
    coverPage: true,
    awDist: true,
    yearSeg: true,
    Irr: true,
    segmentData: true,
    iAsset: true,
    ePotSize: true,
    outliers: true,
    provider: this.providers && this.providers.length > 0 ? this.providers[0].id : null,
    startDate: null,
    endDate: null,
  };

  public get coverPageData() {
    return {
      title: this.selectedProvider && this.selectedProvider.name,
      subTitle: 'VALUE FOR MONEY REPORT',
    };
  }

  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  mounted() {
    if (!this.currentFilters.provider) {
      this.currentFilters.provider = this.providers && this.providers.length > 0 ? this.providers[0].id : null;
    }
  }

  public get user(): User {
    return this.$store.getters.currentUser;
  }

  public get isSuperUser() {
    return this.providers && this.user && this.user.role === 'superuser';
  }

  public get isSampleReport() {
    return this.user && this.user.role === 'bm_user';
  }

  public get isPerformanceAttribution() {
    return true;
  }

  public get page2Data() {
    return {
      title: 'Year Segmentation',
      subTitle: 'Monthly Report',
      avgIrr: '7%',
      underperform: '1%',
    };
  }

  public get outliers() {
    return this.$store.getters.outliers && this.$store.getters.outliers.data;
  }

  public get outlierGroups() {
    return this.$store.getters.outliers && this.$store.getters.outliers.outlierGroups;
  }

  public get totalOutliers() {
    return this.$store.getters.outliers && this.$store.getters.outliers.totalOutlier;
  }

  public get totalMemberCount() {
    return this.$store.getters.outliers && this.$store.getters.outliers.totalMemberCount;
  }

  public get outlierPageData() {
    return {
      outliers: this.outliers,
      outlierGroups: this.outlierGroups,
      totalOutlier: this.totalOutliers,
      totalMemberCount: this.totalMemberCount,
    };
  }

  public get selectedProvider() {
    const id = this.currentFilters && this.currentFilters.provider;
    if (!id) {
      return null;
    }

    return this.providers && this.providers.find((p: any) => p.id === id);
  }

  public get longStartDate() {
    return this.currentFilters.startDate ? this.toLongDate(this.currentFilters.startDate) : '';
  }

  public get longEndDate() {
    return this.currentFilters.endDate ? this.toLongDate(this.currentFilters.endDate) : '';
  }

  public toLongDate(date: string) {
    return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  }

  public updateStartDate(date: any) {
    this.currentFilters.startDate = date;
  }

  public updateEndDate(date: any) {
    this.currentFilters.endDate = date;
  }

  private updateFiltersForBEQuery(filters: any) {
    if (filters.birrRange) {
      this.backendFilters.birr = {
        lt: filters.birrRange[1],
        gte: filters.birrRange[0],
      };
    } else {
      this.backendFilters.birr = {};
    }

    if (filters.irrRange) {
      this.backendFilters.irr = {
        lt: filters.irrRange[1],
        gte: filters.irrRange[0],
      };
    } else {
      this.backendFilters.irr = {};
    }

    if (filters.potRange) {
      this.backendFilters.pot_size = {
        lt: filters.potRange[1],
        gte: filters.potRange[0],
      };
    } else {
      this.backendFilters.pot_size = {};
    }

    if (filters.scoreRange) {
      this.backendFilters.score = {
        lt: filters.scoreRange[1],
        gte: filters.scoreRange[0],
      };
    } else {
      this.backendFilters.score = {};
    }

    if (filters.stringVals) {
      const stringList: any = {};
      filters.stringVals.forEach((stringData: any) => {
        const arrVal = stringList[stringData.order];
        stringList[stringData.order] = (arrVal && [...arrVal, stringData.val]) || [stringData.val];
      });
      this.backendFilters.segment = {
        string: stringList,
      };
    } else {
      this.backendFilters.segment = {};
    }
  }

  private updateCurrentFilters(filters: any) {
    if (filters.birrRange) {
      this.currentFiltersRanges.birrRange = [filters.birrRange[0], filters.birrRange[1]];
    }

    if (filters.irrRange) {
      this.currentFiltersRanges.irrRange = [filters.irrRange[0], filters.irrRange[1]];
    }

    if (filters.potRange) {
      this.currentFiltersRanges.potRange = [filters.potRange[0], filters.potRange[1]];
    }

    if (filters.scoreRange) {
      this.currentFiltersRanges.scoreRange = [filters.scoreRange[0], filters.scoreRange[1]];
    }
    this.currentFiltersRanges.providers = [this.currentFilters.provider];
  }

  public get filters() {
    return {
      providers: this.providers || [],
      ...this.initialFilters,
    };
  }

  public get isBenchmarkUser() {
    return this.user && this.user.role === 'bm_user';
  }

  public async onFiltersApplied(filters: any = {}) {
    const providerId = this.currentFilters.provider;
    this.filterIsVisible = false;
    console.log('filters from filter table. Applying these', filters);
    this.appliedFilters = filters || {};
    this.updateFiltersForBEQuery(filters);
    this.updateCurrentFilters(filters);
    const isReset = Object.keys(filters).length == 0 || false;
    await this.fetchProviderData(providerId, false, isReset);
    this.isReportFiltered = !isReset;
  }

  public async exportReport() {
    //PDF Export Polling with 2sec interval and 16sec Timout
    this.processingReport = true;
    const job = await this.$store.dispatch(GENERATE_REPORT, { ...this.currentFilters, ...this.backendFilters });

    let pdfFetchInterval = setInterval(async () => {
      let response = await this.$store.dispatch(GET_EXPORT_JOB_BY_ID, job.id);
      if (response[0].status === 'completed') {
        const url = await this.$store.dispatch(GET_PDF_URL, job.id);
        await window.open(url);
        this.processingReport = false;
        clearInterval(pdfFetchInterval);
      }
      return response;
    }, Utils.pdfInterval);
    setTimeout(() => {
      clearInterval(pdfFetchInterval);
      if (this.processingReport) {
        this.pdfTimeoutError = true;
      }
      this.processingReport = false;
    }, Utils.pdfTimeout);
  }

  public async bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Reports', tooltip: 'Export Reports' });
    const providerId = this.providers && this.providers.length > 0 ? this.providers[0].id : null;
    if (!this.isSampleReport) {
      if (providerId) {
        await this.fetchProviderData(providerId, true);
      }
      this.$store.dispatch(FETCH_CURRENT_USER);
    }
  }

  public get chartData() {
    return this.$store.getters.chartData;
  }

  public get performanceAttributionData() {
    return this.$store.getters.performanceReport;
  }

  public get fetchingOutliers() {
    return this.$store.getters.fetchingOutliers;
  }

  public get providerDataQuality() {
    return this.$store.getters.providerDataQuality;
  }

  public get sccAndIsyData() {
    if (!this.chartData) {
      return null;
    }

    return {
      // scheme: this.chartData.scheme,
      investments: this.chartData.investments,
    };
  }

  public getProviderName(id: any) {
    const providerData = this.providers.find((item: any) => item.id == id);
    if (providerData) {
      return providerData.name;
    }
    return '';
  }

  public get segmentData() {
    if (!this.chartData) {
      return null;
    }

    return this.chartData.segmentData;
  }

  public get irrAndIavData() {
    if (!this.chartData) {
      return null;
    }

    return {
      irr: this.chartData.irr,
      iav: this.chartData.assetImpactVal,
    };
  }

  public get ePotData() {
    if (!this.chartData) {
      return null;
    }

    return {
      ePot: this.chartData.potEffectVal,
    };
  }

  public get avbData() {
    if (!this.chartData) {
      return null;
    }

    return {
      avb: this.chartData.avb,
    };
  }

  public get fetchingChartData() {
    return this.$store.getters.fetchingChartData;
  }

  public get providers() {
    return this.$store && this.$store.getters.providers;
  }

  public get currentStoredFilters() {
    return this.$store.getters.reportOptions;
  }

  public setStringFilterVals(filters: any) {
    const stringPoints = filters && filters.dataPoints.dataStrings;
    let stringFilterArray: any = [];
    if (stringPoints && stringPoints.length > 0) {
      const segmentNames = [...new Set(stringPoints.map((elm: any) => elm.name))];
      if (segmentNames.length > 0) {
        segmentNames.forEach((sgName) => {
          const segmentList = stringPoints.filter((elm: any) => elm.name == sgName);
          if (segmentList.length > 0) {
            stringFilterArray.push({
              header: sgName,
            });
            stringFilterArray = [...stringFilterArray, ...segmentList];
            stringFilterArray.push({
              divider: true,
            });
          }
        });
      }
      return stringFilterArray;
    }
    return [];
  }

  public async onProviderChange(providerId: any) {
    this.currentFilters.provider = providerId;
    this.initialFilters = {};
    this.currentFiltersRanges = {};
    if (!this.isSampleReport) {
      await this.fetchProviderData(providerId, true);
      if (this.$refs.reportFilterRef) {
        this.isReportFiltered = false;
        (this.$refs.reportFilterRef as Vue & { resetFilters: (arg0: any) => {} }).resetFilters(true);
      }
    }
  }

  private async fetchProviderData(providerId: number, setInitialFilterRanges: boolean, isReset = false) {
    if (setInitialFilterRanges) {
      this.initialFilters = {};
      this.currentFiltersRanges = {};
    }
    const filterVals = ((setInitialFilterRanges || isReset) && {}) || this.currentFiltersRanges;
    // console.log('filters received before fetch ranges calls', JSON.stringify(this.initialFilters));
    const filterValsToSend = { ...filterVals };
    delete filterValsToSend.dataPoints;
    let initialFilters = await this.$store.dispatch(AT.FETCH_MEMBER_FILTER_RANGES, {
      providers: [providerId],
      ...filterValsToSend,
    });
    this.currentFiltersRanges = { ...initialFilters, scoreRange: filterVals.scoreRange };
    if (setInitialFilterRanges) {
      const stringFilterDataPoints = this.setStringFilterVals(initialFilters);
      this.initialFilters = { ...initialFilters, stringFilterDataPoints };
    }
    // console.log('filters received from fetch ranges calls', JSON.stringify(this.initialFilters));
    this.$store.dispatch(FETCH_CHARTDATA, { providerId, ...this.backendFilters });
    this.$store.dispatch(FETCH_OUTLIER_ANALYSIS, { providerId, limit: 15 });
  }

  public async runPASeparately() {
    this.triggeringPA = true;
    const resp = await this.$store.dispatch(RUN_PA_SEPARATELY, this.currentFilters.provider);
    this.triggeringPA = false;
    window.alert(`Performance Attribution has started.
    Please check back in some time to get the report in the Performance Attribution tab.`);
  }

  handleScroll() {
    if (this.$refs.filterPanel && this.$refs.filterPanel instanceof Element) {
      const topPosition = this.$refs.filterPanel.getBoundingClientRect().top;
      if (topPosition == 0) {
        this.scrolledAll = true;
      } else {
        this.scrolledAll = false;
      }
    }
  }
}
</script>
<style lang="scss">
.filter-menu {
  background: #ffffff !important;
  .v-label {
    font-weight: 500;
  }
}

.filter-btn-active {
  border-radius: 4px;
  background-color: #dfc3e6 !important;
}
.filter-btn-active .v-input__slot,
.filter-btn-active .v-field__overlay {
  background-color: #dfc3e6 !important;
}

.page-filters-wrapper {
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  position: sticky !important;
}

.page-filters-panel {
  &.vue-fixed-header--isFixed {
    background: #fff;
    margin-top: 0 !important;
    padding: 1rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  }
}
.list-reports-page {
  .truncate {
    width: 7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .back-btn:hover::before,
  .back-btn::before {
    opacity: 0 !important;
  }
}

.report-template {
  // padding: 0.5rem;
  margin-top: 0.5rem;
  .report-page-card {
    .card-data {
      padding: 1rem 0rem;
      min-height: 70rem;
    }
    .top-triangle {
      position: absolute;
      right: 0;
      .top-right-color-triangle {
        position: absolute;
        right: 0;
        width: 0;
        height: 0;
        z-index: 1;
        border-style: solid;
        border-width: 0 200px 200px 0;
        border-color: transparent #dfc6e6 transparent transparent;
      }
      .top-right-grey-triangle {
        position: absolute;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 400px 200px 0;
        border-color: transparent #f5f5f5 transparent transparent;
      }
    }
    .footer {
      width: 100%;
      max-width: 90%;
      padding: 1.5rem 0;
      margin: auto;
      display: flex;
      justify-content: space-between;

      .page-box {
        padding: 0.2rem;
        width: 7rem;
        margin: auto 0;
        border-radius: 0.2rem;
        background-color: #a994b1;
        .pageno {
          text-align: center;
          color: #fff;
        }
      }
      .footer-img {
        max-width: 10rem;
      }
    }
    .report-page-title {
      margin: 0 auto;
      text-align: center;
      font-size: 2.5rem;
      font-weight: 500;
    }
    .report-page-subtitle {
      margin: 0 auto;
      text-align: center;
      font-size: 2rem;
      font-weight: 300;
    }

    .main-page-feature {
      margin-left: 5rem;
      margin-top: 10rem;
      .v-image {
        width: 30rem;
      }
      .feature-title {
        font-size: 7rem;
        font-weight: 500;
        // color: #e2af06;
      }
      .feature-sub-title {
        font-size: 1.7rem;
        font-weight: 500;
        color: #e2af06;
      }
    }
    .main-page-content {
      margin: 7rem 0 0 5rem;
      .main-page-content-title {
        font-size: 1.5rem;
        font-weight: 600;
        padding-top: 3rem;
        padding-bottom: 1rem;
      }
      .main-page-text {
        // margin: 1.5rem auto 0 auto;
        // text-align: center;
        font-size: 1rem;
        padding-bottom: 4rem;
        max-width: 90%;
        text-align: justify;
      }
    }
    .section {
      max-width: 80%;
      width: 100%;
      margin: auto;
      margin-top: 3rem;
      // text-align: center;
      .section-title {
        margin: 0 auto;
        font-size: 1.4rem;
        font-weight: 600;
        padding-bottom: 1rem;
        display: flex;
        color: #000;
      }
      .section-content {
        font-size: 1rem;
        margin-bottom: 3rem;
        text-align: justify;
      }
    }
    .section-badge {
      height: 30px;
      width: 7px;
      margin-right: 1rem;
      background-color: #796581;
    }
    .report-chart {
      margin: 0 auto;
      width: 100%;
      padding: 2rem 0rem;
      background: #dac5e221;
    }
    .chart-title {
      font-weight: 600;
      font-size: 1.2rem;
      text-align: left;
      padding: 2rem 0 1.7rem 2rem;
    }
    .chartWrapper {
      height: auto;
      width: 100%;
      max-width: 70rem;
      border-radius: 0.2rem;
      background: #fff;
      margin: 0 auto;
    }
    .text-blue {
      color: blue;
    }
  }
}

.page-filter-main {
  min-width: 100% !important;
  max-width: 100% !important;
  left: unset !important;
}

.provider-btn {
  position: relative !important;
  display: flex !important;
  padding: 0 !important;
  width: 95% !important;
  margin: 0 !important;
  flex: 1 1 auto;
  justify-content: flex-start;
  text-transform: none;
  padding-left: 6px !important;
  font-size: 16px !important;
  .v-btn__content {
    max-width: 90% !important;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left !important;
  }
}
</style>
