import { IRootState } from '@/store/state';
import { IB2CState } from '@/modules/b2c-panel/store/state';
import { GetterTree } from 'vuex';
import ProviderB2C from '@/models/ProviderB2C';
import UserB2C from '@/models/UserB2C';
import ImportB2C from '@/models/ImportB2C';

/**
 * --------------------------------------------------
 *	ProviderB2C Getters
 * --------------------------------------------------
 **/
export const B2CGetters: IB2CGetters = {
  providersB2C: (state: IB2CState) => state.providersB2C,
  currentProvider: (state: IB2CState) => state.currentProvider,
  updatingProvider: (state: IB2CState) => state.updatingProvider,
  usersB2C: (state: IB2CState) => state.usersB2C,
  currentB2CUser: (state: IB2CState) => state.currentB2CUser,
  updatingUser: (state: IB2CState) => state.updatingUser,
  addingScore: (state: IB2CState) => state.addingScore,
  importsB2C: (state: IB2CState) => state.importsB2C,
  gettingContributionsData: (state: IB2CState) => state.gettingContributionsData,
  exportingUsers: (state: IB2CState) => state.exportingUsers,
};

/**
 * --------------------------------------------------
 *	ProviderB2C Getters Interface
 * --------------------------------------------------
 **/
export interface IB2CGetters extends GetterTree<IB2CState, IRootState> {
  providersB2C(state: IB2CState): ProviderB2C[];
  currentProvider(state: IB2CState): ProviderB2C;
  updatingProvider(state: IB2CState): Boolean;
  usersB2C: (state: IB2CState) => UserB2C[];
  currentB2CUser: (state: IB2CState) => UserB2C;
  updatingUser: (state: IB2CState) => Boolean;
  addingScore: (state: IB2CState) => Boolean;
  importsB2C: (state: IB2CState) => ImportB2C[];
  gettingContributionsData(state: IB2CState): boolean;
  exportingUsers(state: IB2CState): Boolean;
}
