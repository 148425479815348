<template>
  <div data-testid="add-price-track-page" class="page add-price-track-page">
    <v-container fluid>
      <v-layout class="mt-4 pa-0">
        <div class="w-100">
          <v-card>
            <v-form @submit.prevent="addPriceTrack" class="py-5">
              <v-card-title class="d-flex align-center text-center justify-space-between mb-6">
                <span class="text-h5"> Add PriceTrack </span>
              </v-card-title>
              <v-card-text>
                <v-label>Name</v-label>
                <v-text-field
                  hide-details
                  :persistent-hint="false"
                  variant="underlined"
                  placeholder="Fund Name to appear in the report"
                  v-model="priceTrackObject.name"
                  class="mb-5"
                />
                <v-label>Select Index</v-label>
                <v-autocomplete
                  data-testid="Index-Type-List"
                  v-model="indexType"
                  :items="indexTypes"
                  item-title="name"
                  item-value="id"
                  class="mx-0 mt-2 mb-5"
                  type="text"
                  hide-no-data
                  hide-details
                  label="Index Type"
                  variant="filled"
                />
                <v-label>First Date (YYYY-MM-DD)</v-label>
                <v-text-field
                  hide-details
                  :persistent-hint="false"
                  variant="underlined"
                  placeholder="Start Date"
                  v-model="priceTrackObject.firstDate"
                  class="mb-5"
                />

                <v-switch
                  hide-details
                  color="accent"
                  :persistent-hint="false"
                  :label="`Calculate granular past performance: ${priceTrackObject.calculatePastPerformance.toString()}`"
                  v-model="priceTrackObject.calculatePastPerformance"
                  class="mb-5"
                />

                <v-label>Date Distribution</v-label>
                <v-select
                  hide-details
                  :items="['Monthly']"
                  variant="underlined"
                  :persistent-hint="false"
                  v-model="priceTrackObject.dateDistribution"
                  class="mb-5"
                />
                <v-label>NAV Date (YYYY-MM-DD)</v-label>
                <v-text-field
                  hide-details
                  variant="underlined"
                  :persistent-hint="false"
                  placeholder="Should be present in the price track that you upload"
                  v-model="priceTrackObject.navDate"
                  class="mb-5"
                />

                <v-switch
                  hide-details
                  color="accent"
                  :persistent-hint="false"
                  :label="`Are charges included in the uploaded price track: ${priceTrackObject.chargesIncluded.toString()}`"
                  v-model="priceTrackObject.chargesIncluded"
                  class="mb-5"
                />

                <v-switch
                  hide-details
                  color="accent"
                  :persistent-hint="false"
                  :label="`Allow short contribution history in the member simulation: ${priceTrackObject.includeShortContributionHistory.toString()}`"
                  v-model="priceTrackObject.includeShortContributionHistory"
                  class="mb-5"
                />
                <v-label>Fund Charges (Percentage %)</v-label>
                <v-text-field
                  hide-details
                  :persistent-hint="false"
                  variant="underlined"
                  placeholder="Enter decimal number"
                  type="number"
                  v-model="priceTrackObject.charge"
                  class="mb-5"
                />
                <!-- </div> -->
                <!-- <v-label>Number of contributions/ Max number of contributions(if not fixed)</v-label>
              <v-text-field
                hide-details
                :persistent-hint="false"
                placeholder="Number of contributions/ Max number of contributions(if not fixed)"
                type="number"
                v-model="priceTrackObject.maxContributions"
                class="mb-5"
              /> -->

                <AppUploader
                  data-testid="FileUpload"
                  @app-uploader="handleFile"
                  :disabled="addingPriceTrack"
                  :fileType="'.csv'"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  data-testid="SubmitUploadBtn"
                  :color="addingPriceTrack ? 'disabled' : 'success'"
                  type="submit"
                  :loading="addingPriceTrack"
                  :disabled="addingPriceTrack"
                  variant="flat"
                  >Add</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Vue, Watch } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppUploader from '@/components/AppUploader.vue';
import { ADD_PRICE_TRACK } from '@/modules/price-track/store/actionTypes';
import { FETCH_BENCHMARKS } from '@/modules/benchmarks/store/actionTypes';
import { SET_NAV_HEADER } from '@/store/actionTypes';
import { HTTPServiceClient } from '@/packages/http/service';

@Options({
  components: {
    AppUploader,
  },
})
export default class PriceTrackAdd extends Vue {
  public validationError = '';
  public priceTrackObject = {
    name: '',
    firstDate: '2015-01-01',
    dateDistribution: 'Monthly',
    navDate: '2022-02-28',
    chargesIncluded: false,
    charge: 0.5,
    includeShortContributionHistory: false,
    calculatePastPerformance: false,
  };
  public file: any = null;
  public addingPriceTrack = false;

  public indexType = 'default';
  public defaultIndex = {
    id: 'default',
    name: 'Default morningstar',
  };
  public indexTypes = [this.defaultIndex];

  @Watch('addingPriceTrack')
  public userUpdated() {
    if (this.addingPriceTrack === false) {
      this.$router.push({ name: 'dashboard.price-track.list' });
    }
  }

  get benchmarks() {
    return this.$store.getters.benchmarks;
  }

  public async fetchBenchmarks(data: any = {}) {
    await this.$store.dispatch(FETCH_BENCHMARKS, data);
  }

  public handleFile(data: any) {
    this.file = data.file;
  }

  created() {
    this.bootstrap();
  }

  public async bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'PriceTracks', tooltip: 'Add PriceTrack' });
    await this.fetchBenchmarks();
    this.indexTypes = [
      this.defaultIndex,
      ...this.benchmarks.map((a: any) => {
        return {
          id: a.id,
          name: a.name,
        };
      }),
    ];
  }

  public addPriceTrack() {
    if (!this.file) {
      return false;
    }
    let formData = new FormData();
    const indexType = this.indexType || 'default';

    formData.append('file', this.file, this.file.name);
    formData.append('name', `${this.priceTrackObject.name}`);
    formData.append('firstDate', `${this.priceTrackObject.firstDate}`);
    formData.append('dateDistribution', this.priceTrackObject.dateDistribution);
    formData.append('navDate', this.priceTrackObject.navDate);
    formData.append('chargesIncluded', `${this.priceTrackObject.chargesIncluded}`);
    formData.append('indexType', indexType);
    formData.append('charge', `${this.priceTrackObject.charge}`);
    formData.append('includeShortContribution', `${this.priceTrackObject.includeShortContributionHistory}`);
    formData.append('calculatePastPerformance', `${this.priceTrackObject.calculatePastPerformance}`);

    this.addingPriceTrack = true;
    try {
      return HTTPServiceClient.post('/price-tracks/add', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((res) => {
          console.log('res', res);
        })
        .finally(() => {
          this.addingPriceTrack = false;
          this.file = null;
        });
    } catch (err) {
      this.addingPriceTrack = false;
      console.log('err', err);
    }
  }
}
</script>
