<template>
  <div class="report-template">
    <Watermark :isSampleReport="isSampleReport" />
    <v-card class="report-page-card top-right-color-triangle" outlined flat>
      <div class="top-triangle">
        <div class="top-right-color-triangle"></div>
        <div class="top-right-grey-triangle"></div>
      </div>
      <div class="card-data">
        <div style="font-size: 30px; margin-top: 5rem; text-align: center; font-weight: 500">
          Private and Confidential
        </div>
        <div class="main-page-feature">
          <!-- <v-img src="../../../../assets/crystal-logo.jpg"></v-img> -->
          <div class="feature-title">{{ data.title }}</div>
          <div class="feature-sub-title">{{ data.subTitle }}</div>
          <div
            v-if="!fetchingChartData && this.filterRanges && getNavDateVal"
            class="feature-sub-title text-black mt-1"
          >
            As on {{ getNavDateVal }}
          </div>
        </div>
        <div class="main-page-content">
          <div class="main-page-content-title">Introduction and Context</div>
          <div class="main-page-text">
            This report has been prepared for {{ data.title }}. The data received from {{ data.title }} has assisted
            AgeWage to caliberate returns against a benchmark that can be used to analyse all individual DC pension pots
            and satisfy the Work and Pensions Select Committee's recent call for a common definition of value for money.
          </div>
        </div>
      </div>
      <div class="footer">
        <div></div>
        <!-- <div class="page-box">
          <div class="pageno">Page 1/4</div>
        </div> -->
        <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
      </div>
    </v-card>
  </div>
</template>
<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { FETCH_MEMBERS, FETCH_CONTRIBUTION_HISTORY } from '@/modules/members/store/actionTypes';
import AIRRChart from '@/components/charts/AIRRChart.vue';
import Watermark from './Watermark.vue';

@Options({
  components: {
    AIRRChart,
    Watermark,
  },
})
export default class ReportPageCover extends Vue {
  created() {
    this.bootstrap();
  }
  @Prop()
  public chartData?: any;

  @Prop()
  public data?: any;

  @Prop() isSampleReport!: boolean;

  public barColor: any = {
    // color: '#fe7598',
    // color: '#fea1be',

    primary: '#a085bc',
    secondary: '#cbafd5',
  };

  get moduleIsReady() {
    return true;
  }
  public get airrData() {
    return this.chartData.irr;
  }
  public get fetchingChartData() {
    return this.$store.getters.fetchingChartData;
  }

  private get filterRanges() {
    if (!this.isSampleReport) {
      return this.$store.getters.memberFilterRanges;
    }
    return {};
  }

  public get getNavDateVal() {
    const navDate = this.filterRanges && this.filterRanges.navDate;
    return (navDate && `${navDate.substring(8, 10)}/${navDate.substring(5, 7)}/${navDate.substring(0, 4)}`) || '';
  }

  bootstrap() {
    //
  }
}
</script>
