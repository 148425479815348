import * as MT from '@/modules/providers-analysis/store/mutationTypes';
import { IProviderAnalysisState } from '@/modules/providers-analysis/store/state';
import { MutationTree } from 'vuex';

/*--------------------------------------------------/
 * ProviderAnalysis Mutations
 *--------------------------------------------------*/
export const ProviderAnalysisMutations: IProviderAnalysisMutations = {
  [MT.SET_ANALYSIS](state: any, analysis: []) {
    state.analysis = analysis;
  },
  [MT.SET_CHARTDATA](state: any, chartData: []) {
    state.chartData = chartData;
  },
  [MT.SET_PDF_LINK](state: any, pdfLink: string) {
    state.pdfLink = pdfLink;
  },

  [MT.SET_FETCHING_ANALYSIS](state: any, value: boolean) {
    state.fetchingAnalysis = value;
  },
  [MT.SET_GENERATING_PDF](state: any, value: boolean) {
    state.generatingPdf = value;
  },
  [MT.SET_FETCHING_CHARTDATA](state: any, value: boolean) {
    state.fetchingChartData = value;
  }
};

/*--------------------------------------------------/
 * ProviderAnalysis Mutations Tree Interface
 *--------------------------------------------------*/
export interface IProviderAnalysisMutations extends MutationTree<IProviderAnalysisState> {}
