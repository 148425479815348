<template>
  <div data-testid="B2CPanelModule">
    <router-view v-if="moduleIsReady" />
    <div v-else>Loading Panel...</div>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';

export default class B2CPanelModule extends Vue {
  created() {
    this.bootstrap();
  }

  get moduleIsReady() {
    return true;
  }

  bootstrap() {
  }
}
</script>
