import * as AT from '@/modules/b2c-panel/store/actionTypes';
import * as MT from '@/modules/b2c-panel/store/mutationTypes';
import { IRootState } from '@/store/state';
import { IB2CState } from '@/modules/b2c-panel/store/state';
import { ActionContext, ActionTree } from 'vuex';
import { B2CService } from '@/modules/b2c-panel/services';

/**
 * --------------------------------------------------
 *  ProviderB2C Actions
 * --------------------------------------------------
 **/
export const B2CActions: IB2CActions = {
  /**
   * --------------------------------------------------
   *  Fetch All the ProviderB2Cs
   * --------------------------------------------------
   **/
  [AT.FETCH_PROVIDERS]: ({ commit, dispatch }: any) => {
    return B2CService.getProvidersB2C()
      .then(providers => {
        commit(MT.SET_PROVIDERS, providers);
        return providers;
      });
  },
  [AT.FETCH_PROVIDER]: ({ commit, dispatch }: any, id: String) => {
    return B2CService.getProviderB2C(id)
      .then(currentProvider => {
        commit(MT.SET_PROVIDER, currentProvider);
        return currentProvider;
      });
  },
  [AT.EDIT_PROVIDER]: ({ commit, dispatch }: any, data: any) => {
    commit(MT.UPDATING_PROVIDER, true);
    return B2CService.editProviderB2C(data)
      .then(resp => {
        return resp;
      })
      .finally(() => {
        commit(MT.UPDATING_PROVIDER, false);
      });
  },
  [AT.FETCH_USERS]: ({ commit, dispatch }: any) => {
    return B2CService.getUsersB2C()
      .then(users => {
        commit(MT.SET_USERS, users);
        return users;
      });
  },
  [AT.EXPORT_USERS]: ({ commit, dispatch }: any) => {
    commit(MT.EXPORT_USERS, true);
    return B2CService.getExportUsersURL()
      .finally(() => {
        commit(MT.EXPORT_USERS, false);
      });
  },
  [AT.FETCH_USER]: ({ commit, dispatch }: any, id: String) => {
    return B2CService.getUserB2C(id)
      .then(currentUser => {
        commit(MT.SET_USER, currentUser);
        return currentUser;
      });
  },
  [AT.EDIT_USER]: ({ commit, dispatch }: any, data: any) => {
    commit(MT.UPDATING_USER, true);
    return B2CService.editUserB2C(data)
      .then(resp => {
        return resp;
      })
      .finally(() => {
        commit(MT.UPDATING_USER, false);
      });
  },
  [AT.ADD_SCORE_BULK]: ({ commit, dispatch }: any, data: any) => {
    commit(MT.ADDING_SCORE, true);
    return B2CService.addScoreBulk(data)
      .then(resp => {
        return resp;
      })
      .finally(() => {
        commit(MT.ADDING_SCORE, false);
      });
  },
  [AT.FETCH_IMPORTS]: ({ commit, dispatch }: any) => {
    return B2CService.getImports()
      .then(imports => {
        commit(MT.SET_IMPORTS, imports);
        return imports;
      });
  },
  [AT.EXPORT_B2C_CONTRIBUTIONS]: ({ commit, dispatch }: any, importId: number) => {
    commit(MT.GETTING_B2C_CONTRIBUTIONS, true);
    return B2CService.getContributionsURL(importId)
      .finally(() => {
        commit(MT.GETTING_B2C_CONTRIBUTIONS, false);
      });
  },
};

/**
 * --------------------------------------------------
 *  ProviderB2C Action Context Interface
 * --------------------------------------------------
 **/
export interface IProviderB2CActionContext extends ActionContext<IB2CState, IRootState> { }

/**
 * --------------------------------------------------
 *  ProviderB2C Actions Interface
 * --------------------------------------------------
 **/
export interface IB2CActions extends ActionTree<IB2CState, IRootState> { }
