<template>
  <div v-if="isSampleReport" class="watermark">
    <p>AgeWage Test Report</p>
  </div>
</template>
<script lang="ts">
export default {
  props: ['isSampleReport'],
};
</script>
<style scoped>
.watermark {
  color: rgb(0 0 0 / 12%);
  font-size: 100px;
  position: absolute;
  top: 40%;
  left: 0;
  z-index: 1;
  width: 100%;
  text-align: center;
  transform-origin: center;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
</style>
