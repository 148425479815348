export const FETCH_PROVIDERS = 'provider/FETCH_PROVIDERS';
export const FETCH_ALL_PROVIDERS = 'provider/FETCH_ALL_PROVIDERS';
export const FETCH_PROVIDER_BY_ID = 'provider/FETCH_PROVIDER_BY_ID';
export const FETCH_PROVIDER_BY_USERID = 'provider/FETCH_PROVIDER_BY_USERID';
export const FETCHING_PROVIDERS = 'provider/FETCHING_PROVIDERS';
export const FETCHING_PROVIDER = 'provider/FETCHING_PROVIDER';
export const ADD_PROVIDER = 'provider/ADD_PROVIDER';
export const REMOVE_PROVIDER = 'provider/REMOVE_PROVIDER';
export const FETCH_PROVIDERS_PA = 'provider/FETCH_PROVIDERS_PA';
export const FETCH_PROVIDER_DATA_QUALITY = 'provider/FETCH_PROVIDER_DATA_QUALITY';
export const FETCHING_PROVIDER_DATA_QUALITY = 'provider/FETCHING_PROVIDER_DATA_QUALITY';
export const FETCH_PROVIDER_MASTER_LIST = 'provider/FETCH_PROVIDER_MASTER_LIST';
export const FETCHING_MASTER_PROVIDER = 'provider/FETCHING_MASTER_PROVIDER';

