<template>
  <div data-testid="list-b2c-imports-page" class="page list-b2c-imports-page">
    <v-container fluid>
      <v-layout class="mt-4 pa-0">
        <div style="align-self: center">
          <h2 class="section-title">
            <span>ALL IMPORTS</span>
          </h2>
        </div>
      </v-layout>
      <v-card v-if="!fetchingImports || imports" class="mt-4">
        <AppTable
          :table-data="tableData"
          :loading="fetchingImports"
          v-if="imports && imports.length"
          :disablePagination="true"
        />
      </v-card>
      <div v-if="fetchingImports && !imports" class="d-flex align-center justify-center pt-5 mt-5">
        <v-progress-circular indeterminate color="grey" />
      </div>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppTable from '@/components/AppTable.vue';
import { Table, DataTableHeader, TableRow, TableColumnType } from '@/types';
import { FETCH_IMPORTS } from '@/modules/imports/store/actionTypes';
import { SET_NAV_HEADER } from '@/store/actionTypes';

@Options({
  components: {
    AppTable,
  },
})
export default class ImportsPage extends Vue {
  public pagination = {
    page: 1,
    limit: 15,
  };
  created() {
    this.bootstrap();
  }

  public bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Imports', tooltip: 'All Imports' });
    this.fetchImports(this.pagination);
  }

  get fetchingImports() {
    return this.$store.getters.fetchingImports;
  }

  get imports() {
    return this.$store.getters.imports;
  }

  public get tableData(): Table {
    const columns: DataTableHeader[] = [
      {
        value: 'id',
        title: 'ID',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'status',
        title: 'Status',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'createdAt',
        title: 'Time of Creation',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'dataBatchId',
        title: 'Data Batch',
        type: TableColumnType.ACTIONS,
        align: 'center',
      },
    ];
    return {
      columns,
      rows: this.rows,
    };
  }

  public get rows(): TableRow[] {
    return (
      this.imports &&
      this.imports.map((eachImport: any, it: number) => {
        return {
          id: eachImport.id,
          status: eachImport.status,
          dataBatchId: eachImport.dataBatchId,
          createdAt: eachImport.createdAt,
        };
      })
    );
  }

  public fetchImports(data: any = {}) {
    this.$store.dispatch(FETCH_IMPORTS, data);
  }
}
</script>
