<template>
  <div class="report-template">
    <v-card class="report-page-card top-right-color-triangle" outlined flat>
      <div class="top-triangle">
        <div class="top-right-color-triangle"></div>
        <div class="top-right-grey-triangle"></div>
      </div>
      <div class="card-data">
        <div class="main-page-feature">
          <!-- <v-img src="../../../../assets/crystal-logo.jpg"></v-img> -->
          <div class="feature-title">{{ data.title }}</div>
          <div class="feature-sub-title">{{ data.subTitle }}</div>
        </div>
        <div class="main-page-content">
          <div class="main-page-content-title">Introduction and Context</div>
          <div class="main-page-text">
            This report has been prepared for {{ data.title }}. The data received from {{ data.title }} has assisted AgeWage
            to calibrate returns against a benchmark that can be used to analyse all individual DC pension pots and
            satisfy the Work and Pensions Select Committee's recent call for a common definition of value for money.
            AgeWage intends to create a universe of scheme scores for employer sponsored and multiemployer sponsored
            workplace schemes. The output of this report covers both the AgeWage score (a common measure of value for
            money) and a data quality score. By agreeing to be part of the AgeWage benchmarking program, you will get a
            score for your scheme and a data quality score. These scores will appear on charts which explain how your
            VFM and data quality compare with your peers.
          </div>
        </div>
      </div>
      <div class="footer">
        <div></div>
        <!-- <div class="page-box">
          <div class="pageno">Page 1/4</div>
        </div> -->
        <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
      </div>
    </v-card>
  </div>
</template>
<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';

export default class ReportPageCover extends Vue {
  created() {
    this.bootstrap();
  }

  @Prop()
  public data?: any;

  public barColor: any = {
    // color: '#fe7598',
    // color: '#fea1be',

    primary: '#a085bc',
    secondary: '#cbafd5'
  };

  get moduleIsReady() {
    return true;
  }

  bootstrap() {
    //
  }
}
</script>
