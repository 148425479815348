<template>
  <div data-testid="addnewpassword" class="page login-page">
    <v-layout class="login-layout">
      <v-row class="d-flex justify-center align-center">
        <v-col :xs="8" :sm="7" :md="5" :lg="4" :xl="3">
          <div class="login-logo mb-10">
            <v-img src="@/assets/AgeWage-Icon.svg" class="logo-icon"></v-img>
            <v-img src="@/assets/AgeWage.svg" class="logo-text"></v-img>
          </div>

          <div>
            <v-alert
              data-testid="invalid-alert"
              border="end"
              elevation="2"
              border-color="error"
              class="login-alert"
              text="Error changing password. Please resend the email to yourself as your email is probably expired."
              v-if="changePasswordError"
            ></v-alert>

            <div class="header-text ma-3">
              <h1 class="mb-4">Reset Password {{ email ? `for ${email}` : '' }}</h1>
              <div class="sub-text mb-12">Enter your new password.</div>
            </div>
            <v-form v-model="valid" @submit.prevent="checkCredentials">
              <v-text-field
                data-testid="password-field"
                id="password"
                v-model="password"
                :disabled="isLoading"
                :rules="[getErrors('password')]"
                class="input-wrapper-password text-left my-2"
                prepend-inner-icon="fas fa-lock"
                :height="55"
                required
                variant="solo"
                name="password"
                placeholder="New password"
                type="password"
              ></v-text-field>

              <v-text-field
                data-testid="repeatpassword-field"
                id="repeatpassword"
                v-model="repeatpassword"
                :rules="[getErrors('repeatpassword')]"
                :disabled="isLoading"
                class="input-wrapper-password text-left my-2"
                prepend-inner-icon="fas fa-lock"
                :height="55"
                required
                variant="solo"
                name="repeatpassword"
                placeholder="Retype new password"
                type="password"
              ></v-text-field>
              <v-btn data-testid="login-btn" :loading="isLoading" color="warning" type="submit" :height="55" block
                ><div class="login-btn-text">Change password</div></v-btn
              >
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Vue, Watch } from 'vue-property-decorator';
import * as AT from '@/modules/auth/store/actionTypes';
import { SUCCESSFUL_PASSWORD_CHANGE_STRING } from '@/modules/auth/store/constants';

export default class AddNewPassword extends Vue {
  mounted() {
    if (!this.$route.query.jwt) {
      this.isLinkValid = false;
      return;
    }
    const jwtString: string = String(this.$route.query.jwt);
    const jwtParts = jwtString.split('.');
    const objString = Buffer.from(jwtParts[1], 'base64').toString();
    const userData = JSON.parse(objString);
    this.email = userData.email;
    this.jwt = jwtString;
  }

  public isLinkValid: boolean = true;
  public password: string = '';
  public repeatpassword: string = '';
  public email: string = '';
  public jwt: string = '';
  public addingNewPassword: boolean = false;

  public valid = false;

  public get changePasswordError(): boolean | string {
    return this.$store.getters.changePasswordError;
  }
  public get isLoading() {
    return this.$store.getters.isChangingPassword;
  }

  @Watch('changePasswordError', { immediate: true })
  onchangePasswordError(changePasswordError: string | boolean | string) {
    if (changePasswordError === SUCCESSFUL_PASSWORD_CHANGE_STRING) {
      this.redirect();
    }
  }

  public redirect() {
    // alert('Password changed successfully');
    this.$router.push({ name: 'auth.login' });
  }

  public getErrors(value: string) {
    let errMsg;
    if (value == 'password') {
      errMsg = !this.password && 'The Password field is required.';
    }

    if (value == 'repeatpassword') {
      errMsg = !this.repeatpassword && 'The RepeatPassword field is required.';
    }

    return errMsg || true;
  }

  public checkCredentials() {
    if (this.valid) {
      this.resetPassword();
    }
  }

  public resetPassword() {
    const postData = {
      newpass: this.password,
      jwt: this.jwt,
    };
    this.$store.dispatch(AT.RESET_CONFIRM_JWT, postData);
  }
}
</script>
<style lang="scss">
.login-page {
  display: flex;
  justify-content: center !important;
  flex-direction: column;
  text-align: center;
  align-content: center;
  margin: auto 0px;
  margin-top: 0px;
  background: #300078;
  background-image: url('../../assets/bg@3x.png');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */

  .login-layout {
    justify-content: center;
  }
  .header-text {
    color: #fff;
    text-align: center;

    .sub-text {
      width: 60%;
      margin: 0 auto;
      font-size: 1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .login-logo {
    max-width: 150px;
    min-height: 100px;
    margin: 0 auto;
    .logo-icon {
      max-width: 70px;
      margin: 0.5rem auto;
    }
    .logo-text {
      max-width: 300px;
      margin: 0 auto;
    }
  }
  .login-btn-text {
    color: #0e0c23 !important;
    font-weight: 800;
    line-height: 1.5;
  }

  .login-alert {
    font-size: 16px;
    --v-border-opacity: 1 !important;
  }

  .triangle-1 {
    position: absolute;
    right: -12rem;
    top: -15rem;
    z-index: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 300px 453px 400px;
    border-color: transparent transparent rgb(61, 50, 122) transparent;
    background: rgb(61, 50, 122);
    background: linear-gradient(90deg, rgba(61, 50, 122, 0) 0%, rgba(61, 50, 122, 1) 50%);
  }
}
</style>
