<template>
  <div id="UserModule" data-testid="UserModule">
    <router-view v-if="moduleIsReady" />
    <div v-else>Loading Users...</div>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { FETCH_USERS } from '@/modules/users/store/actionTypes';

export default class UsersModule extends Vue {
  created() {
    this.bootstrap();
  }

  get users() {
    return this.$store.getters.users;
  }

  get moduleIsReady() {
    return this.users;
  }

  bootstrap() {
    this.$store.dispatch(FETCH_USERS);
  }
}
</script>
