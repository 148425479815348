<template>
  <div data-testid="resetpassword" class="page login-page">
    <v-layout class="login-layout">
      <v-row class="d-flex justify-center align-center">
        <v-col :xs="8" :sm="7" :md="5" :lg="4" :xl="3">
          <div class="login-logo mb-10">
            <v-img src="@/assets/AgeWage-Icon.svg" class="logo-icon"></v-img>
            <v-img src="@/assets/AgeWage.svg" class="logo-text"></v-img>
          </div>

          <div>
            <v-alert
              data-testid="invalid-alert"
              border="end"
              elevation="2"
              border-color="success"
              class="login-alert"
              v-if="resetEmailSent"
              text="Reset email is sent."
            ></v-alert>
            <v-alert
              data-testid="invalid-alert"
              border="end"
              elevation="2"
              border-color="error"
              class="login-alert"
              v-if="resetEmailError"
              text="Error sending reset email. Please check email and try again"
            ></v-alert>

            <div class="header-text ma-3">
              <h1 class="mb-4">Reset Password</h1>
              <div class="sub-text mb-12">Enter your email to receive the reset password email.</div>
            </div>

            <v-form v-model="valid" @submit.prevent="checkCredentials">
              <v-text-field
                data-testid="email-field"
                id="email"
                v-model="email"
                :disabled="isLoading"
                :rules="[getErrors('email'), rules.email]"
                class="input-wrapper-email my-2 text-left"
                required
                prepend-inner-icon="far fa-envelope"
                :height="55"
                name="email"
                variant="solo"
                placeholder="ex. johndoe@email.com"
                type="email"
              ></v-text-field>
              <v-btn data-testid="login-btn" :loading="isLoading" color="warning" type="submit" :height="55" block
                ><div class="login-btn-text">Reset password</div></v-btn
              >
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Vue, Watch } from 'vue-property-decorator';
import { AuthService } from '@/modules/auth/services';
import isEmail from 'validator/lib/isEmail';
import User from '@/models/User';

export default class ResetPassword extends Vue {
  public email: string = '';
  public resetEmailSent: boolean = false;
  public resetEmailError: boolean = false;

  public rules: any = {
    required: (value: any) => !!value || 'Required.',
    email: (v: string) => {
      return isEmail(v) ? true : 'The Email field must be a valid email.';
    },
  };

  public valid = false;

  public get currentUser() {
    return this.$store.getters.currentUser;
  }
  public get loggingInError(): boolean | string {
    return this.$store.getters.loggingInError;
  }
  public get isLoading() {
    return this.$store.getters.isLoggingIn;
  }

  @Watch('currentUser', { immediate: true })
  onCurrentUserFetched(user: User | boolean) {
    if (user) {
      this.redirect();
    }
  }
  public redirect() {
    if (this.currentUser.role === 'bm_user') {
      this.$router.push({ name: 'benchmarking.reports.gen' });
    } else {
      this.$router.push({ name: 'dashboard' });
    }
  }
  public getErrors(value: string) {
    let errMsg;
    if (value == 'email') {
      errMsg = !this.email && 'The Email field is required.';
    }
    return errMsg || true;
  }

  public checkCredentials() {
    if (this.valid) {
      this.resetPassword();
    }
  }

  public async resetPassword() {
    const postData = {
      email: this.email,
    };
    let resp = await AuthService.sendResetPasswordEmail(postData);
    if (resp) {
      this.resetEmailSent = true;
    } else {
      this.resetEmailError = true;
    }
  }
}
</script>
<style lang="scss">
.login-page {
  display: flex;
  justify-content: center !important;
  flex-direction: column;
  text-align: center;
  align-content: center;
  margin: auto 0px;
  margin-top: 0px;
  background: #300078;
  background-image: url('../../assets/bg@3x.png');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */

  .login-layout {
    justify-content: center;
  }
  .header-text {
    color: #fff;
    text-align: center;

    .sub-text {
      width: 60%;
      margin: 0 auto;
      font-size: 1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .login-logo {
    max-width: 150px;
    min-height: 100px;
    margin: 0 auto;
    .logo-icon {
      max-width: 70px;
      margin: 0.5rem auto;
    }
    .logo-text {
      max-width: 300px;
      margin: 0 auto;
    }
  }
  .login-btn-text {
    color: #0e0c23 !important;
    font-weight: 800;
    line-height: 1.5;
  }

  .login-alert {
    font-size: 16px;
    --v-border-opacity: 1 !important;
  }

  .triangle-1 {
    position: absolute;
    right: -12rem;
    top: -15rem;
    z-index: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 300px 453px 400px;
    border-color: transparent transparent rgb(61, 50, 122) transparent;
    background: rgb(61, 50, 122);
    background: linear-gradient(90deg, rgba(61, 50, 122, 0) 0%, rgba(61, 50, 122, 1) 50%);
  }
}
</style>
