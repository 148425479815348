// Log In
export const LOGIN_USER = 'auth/LOGIN_USER';
export const LOG_USER_OUT = 'auth/LOG_USER_OUT';
export const LOGIN_USER_ERROR = 'auth/LOGIN_USER_ERROR';
export const LOGIN_USER_SUCCESS = 'auth/LOGIN_USER_SUCCESS';

// Current User
export const FETCH_CURRENT_USER = 'auth/FETCH_CURRENT_USER';
export const FETCH_CURRENT_USER_ERROR = 'auth/FETCH_CURRENT_USER_ERROR';
export const FETCH_CURRENT_USER_SUCCESS = 'auth/FETCH_CURRENT_USER_SUCCESS';
export const SET_CURRENT_USER = 'auth/SET_CURRENT_USER';

// Forgot Password
export const RESET_CONFIRM_JWT = 'auth/RESET_CONFIRM_JWT';

// Register User
export const GET_REGISTER_USER_TOKEN = 'auth/GET_REGISTER_USER_TOKEN';
export const REGISTER_USER = 'auth/REGISTER_USER';

// Session Timeout
export const SESSION_TIME_OUT = 'auth/SESSION_TIME_OUT';
