import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createSlots as _createSlots, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "app-table__loader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-table", { 'app-table--striped': _ctx.striped }])
  }, [
    _createVNode(_component_v_data_table_server, {
      items: _ctx.tableItems,
      headers: _ctx.tableData.columns,
      "hide-default-footer": _ctx.hideActions,
      modelValue: _ctx.selectAll,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectAll) = $event)),
      "show-select": false,
      "sort-by": _ctx.defaultSortColumn,
      "disable-sort": _ctx.disableSort || _ctx.loading,
      "disable-filtering": _ctx.loading,
      "custom-sort": _ctx.tableSort,
      itemsLength: _ctx.pagination.serverItemsLength || '',
      "items-per-page-options": [
        { value: 15, title: '15' },
        { value: 25, title: '25' },
        { value: 50, title: '50' },
        { value: 100, title: '100' },
      ],
      "items-per-page-text": "Rows per Page:",
      "items-per-page": _ctx.pagination.itemsPerPage,
      class: "app-table__data-table",
      "onUpdate:page": _cache[1] || (_cache[1] = (e) => _ctx.$emit('page', e)),
      onPagination: _cache[2] || (_cache[2] = (e) => _ctx.$emit('pagination', e)),
      "onUpdate:options": _cache[3] || (_cache[3] = (e) => _ctx.$emit('update:options', e)),
      "onUpdate:itemsPerPage": _cache[4] || (_cache[4] = (e) => _ctx.$emit('itemsPerPage', e)),
      "onUpdate:sortBy": _cache[5] || (_cache[5] = (e) => _ctx.handleSortBy(e)),
      "onUpdate:sortDesc": _cache[6] || (_cache[6] = (e) => _ctx.handleSortDesc(e))
    }, _createSlots({
      item: _withCtx(({ item, columns }) => [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columns, (column) => {
            return (_openBlock(), _createElementBlock("td", {
              key: column.key,
              class: _normalizeClass(_ctx.generateClasses(column, item)),
              title: _ctx.getTooltipValue(column, item) || _ctx.getCellDisplayValue(column, item)
            }, [
              (column.value !== 'data-table-select' && column.value !== 'view' && column.value !== 'remove')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.getCellDisplayValue(column, item)), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.value === 'view')
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 1,
                    class: "mx-2",
                    color: "success",
                    size: "small",
                    variant: "text",
                    onClick: ($event: any) => (_ctx.onViewClick(item.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" View ")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true),
              (column.value === 'remove')
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 2,
                    class: "mx-2",
                    color: "error",
                    size: "small",
                    variant: "text",
                    onClick: ($event: any) => (_ctx.onRemoveClick(item.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Remove ")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ], 10, _hoisted_1))
          }), 128))
        ])
      ]),
      _: 2
    }, [
      (_ctx.disablePagination)
        ? {
            name: "bottom",
            fn: _withCtx(() => []),
            key: "0"
          }
        : undefined
    ]), 1032, ["items", "headers", "hide-default-footer", "modelValue", "sort-by", "disable-sort", "disable-filtering", "custom-sort", "itemsLength", "items-per-page"]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_progress_circular, {
        indeterminate: "",
        color: "secondary",
        size: "64"
      })
    ], 512), [
      [_vShow, _ctx.loading]
    ])
  ], 2))
}