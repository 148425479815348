import SimulationsModule from './SimulationsModule.vue';
import SimulationsPage from './pages/SimulationsPage.vue';
import AddSimulation from './pages/AddSimulation.vue';

export const SimulationRoutes = [
  {
    path: '/simulations/list',
    component: SimulationsModule,
    children: [
      {
        path: '',
        name: 'dashboard.simulations.list',
        component: SimulationsPage
      },
    ]
  },
  {
    path: '/simulations/add',
    component: SimulationsModule,
    children: [
      {
        path: '',
        name: 'dashboard.simulations.add',
        component: AddSimulation
      },
    ]
  }
];
