<template>
  <div>
    <h2 class="section-title">
      <span>KEY STATS</span>
      <span v-if="selectionCount" class="pl-1 grey--text text--darken-1"
        >({{ selectionCount }} out of {{ totalCount }} selected)</span
      >
    </h2>
    <v-layout class="key-stat my-4">
      <v-row align="center" justify="space-between">
        <v-col cols="12" :sm="6" :md="6" :lg="3">
          <v-row>
            <v-col cols="3" class="pa-3" style="min-width: 100%">
              <v-card class="d-flex stat-card stat-card-1 pa-4" :class="{ 'stat-card--active': statsAreActive }">
                <v-row align-content="center" justify="center" justify-lg="space-between">
                  <v-col
                    cols="12"
                    :sm="5"
                    :md="5"
                    :lg="5"
                    align-self="center"
                    class="text-center pa-0 d-none d-md-block"
                  >
                    <div class="key-stat-illustration">
                      <v-img class="key-stat-illustration__image" src="../assets/icons/score-icon.svg" />
                    </div>
                  </v-col>
                  <v-col cols="12" :sm="7" :md="7" :lg="7" align-self="center" class="text-center pa-0">
                    <div class="card-text">
                      <h1 class="stat-title">
                        <v-counter
                          :startVal="oldData ? oldData.stat1_title : 0"
                          :endVal="data.stat1_title"
                          :formatter="(n) => (type == 'dataQuality' ? formatNumber(n, '0.0') : formatNumber(n, '0'))"
                          :duration="750"
                        ></v-counter>
                        <div v-if="type == 'dataQuality'" class="counter-symbol">%</div>
                      </h1>
                      <div class="stat-desc">
                        {{ data.stat1_text }}
                        <v-tooltip v-if="tooltipVisibility" location="bottom" content-class="tooltip-text">
                          <template #activator="{ props }">
                            <v-icon color="#fff" small v-bind="props">fa fa-info-circle</v-icon>
                          </template>
                          <span>Value achieved against the benchmark</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" :sm="6" :md="6" :lg="3">
          <v-row>
            <v-col cols="3" class="pa-3" style="min-width: 100%">
              <v-card class="d-flex stat-card stat-card-2 pa-4" :class="{ 'stat-card--active': statsAreActive }">
                <v-row align-content="center" justify="center" justify-lg="space-between">
                  <v-col
                    cols="12"
                    :sm="5"
                    :md="5"
                    :lg="5"
                    align-self="center"
                    class="text-center pa-0 d-none d-md-block"
                  >
                    <div class="key-stat-illustration">
                      <v-img class="key-stat-illustration__image" src="../assets/icons/irr-icon.svg" />
                    </div>
                  </v-col>
                  <v-col cols="12" :sm="7" :md="7" :lg="7" align-self="center" class="text-center pa-0">
                    <div class="card-text">
                      <h1 class="stat-title">
                        <v-counter
                          :startVal="oldData ? oldData.stat2_title : 0"
                          :endVal="data.stat2_title"
                          :formatter="(n) => formatNumber(n * 100, '0.00')"
                          :duration="750"
                        ></v-counter>
                        <div class="counter-symbol">%</div>
                      </h1>
                      <div class="stat-desc">
                        {{ data.stat2_text }}
                        <v-tooltip v-if="tooltipVisibility" location="bottom" content-class="tooltip-text">
                          <template #activator="{ props }">
                            <v-icon color="#fff" small v-bind="props">fa fa-info-circle</v-icon>
                          </template>
                          <span>Average annual return over the period of investment</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" :sm="6" :md="6" :lg="3">
          <v-row>
            <v-col cols="3" class="pa-3" style="min-width: 100%">
              <v-card class="d-flex stat-card stat-card-3 pa-4" :class="{ 'stat-card--active': statsAreActive }">
                <v-row align-content="center" justify="center" justify-lg="space-between">
                  <v-col
                    cols="12"
                    :sm="5"
                    :md="5"
                    :lg="5"
                    align-self="center"
                    class="text-center pa-0 d-none d-md-block"
                  >
                    <div class="key-stat-illustration">
                      <v-img class="key-stat-illustration__image" src="../assets/icons/benchmark-irr-icon.svg" />
                    </div>
                  </v-col>
                  <v-col cols="12" :sm="7" :md="7" :lg="7" align-self="center" class="text-center pa-0">
                    <div class="card-text">
                      <h1 v-if="type === 'vfm'" class="stat-title">
                        <div class="counter-symbol">{{ valueSign || '' }}</div>
                        <div class="counter-symbol">£</div>
                        <v-counter
                          :startVal="oldData ? oldData.stat3_title : 0"
                          :endVal="data.stat3_title"
                          :formatter="(n) => formatNumber(n, '0.00a')"
                          :duration="750"
                        ></v-counter>
                      </h1>
                      <h1 v-else class="stat-title">
                        <v-counter
                          :startVal="oldData ? oldData.stat3_title : 0"
                          :endVal="data.stat3_title"
                          :formatter="(n) => formatNumber(n * 100, '0.00')"
                          :duration="750"
                        ></v-counter>
                        <div class="counter-symbol">%</div>
                      </h1>
                      <div class="stat-desc">
                        {{ data.stat3_text }}
                        <v-tooltip v-if="tooltipVisibility" location="bottom" content-class="tooltip-text">
                          <template #activator="{ props }">
                            <v-icon color="#fff" small v-bind="props">fa fa-info-circle</v-icon>
                          </template>
                          <span>Average annual return with same contributions invested in benchmark index</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" :sm="6" :md="6" :lg="3">
          <v-row>
            <v-col cols="3" class="pa-3" style="min-width: 100%">
              <v-card class="d-flex stat-card stat-card-4 pa-4" :class="{ 'stat-card--active': statsAreActive }">
                <v-row align-content="center" justify="center" justify-lg="space-between">
                  <v-col
                    cols="12"
                    :sm="5"
                    :md="5"
                    :lg="5"
                    align-self="center"
                    class="text-center pa-0 d-none d-md-block"
                  >
                    <div class="key-stat-illustration">
                      <v-img class="key-stat-illustration__image" src="../assets/icons/money-analysed-icon.svg" />
                    </div>
                  </v-col>
                  <v-col cols="12" :sm="7" :md="7" :lg="7" align-self="center" class="text-center pa-0">
                    <div class="card-text">
                      <h1 v-if="type === 'dataQuality'" class="stat-title">
                        <v-counter
                          :startVal="oldData ? oldData.stat4_title : 0"
                          :endVal="data.stat4_title"
                          :formatter="(n) => formatNumber(n, '0,00')"
                          :duration="750"
                        ></v-counter>
                      </h1>
                      <h1 v-else class="stat-title">
                        <div class="counter-symbol">£</div>
                        <v-counter
                          :startVal="oldData ? oldData.stat4_title : 0"
                          :endVal="data.stat4_title"
                          :formatter="(n) => formatNumber(n, '0.00a')"
                          :duration="750"
                        ></v-counter>
                      </h1>
                      <div class="stat-desc">
                        {{ data.stat4_text }}
                        <v-tooltip v-if="tooltipVisibility" location="bottom" content-class="tooltip-text">
                          <template #activator="{ props }">
                            <v-icon color="#fff" small v-bind="props">fa fa-info-circle</v-icon>
                          </template>
                          <span>Total value of pots analysed</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Watch } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import VCounter from '@/plugins/v-counter/VCounter.vue';
import Utils from '../utils/Utils';

@Options({
  components: {
    VCounter,
  },
})
export default class KeyStats extends Vue {
  @Prop()
  public data!: any;

  @Prop({
    default: 0,
  })
  public selectionCount!: number;

  @Prop({
    default: 0,
  })
  public totalCount!: number;

  @Prop()
  public type!: string;

  @Prop()
  public valueSign!: string;

  @Prop({
    default: true,
  })
  public tooltipVisibility!: boolean;

  public oldData: any = null;

  public statsAreActive = false;

  @Watch('data')
  onDataChange(newData: any, old: any) {
    this.oldData = old;
    this.statsAreActive = true;
    setTimeout(() => {
      this.statsAreActive = false;
    }, 1000);
  }

  public formatNumber(n: number, format: string) {
    return Utils.formatNumber(n, format);
  }
}
</script>

<style lang="scss">
.stat-card {
  width: 100%;
  min-height: 10rem;
  margin: 0px auto;
  border-radius: 0.57rem !important;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  color: #fff;
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.05), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.06) !important;
  box-shadow: 1px 3px 4px -1px rgba(0, 0, 0, 0.05), 0px 5px 8px 0px rgba(0, 0, 0, 0.07),
    0px 1px 14px 0px rgba(0, 0, 0, 0.06) !important;

  &--active:after {
    animation: shine 1s ease-in-out;
    animation-fill-mode: forwards;
  }

  &:after {
    content: '';
    position: absolute;
    top: -200%;
    left: -300%;
    width: 200%;
    height: 250%;
    opacity: 0;
    transform: rotate(30deg);
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &-1 {
    background-image: linear-gradient(307deg, #fe7598, #fea1be) !important;
  }
  &-2 {
    background-image: linear-gradient(307deg, #ba84ff, #d8aefe) !important;
  }
  &-3 {
    background-image: linear-gradient(307deg, #63cd9e, #8ae4c1) !important;
  }

  &-4 {
    background-image: linear-gradient(127deg, #ffcf5e, #f4b411) !important;
  }

  .key-stat-illustration {
    height: 5rem;
    width: 6rem;
    margin: 0 auto;

    &__image {
      height: 100%;
      width: 100%;
    }
  }

  .stat-title {
    margin: 0 auto;
    font-size: 2.5rem;
    color: #fff;
    display: inline-flex;

    .counter-value,
    .counter-symbol {
      font-size: 1.5rem;
    }
  }
  .stat-desc {
    margin: 0 auto;
    color: #fff;
    justify-content: center;
  }
  .tooltip {
    position: absolute;
    top: 0.5em;
    right: 1em;
  }
  .tooltip-content {
    margin-left: 10px;
  }
}

@keyframes shine {
  10% {
    opacity: 1;
    top: -200%;
    left: -300%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -100%;
    left: -50%;
    transition-property: left, top, opacity;
  }
}
</style>
