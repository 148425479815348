import * as AT from '@/modules/members/store/actionTypes';
import * as MT from '@/modules/members/store/mutationTypes';
import { IRootState } from '@/store/state';
import { IMemberState } from '@/modules/members/store/state';
import { ActionContext, ActionTree } from 'vuex';
import { MemberService } from '@/modules/members/services';

/**
 * --------------------------------------------------
 *  Member Actions
 * --------------------------------------------------
 **/
export const MemberActions: IMemberActions = {
  /**
   * --------------------------------------------------
   *  Fetch All the Members
   * --------------------------------------------------
   **/
  [AT.FETCH_MEMBERS]: ({ commit, dispatch }: any) => {
    dispatch(AT.TOGGLE_FETCHING_MEMBERS);
    return MemberService.getMembers()
      .then(members => {
        commit(MT.SET_MEMBERS, members);
        return members;
      })
      .finally(() => dispatch(AT.TOGGLE_FETCHING_MEMBERS));
  },

  [AT.FETCH_MEMBER_HISTORY]: ({ commit, dispatch }: any, id: number) => {
    return MemberService.getMemberHistory(id).then(history => {
      commit(MT.SET_MEMBER_HISTORY, history);
      return history;
    });
  },

  [AT.FETCH_CONTRIBUTION_HISTORY]: ({ commit, dispatch }: any) => {
    return MemberService.getContributionHistory().then(history => {
      commit(MT.SET_CONTRIBUTION_HISTORY, history);
      return history;
    });
  },

  [AT.FETCH_MEMBERS_ANALYSIS]: ({ commit, dispatch }: any, params: any) => {
    return MemberService.getMemberAnalysisByPage(params).then((analysis: any) => {
      commit(MT.SET_MEMBER_ANALYSIS, analysis);
      return analysis.data;
    });
  },

  [AT.GET_CSV_URL]: ({ commit, dispatch }: any, id: any) => {
    return MemberService.getCSVUrl(id).then((url: string) => url);
  },

  [AT.EXPORT_MEMBERS_ANALYSIS]: ({ commit, dispatch }: any, data: any) => {
    return MemberService.getMemberAnalysisCSVUrl(data);
  },

  [AT.FETCH_SAMPLE_MEMBERS_ANALYSIS]: ({ commit, dispatch }: any, params: any) => {
    return MemberService.getSampleMemberAnalysisByPage(params).then((analysis: any) => {
      commit(MT.SET_MEMBER_ANALYSIS, analysis);
      return analysis.data;
    });
  },

  [AT.EXPORT_OUTLIER_ANALYSIS]: ({ commit, dispatch }: any, providerId: number) => {
    return MemberService.getOutlierAnalysisCSVUrl(providerId);
  },

  [AT.FETCH_OUTLIERS_ANALYSIS]: ({ commit, dispatch }: any, params: any) => {
    return MemberService.getOutlierAnalysis(params).then(outliers => {
      commit(MT.SET_OUTLIER_ANALYSIS, outliers.data);
      return outliers;
    });
  },

  [AT.FETCH_FILTERED_MEMBERS_ANALYSIS]: ({ commit, dispatch }: any, filters: any) => {
    return MemberService.getMemberAnalysisByFilter(filters).then(analysis => {
      commit(MT.SET_MEMBER_ANALYSIS, analysis);
      return analysis;
    });
  },

  [AT.FETCH_MEMBER_FILTER_RANGES]: async ({ commit, dispatch }, params?: any) => {
    const { data: ranges } = await MemberService.getMemberFilterRanges(params);
    commit(MT.SET_MEMBER_FILTER_RANGES, ranges);
    return ranges;
  },

  //TOGGLE
  [AT.TOGGLE_FETCHING_MEMBERS]: ({ commit, state }: any, value: boolean) => {
    if (value != null) {
      if (state.fetchingMembers == true) commit(MT.SET_FETCHING_MEMBERS, false);
      else commit(MT.SET_FETCHING_MEMBERS, false);
    } else commit(MT.SET_FETCHING_MEMBERS, value);
  },

  [AT.FETCH_OUTLIER_REASON_ANALYSIS]: ({ commit, dispatch }: any, params: any) => {
    return MemberService.getMemberAnalysisByPage(params).then((analysis: any) => {
      commit(MT.SET_OUTLIER_REASON_ANALYSIS, analysis);
      return analysis.data;
    });
  },

  [AT.FETCH_SAMPLE_OUTLIER_REASON_ANALYSIS]: ({ commit, dispatch }: any, params: any) => {
    return MemberService.getSampleMemberAnalysisByPage(params).then((analysis: any) => {
      commit(MT.SET_OUTLIER_REASON_ANALYSIS, analysis);
      return analysis.data;
    });
  },

  [AT.EXPORT_OUTLIER_REASON_ANALYSIS]: ({ commit, dispatch }: any, data: any) => {
    return MemberService.getOutlierReasonAnalysisCSVUrl(data);
  },

  [AT.FETCH_SAMPLE_MEMBER_DATA]: ({ commit, dispatch }: any, params: any) => {
    return MemberService.getSampleMemberAnalysis(params).then((analysis: any) => {
      commit(MT.SET_SAMPLE_MEMBER_ANALYSIS, analysis);
      return analysis.data;
    });
  },

};

/**
 * --------------------------------------------------
 *  Member Action Context Interface
 * --------------------------------------------------
 **/
export interface IMemberActionContext extends ActionContext<IMemberState, IRootState> {}

/**
 * --------------------------------------------------
 *  Mmeber Actions Interface
 * --------------------------------------------------
 **/
export interface IMemberActions extends ActionTree<IMemberState, IRootState> {}
