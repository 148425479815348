import * as AT from '@/modules/benchmarking/store/actionTypes';
import * as MT from '@/modules/benchmarking/store/mutationTypes';
import { IRootState } from '@/store/state';
import { IReportState } from '@/modules/benchmarking/store/state';
import { ActionContext, ActionTree } from 'vuex';
import { ReportsService } from '@/modules/benchmarking/services';

/**
 * --------------------------------------------------
 *  Reports Actions
 * --------------------------------------------------
 **/
export const ReportActions: IReportActions = {
  /**
   * --------------------------------------------------
   *  Fetch All the reports
   * --------------------------------------------------
   **/
  [AT.FETCH_REPORT_DATA]: ({ commit, dispatch }: any, data: any) => {
    commit(MT.SET_FETCHING_REPORTS, true);
    commit(MT.SET_REPORTS, {});
    return ReportsService.fetchReports(data.providerId)
      .then((reports: any) => {
        commit(MT.SET_REPORTS, reports);
        return reports;
      })
      .finally(() => commit(MT.SET_FETCHING_REPORTS, false));
  },
  [AT.GENERATE_BENCHMARK_REPORT]: ({ commit, dispatch }: any, options: any) => {
    return ReportsService.exportReport(options).then((jobId: any) => jobId);
  },
  [AT.REQUEST_VFM_REPORT]: ({ commit, dispatch }: any, options: any) => {
    return ReportsService.requestVFMReport().then((data: any) => data);
  }
};

/**
 * --------------------------------------------------
 *  Reports Action Context Interface
 * --------------------------------------------------
 **/
export interface IReportActionContext extends ActionContext<IReportState, IRootState> {}

/**
 * --------------------------------------------------
 *  Reports Actions Interface
 * --------------------------------------------------
 **/
export interface IReportActions extends ActionTree<IReportState, IRootState> {}
