<template>
  <div>
    <!-- No data snackbar -->
    <v-snackbar v-model="noDataAlert" :timeout="3000" color="primary" location="top">
      <h3>No data available for this range</h3>
      <v-btn color="blue" variant="text" class="d-flex ml-auto" @click="noDataAlert = false"> Close </v-btn>
    </v-snackbar>
    <v-snackbar v-model="pdfTimeoutError" :timeout="3000" color="primary" location="top">
      <h3>Error downloading requested pdf. Please try again.</h3>
      <v-btn color="blue" variant="text" class="d-flex ml-auto" @click="pdfTimeoutError = false"> Close </v-btn>
    </v-snackbar>
    <!-- Chart -->
    <div class="potSizeWrapper">
      <div class="elevation-1 chartWrapper potSizeCard">
        <div class="chart-title d-block text-center">Effect of Pot Size</div>
        <base-chart :options="chartOptions" :scroll-options="chartSize" @click="(e) => chartClick(e)" />
      </div>
    </div>
    <!-- Members Modal -->
    <v-dialog
      v-model="membersDialog"
      @click:outside="membersDialog = false"
      persistent
      max-width="80vw"
      max-height="90%"
    >
      <v-card class="py-4">
        <v-card-title class="d-flex align-center justify-space-between">
          <div>
            <div>Member Analysis for the selected range:</div>
          </div>
          <div>
            <v-btn
              left
              color="primary"
              class="mr-4"
              :disabled="!provider || isSampleReport"
              :loading="processingCSV"
              @click="exportMemberCSV()"
            >
              <v-icon small class="mr-3">fas fas fa-file-download</v-icon>Download
            </v-btn>

            <v-btn rounded size="small" density="comfortable" variant="flat" @click="membersDialog = false">
              <v-icon small>fa fa-times</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <AppTable
            :table-data="membersTableData"
            :disableSort="true"
            :disablePagination="true"
            :default-sort-column="[{ key: 'dataId', order: 'asc' }]"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey-darken-1" variant="text" @click="membersDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AppLoader v-if="fetchingMembersAnalysis" />
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import BaseChart from '@/components/charts/BaseChart.vue';
import data from '@/packages/stubs/echarts/rangeArray';
import _ from 'lodash';
import Utils from '../../utils/Utils';
import * as AT from '@/modules/members/store/actionTypes';
import * as PTAT from '@/modules/price-track/store/actionTypes';
import { GET_EXPORT_JOB_BY_ID } from '@/modules/reports/store/actionTypes';
import { GET_CSV_URL } from '@/modules/members/store/actionTypes';
import { Table, TableColumn, TableRow, TableColumnType } from '@/types';
import AppTable from '@/components/AppTable.vue';
import AppLoader from '@/components/AppLoder.vue';
import { PriceTrackObject } from '@/models/PriceTrack';

@Options({
  components: {
    BaseChart,
    AppTable,
    AppLoader,
  },
})
export default class PotSizeChart extends Vue {
  @Prop({
    default: () => [],
  })
  chartData!: number[][];

  @Prop({
    default: () => ({ primary: '#fe7598', secondary: '#fea1be' }),
  })
  barColor!: any;

  @Prop({
    default: () => null,
  })
  provider!: number;

  @Prop({
    default: () => null,
  })
  priceTrack!: PriceTrackObject;

  @Prop({
    default: () => {},
  })
  reportfilterRanges!: any;

  @Prop() isSampleReport!: boolean;

  public chartSize: any = {
    chartWidth: 50,
    chartHeight: 30,
    unit: 'rem',
  };

  public membersDialog = false;

  public fetchingMembersAnalysis = false;

  public noDataAlert = false;

  public processingCSV = false;

  public selectedRange = [];

  public currentFilter: any = {};

  public pdfTimeoutError = false;

  public pagination = {
    page: 1,
    limit: 15,
  };

  private async fetchMembersAnalysis(data: any = {}) {
    this.fetchingMembersAnalysis = true;
    let analysis = null;
    if (this.isSampleReport) {
      analysis = await this.$store.dispatch(AT.FETCH_SAMPLE_MEMBERS_ANALYSIS, data);
    } else if (this.provider) {
      analysis = await this.$store.dispatch(AT.FETCH_MEMBERS_ANALYSIS, data);
    } else if (this.priceTrack) {
      analysis = await this.$store.dispatch(PTAT.FETCH_MEMBERS_ANALYSIS_PRICE_TRACK, data);
    }
    this.fetchingMembersAnalysis = false;
    return analysis;
  }

  public async chartClick(e: any) {
    if (e.dataIndex !== undefined) {
      this.noDataAlert = false;
      const index = await e.dataIndex;
      const range = this.mappedData.newKeys[index].map((it) => it);
      if (range[1] === 0) {
        range.splice(1, 1);
      }
      const convertedFilters = Utils.convertMinMaxToArrayFilters(this.reportfilterRanges);
      if (convertedFilters.potRange) {
        if (convertedFilters.potRange[0] > range[0]) {
          range[0] = convertedFilters.potRange[0];
        }

        if (convertedFilters.potRange[1] < range[1]) {
          range[1] = convertedFilters.potRange[1];
        }
      }
      const appliedFilters: any = {
        ...convertedFilters,
        potRange: range,
      };
      if (this.provider) {
        appliedFilters.providers = [this.provider];
      } else if (this.priceTrack) {
        appliedFilters.dataBatchIds = [this.priceTrack.dataBatchId];
      }
      this.currentFilter = {
        ...this.pagination,
        ...appliedFilters,
      };
      const analysis = await this.fetchMembersAnalysis(this.currentFilter);
      analysis.length ? (this.membersDialog = true) : (this.noDataAlert = true);
    }
  }

  public get memberAnalysis(): any[] | null {
    return this.$store.getters.memberAnalysis && this.$store.getters.memberAnalysis.data;
  }

  public get membersTableData(): Table {
    const data = this.memberAnalysis ? this.memberAnalysis : [];
    const rows = data.map((it) => {
      return {
        dataId: it.dataId,
        firstContributionDate: it.firstContributionDate,
        // fundName: it.fund.name,
        score: it.scores.score || it.scores.score === 0 ? parseInt(it.scores.score) : '-',
        totalContributionAmount: it.totalContributionAmount,
        navAmount: it.analysis.navAmount,
        navDate: it.analysis.navDate,
        irr: it.analysis.irr,
        benchmarkIrr: it.analysis.benchmarkIrr,
        benchmarkNavAmount: it.analysis.benchmarkNavAmount,
      };
    });
    return {
      columns: [
        {
          value: 'dataId',
          title: 'Saver ID',
          type: TableColumnType.NUMBER,
          align: 'center',
        },
        // {
        //   value: 'fundName',
        //   text: 'Fund Name',
        //   type: TableColumnType.STRING
        // },
        {
          value: 'firstContributionDate',
          title: 'First Contribution Date',
          type: TableColumnType.DATE,
          formatter: (v: any) => Utils.formatDate(new Date(v), 'MMM dd, yyyy'),
          align: 'center',
        },
        {
          value: 'navDate',
          title: 'NAV Date',
          type: TableColumnType.DATE,
          formatter: (v: any) => Utils.formatDate(new Date(v), 'MMM dd, yyyy'),
          align: 'center',
        },
        {
          value: 'score',
          title: 'AW Score',
          type: TableColumnType.NUMBER,
          align: 'center',
        },
        {
          value: 'totalContributionAmount',
          title: 'Total Contribution Amount',
          type: TableColumnType.NUMBER,
          formatter: (n) => Utils.formatNumber(n, '$0.00a'),
          align: 'center',
        },
        {
          value: 'navAmount',
          title: 'NAV Amount',
          type: TableColumnType.NUMBER,
          formatter: (n) => Utils.formatNumber(n, '$0.00a'),
          align: 'center',
        },
        {
          value: 'benchmarkNavAmount',
          title: 'Benchmark Nav Amount',
          type: TableColumnType.NUMBER,
          formatter: (n) => Utils.formatNumber(n, '$0.00a'),
          align: 'center',
        },
        {
          value: 'irr',
          title: 'IRR (%)',
          type: TableColumnType.NUMBER,
          formatter: (v: any) => (v * 100).toFixed(2),
          align: 'center',
        },
        {
          value: 'benchmarkIrr',
          title: 'Benchmark IRR (%)',
          type: TableColumnType.NUMBER,
          formatter: (v: any) => (v * 100).toFixed(2),
          align: 'center',
        },
      ],
      rows,
    };
  }

  public async exportMemberCSV() {
    //PDF Export Polling with 2sec interval and 16sec Timout
    let job = { id: -1 };

    this.processingCSV = true;
    job = await this.$store.dispatch(AT.EXPORT_MEMBERS_ANALYSIS, {
      page: 1,
      limit: -1,
      providers: this.currentFilter.providers,
      potRange: this.currentFilter.potRange,
      irrRange: this.currentFilter.irrRange,
      birrRange: this.currentFilter.birrRange,
      scoreRange: this.currentFilter.scoreRange,
    });

    let pdfFetchInterval = setInterval(async () => {
      let response = await this.$store.dispatch(GET_EXPORT_JOB_BY_ID, job.id);
      if (response[0].status === 'completed') {
        const url = await this.$store.dispatch(GET_CSV_URL, job.id);
        await window.open(url);
        this.processingCSV = false;
        this.processingCSV = false;
        clearInterval(pdfFetchInterval);
      }
      return response;
    }, Utils.pdfInterval);
    setTimeout(() => {
      clearInterval(pdfFetchInterval);
      if (this.processingCSV) {
        this.pdfTimeoutError = true;
      }
      this.processingCSV = false;
    }, Utils.pdfTimeout);
  }

  public get mappedData() {
    const newValues: number[] = [];
    const newMembers: number[] = [];
    const newKeys: number[][] = [];
    this.chartData.forEach((item: any = {}) => {
      newMembers.push(item.memberCount);
      newValues.push(item.avg_score);
      newKeys.push(item.range);
    });
    const length = newValues.length;
    let isDataAvailable = false;
    const tempArray = [...newValues];
    tempArray.reverse().forEach((item, index) => {
      if (!item && !isDataAvailable) {
        const keyToRemove = length - index - 1;
        newValues.splice(keyToRemove, 1);
        newKeys.splice(keyToRemove, 1);
        if (keyToRemove - 1 > 0) {
          newKeys[keyToRemove - 1][1] = 0;
        }
      } else {
        isDataAvailable = true;
      }
    });
    return { newKeys, newValues, newMembers };
  }

  public get xAxisData() {
    return this.mappedData.newKeys;
  }

  public get seriesData() {
    return this.mappedData.newValues;
  }

  public get membersData() {
    return this.mappedData.newMembers;
  }

  public numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  public get chartOptions() {
    return {
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 1,
            color: '#fe7598', // color at 0% position
          },
          {
            offset: 0,
            color: '#fea1be', // color at 100% position
          },
        ],
      },
      tooltip: {
        show: true,
        formatter: (v: any) => `AgeWage Score: ${v.value.toFixed(0)}`,
      },
      xAxis: [
        {
          name: 'Pot Size',
          nameLocation: 'middle',
          nameTextStyle: {
            color: '#6f6c76',
            fontFamily: 'Open Sans',
            fontWeight: 'bolder',
            align: 'center',
            padding: [35, 0, 0, 0],
          },
          type: 'category',
          axisTick: { show: true, alignWithLabel: true },
          axisLine: { show: true, lineStyle: { color: '#ccc' } },
          data: this.xAxisData,
          axisLabel: {
            rotate: 18,
            fontWeight: '600',
            fontFamily: 'Open Sans',
            color: '#6f6c76',
            formatter: (value: any) => {
              if (value.split(',')[0] === '0') {
                const i = value.split(',')[1];
                return `< £${Utils.formatNumber(i, '0a')}`;
              } else if (value.split(',')[1] === '0') {
                const i = value.split(',')[0];
                return `> £${Utils.formatNumber(i, '0a')}`;
              }
              return value
                .split(',')
                .map((i: number) => `£${Utils.formatNumber(i, '0a')}`)
                .join(' - ');
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: 'AgeWage Score',
          nameLocation: 'middle',
          nameRotate: 90,
          color: '#6f6c76',
          nameTextStyle: { color: '#6f6c76', padding: [0, 0, 40, 0], fontFamily: 'Open Sans', fontWeight: 'bolder' },
          axisLine: { show: true, lineStyle: { color: '#ccc' } },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#eee',
            },
          },
          axisLabel: {
            fontWeight: '600',
            fontFamily: 'Open Sans',
            color: '#6f6c76',
            interval: false,
          },
        },
      ],
      series: [
        {
          type: 'bar',
          data: this.seriesData,
          label: {
            show: true,
            position: 'top',
            color: '#fe7598',
            rich: { name: { fontFamily: 'Open Sans', color: '#6f6c76' } },
            textBorderColor: '#fff',
            formatter: (params: any) => `${this.numberWithCommas(this.membersData[params.dataIndex])}`,
          },
        },
      ],
      barWidth: 50,
      barGap: '30%',
    };
  }
}
</script>
<style lang="scss">
.potSizeWrapper {
  padding: 1rem 0rem !important;
  .potSizeCard {
    padding-bottom: 3rem !important;
  }
}
</style>
