import { B2CActions } from '@/modules/b2c-panel/store/actions';
import { B2CGetters } from '@/modules/b2c-panel/store/getters';
import { B2CMutations } from '@/modules/b2c-panel/store/mutations';
import { B2CState, IB2CState } from '@/modules/b2c-panel/store/state';
import { IRootState } from '@/store/state';
import { Module } from 'vuex';

export const B2CStore: IB2CStore = {
  state: B2CState,
  actions: B2CActions,
  getters: B2CGetters,
  mutations: B2CMutations
};

/**
 * --------------------------------------------------
 *	ProviderB2C Store Interface
 * --------------------------------------------------
 **/
export interface IB2CStore extends Module<IB2CState, IRootState> {}
