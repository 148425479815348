export const SET_PROVIDERS = 'provider/SET_PROVIDERS';
export const SET_PROVIDER = 'provider/SET_PROVIDER';
export const SET_ALL_PROVIDERS = 'provider/SET_ALL_PROVIDERS';
export const SET_FETCHING_PROVIDERS = 'provider/SET_FETCHING_PROVIDERS';
export const SET_FETCHING_PROVIDER = 'provider/SET_FETCHING_PROVIDER';
export const SET_PROVIDERS_PA = 'provider/SET_PROVIDERS_PA';
export const SET_PROVIDER_DATA_QUALITY = 'provider/SET_PROVIDER_DATA_QUALITY';
export const SET_FETCHING_DATA_QUALITY = 'provider/SET_FETCHING_DATA_QUALITY';
export const SET_MASTER_PROVIDERS = 'provider/SET_MASTER_PROVIDERS';
export const SET_FETCHING_MASTER_PROVIDER = 'provider/SET_FETCHING_MASTER_PROVIDER';
