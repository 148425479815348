import Provider from '@/models/Provider';
import { HTTPServiceClient } from '@/packages/http/service';
import * as ep from './endpoints';

export const ProviderService = {
  /**
   * Description:
   * @param {}
   * @returns { Promise<Provider[]> }
   */
  getProviders(): Promise<Provider[]> {
    return HTTPServiceClient.get(ep.PROVIDERS).then(({ data }) => data);
  },
  getAllProviders(): Promise<Provider[]> {
    return HTTPServiceClient.get(ep.ALL_PROVIDERS).then(({ data }) => data);
  },
  getProvidersWithPA(): Promise<Provider[]> {
    return HTTPServiceClient.get(ep.PROVIDERS_WITH_PA).then(({ data }) => data);
  },

  /**
   *  Add new Provider
   */
  addProvider(provider: Provider) {
    return HTTPServiceClient.post(ep.PROVIDERS, provider);
  },
  /**
   *  Remove Provider
   */
  removeProvider(provider: Provider) {
    return HTTPServiceClient.delete(ep.DELETE_PROVIDER(provider.id));
  },
  /**
   *  Get Provider By Id
   */
  getProviderById(id: number): Promise<Provider[]> {
    return HTTPServiceClient.get(ep.FETCH_PROVIDER_BY_ID(id)).then(({ data }) => data);
  },

  getProviderByUserId(id: number): Promise<Provider[]> {
    return HTTPServiceClient.get(ep.FETCH_PROVIDER_BY_USERID(id)).then(({ data }) => data);
  },

  getProvidersDataQuality(id: number){
    return HTTPServiceClient.get(ep.FETCH_PROVIDER_DATA_QUALITY(id)).then(({ data }) => data);
  },
  getMasterProviders(id: number){
    return HTTPServiceClient.get(ep.FETCH_MASTER_PROVIDER_LIST(id)).then(({ data }) => data);
  }
};
