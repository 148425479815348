import { PriceTrackListItems, PriceTrackObject } from '@/models/PriceTrack';
/**
 * --------------------------------------------------
 *	PriceTrack State
 * --------------------------------------------------
 **/

export const PriceTrackState: IPriceTrackState = {
  priceTracks: [],
  addingPriceTrack: false,
  currentPriceTrack: {
    id: null,
    status: '',
    startDate: '',
    endDate: '',
    dataBatchId: null,
    details: {}
  },
};

export interface IPriceTrackState {
  priceTracks: PriceTrackListItems[];
  addingPriceTrack: boolean;
  currentPriceTrack: PriceTrackObject;
}
