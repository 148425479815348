<template>
  <div class="report-template">
    <Watermark :isSampleReport="isSampleReport" />

    <v-card class="report-page-card" outlined flat>
      <div v-if="filters.Irr" class="section">
        <div class="section-title">
          <div class="section-badge"></div>
          Actual IRR Distribution
        </div>
        <div class="section-content">
          The Internal Rate of Return is calculated using the contribution history of savers. It takes the time value of
          money into account and generates annualised returns. The IRR distribution below shows the number of pots in
          different ranges of IRRs. The average IRRs for data provided is
          {{ (filterRanges && (filterRanges.irrRange.avg * 100).toFixed(2)) || '' }}%.
        </div>
      </div>

      <div v-if="!fetchingChartData && filters.Irr" class="report-chart">
        <AIRRChart
          data-testid="AIRRChart"
          v-if="airrData"
          :chartData="airrData"
          :barColor="barColor"
          :provider="filters.provider"
          :priceTrack="filters.priceTrackObject"
          :isSampleReport="isSampleReport"
          :reportfilterRanges="reportfilterRanges"
        ></AIRRChart>
      </div>

      <div v-if="filters.iAsset && filterRanges" class="section">
        <div class="section-title">
          <div class="section-badge"></div>
          Impact on Asset Value
        </div>
        <div class="section-content">
          The AgeWage score is calculated by comparing the Net Asset Value of a member against the value they would have
          generated in the Benchmark index with the same contribution history. The following graph compares the Net
          Asset Value of all the members against their benchmark value. A net of
          <span :class="iavText.diffStatus === 'generated' ? 'text--green bold-700' : 'text--red bold-700'">{{
            formatNumber(iavText.netDiffVal, '$0.00a')
          }}</span>
          {{ iavText.diffStatus === 'generated' ? 'excess' : '' }} value was {{ iavText.diffStatus }} compared to the
          benchmark as a result of {{ iavText.performanceStatus }} performance of the assets. As shown in the figure
          below,
          <span class="text--green bold-700">{{ formatNumber(iavText.scorePart2.value, '$0.00a') }}</span>
          of value was generated as a result of over performance for the
          {{ numberWithCommas(iavText.scorePart2.count, false) }} members with scores greater than 50, while
          <span class="text--red bold-700">{{ formatNumber(iavText.scorePart1.value, '$0.00a') }}</span>
          of value was lost as a result of under performance for the
          {{ numberWithCommas(iavText.scorePart1.count, false) }} members with scores less than 50
        </div>
      </div>

      <div v-if="!fetchingChartData && filters.iAsset && filterRanges" class="report-chart">
        <AssetImpactChart
          v-if="iavData"
          :chartData="iavData"
          :iavData="iavChartData"
          :plotterColor="plotterColor"
          :provider="filters.provider"
          :priceTrack="filters.priceTrackObject"
          :isSampleReport="isSampleReport"
          :reportfilterRanges="reportfilterRanges"
        ></AssetImpactChart>
      </div>

      <div class="footer">
        <div></div>
        <!-- <div class="page-box">
          <div class="pageno">Page 2/4</div>
        </div> -->
        <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
      </div>
    </v-card>
  </div>
</template>
<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import Utils from '../../../../utils/Utils';
import { FETCH_MEMBERS, FETCH_CONTRIBUTION_HISTORY } from '@/modules/members/store/actionTypes';
import AIRRChart from '@/components/charts/AIRRChart.vue';
import AssetImpactChart from '@/components/charts/AssetImpactChart.vue';
import Watermark from './Watermark.vue';

@Options({
  components: {
    AIRRChart,
    AssetImpactChart,
    Watermark
  }
})
export default class IrrAndIav extends Vue {
  @Prop()
  public chartData?: any;

  @Prop()
  public data?: any;

  @Prop({
    default: () => ({
      coverPage: true,
      awDist: true,
      yearSeg: true,
      Irr: true,
      avb: true,
      schemeSeg: true,
      outliers: true,
      startDate: null,
      endDate: null,
      provider: null
    })
  })
  filters?: any;

  @Prop({
    default: () => ({})
  })
  reportfilterRanges?: any;

  @Prop() isSampleReport!: boolean;

  public barColor: any = {
    primary: '#a085bc',
    secondary: '#cbafd5'
  };

  public plotterColor: any = {
    primary: '#756287',
    secondary: '#cbafd5',
    shadow: '#eee6f1'
  };

  get moduleIsReady() {
    return true;
  }

  public get iavChartData() {
    const navVal: any[] = [];

    const diff: any[] = [];
    const range: any[] = [];
    const count: any[] = [];
    this.iavData &&
      this.iavData.forEach((element: any) => {
        range.push(element.range);
        count.push(element.count);
        if (element.total_nav > element.total_benchmarkNav) {
          const diffVal = element.total_nav - element.total_benchmarkNav;
          diff.push({
            value: diffVal,
            itemStyle: {
              color: 'green'
            }
          });
          navVal.push({
            value: element.total_benchmarkNav,
            itemStyle: {
              color: '#0099ff'
            }
          });
        } else {
          const diffVal = element.total_benchmarkNav - element.total_nav;
          diff.push({
            value: diffVal,
            itemStyle: {
              color: 'red'
            }
          });
          navVal.push({
            value: element.total_nav,
            itemStyle: {
              color: '#0099ff'
            }
          });
        }
      });
    const data = {
      navVal,
      diff,
      range,
      count
    };
    return data;
  }

  public formatNumber(value: any, type: string) {
    if (value < 1000000) {
      type = '$0a';
    }
    return Utils.formatNumber(value, type);
  }

  public numberWithCommas(x: any, setLimit: boolean) {
    if (!isNaN(x) && setLimit) {
      x = x.toFixed(2);
    }
    return (x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) || 0;
  }

  public get iavText() {
    let compare1 = this.iavChartData.diff[0];
    let compare2 = this.iavChartData.diff[1];
    if (this.iavChartData.diff && this.iavChartData.diff.length > 0) {
      if (this.iavChartData.diff[0] && this.iavChartData.diff[0].itemStyle.color === 'green') {
        compare1 = this.iavChartData.diff[0];
        compare2 = this.iavChartData.diff[1];
      } else {
        compare1 = this.iavChartData.diff[1];
        compare2 = this.iavChartData.diff[0];
      }
      let netDiffVal = 0;
      let diffStatus = '';
      let performanceStatus = '';
      if (compare1.value > compare2.value) {
        diffStatus = 'generated';
        performanceStatus = 'over';
        netDiffVal = compare1.value - compare2.value;
      } else {
        diffStatus = 'lost';
        performanceStatus = 'under';
        netDiffVal = compare2.value - compare1.value;
      }

      const scorePart1 = {
        value: this.iavChartData.diff[0].value,
        count: this.iavChartData.count[0]
      };
      const scorePart2 = {
        value: this.iavChartData.diff[1].value,
        count: this.iavChartData.count[1]
      };
      const data = {
        netDiffVal,
        diffStatus,
        performanceStatus,
        scorePart1,
        scorePart2
      };
      return data;
    }
    const data = {
      netDiffVal: 0,
      diffStatus: 0,
      performanceStatus: '',
      scorePart1: '',
      scorePart2: ''
    };
    return data;
  }

  public get airrData() {
    return this.chartData && this.chartData.irr && this.chartData.irr.map((i: any) => i);
  }

  public get iavData() {
    return this.chartData && this.chartData.iav && this.chartData.iav.map((i: any) => i);
  }

  public get sampleReport() {
    return this.$store.getters.sampleReport;
  }

  private get filterRanges() {
    if (this.isSampleReport) {
      return this.sampleReport.filterRanges.data;
    }
    return this.$store.getters.memberFilterRanges;
  }

  public get fetchingChartData() {
    return this.$store.getters.fetchingChartData;
  }
}
</script>

<style scoped>
.text--green {
  color: green;
}
.text--red {
  color: red;
}
.bold-700 {
  font-weight: 700;
}
</style>
