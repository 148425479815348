import { HttpClient, IHttpClient } from '@/packages/http/client';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import Auth from '../Auth';
import RootStore from '@/store';
import { LOG_USER_OUT } from '@/modules/auth/store/actionTypes';

/**
 * --------------------------------------------------
 *	HTTP Service
 * --------------------------------------------------
 **/
export class HttpService {
  /**
   * Http Client Constructor.
   * @param {IHttpClient} httpClient HTTP Service
   */
  constructor(protected httpClient: IHttpClient) {
    this.prepareClient(httpClient);
  }

  /**
   * Send a Request.
   * @param {HTTPRequestConfig} config Request Configuration.
   * @returns {HTTPPromise} Response Promise.
   */
  public request<T = any>(config: AxiosRequestConfig): HTTPPromise<T> {
    return this.httpClient.request(config);
  }

  /**
   * Send a Request.
   * @param {HTTPRequestConfig} config Request Configuration.
   * @returns {HTTPPromise} Response Promise.
   */
  public get<T = any>(url: string, config?: AxiosRequestConfig): HTTPPromise<T> {
    return this.httpClient.get(url, config);
  }

  /**
   * Send a Request.
   * @param {HTTPRequestConfig} config Request Configuration.
   * @returns {HTTPPromise} Response Promise.
   */
  public delete(url: string, config?: AxiosRequestConfig): HTTPPromise {
    return this.httpClient.delete(url, config);
  }

  /**
   * Send a Request.
   * @param {HTTPRequestConfig} config Request Configuration.
   * @returns {HTTPPromise} Response Promise.
   */
  public head(url: string, config?: AxiosRequestConfig): HTTPPromise {
    return this.httpClient.head(url, config);
  }

  /**
   * Send a Request.
   * @param {HTTPRequestConfig} config Request Configuration.
   * @returns {HTTPPromise} Response Promise.
   */
  public post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): HTTPPromise<T> {
    return this.httpClient.post(url, data, config);
  }

  /**
   * Send a Request.
   * @param {HTTPRequestConfig} config Request Configuration.
   * @returns {HTTPPromise} Response Promise.
   */
  public put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): HTTPPromise<T> {
    return this.httpClient.put(url, data, config);
  }

  /**
   * Send a Request.
   * @param {HTTPRequestConfig} config Request Configuration.
   * @returns {HTTPPromise} Response Promise.
   */
  public patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): HTTPPromise<T> {
    return this.httpClient.patch(url, data, config);
  }

  protected prepareClient(httpClient: IHttpClient) {
    this.addRequestInterceptor(httpClient);
    this.addResponseInterceptor(httpClient);
  }

  protected addResponseInterceptor(httpClient: IHttpClient) {
    httpClient.interceptors.response.use(
      response => response,
      error => {
        if (error && error.response && error.response.status === 401) {
          if (Auth.getUser() || Auth.getToken()) {
            RootStore.dispatch(LOG_USER_OUT);
          }
        }
        return Promise.reject(error && error.response);
      }
    );
  }

  protected addRequestInterceptor(httpClient: IHttpClient) {
    httpClient.interceptors.request.use(
      request => {
        const authToken = Auth.getToken();
        const isSameOrigin = request.url && !request.url.startsWith('http');

        if (isSameOrigin && authToken) {
          request.headers.Authorization = `Bearer ${authToken}`;
        } else {
          delete request.headers.Authorization;
        }

        return request;
      },
      err => Promise.reject(err)
    );
  }
}

/**
 * --------------------------------------------------
 *	HTTP Request Configuration Interface
 * --------------------------------------------------
 **/
export interface HTTPRequestConfig extends AxiosRequestConfig {
  //
}

/**
 * --------------------------------------------------
 *	HTTP Promise Interface
 * --------------------------------------------------
 **/
export interface HTTPPromise<T = any> extends AxiosPromise<T> {
  //
}

export const HTTPServiceClient: HttpService = new HttpService(HttpClient);
