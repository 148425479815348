<template>
  <v-app id="app" class="app">
    <router-view />
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
});
</script>

<style lang="scss">
.app {
  background: #ffffff !important;
  font-family: 'Open Sans', sans-serif !important;
}
.v-icon {
  font-size: 16.03px !important;
}
</style>
