import Import from '@/models/Import';
import { HTTPServiceClient } from '@/packages/http/service';
import * as ep from './endpoints';

export const ImportService = {
  /**
   * Description:
   * @param {}
   * @returns { Promise<Scheme[]> }
   */
  getImports(): Promise<Import[]> {
    return HTTPServiceClient.get(ep.FETCH_IMPORTS).then(({ data }) => data);
  }
};
