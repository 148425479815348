import * as AT from '@/modules/reports/store/actionTypes';
import * as MT from '@/modules/reports/store/mutationTypes';
import { IRootState } from '@/store/state';
import { IReportState } from '@/modules/reports/store/state';
import { ActionContext, ActionTree } from 'vuex';
import { ReportsService } from '@/modules/reports/services';

/**
 * --------------------------------------------------
 *  Reports Actions
 * --------------------------------------------------
 **/
export const ReportActions: IReportActions = {
  /**
   * --------------------------------------------------
   *  Fetch All the reports
   * --------------------------------------------------
   **/
  [AT.GENERATE_REPORT]: ({ commit, dispatch }: any, options: any) => {
    commit(MT.SET_REPORT_OPTIONS, options);
    return ReportsService.exportReport(options).then((jobId: any) => jobId);
  },

  [AT.GENERATE_PRICE_TRACK_REPORT]: ({ commit, dispatch }: any, options: any) => {
    // commit(MT.SET_REPORT_OPTIONS, options);
    return ReportsService.exportPriceTrackReport(options).then((jobId: any) => jobId);
  },

  [AT.GET_PDF_URL]: ({ commit, dispatch }: any, id: any) => {
    return ReportsService.getPdfUrl(id).then((url: string) => url);
  },
  [AT.FETCH_PA_REPORT_URL]: ({ commit, dispatch }: any, options: any) => {
    return ReportsService.getPAPdfUrl(options).then((jobId: any) => jobId);
  },

  [AT.GET_EXPORT_JOB_BY_ID]: ({ commit, dispatch }: any, id: any) => {
    return ReportsService.getExportJobStatus(id).then((data: any) => data);
  },

  [AT.RUN_PA_SEPARATELY]: ({ commit, dispatch }: any, id: any) => {
    return ReportsService.triggerPASeparately(id).then((data: any) => data);
  },

  [AT.FETCH_REPORTS]: ({ commit, dispatch }: any) => {
    dispatch(AT.TOGGLE_FETCHING_REPORTS);
    return ReportsService.fetchReports()
      .then((reports: any) => {
        commit(MT.SET_REPORTS, reports);
        return reports;
      })
      .finally(() => dispatch(AT.TOGGLE_FETCHING_REPORTS));
  },
  [AT.FETCH_OUTLIER_ANALYSIS]: ({ commit, dispatch }: any, data: any) => {
    commit(MT.FETCHING_OUTLIER_ANALYSIS, true);
    commit(MT.SET_OUTLIERS, {});
    return ReportsService.getOutlierAnalysis(data.providerId, data)
      .then((analysis: any) => {
        commit(MT.SET_OUTLIERS, analysis);
        return analysis;
      })
      .finally(() => commit(MT.FETCHING_OUTLIER_ANALYSIS, false));
  },
  [AT.FETCH_PERFORMANCE_ATTRIBUTION]: ({ commit, dispatch }: any, data: any) => {
    commit(MT.SET_FETCHING_REPORTS, true);
    commit(MT.SET_PERFORMANCE_ATTRIBUTION, {});
    return ReportsService.getPerformanceAttribution(data.providerID)
      .then((analysis: any) => {
        commit(MT.SET_PERFORMANCE_ATTRIBUTION, analysis.data);
        return analysis;
      })
      .finally(() => commit(MT.SET_FETCHING_REPORTS, false));
  },
  [AT.UPDATE_SEGMENT_LABEL_DATA]: ({ commit, dispatch }: any, data: any) => {
    return ReportsService.updateSegmentationLabelData(data);
  },
  [AT.ADD_PA_FREE_TEXT]: ({ commit, dispatch }: any, data: any) => {
    commit(MT.SETTING_PA_FREE_TEXT, { status: true, key: data.key });
    return ReportsService.addPerformanceAttributionFreeText(data)
      .then((result: any) => {
        commit(MT.ADD_PA_FREE_TEXT, result.data);
        return result;
      })
      .finally(() => commit(MT.SETTING_PA_FREE_TEXT, { status: false }));
  },
  [AT.FETCH_SAMPLE_REPORT]: ({ commit, dispatch }: any) => {
    commit(MT.SET_FETCHING_SAMPLE_REPORTS, true);
    return ReportsService.fetchSampleReport()
      .then((report: any) => {
        commit(MT.SET_SAMPLE_REPORT, report);
        return report;
      })
      .finally(() => commit(MT.SET_FETCHING_SAMPLE_REPORTS, false));
  }
};

/**
 * --------------------------------------------------
 *  Reports Action Context Interface
 * --------------------------------------------------
 **/
export interface IReportActionContext extends ActionContext<IReportState, IRootState> {}

/**
 * --------------------------------------------------
 *  Reports Actions Interface
 * --------------------------------------------------
 **/
export interface IReportActions extends ActionTree<IReportState, IRootState> {}
