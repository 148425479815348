<template>
  <div class="data-quality-wrapper">
    <div class="elevation-1 chartWrapper data-quality-card">
      <div class="chart-title d-block text-center">{{ providerName }} Data Quality compared to the market</div>
      <div class="d-flex">
        <base-chart :options="chartOptions" :scroll-options="chartSize" />
      </div>
      <div>
        <v-list class="d-flex" style="margin-left: 5rem">
          <v-row>
            <v-col>
              <v-list-item color="#ccc" class="align-center">
                <template v-slot:prepend>
                  <v-icon class="mr-0" color="#e62e00" style="font-size: 12px !important">fas fa-circle</v-icon>
                </template>
                <v-list-item-title style="font-size: 13px !important">My Scheme</v-list-item-title>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item color="#ccc" class="align-center">
                <template v-slot:prepend>
                  <v-icon class="mr-0" color="#008000" style="font-size: 12px !important">fas fa-circle</v-icon>
                </template>
                <v-list-item-title style="font-size: 13px !important"
                  >Schemes accepting <br />
                  consolidation</v-list-item-title
                >
              </v-list-item></v-col
            >
            <v-col>
              <v-list-item color="#ccc" class="align-center">
                <template v-slot:prepend>
                  <v-icon class="mr-0" color="#0066cc" style="font-size: 12px !important">fas fa-circle</v-icon>
                </template>
                <v-list-item-title style="font-size: 13px !important">Other Scheme types</v-list-item-title>
              </v-list-item></v-col
            >
          </v-row>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import BaseChart from '@/components/charts/BaseChart.vue';
import Utils from '../../utils/Utils';

const mapConsolidator = {
  yes: 'Accepting Full Consolidation',
  no: 'Not Accepting Consolidation',
  partial: 'Accepting Partial Consolidation',
};

const extraPrviderList = [
  'Aviva',
  'Standard Life',
  'L&G',
  'Aegon',
  'Fidelity',
  'Lifesight',
  'Nest',
  "People's Pension",
  'Mercer',
  'Now Pensions',
];

export default {
  components: {
    BaseChart,
  },
  props: ['data', 'providerId', 'providerName', 'providerDataQuality'],
  data() {
    return {
      chartData: [],
      chartSize: {
        chartWidth: 75,
        chartHeight: 30,
        unit: 'rem',
      },
      chartOptions: {},
    };
  },
  created() {
    this.setChartData();
    this.setOption();
  },
  watch: {
    data(newVal, oldVal) {
      this.chartData = [];
      this.chartOptions = {};
      this.setChartData();
      this.setOption();
    },
  },
  methods: {
    setChartData() {
      if (this.data) {
        const isProviderAvailable = this.data.find((it) => it.providerId === this.providerId);
        this.data.forEach((element) => {
          let colorCode = '#0066cc';
          if (element.consolidator == 'yes') {
            colorCode = '#008000';
          }
          if (element.providerId == this.providerId) {
            colorCode = '#e62e00';
          }
          if (extraPrviderList.includes(element.name)) {
            this.chartData.push({
              value: [
                element.total_nav,
                element.suspectData,
                element.tax_year,
                element.scheme,
                element.consolidator,
                element.pathway,
                element.data_type,
              ],
              symbolSize: 17,
              itemStyle: {
                color: colorCode,
              },
              symbol: `image://data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idX
              RmLTgiPz4KPHN2ZyB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sbnM9Imh0dHA6Ly
              93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNIDUwMyAyNTYgQyA1MDMgMzI0LjI5
              NiA0NzUuNDE3IDM4NS45MTkgNDMwLjY2OCA0MzAuNjY4IEMgMzg1LjkxOSA0NzUu
              NDE3IDMyNC4yOTYgNTAzIDI1NiA1MDMgQyAxODcuNzA0IDUwMyAxMjYuMDgxIDQ3NS40MTcgODEuMzMy
              IDQzMC42NjggQyAzNi41ODMgMzg1LjkxOSA5IDMyNC4yOTYgOSAyNTYgQy
              A5IDE4Ny43MDQgMzYuNTgzIDEyNi4wODEgODEuMzMyIDgxLjMzMiBDIDEyNi4wODEgMzYuNTgzIDE4Ny
              43MDQgOSAyNTYgOSBDIDMyNC4yOTYgOSAzODUuOTE5IDM2LjU4MyA0MzAuNj
              Y4IDgxLjMzMiBDIDQ3NS40MTcgMTI2LjA4MSA1MDMgMTg3LjcwNCA1MDMgMjU2IFoiIHN0eWxlPSJmaWxsOiBy
              Z2IoMCwgMTI4LCAwKTsgZmlsbC1ydWxlOiBub256ZXJvOyBwYWludC1
              vcmRlcjogZmlsbDsiLz4KICA8cmVjdCBzdHlsZT0iZmlsbDogcmdiKDIzMCwgNDYsIDApOyBmaWxsLXJ1bGU6IG
              5vbnplcm87IHBhaW50LW9yZGVyOiBzdHJva2U7IHN0cm9rZTog
              cmdiKDIzMCwgNDYsIDApOyIgdHJhbnNmb3JtPSJtYXRyaXgoLTAuNzA3MDksIDAuNzA3MTI0LCAtMC43MDM1Mjg
              sIC0wLjcxMDY4NSwgNzE4OC4wOTYxOTEsIDY3MDUuNzU1MzcxKSIge
              D0iMTM5LjM3OCIgeT0iOTQyNC41MzkiIHdpZHRoPSI0OTQuMzI4IiBoZWlnaHQ9IjYxLjE2MSIvPgo8L3N2Zz4=`,
            });
          } else {
            this.chartData.push({
              value: [
                element.total_nav,
                element.suspectData,
                element.tax_year,
                element.scheme,
                element.consolidator,
                element.pathway,
                element.data_type,
              ],
              itemStyle: {
                color: colorCode,
              },
              symbolSize: 17,
            });
          }
        });
        if (!isProviderAvailable) {
          this.chartData.push({
            value: [this.providerDataQuality.asset_size, +this.providerDataQuality.suspect_data],
            itemStyle: {
              color: '#e62e00',
            },
            symbolSize: 17,
          });
        }
      }
    },
    setOption() {
      this.chartOptions = {
        tooltip: {
          show: true,
          formatter: (v) => {
            return (
              v.value &&
              `Asset Size: ${Utils.formatNumber(v.value[0], '$0a')} <br /> Suspect Data: ${Number(v.value[1]).toFixed(
                1
              )}% ${(v.value[2] && `<br /> Tax Year: ${v.value[2]}`) || ''} ${
                (v.value[3] && `<br /> Scheme: ${v.value[3]}`) || ''
              }  ${(v.value[4] && `<br /> Consolidator: ${mapConsolidator[v.value[4]]}`) || ''} ${
                (v.value[5] && `<br /> Pathway: ${v.value[5]}`) || ''
              } ${(v.value[6] && `<br /> Data Type: ${v.value[6]}`) || ''}`
            );
          },
        },
        xAxis: {
          name: 'Asset Size (£mn)',
          nameLocation: 'middle',
          nameTextStyle: { fontFamily: 'Open Sans', color: '#6f6c76', padding: 20, fontWeight: 'bold' },
          axisLabel: {
            formatter: (v) => `${(v / 1000000).toFixed(0)}`,
            padding: [0, 0, 0, 0],
            fontWeight: '600',
            fontFamily: 'Open Sans',
            color: '#6f6c76',
            interval: false,
          },
          axisLine: { show: true, lineStyle: { color: '#6f6c76' } },
          splitLine: {
            show: false,
          },
          max: 1200000000,
          interval: 100000000,
        },
        yAxis: {
          name: 'Suspect Data (%)',
          nameLocation: 'middle',
          nameRotate: 90,
          nameTextStyle: { fontFamily: 'Open Sans', color: '#6f6c76', padding: 30, fontWeight: 'bold' },
          axisLabel: {
            fontWeight: '600',
            fontFamily: 'Open Sans',
            color: '#6f6c76',
            interval: false,
          },
          axisLine: { show: true, lineStyle: { color: '#6f6c76' } },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#eee',
            },
          },
          scale: true,
        },
        series: [
          {
            symbolSize: 13,
            data: this.chartData,
            type: 'scatter',
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss">
.data-quality-wrapper {
  padding: 1rem 0rem !important;
  .data-quality-card {
    padding-bottom: 3rem !important;
  }
}
</style>
