export default class Config {
  public static getApiUrl(): string {
    return process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '';
  }

  public static getApiKey(): string {
    return process.env.VUE_APP_API_KEY ? process.env.VUE_APP_API_KEY : '';
  }
  public static getCsvUrl(): string {
    return process.env.VUE_APP_s3CSVPath ? process.env.VUE_APP_s3CSVPath : '';
  }
}
