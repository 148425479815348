<template>
  <div
    class="base-chart"
    :style="{
      width: scrollOptions && scrollOptions.chartWidth + scrollOptions.unit,
      height: scrollOptions && scrollOptions.chartHeight + scrollOptions.unit,
    }"
  >
    <div v-if="renderer == 'svg'" ref="svgRef" class="base-chart__wrapper echarts-render-wrapper svg">
      <!--  -->
    </div>
    <div v-if="renderer == 'canvas'" ref="canvasReference" class="base-chart__wrapper echarts-render-wrapper can" />
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Watch } from 'vue-property-decorator';

const echarts = require('echarts/lib/echarts');
require('echarts-gl');
require('echarts/lib/chart/bar');
require('echarts/lib/chart/scatter');
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');
require('echarts/lib/component/legend');
import Utils from '@/utils/Utils';
import _debounce from 'lodash/debounce';
import { addListener, removeListener } from 'resize-detector';

export default class BaseChart extends Vue {
  @Watch('options', { deep: true })
  watchOptions(newValue: any, oldValue: any) {
    if (!this.manualUpdate) {
      if (!this.chart && newValue) {
        this.init();
      } else {
        this.chart.setOption(this.allOptions);
      }
    }
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  exportImage!: boolean;

  @Watch('exportImage')
  exportImageData() {
    const data = this.chart.getDataURL({
      pixelRatio: 2,
    });
    this.$emit('export-data', data);
  }

  public chart: any = null;

  public isMounted = false;

  public __resizeHandler: any;

  public lastArea = 0;

  public manualOptions: any;

  @Prop({
    required: false,
  })
  scrollOptions?: any;

  @Prop() options!: Object;

  @Prop({
    type: String,
    default: 'canvas',
  })
  renderer!: string;

  @Prop() theme!: string | Object;

  @Prop() initOptions!: Object;

  @Prop() manualUpdate!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  watchShallow!: boolean;

  @Prop({
    default: true,
    type: Boolean,
  })
  autoresize!: boolean;

  // ECharts Events
  public EVENTS = [
    'legendselectchanged',
    'legendselected',
    'legendunselected',
    'legendscroll',
    'datazoom',
    'datarangeselected',
    'timelinechanged',
    'timelineplaychanged',
    'restore',
    'dataviewchanged',
    'magictypechanged',
    'geoselectchanged',
    'geoselected',
    'geounselected',
    'pieselectchanged',
    'pieselected',
    'pieunselected',
    'mapselectchanged',
    'mapselected',
    'mapunselected',
    'axisareaselected',
    'focusnodeadjacency',
    'unfocusnodeadjacency',
    'brush',
    'brushselected',
    'rendered',
    'finished',
    'click',
    'dblclick',
    'mouseover',
    'mouseout',
    'mousemove',
    'mousedown',
    'mouseup',
    'globalout',
    'contextmenu',
  ];

  created() {
    this.init();
    this.watchProps();
  }

  mounted() {
    this.isMounted = true;
    // auto init if `options` is already provided
    if (this.allOptions) {
      this.init();
    }
  }

  beforeUnmount() {
    if (!this.chart) {
      return;
    }
    this.destroy();
  }

  protected watchProps() {
    ['theme', 'initOptions', 'autoresize', 'manualUpdate', 'watchShallow'].forEach((prop) => {
      this.$watch(prop, () => this.refresh(), { deep: true });
    });
  }

  protected get allOptions(): any {
    return Utils.merge(this.defaultOptions, this.options);
  }

  protected get defaultOptions() {
    return {
      tooltip: {
        confine: true,
        textStyle: {
          fontSize: 12,
          fontWeight: 'bold',
          color: 'white',
        },
        borderColor: 'rgba(35, 41, 47, 0.85)',
        backgroundColor: 'rgba(35, 41, 47, 0.85)',
      },
    };
  }

  protected get canvasRef(): any {
    return this.isMounted ? this.$refs.canvasReference : null;
  }

  protected get svgRef(): any {
    return this.isMounted ? this.$refs.svgRef : null;
  }

  public get activeRef(): any {
    return this.svgRef ? this.svgRef : this.canvasRef;
  }

  getArea() {
    const offsetWidth = this.activeRef && this.activeRef.offsetWidth;
    const offsetHeight = this.activeRef && this.activeRef.offsetHeight;
    return offsetWidth * offsetHeight;
  }

  init() {
    if (this.chart && this.chart !== null) {
      return;
    }

    if (!this.activeRef) {
      return;
    }

    this.chart = echarts.init(this.activeRef, '', {
      ...this.allOptions,
      renderer: this.renderer,
    });

    this.chart.setOption(this.allOptions || {}, true);

    // expose ECharts events as custom events
    this.EVENTS.forEach((event) => {
      this.chart.on(event, (params: any) => {
        this.$emit(event, params, this.chart);
      });
    });

    if (this.autoresize) {
      this.lastArea = this.getArea();
      this.__resizeHandler = _debounce(
        () => {
          if (this.lastArea === 0) {
            // emulate initial render for initially hidden charts
            this.mergeOptions({}, true);
            this.resize();
            this.mergeOptions(this.allOptions || this.manualOptions || {}, true);
          } else {
            this.resize();
          }
          this.lastArea = this.getArea();
        },
        100,
        { leading: true }
      );
    }

    addListener(this.activeRef, this.__resizeHandler);

    Object.defineProperties(this, {
      // Only recalculated when accessed from JavaScript.
      // Won't update DOM on value change because getters
      // don't depend on reactive values
      width: {
        configurable: true,
        get: () => {
          return this.delegateGet('width', 'getWidth');
        },
      },
      height: {
        configurable: true,
        get: () => {
          return this.delegateGet('height', 'getHeight');
        },
      },
      isDisposed: {
        configurable: true,
        get: () => {
          return !!this.delegateGet('isDisposed', 'isDisposed');
        },
      },
      computedOptions: {
        configurable: true,
        get: () => {
          return this.delegateGet('computedOptions', 'getOption');
        },
      },
    });
  }

  delegateMethod(name: any, ...args: any[]) {
    if (!this.chart) {
      this.init();
    }
    return this.chart[name](...args);
  }

  // provide a explicit merge option method
  mergeOptions(options: any, notMerge: any, lazyUpdate?: any) {
    if (this.manualUpdate) {
      this.manualOptions = options;
    }

    if (!this.chart) {
      this.init();
    } else {
      this.delegateMethod('setOption', options, notMerge, lazyUpdate);
    }
  }

  // just delegates ECharts methods to Vue component
  // use explicit params to reduce transpiled size for now
  resize(options?: any) {
    this.delegateMethod('resize', options);
  }

  dispose() {
    this.delegateMethod('dispose');
  }

  delegateGet(name: any, method: any) {
    if (!this.chart) {
      this.init();
    }
    return this.chart[method]();
  }

  destroy() {
    if (this.autoresize) {
      removeListener(this.activeRef, this.__resizeHandler);
    }
    this.dispose();
    this.chart = null;
  }

  refresh() {
    if (this.chart) {
      this.destroy();
      this.init();
    }
  }

  appendData(params: any) {
    this.delegateMethod('appendData', params);
  }

  dispatchAction(payload: any) {
    this.delegateMethod('dispatchAction', payload);
  }

  convertToPixel(finder: any, value: any) {
    return this.delegateMethod('convertToPixel', finder, value);
  }

  convertFromPixel(finder: any, value: any) {
    return this.delegateMethod('convertFromPixel', finder, value);
  }

  containPixel(finder: any, value: any) {
    return this.delegateMethod('containPixel', finder, value);
  }

  showLoading(type: any, options: any) {
    this.delegateMethod('showLoading', type, options);
  }

  hideLoading() {
    this.delegateMethod('hideLoading');
  }

  getDataURL(options: any) {
    return this.delegateMethod('getDataURL', options);
  }

  getConnectedDataURL(options: any) {
    return this.delegateMethod('getConnectedDataURL', options);
  }

  clear() {
    this.delegateMethod('clear');
  }

  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.destroy();
  }

  connect(group: any) {
    if (typeof group !== 'string') {
      group = group.map((chart: any) => chart.chart);
    }
    echarts.connect(group);
  }

  disconnect(group: any) {
    echarts.disConnect(group);
  }

  registerMap(mapName: any, geoJSON: any, specialAreas: any) {
    echarts.registerMap(mapName, geoJSON, specialAreas);
  }

  registerTheme(name: any, theme: any) {
    echarts.registerTheme(name, theme);
  }
}
</script>

<style lang="scss">
.base-chart {
  height: 100%;
  min-height: inherit;
  position: relative;
  margin: 0 auto !important;

  &__wrapper {
    height: 100%;
    margin-left: 1.25rem;
    margin-top: -3rem;
    padding-bottom: 10px;
    min-height: fit-content;
  }
}
</style>
