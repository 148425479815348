<template>
  <div data-testid="list-simulations-page" class="page list-simulations-page">
    <v-container fluid>
      <v-layout class="mt-4 pa-0">
        <div style="align-self: center">
          <h2 class="section-title">
            <span>ALL Simulations</span>
          </h2>
        </div>
        <v-layout align-end class="py-2 justify-end">
          <v-btn class="mx-4" @click="downloadStdDevs" :loading="fetchingStDevs" :disabled="fetchingStDevs">
            <span>Download Std Devs</span>
          </v-btn>
          <v-btn @click="$router.push({ name: 'dashboard.simulations.add' })">
            <span>Add Simulation</span>
          </v-btn>
        </v-layout>
      </v-layout>
      <v-card v-if="simulations" class="mt-4">
        <AppTable
          :table-data="tableData"
          v-if="simulations && simulations.length"
          v-on:viewItemClick="(e) => getSimulationDetails(e)"
          :disablePagination="true"
        />
      </v-card>
      <div v-if="!simulations" class="d-flex align-center justify-center pt-5 mt-5">
        <v-progress-circular indeterminate color="grey" />
      </div>
    </v-container>
    <v-dialog v-model="showCurrentSimulation" width="50%">
      <v-card v-if="currentSimulation.id">
        <v-card-title>
          {{ currentSimulation.id }}. {{ currentSimulation.name }}<br />{{
            currentSimulation.result && currentSimulation.result.stDev
              ? `Standard Deviation is '${currentSimulation.result.stDev} %'`
              : 'Still processing'
          }}
        </v-card-title>
        <v-card-text style="color: #0000008a">
          <div v-for="(value, prop) in currentSimulation" v-bind:key="prop">{{ prop }} : {{ value }}</div>
        </v-card-text>
      </v-card>
      <v-card v-else> LOADING... </v-card>
    </v-dialog>
    <AppLoader v-if="fetchingSimulations" />
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppTable from '@/components/AppTable.vue';
import { Table, DataTableHeader, TableRow, TableColumnType } from '@/types';
import { FETCH_SIMULATIONS, FETCH_SIMULATION, DOWNLOAD_STD_DEVS } from '@/modules/simulations/store/actionTypes';
import { SET_NAV_HEADER } from '@/store/actionTypes';
import AppLoader from '@/components/AppLoder.vue';

@Options({
  components: {
    AppTable,
    AppLoader,
  },
})
export default class ProvidersPage extends Vue {
  public pagination = {
    page: 1,
    limit: 15,
  };

  public showCurrentSimulation = false;
  public fetchingSimulations = false;

  created() {
    this.bootstrap();
  }

  public bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Simulations', tooltip: 'All Simulations' });
    this.fetchProviders(this.pagination);
  }

  get simulations() {
    return this.$store.getters.simulations;
  }

  get fetchingStDevs() {
    return this.$store.getters.gettingStandardDeviations;
  }

  get currentSimulation() {
    return this.$store.getters.currentSimulation;
  }

  public async downloadStdDevs() {
    const url = await this.$store.dispatch(DOWNLOAD_STD_DEVS);
    if (url && url.length) {
      window.open(url);
    }
  }

  public get tableData(): Table {
    const columns: DataTableHeader[] = [
      {
        value: 'id',
        title: 'ID',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'isSos',
        title: 'Is SoS?',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'status',
        title: 'Current Status',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'view',
        title: 'View Details',
        type: TableColumnType.ACTIONS,
        align: 'center',
      },
    ];
    return {
      columns,
      rows: this.rows,
    };
  }

  public get rows(): TableRow[] {
    return (
      this.simulations &&
      this.simulations.map((simulation: any, it: number) => {
        return {
          id: simulation.id,
          status: simulation.status,
          isSos: simulation.isSos ? 'Yes' : 'No',
        };
      })
    );
  }

  public get filterVisibility(): boolean {
    return this.$store.getters.filterIsVisible;
  }

  public fetchProviders(data: any = {}) {
    this.$store.dispatch(FETCH_SIMULATIONS, data);
  }
  public async getSimulationDetails(id: String) {
    this.fetchingSimulations = true;
    await this.$store.dispatch(FETCH_SIMULATION, id);
    this.showCurrentSimulation = true;
    this.fetchingSimulations = false;
  }
}
</script>
