// Log In
export const SET_LOGGING_IN = 'auth/SET_LOGGING_IN';
export const SET_LOGIN_ERROR = 'auth/SET_LOGIN_ERROR';
// Current User
export const SET_CURRENT_USER = 'auth/SET_CURRENT_USER';
export const SET_FETCHING_CURRENT_USER = 'auth/SET_FETCHING_CURRENT_USER';
export const SET_FETCH_CURRENT_USER_ERROR = 'auth/SET_FETCH_CURRENT_USER_ERROR';

// Session Timeout
export const SET_SESSION_TIME_OUT = 'auth/SET_SESSION_TIME_OUT';
export const SET_CHANGING_PASSWORD = 'auth/SET_CHANGING_PASSWORD';
export const SET_CHANGE_PASSWORD_ERROR = 'auth/SET_CHANGE_PASSWORD_ERROR';