import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_flex = _resolveComponent("v-flex")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_AppTable = _resolveComponent("AppTable")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_Analysis = _resolveComponent("Analysis")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      (_ctx.provider)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_v_snackbar, {
              value: _ctx.generatingPdf,
              timeout: 5000,
              top: true
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Please wait while PDF is being generated "),
                _createVNode(_component_v_btn, {
                  variant: "text",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.snackbar = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Close")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_v_alert, {
              modelValue: _ctx.pdfAlert,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pdfAlert) = $event)),
              dismissible: true,
              outlined: false,
              "colored-border": "",
              elevation: "2",
              border: "start",
              color: "#673AB7",
              class: "mb-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" PDF URL: "),
                _createElementVNode("a", {
                  href: _ctx.pdfUrl,
                  target: "_blank"
                }, _toDisplayString(_ctx.pdfUrl), 9, _hoisted_2)
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_v_layout, { "justify-space-between": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_flex, null, {
                  default: _withCtx(() => [
                    _createElementVNode("h1", null, "Analysis for " + _toDisplayString(_ctx.provider.name), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_flex, { class: "text-right" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      onClick: _ctx.exportPdf,
                      small: "",
                      color: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Export")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_divider),
            _createVNode(_component_v_card, { class: "mt-4" }, {
              default: _withCtx(() => [
                (_ctx.tableData)
                  ? (_openBlock(), _createBlock(_component_AppTable, {
                      key: 0,
                      "data-testid": "ProviderAnalysisTable",
                      "table-data": _ctx.tableData,
                      "hide-actions": false
                    }, null, 8, ["table-data"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.chartData && !_ctx.fetchingChartData)
        ? (_openBlock(), _createBlock(_component_Analysis, {
            key: 1,
            id: _ctx.provider.id,
            chartData: _ctx.chartData
          }, null, 8, ["id", "chartData"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}