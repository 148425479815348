import { BenchmarkObject, BenchmarkListItems } from '@/models/Benchmark';
import { HTTPServiceClient } from '@/packages/http/service';
import * as ep from './endpoints';

export const BenchmarkService = {
  /**
   * Description:
   * @param {}
   * @returns { Promise<BenchmarkListItems[]> }
   */
  getBenchmarks(): Promise<BenchmarkListItems[]> {
    return HTTPServiceClient.get(ep.FETCH_BENCHMARKS).then(({ data }) => data);
  },
  getBenchmark(id: string): Promise<BenchmarkObject> {
    return HTTPServiceClient.get(ep.FETCH_BENCHMARK(id)).then(({ data }) => data);
  },
  addBenchmark(data: any) {
    return HTTPServiceClient.post(ep.ADD_BENCHMARK, data);
  },
};
