import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-testid": "ProviderAnalysisChart"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AIRRChart = _resolveComponent("AIRRChart")!
  const _component_ISYChart = _resolveComponent("ISYChart")!
  const _component_AVBChart = _resolveComponent("AVBChart")!
  const _component_SchemeComparisonChart = _resolveComponent("SchemeComparisonChart")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.fetchingChartData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.airrData)
            ? (_openBlock(), _createBlock(_component_AIRRChart, {
                key: 0,
                "data-testid": "AIRRChart",
                chartData: _ctx.airrData
              }, null, 8, ["chartData"]))
            : _createCommentVNode("", true),
          (_ctx.isyData)
            ? (_openBlock(), _createBlock(_component_ISYChart, {
                key: 1,
                "data-testid": "ISYChart",
                chartData: _ctx.isyData
              }, null, 8, ["chartData"]))
            : _createCommentVNode("", true),
          (_ctx.avbData)
            ? (_openBlock(), _createBlock(_component_AVBChart, {
                key: 2,
                "data-testid": "AVBChart",
                chartData: _ctx.avbData
              }, null, 8, ["chartData"]))
            : _createCommentVNode("", true),
          (_ctx.sccData)
            ? (_openBlock(), _createBlock(_component_SchemeComparisonChart, {
                key: 3,
                "data-testid": "SCC-Chart",
                chartData: _ctx.sccData
              }, null, 8, ["chartData"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_v_progress_circular, { indeterminate: "" })
        ]))
  ]))
}