import { ProviderActions } from '@/modules/providers/store/actions';
import { ProviderGetters } from '@/modules/providers/store/getters';
import { ProviderMutations } from '@/modules/providers/store/mutations';
import { ProviderState, IProviderState } from '@/modules/providers/store/state';
import { IRootState } from '@/store/state';
import { Module } from 'vuex';

export const ProviderStore: IProviderStore = {
  state: ProviderState,
  actions: ProviderActions,
  getters: ProviderGetters,
  mutations: ProviderMutations
};

/**
 * --------------------------------------------------
 *	Provider Store Interface
 * --------------------------------------------------
 **/
export interface IProviderStore extends Module<IProviderState, IRootState> {}
