import User from '@/models/User';

export default class Auth {
  public static setToken(token: string): void {
    window.localStorage.setItem('token', token);
  }

  public static getToken(): string | null | undefined {
    return window.localStorage.getItem('token');
  }

  public static destroyToken(): void {
    window.localStorage.removeItem('token');
  }

  public static setUser(user: User): void {
    window.localStorage.setItem('user', JSON.stringify(user));
  }

  public static getUser(): User | null | undefined {
    const user = window.localStorage.getItem('user');
    if (!user) {
      return null;
    }
    return JSON.parse(user);
  }

  public static removeUser(): void {
    window.localStorage.removeItem('user');
  }

  public static isAuthenticated(): boolean {
    return Auth.getUser() != null && Auth.getToken() != null;
  }

  public static setActiveRoles(roles: any) {
    // TODO
  }
}
