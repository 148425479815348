import { RootActions } from '@/store/actions';
import { RootGetters } from '@/store/getters';
import { RootMutations } from '@/store/mutations';
import { AppModules } from '@/modules/store';
// import Vue from 'vue';
import Vuex, { Store, StoreOptions, createStore } from 'vuex';
import { RootState } from './state';

/**
 * --------------------------------------------------
 *	Root Store
 * --------------------------------------------------
 **/

const RootStore = createStore({
  state: RootState,
  getters: RootGetters,
  actions: RootActions,
  mutations: RootMutations,
  modules: AppModules,
});
// export const RootStore = new Vuex.Store({
//   state: RootState,
//   getters: RootGetters,
//   actions: RootActions,
//   mutations: RootMutations,
//   modules: AppModules
// });

export default RootStore;


/**
 * --------------------------------------------------
 *	Root Store Interface
 * --------------------------------------------------
 **/
// export interface IRootStore extends Store<StoreOptions<IRootStore>> {}
