import { PriceTrackActions } from '@/modules/price-track/store/actions';
import { PriceTrackGetters } from '@/modules/price-track/store/getters';
import { PriceTrackMutations } from '@/modules/price-track/store/mutations';
import { PriceTrackState, IPriceTrackState } from '@/modules/price-track/store/state';
import { IRootState } from '@/store/state';
import { Module } from 'vuex';

export const PriceTrackStore: IPriceTrackStore = {
  state: PriceTrackState,
  actions: PriceTrackActions,
  getters: PriceTrackGetters,
  mutations: PriceTrackMutations
};

/**
 * --------------------------------------------------
 *	PriceTrack Store Interface
 * --------------------------------------------------
 **/
export interface IPriceTrackStore extends Module<IPriceTrackState, IRootState> {}
