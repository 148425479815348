<template>
  <div data-testid="list-reports-page" class="page list-reports-page">
    <div>
      <div ref="filterPanel" class="mt-10 page-filters-wrapper mb-6">
        <div
          :threshold="filterPanelPosition"
          class="navbar page-filters-panel pos-relative justify-space-between"
          :class="scrolledAll ? 'vue-fixed-header--isFixed' : ''"
        >
          <v-layout>
            <v-row no-gutters align="end" justify="space-between">
              <!-- Date Filter -->
              <v-col class="d-flex" cols="12" sm="9">
                <!-- Provider Filter -->
                <v-row>
                  <v-col cols="3" align-self="center">
                    <v-label>Provider</v-label>
                    <v-autocomplete
                      :model-value="currentFilters.provider"
                      :items="providers"
                      item-title="name"
                      item-value="id"
                      label="Provider"
                      variant="solo"
                      :disabled="fetchingChartData"
                      hide-details
                      density="compact"
                      class="mt-2 filter-btn-active"
                      single-line
                      @update:modelValue="
                        (e) => {
                          onProviderChange(e);
                        }
                      "
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <!-- End Buttons -->
              <v-col class="d-flex justify-end my-2" cols="12" sm="3">
                <v-btn
                  class="filter-btn elevation-6 mt-0 mx-2"
                  :ripple="false"
                  data-testid="ExportReportBtn"
                  variant="text"
                  :disabled="fetchingChartData || isSampleReport"
                  :loading="processingReport"
                >
                  <div class="mx-2">Export</div>
                  <v-icon small>fas fa-file-pdf</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-layout>
        </div>
      </div>

      <div>
        <div v-if="!fetchingChartData && !fetchingSampleReport" height="100%">
          <ReportPageCover
            v-if="currentFilters.coverPage"
            :data="coverPageData"
            :chartData="chartData"
            :isSampleReport="isSampleReport"
          ></ReportPageCover>
          <ScoreDist
            v-if="currentFilters.awDist"
            :data="page2Data"
            :chartData="chartData"
            :filters="currentFilters"
            :isSampleReport="isSampleReport"
          ></ScoreDist>
          <IrrAndIav
            v-if="currentFilters.iAsset || currentFilters.Irr"
            :data="page2Data"
            :chartData="irrAndIavData"
            :filters="currentFilters"
            :isSampleReport="isSampleReport"
          ></IrrAndIav>
          <EPS
            v-if="currentFilters.ePotSize"
            :data="page2Data"
            :isSampleReport="isSampleReport"
            :chartData="ePotData"
            :filters="currentFilters"
          ></EPS>
          <div v-if="segmentData">
            <DataSetSegmentation
              v-for="(val, key) in segmentData.numberGraphs"
              :id="key"
              :key="key"
              :title="capitalizeFirstLetterEveryWord(key)"
              type="number"
              :chartData="val"
              :filters="currentFilters"
              :isSampleReport="isSampleReport"
            >
            </DataSetSegmentation>

            <DataSetSegmentation
              v-for="(val, key) in segmentData.stringGraphs"
              :id="key"
              :key="key"
              :title="capitalizeFirstLetterEveryWord(key)"
              type="string"
              :chartData="val"
              :filters="currentFilters"
              :isSampleReport="isSampleReport"
            >
            </DataSetSegmentation>
          </div>
          <SccAndIsy
            v-if="(sccAndIsyData && currentFilters.schemeSeg) || currentFilters.yearSeg"
            :data="page2Data"
            :chartData="sccAndIsyData"
            :filters="currentFilters"
            :isSampleReport="isSampleReport"
          ></SccAndIsy>
          <Outliers
            v-if="currentFilters.outliers && outliers && outliers.length"
            :data="outlierPageData"
            :providerId="currentFilters.provider"
            :isSampleReport="isSampleReport"
          ></Outliers>
          <ReportStaticText :isSampleReport="isSampleReport"> </ReportStaticText>
          <div v-if="isSampleReport" class="d-flex">
            <v-btn class="filter-btn mx-auto elevation-6 my-8" variant="text" @click="getVFMReport">
              <span class="call-btn-text">Request my VFM report</span>
            </v-btn>
          </div>
        </div>
        <div v-if="fetchingChartData || fetchingSampleReport" class="d-flex align-center justify-center pt-5 mt-5">
          <v-progress-circular indeterminate color="grey" />
        </div>
      </div>
      <v-dialog
        v-model="requestVFMSuccessDialog"
        @click:outside="requestVFMSuccessDialog = false"
        persistent
        max-width="50vw"
      >
        <v-card>
          <v-card-text class="text-h6" style="word-break: break-word">
            {{ requestVFMmsg }}
          </v-card-text>
          <v-card-actions>
            <v-btn class="mx-auto" color="grey-darken-1" variant="text" @click="requestVFMSuccessDialog = false"
              >Ok</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { SET_NAV_HEADER } from '@/store/actionTypes';
import { FETCH_SAMPLE_REPORT } from '@/modules/reports/store/actionTypes';
import { REQUEST_VFM_REPORT } from '@/modules/benchmarking/store/actionTypes';
import { FETCH_CURRENT_USER } from '@/modules/auth/store/actionTypes';
import User from '@/models/User';
import ReportPageCover from '../../reports/pages/templates/ReportPageCover.vue';
import SccAndIsy from '../../reports/pages/templates/SccAndIsy.vue';
import Outliers from '../../reports/pages/templates/Outliers.vue';
import DataSetSegmentation from '../../reports/pages/templates/DataSetSegmentation.vue';
import IrrAndIav from '../../reports/pages/templates/IrrAndIav.vue';
import EPS from '../../reports/pages/templates/EPS.vue';
import ScoreDist from '../../reports/pages/templates/ScoreDist.vue';
import ReportStaticText from '../../reports/pages/templates/ReportStaticText.vue';
import Utils from '@/utils/Utils';

@Options({
  components: {
    ReportPageCover,
    IrrAndIav,
    EPS,
    SccAndIsy,
    Outliers,
    ReportStaticText,
    // FixedHeader,
    ScoreDist,
    DataSetSegmentation,
  },
})
export default class ReportGenPage extends Vue {
  created() {
    window.scrollTo(0, 0);
    this.bootstrap();
  }

  public startDatePicker = false;
  public endDatePicker = false;

  public filtersAreApplied: boolean = false;

  public fetchingReport = false;

  public processingReport = false;

  // public paCallSuccessful = false;

  public filterIsVisible = false;

  public filterPanelPosition = 200;

  public scrolledAll: Boolean = false;

  public capitalizeFirstLetterEveryWord = Utils.capitalizeLetter;

  public currentFilters: any = {
    coverPage: true,
    awDist: true,
    yearSeg: true,
    avb: true,
    Irr: true,
    schemeSeg: false,
    segmentData: true,
    iAsset: true,
    ePotSize: true,
    outliers: true,
    provider: this.providers && this.providers.length > 0 ? this.providers[0].id : null,
    startDate: null,
    endDate: null,
  };

  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  public requestVFMSuccessDialog = false;

  public requestVFMmsg = '';

  public get coverPageData() {
    return {
      title: 'AgeWage Test',
      subTitle: 'VALUE FOR MONEY REPORT',
    };
  }

  public get user(): User {
    return this.$store.getters.currentUser;
  }

  public get isSuperUser() {
    return this.providers && this.user && this.user.role === 'superuser';
  }

  public get isSampleReport() {
    return this.user && (this.user.role === 'bm_user' || this.isSuperUser);
  }

  public get page2Data() {
    return {
      title: 'Year Segmentation',
      subTitle: 'Monthly Report',
      avgIrr: '7%',
      underperform: '1%',
    };
  }

  public get outliers() {
    return this.sampleReport && this.sampleReport.outlierData.data;
  }

  public get outlierGroups() {
    return this.sampleReport && this.sampleReport.outlierData.outlierGroups;
  }

  public get totalOutliers() {
    return this.sampleReport && this.sampleReport.outlierData.totalOutlier;
  }

  public get totalMemberCount() {
    return this.sampleReport && this.sampleReport.outlierData.totalMemberCount;
  }

  public get outlierPageData() {
    return {
      outliers: this.outliers,
      outlierGroups: this.outlierGroups,
      totalOutlier: this.totalOutliers,
      totalMemberCount: this.totalMemberCount,
    };
  }

  public async bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Reports', tooltip: 'Export Reports' });
    await this.$store.dispatch(FETCH_SAMPLE_REPORT);
    this.currentFilters.provider = Number(this.sampleReport.analysisData.providerId);
    this.$store.dispatch(FETCH_CURRENT_USER);
  }

  public get chartData() {
    return this.sampleReport && this.sampleReport.analysisData;
  }

  public get fetchingSampleReport() {
    return this.$store.getters.fetchingSampleReport;
  }

  public get sampleReport() {
    return this.$store.getters.sampleReport;
  }

  public get sccAndIsyData() {
    if (!this.chartData) {
      return null;
    }

    return {
      // scheme: this.chartData.scheme,
      investments: this.chartData.investments,
    };
  }

  public get segmentData() {
    if (!this.chartData) {
      return null;
    }

    return this.chartData.segmentData;
  }

  public get irrAndIavData() {
    if (!this.chartData) {
      return null;
    }

    return {
      irr: this.chartData.irr,
      iav: this.chartData.assetImpactVal,
    };
  }

  public get ePotData() {
    if (!this.chartData) {
      return null;
    }

    return {
      ePot: this.chartData.potEffectVal,
    };
  }

  public get avbData() {
    if (!this.chartData) {
      return null;
    }

    return {
      avb: this.chartData.avb,
    };
  }

  public get fetchingChartData() {
    return this.$store.getters.fetchingChartData;
  }

  public get providers() {
    return this.$store && this.$store.getters.providers;
  }

  public async onProviderChange(providerId: any) {
    await this.$store.dispatch(FETCH_SAMPLE_REPORT);
  }

  public async setProvider(event: any) {
    await this.$store.dispatch(FETCH_SAMPLE_REPORT);
  }

  public async getVFMReport() {
    const result = await this.$store.dispatch(REQUEST_VFM_REPORT);
    if (result) {
      this.requestVFMSuccessDialog = true;
      this.requestVFMmsg =
        'Thank you for requesting the VFM report. The AgeWage team will shortly be in touch with you.';
    } else {
      this.requestVFMSuccessDialog = true;
      this.requestVFMmsg = 'Error sending request for the VFM report. Please try again.';
    }
  }

  handleScroll() {
    if (this.$refs.filterPanel && this.$refs.filterPanel instanceof Element) {
      const topPosition = this.$refs.filterPanel.getBoundingClientRect().top;
      if (topPosition == 0) {
        this.scrolledAll = true;
      } else {
        this.scrolledAll = false;
      }
    }
  }
}
</script>
<style lang="scss">
.filter-menu {
  background: #ffffff !important;
  .v-label {
    font-weight: 500;
  }
}

.filter-btn-active {
  background-color: #dfc3e6 !important;
}
.filter-btn-active .v-input__slot {
  background-color: #dfc3e6 !important;
}

.page-filters-wrapper {
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  position: sticky !important;
}

.page-filters-panel {
  &.vue-fixed-header--isFixed {
    background: #fff;
    margin-top: 0 !important;
    padding: 1rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  }
}
.list-reports-page {
  .truncate {
    width: 7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .back-btn:hover::before,
  .back-btn::before {
    opacity: 0 !important;
  }
}

.report-template {
  // padding: 0.5rem;
  margin-top: 0.5rem;
  position: relative;
  .report-page-card {
    .card-data {
      padding: 1rem 0rem;
      min-height: 70rem;
    }
    .top-triangle {
      position: absolute;
      right: 0;
      .top-right-color-triangle {
        position: absolute;
        right: 0;
        width: 0;
        height: 0;
        z-index: 1;
        border-style: solid;
        border-width: 0 200px 200px 0;
        border-color: transparent #dfc6e6 transparent transparent;
      }
      .top-right-grey-triangle {
        position: absolute;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 400px 200px 0;
        border-color: transparent #f5f5f5 transparent transparent;
      }
    }
    .footer {
      width: 100%;
      max-width: 90%;
      padding: 1.5rem 0;
      margin: auto;
      display: flex;
      justify-content: space-between;

      .page-box {
        padding: 0.2rem;
        width: 7rem;
        margin: auto 0;
        border-radius: 0.2rem;
        background-color: #a994b1;
        .pageno {
          text-align: center;
          color: #fff;
        }
      }
      .footer-img {
        max-width: 10rem;
      }
    }
    .report-page-title {
      margin: 0 auto;
      text-align: center;
      font-size: 2.5rem;
      font-weight: 500;
    }
    .report-page-subtitle {
      margin: 0 auto;
      text-align: center;
      font-size: 2rem;
      font-weight: 300;
    }

    .main-page-feature {
      margin-left: 5rem;
      margin-top: 10rem;
      .v-image {
        width: 30rem;
      }
      .feature-title {
        font-size: 7rem;
        font-weight: 500;
        // color: #e2af06;
      }
      .feature-sub-title {
        font-size: 1.7rem;
        font-weight: 500;
        color: #e2af06;
      }
    }
    .main-page-content {
      margin: 7rem 0 0 5rem;
      .main-page-content-title {
        font-size: 1.5rem;
        font-weight: 600;
        padding-top: 3rem;
        padding-bottom: 1rem;
      }
      .main-page-text {
        // margin: 1.5rem auto 0 auto;
        // text-align: center;
        font-size: 1rem;
        padding-bottom: 4rem;
        max-width: 90%;
        text-align: justify;
      }
    }
    .section {
      max-width: 80%;
      width: 100%;
      margin: auto;
      margin-top: 3rem;
      // text-align: center;
      .section-title {
        margin: 0 auto;
        font-size: 1.4rem;
        font-weight: 600;
        padding-bottom: 1rem;
        display: flex;
        color: #000;
      }
      .section-content {
        font-size: 1rem;
        margin-bottom: 3rem;
        text-align: justify;
      }
    }
    .section-badge {
      height: 30px;
      width: 7px;
      margin-right: 1rem;
      background-color: #796581;
    }
    .report-chart {
      margin: 0 auto;
      width: 100%;
      padding: 2rem 0rem;
      background: #dac5e221;
    }
    .chart-title {
      font-weight: 600;
      font-size: 1.2rem;
      text-align: left;
      padding: 2rem 0 1.7rem 2rem;
    }
    .chartWrapper {
      height: auto;
      width: 100%;
      max-width: 70rem;
      border-radius: 0.2rem;
      background: #fff;
      margin: 0 auto;
    }
    .text-blue {
      color: blue;
    }
  }
}
.provider-btn {
  position: relative !important;
  display: flex !important;
  padding: 0 !important;
  width: 95% !important;
  margin: 0 !important;
  flex: 1 1 auto;
  justify-content: flex-start;
  text-transform: none;
  padding-left: 6px !important;
  font-size: 16px !important;
  .v-btn__content {
    max-width: 90% !important;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left !important;
  }
}
</style>
