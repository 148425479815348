import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "data-testid": "edit-b2c-provider-page",
  class: "page edit-b2c-provider-page"
}
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { class: "text-h5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_AppUploader = _resolveComponent("AppUploader")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_layout, { class: "mt-4 pa-0" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, {
                    onSubmit: _withModifiers(_ctx.editProvider, ["prevent"]),
                    class: "py-5"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.currentProvider.id && _ctx.currentProvider.id !== '' ? 'Edit Provider' : 'Add Provider'), 1),
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_img, {
                                    src: _ctx.currentProvider.img ? _ctx.currentProvider.img : '',
                                    "max-height": "100px",
                                    "min-height": "100px",
                                    "max-width": "100%"
                                  }, null, 8, ["src"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("div", null, [
                            _createElementVNode("div", null, [
                              _createVNode(_component_v_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Provider Name")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_text_field, {
                                "hide-details": "",
                                variant: "underlined",
                                "persistent-hint": false,
                                placeholder: "Provider Name",
                                modelValue: _ctx.pName,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pName) = $event)),
                                class: "mb-5"
                              }, null, 8, ["modelValue"])
                            ])
                          ], 512), [
                            [_vShow, true]
                          ]),
                          _createVNode(_component_v_container, { "grid-list-md": "" }, {
                            default: _withCtx(() => [
                              (!_ctx.dataUploaded)
                                ? (_openBlock(), _createBlock(_component_v_layout, {
                                    key: 0,
                                    "data-testid": "Upload-Container",
                                    wrap: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, {
                                            cols: 12,
                                            class: "text-center mt-2"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_AppUploader, {
                                                "data-testid": "FileUpload",
                                                onAppUploader: _ctx.handleFile,
                                                disabled: _ctx.updatingProvider,
                                                fileType: "image/*"
                                              }, null, 8, ["onAppUploader", "disabled"])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (_ctx.dataUploaded)
                                ? (_openBlock(), _createBlock(_component_v_layout, {
                                    key: 1,
                                    wrap: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_alert, {
                                                modelValue: _ctx.dataUploaded,
                                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataUploaded) = $event)),
                                                closable: "",
                                                type: "success",
                                                class: "mb-4",
                                                prominent: ""
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode("Provider updated successfully.")
                                                ]),
                                                _: 1
                                              }, 8, ["modelValue"])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (_ctx.dataUploadError)
                                ? (_openBlock(), _createBlock(_component_v_layout, {
                                    key: 2,
                                    wrap: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_alert, {
                                                "data-testid": "FileUploadError",
                                                modelValue: _ctx.dataUploadError,
                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dataUploadError) = $event)),
                                                closable: "",
                                                type: "error",
                                                class: "mb-4",
                                                prominent: ""
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.validationError), 1)
                                                ]),
                                                _: 1
                                              }, 8, ["modelValue"])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_actions, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_spacer),
                          _createVNode(_component_v_btn, {
                            "data-testid": "SubmitUploadBtn",
                            color: _ctx.updatingProvider || _ctx.dataUploaded ? 'disabled' : 'success',
                            type: "submit",
                            variant: "flat",
                            loading: _ctx.updatingProvider,
                            disabled: _ctx.updatingProvider || _ctx.dataUploaded
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.currentProvider.id && _ctx.currentProvider.id !== '' ? 'Edit' : 'Add'), 1)
                            ]),
                            _: 1
                          }, 8, ["color", "loading", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onSubmit"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}