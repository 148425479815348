import { IRootState } from '@/store/state';
import { IProviderState } from '@/modules/providers/store/state';
import { GetterTree } from 'vuex';

/**
 * --------------------------------------------------
 *	Provider Getters
 * --------------------------------------------------
 **/
export const ProviderGetters: IProviderGetters = {
  providers: (state: any) => state.providers,
  providerWithPA: (state: any) => state.providerWithPA,
  provider: (state: any) => state.provider,
  allProviders: (state: any) => state.allProviders,
  fetchingProviders: (state: any) => state.fetchingProviders,
  fetchingProvider: (state: any) => state.fetchingProvider,
  fetchingDataQuality: (state: any) => state.fetchingDataQuality,
  providerDataQuality: (state: any) => state.providerDataQuality,
  fetchingMasterProviders: (state: any) => state.fetchingMasterProviders,
  masterProviders: (state: any) => state.masterProviders
};

/**
 * --------------------------------------------------
 *	Provider Getters Interface
 * --------------------------------------------------
 **/
export interface IProviderGetters extends GetterTree<IProviderState, IRootState> {}
