<template>
  <div class="report-template">
    <Watermark :isSampleReport="isSampleReport" />
    <v-card class="report-page-card" outlined flat>
      <div v-if="filters.segmentData" class="section">
        <div class="section-title">
          <div class="section-badge"></div>
          {{ this.title }} Segmentation
        </div>
        <div class="section-content">
          The performance of the savers has been separated by {{ this.title }} to analyse which segments are performing
          better. As the data sets are invested over different time periods, we have compared the segments against the
          benchmark to get meaningful analysis. The scores for different segments as well as the number of savers in
          each segment can be seen in the graph below. A significantly underperforming data set would warrant a closer
          look into the constituent elements.
        </div>
      </div>

      <div v-if="!fetchingChartData" class="report-chart">
        <NumberChart
          v-if="chartData"
          :type="type"
          :title="title"
          :chartData="chartData"
          :barColor="barColor"
          :provider="filters.provider"
          :isSampleReport="isSampleReport"
          :reportfilterRanges="reportfilterRanges"
        ></NumberChart>
      </div>
      <div v-else>
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <div class="footer">
        <div></div>
        <v-img class="footer-img" src="../../../../assets/aw-footer-logo.jpg"></v-img>
      </div>
    </v-card>
  </div>
</template>
<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { FETCH_MEMBERS, FETCH_CONTRIBUTION_HISTORY } from '@/modules/members/store/actionTypes';
import NumberChart from '@/components/charts/NumberChart.vue';
import Watermark from './Watermark.vue';

@Options({
  components: {
    NumberChart,
    Watermark,
  },
})
export default class DataSetSegmentation extends Vue {
  created() {
    this.bootstrap();
  }
  @Prop()
  public chartData?: any;

  @Prop()
  public title?: string;

  @Prop()
  public type?: string;

  @Prop()
  public reportfilterRanges?: string;

  @Prop()
  public data?: any;

  @Prop({
    default: () => ({
      awDist: true,
      yearSeg: true,
      Irr: true,
      avb: true,
      schemeSeg: true,
      outliers: true,
      startDate: null,
      endDate: null,
      provider: null,
    }),
  })
  filters?: any;

  @Prop() isSampleReport!: boolean;

  public barColor: any = {
    primary: '#a085bc',
    secondary: '#cbafd5',
  };

  public plotterColor: any = {
    primary: '#756287',
    secondary: '#cbafd5',
    shadow: '#eee6f1',
  };

  get moduleIsReady() {
    return true;
  }

  public get numberChartsData() {
    return this.chartData.numberGraphs;
  }

  public get stringChartsData() {
    return this.chartData.stringGraphs;
  }

  public get fetchingChartData() {
    return this.$store.getters.fetchingChartData;
  }

  bootstrap() {
    //
  }
}
</script>
