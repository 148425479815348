<template>
  <div data-testid="MemberModule">
    <router-view v-if="moduleIsReady" />
    <div v-else>Loading Members...</div>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { FETCH_MEMBERS, FETCH_CONTRIBUTION_HISTORY } from '@/modules/members/store/actionTypes';

export default class MemberModule extends Vue {
  created() {
    this.bootstrap();
  }

  get moduleIsReady() {
    return true;
  }

  bootstrap() {
    //
  }
}
</script>
