import BenchmarkModule from './BenchmarkModule.vue';
import BenchmarkList from './pages/BenchmarkList.vue';
import BenchmarkAdd from './pages/BenchmarkAdd.vue';

export const BenchmarkRoutes = [
  {
    path: '/benchmark/list',
    component: BenchmarkModule,
    children: [
      {
        path: '',
        name: 'dashboard.benchmark.list',
        component: BenchmarkList,
      },
    ],
  },
  {
    path: '/benchmark/add',
    component: BenchmarkModule,
    children: [
      {
        path: '',
        name: 'dashboard.benchmark.add',
        component: BenchmarkAdd,
      },
    ],
  },
];
