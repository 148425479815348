import * as MT from '@/modules/providers/store/mutationTypes';
import { IProviderState } from '@/modules/providers/store/state';
import { MutationTree } from 'vuex';
import Provider from '@/models/Provider';

/*--------------------------------------------------/
 * Provider Mutations
 *--------------------------------------------------*/
export const ProviderMutations: IProviderMutations = {
  [MT.SET_PROVIDERS](state: IProviderState, providers: Provider[]) {
    state.providers = providers;
  },
  [MT.SET_ALL_PROVIDERS](state: IProviderState, providers: Provider[]) {
    state.allProviders = providers;
  },
  [MT.SET_PROVIDERS_PA](state: IProviderState, providers: Provider[]) {
    state.providerWithPA = providers;
  },
  [MT.SET_PROVIDER](state: IProviderState, provider: Provider) {
    state.currentProvider = provider;
  },

  [MT.SET_FETCHING_PROVIDERS](state: IProviderState, value: boolean) {
    state.fetchingProviders = value;
  },

  [MT.SET_FETCHING_PROVIDER](state: IProviderState, value: boolean) {
    state.fetchingProvider = value;
  },
  [MT.SET_FETCHING_DATA_QUALITY](state: IProviderState, value: boolean) {
    state.fetchingDataQuality = value;
  },
  [MT.SET_PROVIDER_DATA_QUALITY](state: IProviderState, value: boolean) {
    state.providerDataQuality = value;
  },
  [MT.SET_MASTER_PROVIDERS](state: IProviderState, providers: Provider[]) {
    state.masterProviders = providers;
  },
  [MT.SET_FETCHING_MASTER_PROVIDER](state: IProviderState, value: boolean) {
    state.fetchingMasterProviders = value;
  }
};

/*--------------------------------------------------/
 * Provider Mutations Tree Interface
 *--------------------------------------------------*/
export interface IProviderMutations extends MutationTree<IProviderState> {}
