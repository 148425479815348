import MemberModule from './MemberModule.vue';
import ListMembersPage from './pages/ListMembersPage.vue';

export const MemberRoutes = [
  {
    path: '/members',
    component: MemberModule,
    children: [
      {
        path: '',
        name: 'dashboard.members.list',
        component: ListMembersPage
      }
    ]
  }
];
