import { AuthModuleStore } from '@/modules/auth/store';
import { UserStore } from '@/modules/users/store/index';
import { ProviderStore } from '@/modules/providers/store/index';
import { ProviderAnalysisStore } from '@/modules/providers-analysis/store/index';
// import { SchemeStore } from '@/modules/schemes/store/index';
import { MemberStore } from '@/modules/members/store/index';
// import { FundStore } from '@/modules/funds/store/index';
import { ReportStore } from '@/modules/reports/store/index';
import { B2CStore } from '@/modules/b2c-panel/store/index';
import { SimulationStore } from '@/modules/simulations/store/index';
import { ImportStore } from '@/modules/imports/store/index';

import { IRootState } from '@/store/state';
import { ModuleTree } from 'vuex';
import { BenchmarkingStore } from '@/modules/benchmarking/store/index';
import { PriceTrackStore } from '@/modules/price-track/store/index';
import { BenchmarkStore } from '@/modules/benchmarks/store/index';

/**
 * --------------------------------------------------
 *	Application Modules
 * --------------------------------------------------
 **/
export const AppModules: ModuleTree<IRootState> = {
  AuthModuleStore,
  UserStore,
  ProviderStore,
  ProviderAnalysisStore,
  // SchemeStore,
  MemberStore,
  // FundStore,
  BenchmarkingStore,
  ReportStore,
  B2CStore,
  SimulationStore,
  ImportStore,
  PriceTrackStore,
  BenchmarkStore
};
