import { IRootState } from '@/store/state';
import { IAuthState } from '@/modules/auth/store/state';
import { GetterTree } from 'vuex';
import User from '@/models/User';

/**
 * --------------------------------------------------
 *	Auth Getters
 * --------------------------------------------------
 **/
export const AuthGetters: IAuthGetters = {
  // Log In
  isLoggingIn: (state: IAuthState): boolean => state.loggingIn,
  loggingInError: (state: IAuthState): boolean | object => state.loggingInError,
  // Current User
  currentUser: (state: IAuthState): User | boolean => state.currentUser,
  isFetchingCurrentUser: (state: IAuthState): boolean => state.fetchingCurrentUser,
  fetchCurrentUserError: (state: IAuthState): boolean | object => state.fetchCurrentUserError,
  sessionTimeout: (state: IAuthState): boolean => state.timeout,
  // Password reset
  isChangingPassword: (state: IAuthState): boolean => state.isChangingPassword,
  changePasswordError:(state: IAuthState): boolean | object | string => state.changePasswordError,
};

/**
 * --------------------------------------------------
 *	Auth Getters Interface
 * --------------------------------------------------
 **/
export interface IAuthGetters extends GetterTree<IAuthState, IRootState> {}
