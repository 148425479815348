<template>
  <div data-testid="list-b2c-users-page" class="page list-b2c-users-page">
    <v-container fluid>
      <v-layout class="mt-4 pa-0">
        <div style="align-self: center">
          <h2 class="section-title">
            <span>ALL USERS</span>
          </h2>
        </div>
        <v-layout align-end class="py-2 justify-end">
          <v-btn class="mx-4" @click="downloadUsers" :loading="exportingUsers" :disabled="exportingUsers">
            <span>Download User Details</span>
          </v-btn>
        </v-layout>
      </v-layout>
      <v-card v-if="!fetchingUsers || users" class="mt-4">
        <AppTable
          :table-data="tableData"
          :loading="fetchingUsers"
          v-if="users && users.length"
          v-on:viewItemClick="(e) => editUser(e)"
          :disablePagination="true"
        />
      </v-card>
      <div v-if="fetchingUsers && !users" class="d-flex align-center justify-center pt-5 mt-5">
        <v-progress-circular indeterminate color="grey" />
      </div>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppTable from '@/components/AppTable.vue';
import { Table, DataTableHeader, TableRow, TableColumnType } from '@/types';
import { FETCH_USERS, EXPORT_USERS } from '@/modules/b2c-panel/store/actionTypes';
import { SET_NAV_HEADER } from '@/store/actionTypes';

@Options({
  components: {
    AppTable,
  },
})
export default class UsersPage extends Vue {
  created() {
    this.bootstrap();
  }

  public bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Users', tooltip: 'All Users' });
    this.fetchUsers();
  }

  get exportingUsers() {
    return this.$store.getters.exportingUsers;
  }

  get fetchingUsers() {
    return this.$store.getters.fetchingUsers;
  }

  get users() {
    return this.$store.getters.usersB2C;
  }

  public async downloadUsers() {
    const url = await this.$store.dispatch(EXPORT_USERS);
    if (url && url.length) {
      window.open(url);
    }
  }
  public get tableData(): Table {
    const columns: DataTableHeader[] = [
      //  {
      //   value: 'id',
      //   text: 'ID',
      //   type: TableColumnType.STRING
      // },
      {
        value: 'email',
        title: 'Email',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'firstName',
        title: 'First Name',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'lastName',
        title: 'Last Name',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'createdAt',
        title: 'Creation Time',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'userType',
        title: 'User Product',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'view',
        title: 'View',
        type: TableColumnType.ACTIONS,
        align: 'center',
      },
    ];
    return {
      columns,
      rows: this.rows,
    };
  }

  public get rows(): TableRow[] {
    return (
      this.users &&
      this.users.map((user: any, it: number) => {
        return {
          id: user.id,
          lastName: user.lastName,
          firstName: user.firstName,
          email: user.email,
          createdAt: user.date,
          userType: user.type,
        };
      })
    );
  }

  public get filterVisibility(): boolean {
    return this.$store.getters.filterIsVisible;
  }

  public fetchUsers(data: any = {}) {
    this.$store.dispatch(FETCH_USERS, data);
  }
  public editUser(id: String) {
    this.$router.push(`/b2cpanel/user/${id}`);
  }
}
</script>
