<template>
  <div>
    <!-- {{outliers}} -->
    <table id="outliersTable">
      <thead>
        <tr>
          <th>Saver ID</th>
          <th>Actual IRR</th>
          <th>Benchmark IRR (%)</th>
          <th>Remarks</th>
        </tr>
      </thead>

      <tr
        :key="outlier.dataId"
        v-for="outlier in outliers"
      >
        <td>{{ outlier.dataId }}</td>
        <td>{{ outlier.irr }}</td>
        <td>{{ outlier.benchmarkIrr }}</td>
        <td>{{ outlier.reason }}</td>

      </tr>
    </table>

  </div>
</template>

<script lang="ts">
import { Vue, Prop, Watch } from 'vue-property-decorator';
export default class ReportTable extends Vue {
  @Prop()
  public outliers?: any[];
}
</script>




<style>
#outliersTable {
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 80%;
  max-width: 100%;
  margin: auto;
}

#outliersTable td,
#outliersTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

#outliersTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

#outliersTable tr:hover {
  background-color: #ddd;
}

#outliersTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #756287;
  color: white;
}
</style>


