import * as MT from '@/modules/auth/store/mutationTypes';
import { IAuthState } from '@/modules/auth/store/state';
import { MutationTree } from 'vuex';
import User from '@/models/User';

/*--------------------------------------------------/
 * Auth Mutations
 *--------------------------------------------------*/
export const AuthMutations: IAuthMutations = {
  // Log In
  [MT.SET_LOGIN_ERROR]: (state: IAuthState, error: boolean) => (state.loggingInError = error),
  [MT.SET_LOGGING_IN]: (state: IAuthState, inProgress: boolean) => (state.loggingIn = inProgress),
  // Current User
  [MT.SET_CURRENT_USER]: (state: IAuthState, user: User) => (state.currentUser = user),
  [MT.SET_FETCHING_CURRENT_USER]: (state: IAuthState, loading: boolean) => (state.fetchingCurrentUser = loading),
  [MT.SET_FETCH_CURRENT_USER_ERROR]: (state: IAuthState, error: boolean) => (state.fetchCurrentUserError = error),

  // Session Timeout
  [MT.SET_SESSION_TIME_OUT]: (state: IAuthState, status: boolean) => (state.timeout = status),
  [MT.SET_CHANGING_PASSWORD]: (state: IAuthState, status: boolean) => (state.isChangingPassword = status),
  [MT.SET_CHANGE_PASSWORD_ERROR]: (state: IAuthState, error: boolean) => (state.changePasswordError = error),
};

/*--------------------------------------------------/
 * Auth Mutations Tree Interface
 *--------------------------------------------------*/
export interface IAuthMutations extends MutationTree<IAuthState> {
  // Log In
  [MT.SET_LOGIN_ERROR](state: IAuthState, error: boolean): void;
  [MT.SET_LOGGING_IN](state: IAuthState, inProgress: boolean): void;
  // Current User
  [MT.SET_CURRENT_USER](state: IAuthState, user: User): void;
  [MT.SET_FETCHING_CURRENT_USER](state: IAuthState, loading: boolean): void;
  [MT.SET_FETCH_CURRENT_USER_ERROR](state: IAuthState, error: boolean): void;

  // Session Timeout
  [MT.SET_SESSION_TIME_OUT](state: IAuthState, status: boolean): void;
  [MT.SET_CHANGING_PASSWORD](state: IAuthState, status: boolean): void;
  [MT.SET_CHANGE_PASSWORD_ERROR](state: IAuthState, error: boolean): void;
}
