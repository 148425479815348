import { IRootState } from '@/store/state';
import { ISimulationState } from '@/modules/simulations/store/state';
import { GetterTree } from 'vuex';
import { Simulation, SimulationFull } from '@/models/Simulation';

/**
 * --------------------------------------------------
 *	Simulation Getters
 * --------------------------------------------------
 **/
export const SimulationGetters: ISimulationGetters = {
  simulations: (state: ISimulationState) => state.simulations,
  addingSimulation: (state: ISimulationState) => state.addingSimulation,
  currentSimulation: (state: ISimulationState) => state.currentSimulation,
  gettingStandardDeviations: (state: ISimulationState) => state.gettingStandardDeviations
};

/**
 * --------------------------------------------------
 *	Simulation Getters Interface
 * --------------------------------------------------
 **/
export interface ISimulationGetters extends GetterTree<ISimulationState, IRootState> {
  simulations(state: ISimulationState): Simulation[];
  addingSimulation(state: ISimulationState): boolean;
  currentSimulation(state: ISimulationState): SimulationFull;
  gettingStandardDeviations(state: ISimulationState): boolean;
}
