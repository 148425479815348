<template>
  <div class="elevation-10 chartWrapper">
    <div class="chart-title text-center pb-8">IRR - Regular Contribution</div>
    <BaseChart :options="chartOptions" :scrollOptions="scrollOptions" />
  </div>
</template>

<script>
import BaseChart from './BaseChart.vue';
export default {
  components: {
    BaseChart
  },
  props: ['chartData'],
  data() {
    return {
      scrollOptions: {
        chartHeight: '40',
        unit: 'rem',
      },
      chartOptions: this.editChartData()
    };
  },
  methods: {
    editChartData() {
      const chartOptions = this.chartData;
      if (chartOptions) {
        chartOptions.yAxis3D.axisLabel.formatter = v => {
          v = parseInt(v);
          return Math.floor(v / 12);
        };

         chartOptions.zAxis3D.axisLabel.formatter = v => {
          v = parseInt(v);
          if(v !== chartOptions.zAxis3D.max && v !== chartOptions.zAxis3D.min){
            return v;
          }
        };
      }
      return chartOptions;
    }
  }
};
</script>
