import {
  SET_REPORT_OPTIONS,
  SET_REPORTS,
  SET_FETCHING_REPORTS,
  SET_OUTLIERS,
  SET_PERFORMANCE_ATTRIBUTION,
  SETTING_PA_FREE_TEXT,
  ADD_PA_FREE_TEXT,
  FETCHING_OUTLIER_ANALYSIS,
  SET_FETCHING_SAMPLE_REPORTS,
  SET_SAMPLE_REPORT
} from '@/modules/reports/store/mutationTypes';
import { IReportState } from '@/modules/reports/store/state';
import { MutationTree } from 'vuex';

/*--------------------------------------------------/
 * Report Mutations
 *--------------------------------------------------*/
export const ReportMutations: IReportMutations = {
  [SET_REPORT_OPTIONS](state: any, options: any) {
    state.reportOptions = options;
  },
  [SET_REPORTS](state: any, data: any) {
    state.reports = data;
  },
  [SET_OUTLIERS](state: any, data: any) {
    state.outliers = data;
  },
  [SET_FETCHING_REPORTS](state: any, status: any) {
    state.fetchingReports = status;
  },
  [SET_PERFORMANCE_ATTRIBUTION](state: any, data: any) {
    state.performanceAttribution = data;
  },
  [SETTING_PA_FREE_TEXT](state: any, data: any) {
    state.addingFreeText = data;
  },
  [ADD_PA_FREE_TEXT](state: any, data: any) {
    state.addedFreeText = data;
  },
  [FETCHING_OUTLIER_ANALYSIS](state: any, status: any) {
    state.fetchingOutliers = status;
  },
  [SET_FETCHING_SAMPLE_REPORTS](state: any, status: any) {
    state.fetchingSampleReport = status;
  },
  [SET_SAMPLE_REPORT](state: any, data: any) {
    state.sampleReport = data;
  }
};

/*--------------------------------------------------/
 * Report Mutations Tree Interface
 *--------------------------------------------------*/
export interface IReportMutations extends MutationTree<IReportState> {}
