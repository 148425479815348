import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-testid": "DashboardModule",
  class: "page dashboard-page pb-7"
}
const _hoisted_2 = { class: "px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardDrawer = _resolveComponent("DashboardDrawer")!
  const _component_DashboardToolbar = _resolveComponent("DashboardToolbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_DataUploadDialog = _resolveComponent("DataUploadDialog")!

  return (_ctx.moduleIsReady)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_DashboardDrawer),
        _createVNode(_component_v_main, { class: "content-view" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, { fluid: _ctx.navDrawerIsVisible }, {
              default: _withCtx(() => [
                _createVNode(_component_DashboardToolbar, {
                  user: _ctx.user,
                  "show-upload-button": _ctx.isAdmin
                }, null, 8, ["user", "show-upload-button"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_router_view)
                ])
              ]),
              _: 1
            }, 8, ["fluid"])
          ]),
          _: 1
        }),
        _createVNode(_component_DataUploadDialog)
      ]))
    : _createCommentVNode("", true)
}