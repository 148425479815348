import axios, { AxiosInstance } from 'axios';
import Config from '@/config';

/**
 * --------------------------------------------------
 *	HTTP Client Interface
 * --------------------------------------------------
 **/
export interface IHttpClient extends AxiosInstance {}

/**
 * --------------------------------------------------
 *	Http Client Instance
 * --------------------------------------------------
 **/
const HttpClient = axios.create({
  baseURL: Config.getApiUrl()
});

export { HttpClient };
