<template>
  <div
    data-testid="list-reports-page"
    class="page list-reports-page"
  >
    <router-view></router-view>
    <v-container>
      <v-layout class="mt-4 pa-0">
        <v-flex align-self-center>
          <h2 class="section-title">
            <span>ALL REPORTS</span>
          </h2>
        </v-flex>
        <v-layout
          align-end
          justify-end
        >
          <v-btn
            class="filter-btn elevation-6"
            :ripple="false"
            data-testid="FilterBtn"
            text
            v-if="true"
            to="/reports/generate"
          >
            <v-icon small>fas fa-plus</v-icon>
          </v-btn>
        </v-layout>
      </v-layout>

      <v-card
        v-if="!fetchingReports || reports"
        class="mt-4"
      >
        <AppTable
          data-testid="ReportTable"
          :table-data="tableData"
          :loading="fetchingReports"
          :striped="true"
        />
      </v-card>
      <div
        v-if="fetchingReports && !reports"
        class="d-flex align-center justify-center pt-5 mt-5"
      >
        <v-progress-circular
          indeterminate
          color="grey"
        />
      </div>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppTable from '@/components/AppTable.vue';
import { Table, DataTableHeader, TableRow, TableColumnType } from '@/types';
import { HTTPServiceClient } from '@/packages/http/service';
import FilterTable from '@/components/FilterTable.vue';
import { FETCH_REPORTS } from '@/modules/reports/store/actionTypes';
import { TOGGLE_FILTER_TABLE, SET_NAV_HEADER } from '@/store/actionTypes';

@Options({
  components: {
    AppTable,
    FilterTable
  }
})
export default class ListReportsPage extends Vue {
  created() {
    this.bootstrap();
  }

  public bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, 'Reports');
    // this.$store.dispatch(FETCH_REPORTS);
    this.fetchReports();
  }

  get fetchingReports() {
    return this.$store.getters.fetchingReports;
  }

  get outliers() {
    return this.$store.getters.outliers;
  }
  get reports() {
    return this.$store.getters.reports;
  }

  public get tableData(): Table {
    const columns: DataTableHeader[] = [
      {
        value: 'id',
        title: '#',
        type: TableColumnType.STRING
      },
      {
        value: 'name',
        title: 'Report',
        type: TableColumnType.STRING
      },
      {
        value: 'status',
        title: 'Status',
        type: TableColumnType.STRING
      },
      {
        value: 'download',
        title: 'Download',
        type: TableColumnType.STRING
      }
    ];
    return {
      columns,
      rows: this.rows
    };
  }

  public get rows(): TableRow[] {
    return (
      this.reports &&
      this.reports.map((report: any, it: number) => {
        return {
          id: report.id,
          name: report.name,
          status: report.status,
          download: report.download
        };
      })
    );
  }

  public get filterVisibility(): boolean {
    return this.$store.getters.filterIsVisible;
  }

  public toggleFilter() {
    this.$store.dispatch(TOGGLE_FILTER_TABLE);
  }

  public fetchReports() {
    this.$store.dispatch(FETCH_REPORTS);
  }
}
</script>
