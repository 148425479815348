import * as MT from '@/modules/simulations/store/mutationTypes';
import { ISimulationState } from '@/modules/simulations/store/state';
import { MutationTree } from 'vuex';
import { Simulation, SimulationFull } from '@/models/Simulation';

/*--------------------------------------------------/
 * Simulation Mutations
 *--------------------------------------------------*/
export const SimulationMutations: ISimulationMutations = {
  [MT.SET_SIMULATIONS](state: ISimulationState, simulations: Simulation[]) {
    state.simulations = simulations;
  },
  [MT.SET_SIMULATION](state: ISimulationState, simulation: SimulationFull) {
    state.currentSimulation = simulation;
  },
  [MT.ADDING_SIMULATION](state: ISimulationState, addingSimulation: boolean) {
    state.addingSimulation = addingSimulation;
  },
  [MT.GETTING_STANDARD_DEVIATIONS](state: ISimulationState, gettingStandardDeviations: boolean) {
    state.gettingStandardDeviations = gettingStandardDeviations;
  },
};

/*--------------------------------------------------/
 * Simulation Mutations Tree Interface
 *--------------------------------------------------*/
export interface ISimulationMutations extends MutationTree<ISimulationState> {}
