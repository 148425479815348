<template>
  <div data-testid="list-b2c-imports-page" class="page list-b2c-imports-page">
    <v-container fluid>
      <v-layout class="mt-4 pa-0">
        <div style="align-self: center">
          <h2 class="section-title">
            <span>ALL Imports</span>
          </h2>
        </div>
        <v-layout align-end class="py-2 justify-end">
          <v-btn @click="showUploadDialog = true">
            <span>Upload Users Information</span>
          </v-btn>
          <v-dialog v-model="showUploadDialog" max-width="600px">
            <v-card>
              <v-container grid-list-md>
                <v-layout data-testid="Upload-Container" v-if="!addingScore" wrap>
                  <v-row>
                    <v-col :cols="12" class="text-center mt-2">
                      <AppUploader
                        data-testid="FileUpload"
                        @app-uploader="handleFile"
                        :disabled="addingScore"
                        fileType="*"
                      />
                    </v-col>
                  </v-row>
                </v-layout>
              </v-container>
              <v-card-actions>
                <v-btn @click="startUpload" :loading="addingScore" variant="flat"> Upload </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-layout>
      <v-card v-if="!fetchingImports || imports" class="mt-4">
        <AppTable
          :table-data="tableData"
          :loading="fetchingImports"
          v-if="imports && imports.length"
          v-on:viewItemClick="(e) => showImport(e)"
          :disablePagination="true"
        />
      </v-card>
      <div v-if="fetchingImports && !imports" class="d-flex align-center justify-center pt-5 mt-5">
        <v-progress-circular indeterminate color="grey" />
      </div>
    </v-container>

    <v-dialog v-model="showImportDetailsDialog" width="50%">
      <v-card v-if="currentImportID.id" class="pa-2">
        <v-card-title> {{ currentImportID.id }}. {{ currentImportID.status }} </v-card-title>
        <v-card-text>
          {{ currentImportID.details }}
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="gettingContributionsData" @click="exportContributionCSV" variant="flat" color="green">
            Download Contributions
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else> LOADING... </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.model"
      :color="snackbar.color ? snackbar.color : ''"
      bottom
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import AppTable from '@/components/AppTable.vue';
import { Table, DataTableHeader, TableRow, TableColumnType } from '@/types';
import { FETCH_IMPORTS, ADD_SCORE_BULK, EXPORT_B2C_CONTRIBUTIONS } from '@/modules/b2c-panel/store/actionTypes';
import { SET_NAV_HEADER } from '@/store/actionTypes';
import AppUploader from '@/components/AppUploader.vue';

@Options({
  components: {
    AppTable,
    AppUploader,
  },
})
export default class Imports extends Vue {
  public file: any = null;
  public showImportDetailsDialog: boolean = false;
  public currentImportID: any = {};
  public showUploadDialog: boolean = false;

  public snackbar: any = {
    model: false,
    text: '',
    color: 'red',
    timeout: 2000,
  };

  created() {
    this.bootstrap();
  }

  public bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Imports', tooltip: 'All Imports' });
    this.fetchImports();
  }

  get gettingContributionsData() {
    return this.$store.getters.gettingContributionsData;
  }

  get fetchingImports() {
    return this.$store.getters.fetchingImports;
  }

  get addingScore() {
    return this.$store.getters.addingScore;
  }

  get imports() {
    return this.$store.getters.importsB2C;
  }

  public get tableData(): Table {
    const columns: DataTableHeader[] = [
      {
        value: 'id',
        title: 'ID',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'updatedAt',
        title: 'Last Updated',
        type: TableColumnType.DATE,
        align: 'center',
      },
      {
        value: 'status',
        title: 'Current Status',
        type: TableColumnType.STRING,
        align: 'center',
      },
      {
        value: 'view',
        title: 'View',
        type: TableColumnType.ACTIONS,
        align: 'center',
      },
    ];
    return {
      columns,
      rows: this.rows,
    };
  }

  public get rows(): TableRow[] {
    return (
      this.imports &&
      this.imports.map((importDetails: any, it: number) => {
        return {
          id: importDetails.id,
          updatedAt: importDetails.updatedAt,
          status: importDetails.status,
        };
      })
    );
  }

  public get filterVisibility(): boolean {
    return this.$store.getters.filterIsVisible;
  }

  public fetchImports(data: any = {}) {
    this.$store.dispatch(FETCH_IMPORTS, data);
  }
  public showImport(id: string) {
    this.imports.forEach((element: any) => {
      if (element.id === id) {
        this.currentImportID = element;
      }
    });
    this.showImportDetailsDialog = true;
  }

  public handleFile(data: any) {
    this.file = data.file;
  }

  public async startUpload() {
    if (!this.file) {
      this.snackbar.text = 'Please input some file';
      this.snackbar.model = true;
      this.snackbar.color = 'red';
      this.snackbar.timeout = 5000;
      return;
    }
    let formData = new FormData();
    formData.append('file', this.file);
    await this.$store.dispatch(ADD_SCORE_BULK, formData);
    this.showUploadDialog = false;
  }

  public async exportContributionCSV() {
    const url = await this.$store.dispatch(EXPORT_B2C_CONTRIBUTIONS, this.currentImportID.id);
    if (url && url.length) {
      window.open(url);
    } else {
      this.snackbar.text = 'Could not download data';
      this.snackbar.model = true;
      this.snackbar.color = 'red';
      this.snackbar.timeout = 5000;
    }
  }
}
</script>
