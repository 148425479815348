<template>
  <div data-testid="list-reports-page" class="page list-reports-page">
    <div>
      <div ref="filterPanel" class="mt-10 page-filters-wrapper mb-6">
        <div
          :threshold="filterPanelPosition"
          class="navbar page-filters-panel pos-relative justify-space-between"
          :class="scrolledAll ? 'vue-fixed-header--isFixed' : ''"
        >
          <v-layout>
            <v-row no-gutters align="end" justify="space-between">
              <!-- Date Filter -->
              <v-col class="d-flex" cols="12" sm="9">
                <!-- Provider Filter -->
                <v-row>
                  <v-col cols="3" align-self="center">
                    <v-label>Provider</v-label>
                    <v-autocomplete
                      :model-value="currentFilters.provider"
                      :items="providers"
                      item-title="name"
                      item-value="id"
                      label="Provider"
                      variant="solo"
                      :disabled="fetchingChartData"
                      hide-details
                      density="compact"
                      class="mt-2 filter-btn-active"
                      single-line
                      @update:modelValue="
                        (e) => {
                          onProviderChange(e);
                        }
                      "
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <!-- End Buttons -->
              <v-col class="d-flex justify-end my-2" cols="12" sm="3">
                <v-btn
                  v-if="isSuperUser"
                  class="filter-btn elevation-6 mt-0 mx-2"
                  :ripple="false"
                  data-testid="ShowData"
                  variant="text"
                  :disabled="fetchingChartData"
                  @click="showDetails = true"
                >
                  <div class="mx-2">Show Details</div>
                </v-btn>
                <v-btn
                  class="filter-btn back-btn elevation-6 mt-0 mr-2"
                  :ripple="false"
                  v-if="false"
                  variant="text"
                  to="/reports"
                >
                  <v-icon small>fas fa-arrow-left</v-icon>
                </v-btn>
                <v-btn
                  class="filter-btn elevation-6 mt-0 mx-2"
                  :ripple="false"
                  data-testid="ExportReportBtn"
                  variant="text"
                  :disabled="fetchingChartData"
                  :loading="processingReport"
                  @click="exportReport"
                >
                  <div class="mx-2">Export</div>
                  <v-icon small>fas fa-file-pdf</v-icon>
                </v-btn>
                <v-menu
                  content-class="filter-content"
                  v-model="filterIsVisible"
                  :nudge-bottom="15"
                  :close-on-content-click="false"
                  left
                  offset="20"
                  transition="slide-y-transition"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="filter-btn elevation-6 mt-0"
                      data-testid="FilterBtn"
                      color="primary"
                      variant="text"
                      outlined
                      :disabled="fetchingChartData"
                      v-bind="props"
                      small
                    >
                      <v-icon small>fas fa-filter</v-icon>
                    </v-btn>
                  </template>

                  <!-- Filter Menu -->
                  <div class="filter-menu">
                    <v-list>
                      <v-list-item
                        :class="currentFilters.priceTrackChart ? 'v-list-item--active' : ''"
                        class="filter-list"
                        @click="updateFilter('priceTrackChart')"
                      >
                        <v-list-item-title>Price Track Comparison</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :class="currentFilters.irrSingle ? 'v-list-item--active' : ''"
                        class="filter-list"
                        @click="updateFilter('irrSingle')"
                      >
                        <v-list-item-title>IRR Single Contribution</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :class="currentFilters.irrSingleHeatmap ? 'v-list-item--active' : ''"
                        class="filter-list"
                        @click="updateFilter('irrSingleHeatmap')"
                      >
                        <v-list-item-title>IRR Single Heatmap</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :class="currentFilters.irrSingle3d ? 'v-list-item--active' : ''"
                        class="filter-list"
                        @click="updateFilter('irrSingle3d')"
                      >
                        <v-list-item-title>IRR Single Contribution 3D</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :class="currentFilters.irrRegular ? 'v-list-item--active' : ''"
                        class="filter-list"
                        @click="updateFilter('irrRegular')"
                      >
                        <v-list-item-title>IRR Regular Contribution</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :class="currentFilters.irrRegularHeatmap ? 'v-list-item--active' : ''"
                        class="filter-list"
                        @click="updateFilter('irrRegularHeatmap')"
                      >
                        <v-list-item-title>IRR Regular Heatmap</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :class="currentFilters.irrRegular3d ? 'v-list-item--active' : ''"
                        class="filter-list"
                        @click="updateFilter('irrRegular3d')"
                      >
                        <v-list-item-title>IRR Regular Contribution 3D</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :class="currentFilters.effectOfContrib ? 'v-list-item--active' : ''"
                        class="filter-list"
                        @click="updateFilter('effectOfContrib')"
                      >
                        <v-list-item-title>Effect Of Contributions</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :class="currentFilters.actualIrrChart ? 'v-list-item--active' : ''"
                        class="filter-list"
                        @click="updateFilter('actualIrrChart')"
                      >
                        <v-list-item-title>Portfolio IRR</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :class="currentFilters.regressionChart ? 'v-list-item--active' : ''"
                        class="filter-list"
                        @click="updateFilter('regressionChart')"
                      >
                        <v-list-item-title>Regression Chart</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-menu>
              </v-col>
            </v-row>
          </v-layout>
        </div>
      </div>

      <v-dialog v-model="showDetails" width="50%">
        <v-card v-if="performanceAttributionData">
          <v-card-text style="color: #0000008a">
            Beta : {{ performanceAttributionData.Beta }} <br />
            Alpha : {{ performanceAttributionData.SAAValue }}<br />
            Final Price Tracker : {{ performanceAttributionData.finalPriceTracker }}<br />
            Correct Prices, Wrong Prices, Wrong Members Included, Members Contributed To Price Calculation:
            {{ performanceAttributionData.correctPrices }} Complete Price Tracker(Run again if not available) :
            {{ performanceAttributionData.priceTracker }}<br />
          </v-card-text>
        </v-card>
        <v-card v-else> LOADING... </v-card>
      </v-dialog>
      <div>
        <PerformanceAttribution
          v-if="!fetchingReports && this.providers && this.providers.length > 0 && performanceAttributionData"
          :reportData="performanceAttributionData"
          :providerId="currentFilters.provider"
          :currentFilters="currentFilters"
        />
        <div v-else-if="fetchingReports" class="d-flex align-center justify-center pt-5 mt-5">
          <v-progress-circular indeterminate color="grey" />
        </div>
        <div v-else>
          <v-card>
            <v-card-title class="justify-center">No Data Available.</v-card-title>
          </v-card>
        </div>
      </div>
    </div>
    <v-snackbar v-model="pdfTimeoutError" :timeout="3000" color="primary" location="top">
      <h3>Error downloading requested pdf. Please try again.</h3>
      <v-btn color="blue" variant="text" class="d-flex ml-auto" @click="pdfTimeoutError = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Vue, Prop } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { SET_NAV_HEADER } from '@/store/actionTypes';
import { FETCH_CHARTDATA, FETCH_ANALYSIS_BY_ID } from '@/modules/providers-analysis/store/actionTypes';
import {
  FETCH_PA_REPORT_URL,
  GENERATE_REPORT,
  FETCH_OUTLIER_ANALYSIS,
  GET_PDF_URL,
  FETCH_PERFORMANCE_ATTRIBUTION,
  GET_EXPORT_JOB_BY_ID,
} from '@/modules/reports/store/actionTypes';
import { FETCH_CURRENT_USER } from '@/modules/auth/store/actionTypes';
import User from '@/models/User';
import * as AT from '@/modules/members/store/actionTypes';
// import FixedHeader from 'vue-fixed-header';
import PerformanceAttribution from './templates/PerformanceAttribution.vue';
import axios from 'axios';
import Utils from '@/utils/Utils';

@Options({
  components: {
    // FixedHeader,
    PerformanceAttribution,
  },
})
export default class PAReportPage extends Vue {
  created() {
    this.bootstrap();
  }

  public showDetails = false;

  public fetchingReport = false;

  public processingReport = false;

  public filterIsVisible = false;

  public filtersAreApplied: boolean = false;

  public filterPanelPosition = 200;

  public displayAttribution = false;

  public capitalizeFirstLetterEveryWord = Utils.capitalizeLetter;

  public pdfTimeoutError = false;

  public scrolledAll: Boolean = false;

  public currentFilters: any = {
    provider: this.providers && this.providers.length > 0 ? this.providers[0].id : null,
    priceTrackChart: true,
    irrSingle: true,
    irrSingleHeatmap: true,
    irrSingle3d: true,
    irrRegular: true,
    irrRegularHeatmap: true,
    irrRegular3d: true,
    effectOfContrib: true,
    actualIrrChart: true,
    regressionChart: true,
  };

  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  mounted() {
    if (!this.currentFilters.provider) {
      this.currentFilters.provider = this.providers && this.providers.length > 0 ? this.providers[0].id : null;
    }
  }

  public get user(): User {
    return this.$store.getters.currentUser;
  }

  public get isSuperUser() {
    return this.providers && this.user && this.user.role === 'superuser';
  }

  public get isPerformanceAttribution() {
    return true;
  }

  public showAttributionReport() {
    this.$store.dispatch(FETCH_PERFORMANCE_ATTRIBUTION, { providerID: this.currentFilters.provider });
    this.displayAttribution = true;
  }

  public get selectedProvider() {
    const id = this.currentFilters && this.currentFilters.provider;
    if (!id) {
      return null;
    }

    return this.providers && this.providers.find((p: any) => p.id === id);
  }

  public async exportReport() {
    this.processingReport = true;
    const job = await this.$store.dispatch(FETCH_PA_REPORT_URL, this.currentFilters);
    let pdfFetchInterval = setInterval(async () => {
      let response = await this.$store.dispatch(GET_EXPORT_JOB_BY_ID, job.id);
      if (response[0].status === 'completed') {
        const url = await this.$store.dispatch(GET_PDF_URL, job.id);
        await window.open(url);
        this.processingReport = false;
        clearInterval(pdfFetchInterval);
      }
      return response;
    }, Utils.pdfInterval);
    setTimeout(() => {
      clearInterval(pdfFetchInterval);
      if (this.processingReport) {
        this.pdfTimeoutError = true;
      }
      this.processingReport = false;
    }, Utils.pdfTimeout);
  }

  public async bootstrap() {
    this.$store.dispatch(SET_NAV_HEADER, { title: 'Reports', tooltip: 'Export Reports' });
    const providerId = this.providers && this.providers.length > 0 ? this.providers[0].id : null;
    if (providerId) {
      this.currentFilters.provider = providerId;
      this.$store.dispatch(FETCH_PERFORMANCE_ATTRIBUTION, { providerID: providerId });
      this.displayAttribution = true;
    }
    this.$store.dispatch(FETCH_CURRENT_USER);
  }

  public get chartData() {
    return this.$store.getters.chartData;
  }

  public get performanceAttributionData() {
    return this.$store.getters.performanceReport;
  }

  public get fetchingReports() {
    return this.$store.getters.fetchingReports;
  }

  public get fetchingChartData() {
    return this.$store.getters.fetchingChartData;
  }

  public get providers() {
    return this.$store && this.$store.getters.providerWithPA;
  }

  public async onProviderChange(providerId: any) {
    this.currentFilters.provider = providerId;
    this.displayAttribution = false;
    this.$store.dispatch(FETCH_PERFORMANCE_ATTRIBUTION, { providerID: this.currentFilters.provider });
    this.displayAttribution = true;
  }

  public async updateFilter(filterVal: string) {
    this.currentFilters[filterVal] = !this.currentFilters[filterVal];
  }

  handleScroll() {
    if (this.$refs.filterPanel && this.$refs.filterPanel instanceof Element) {
      const topPosition = this.$refs.filterPanel.getBoundingClientRect().top;
      if (topPosition == 0) {
        this.scrolledAll = true;
      } else {
        this.scrolledAll = false;
      }
    }
  }
}
</script>
<style lang="scss">
.filter-menu {
  max-height: 500px;
  overflow: scroll;
  .v-label {
    font-weight: 500;
  }
  .filter-list {
    margin: 10px;
    cursor: pointer;
  }
  .v-list-item--active:before {
    opacity: 0.12 !important;
    background-color: #300078 !important;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  .v-list-item--active .v-list-item__overlay {
    background: none !important;
  }
}

.filter-content {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1), 0 8px 10px 1px rgba(0, 0, 0, 0.07), 0 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.filter-btn-active {
  border-radius: 4px;
  background-color: #dfc3e6 !important;
}
.filter-btn-active .v-input__slot,
.filter-btn-active .v-field__overlay {
  background-color: #dfc3e6 !important;
}

.page-filters-wrapper {
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  position: sticky !important;
}

.page-filters-panel {
  &.vue-fixed-header--isFixed {
    background: #fff;
    margin-top: 0 !important;
    padding: 1rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  }
}
.list-reports-page {
  .truncate {
    width: 7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .back-btn:hover::before,
  .back-btn::before {
    opacity: 0 !important;
  }
}

.report-template {
  // padding: 0.5rem;
  margin-top: 0.5rem;
  .report-page-card {
    .card-data {
      padding: 1rem 0rem;
      min-height: 70rem;
    }
    .top-triangle {
      position: absolute;
      right: 0;
      .top-right-color-triangle {
        position: absolute;
        right: 0;
        width: 0;
        height: 0;
        z-index: 1;
        border-style: solid;
        border-width: 0 200px 200px 0;
        border-color: transparent #dfc6e6 transparent transparent;
      }
      .top-right-grey-triangle {
        position: absolute;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 400px 200px 0;
        border-color: transparent #f5f5f5 transparent transparent;
      }
    }
    .footer {
      width: 100%;
      max-width: 90%;
      padding: 1.5rem 0;
      margin: auto;
      display: flex;
      justify-content: space-between;

      .page-box {
        padding: 0.2rem;
        width: 7rem;
        margin: auto 0;
        border-radius: 0.2rem;
        background-color: #a994b1;
        .pageno {
          text-align: center;
          color: #fff;
        }
      }
      .footer-img {
        max-width: 10rem;
      }
    }
    .report-page-title {
      margin: 0 auto;
      text-align: center;
      font-size: 2.5rem;
      font-weight: 500;
    }
    .report-page-subtitle {
      margin: 0 auto;
      text-align: center;
      font-size: 2rem;
      font-weight: 300;
    }

    .main-page-feature {
      margin-left: 5rem;
      margin-top: 10rem;
      .v-image {
        width: 30rem;
      }
      .feature-title {
        font-size: 7rem;
        font-weight: 500;
        // color: #e2af06;
      }
      .feature-sub-title {
        font-size: 1.7rem;
        font-weight: 500;
        color: #e2af06;
      }
    }
    .main-page-content {
      margin: 7rem 0 0 5rem;
      .main-page-content-title {
        font-size: 1.5rem;
        font-weight: 600;
        padding-top: 3rem;
        padding-bottom: 1rem;
      }
      .main-page-text {
        // margin: 1.5rem auto 0 auto;
        // text-align: center;
        font-size: 1rem;
        padding-bottom: 4rem;
        max-width: 90%;
        text-align: justify;
      }
    }
    .section {
      max-width: 80%;
      width: 100%;
      margin: auto;
      margin-top: 3rem;
      // text-align: center;
      .section-title {
        margin: 0 auto;
        font-size: 1.4rem;
        font-weight: 600;
        padding-bottom: 1rem;
        display: flex;
        color: #000;
      }
      .section-content {
        font-size: 1rem;
        margin-bottom: 3rem;
        text-align: justify;
      }
    }
    .section-badge {
      height: 30px;
      width: 7px;
      margin-right: 1rem;
      background-color: #796581;
    }
    .report-chart {
      margin: 0 auto;
      width: 100%;
      padding: 2rem 0rem;
      background: #dac5e221;
    }
    .chart-title {
      font-weight: 600;
      font-size: 1.2rem;
      text-align: left;
      padding: 2rem 0 1.7rem 2rem;
    }
    .chartWrapper {
      height: auto;
      width: 100%;
      max-width: 70rem;
      border-radius: 0.2rem;
      background: #fff;
      margin: 0 auto;
    }
    .text-blue {
      color: blue;
    }
  }
}
.provider-btn {
  position: relative !important;
  display: flex !important;
  padding: 0 !important;
  width: 95% !important;
  margin: 0 !important;
  flex: 1 1 auto;
  justify-content: flex-start;
  text-transform: none;
  padding-left: 6px !important;
  font-size: 16px !important;
  .v-btn__content {
    max-width: 90% !important;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left !important;
  }
}
</style>
